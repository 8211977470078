import React, { useContext } from "react";
import "./GeoChart.scss";
import Chart from "react-google-charts";
import SelectedMarkerBeach from "../../assets/selected_marker_beach.svg";
import UnSelectedMarkerBeach from "../../assets/unselected_marker_beach.svg";
import SelectedMarkerFreshwater from "../../assets/selected_marker_freshwater.svg";
import UnSelectedMarkerFreshwater from "../../assets/unselected_marker_freshwater.svg";
import SelectedMarkerStormwater from "../../assets/selected_marker_stormwater.svg";
import UnSelectedMarkerStormwater from "../../assets/unselected_marker_stormwater.svg";
import { RootStoreContext } from "../../store/rootStore";

export interface GeoChartData {
  latitude: number;
  longitude: number;
  name: string;
  numberOfSurveys: number;
}

type GeoChartProps = {
  locationList: GeoChartData[];
};

export const GeoChart: React.FC<GeoChartProps> = ({ locationList }) => {
  const rootStore = useContext(RootStoreContext);

  const { isStormwaterSelected, isFreshwaterSelected } = rootStore.commonStore;
  let markerSelected = SelectedMarkerBeach;
  let markerUnselected = UnSelectedMarkerBeach;
  if (isStormwaterSelected) {
    markerSelected = SelectedMarkerStormwater;
    markerUnselected = UnSelectedMarkerStormwater;
  } else if (isFreshwaterSelected) {
    markerSelected = SelectedMarkerFreshwater;
    markerUnselected = UnSelectedMarkerFreshwater;
  }
  return (
    <div>
      <div style={{ display: "none" }}>
        <Chart
          chartType="LineChart"
          data={[
            ["Test", "WorkAround"],
            [4, 5.5],
            [8, 12],
          ]}
          width="100%"
          height="400px"
          legendToggle
        />
      </div>
      <div className="google_maps_container">
        <Chart
          width={"100%"}
          height={"100%"}
          mapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}
          loader={<div>Loading Chart</div>}
          chartPackages={["map"]}
          chartType="Map"
          data={[
            ["Lat", "Long", "Name"],
            ...locationList.map((item) => [
              item.latitude,
              item.longitude,
              `<div style="max-width: 200px"><div style="font-family: 'TTNormsRegular'; font-size: 14px; margin: 0px 20px 10px 0px;">Monitoring Site: ${item.name} </div> <div style="margin: 10px 20px 10px 0px; font-family: 'TTNormsRegular'; font-size: 14px">Number of Surveys: ${item.numberOfSurveys}</div></div>`, //Just add HTML as a string here for styling
            ]),
          ]}
          options={{
            showTooltip: true,
            showInfoWindow: true,
            mapType: "satellite",
            icons: {
              default: {
                normal: markerUnselected,
                selected: markerSelected,
              },
            },
          }}
          rootProps={{ "data-id": "1" }}
        />
      </div>
    </div>
  );
};
