import { IFilter, LoadingStatus } from "../util";
import _ from 'lodash';
import { toJS } from "mobx";
import { ICountry, IMaterials, IProducts, IRegion, ISurveyAreas } from "../constants";


export interface FilterApiData {
    countries: ICountry[],
    regions: IRegion[],
    surveyAreas: ISurveyAreas[],
    materials: IMaterials[],
    products: IProducts[]
}

export function isSameFilter(oldFilter: IFilter, newFilter: IFilter) {

    let oldFilterProps = oldFilter === null || oldFilter === undefined ? [] : Object.getOwnPropertyNames(oldFilter);
    let newFilterProps = newFilter === null || newFilter === undefined ? [] : Object.getOwnPropertyNames(newFilter);

    if(oldFilterProps.length !== newFilterProps.length) {
        return false
    }
    return _.isEqual(oldFilter, newFilter);
}


export function shouldNotLoadData(loadingStatus: LoadingStatus, oldFilter: IFilter, newFilter: IFilter) {
    return loadingStatus === LoadingStatus.Success && isSameFilter(toJS(oldFilter), toJS(newFilter))
}





