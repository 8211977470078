import React from "react";
import "./CustomLegend.scss";
import { TreemapLegend } from "./TreemapUtils";
import { sortArray } from "../Table/TableUtil";

type CustomLegendProps = {
  items: TreemapLegend[];
  isComparison?: boolean;
};

export const CustomLegend: React.FC<CustomLegendProps> = ({ items, isComparison }) => {
  return (
    <div className={isComparison ? "treemap_legend_container__comparison" : "treemap_legend_container"}>
      {sortArray(items, "legendPercent", "desc").map((item: TreemapLegend) => (
        <div key={item.legendName} className="legend_item_container">
          <div className="item">
            <div
              className="color"
              style={{
                backgroundColor: `rgb(${item.legendColor.r}, ${item.legendColor.g}, ${item.legendColor.b})`,
              }}
            ></div>
            <p className="name">{item.legendName}</p>
          </div>

          <p className="percent">{item.legendPercent}%</p>
        </div>
      ))}
    </div>
  );
};
