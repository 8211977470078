export interface MultiSelectOption {
  label: string;
  value: string;
  isSelected: boolean;
}

export interface MultiSelectOptionSet {
  label: string;
  value: string;
  isSelected: boolean;
  set: number;
}

export function optionReducer(
  state: MultiSelectOptionSet[],
  action: { type: string; option: MultiSelectOptionSet | MultiSelectOptionSet[] }
): MultiSelectOptionSet[] {
  if (action.type.toLowerCase() === "toggle") {
    //Toggle the option selected in the state
    return state.map((el) =>
      el.value === (action.option as MultiSelectOptionSet).value &&
      el.label === (action.option as MultiSelectOptionSet).label &&
      el.set === (action.option as MultiSelectOptionSet).set
        ? { ...el, isSelected: !el.isSelected }
        : el
    ); //Just toggling the state
  } else if (action.type.toLowerCase() === "set") {
    return (action.option as MultiSelectOptionSet[]);
  }
  throw new Error("This action type is not supported");
}

export function getCustomSelectTitle(
  defaultTitle: string,
  options: MultiSelectOptionSet[]
) {
  const selectedLabels = options
    .filter((item) => item.isSelected)
    .map((item) => item.label);

  if (Array.isArray(selectedLabels) && selectedLabels.length > 0) {
    return selectedLabels.join(", ");
  }
  return defaultTitle;
}
