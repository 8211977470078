import React from "react";
import "./CustomTooltip.scss";
import ReactGA from 'react-ga';
import { isWeightGreaterThanOneKg } from "../TreemapChart/TreemapUtils";

export type CustomTooltipProps = {
  top: number;
  left: number;
  date: string;
  noOfSurveys: number;
  totalItemCount?: number;
  totalWeightCount?: number;
};

export const CustomTooltip: React.FC<CustomTooltipProps> = ({
  top,
  left,
  date,
  noOfSurveys,
  totalItemCount,
  totalWeightCount
}) => {

  return (
    <div
      className="custom_tooltip_container"
      style={{
        position: "fixed",
        top: top,
        left: left,
      }}
    >
      <p className="date_header">{date}</p>
      <div className="details_div" style={{ marginTop: "16px" }}>
        <p className="description">Avg. Litter Density</p>
        <p className="value">
          {totalItemCount !== null && totalItemCount !== undefined
            ? totalItemCount
            : isWeightGreaterThanOneKg(totalWeightCount) ? `${(totalWeightCount / 1000).toFixed(2)} kg` : `${totalWeightCount.toFixed(0)} g`}
        </p>
      </div>
      <div className="details_div">
        <p className="description">Surveys</p>
        <p className="value">{noOfSurveys}</p>
      </div>
      <a
        target="_blank"
        href="https://litterintelligence.org/data/"
        rel="noopener noreferrer"
        className="view_all_btn"
        onClick={() => {
          ReactGA.event({
            category: 'View All Surveys (Tooltip)',
            action: 'Click Event'
          })
        }}
      >
        View All
      </a>
    </div>
  );
};

