import React from "react";

import { Sidebar } from "./components/Sidebar/Sidebar";
import "./App.scss";
import { MainContent } from "./components/MainContent/MainContent";
import { Header } from "./components/Header/Header";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga";

const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID
}

TagManager.initialize(tagManagerArgs);
ReactGA.initialize(process.env.REACT_APP_GA_ID);

function App() {
  return (
    <div className="App">
      <Header />
      <div className="master_content">
        <Sidebar />
        <MainContent />
      </div>
    </div>
  );
}

export default App;
