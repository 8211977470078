export const DEFAULT_PAGE_SIZE = 10;

// eslint-disable-next-block
export function chunkArray(data: any[], chunkSize = DEFAULT_PAGE_SIZE) {
  const results: any[] = [];
  if (chunkSize >= data.length) {
    return [data];
  }
  const numberOfChildArrays = data.length / chunkSize;
  let counter = 0;
  while (counter < numberOfChildArrays) {
    results.push(
      // eslint-disable-next-line 
      data.filter((item, arrayIndex) => {
        if (counter === 0) {
          return arrayIndex >= 0 && arrayIndex < chunkSize;
        } else {
          return (
            arrayIndex >= counter * chunkSize &&
            arrayIndex < counter * chunkSize + chunkSize
          );
        }
      })
    );
    counter++;
  }
  return results;
}

export function isNumber(value: any) {
  return typeof value === "number";
}

export function isValidString(value: any) {
  return typeof value === "string" && value.length > 0
}

function getSortByPropertyValue(value: any) {
  if(typeof value === 'string' && value.endsWith("%")) {
    return parseFloat(value)
  }
  if(typeof value === 'string' && value.endsWith("kg")) {
    return parseFloat(value) * 1000
  }
  if(typeof value === 'string' && value.endsWith(" g")) {
    return parseFloat(value)
  }
  return value
}

export function sortArray(
  array: any[],
  sortByProperty: string,
  sortOrder: string
): any[] {
  return array.sort((a, b) => {
    const propA = getSortByPropertyValue(a[sortByProperty]);
    const propB = getSortByPropertyValue(b[sortByProperty]);

    let comparison = 0;
    if (propA > propB) {
      comparison = 1;
    } else if (propA < propB) {
      comparison = -1;
    }
    return sortOrder === "asc" ? comparison : comparison * -1;
  });
}
