import React, { useContext, useEffect } from "react";
import "./MaterialsAndProducts.scss";
import { PageTitle } from "../components/PageTitle/PageTitle";
import { PageSubTitle } from "../components/PageSubTitle/PageSubTitle";
import { LitterDensityPerTypeChart } from "../components/LitterDensityPerTypeChart/LitterDensityPerTypeChart";
import {
  ComparisonCard,
  DataSet,
} from "../components/ComparisonCard/ComparisonCard";
import { RootStoreContext } from "../store/rootStore";
import { observer } from "mobx-react-lite";
import { DataSetView } from "../components/DataSetView/DataSetView";
import { LoadingStatus } from "../store/util";
import ReactGA from "react-ga";
import { SummaryCard } from "../components/SummaryCard/SummaryCard";
import { LitterTypeTable } from "../components/LitterTypeTable/LitterTypeTable";

export const MaterialsAndProducts: React.FC<{}> = observer(() => {
  const SummaryCardWithCompCard = ComparisonCard(SummaryCard);
  const LitterDensityPerTypeWithCompCard = ComparisonCard(
    LitterDensityPerTypeChart
  );
  const LitterTypeTableWithCompCard = ComparisonCard(LitterTypeTable);
  const DataSetViewWithCompCard = ComparisonCard(DataSetView);

  const rootStore = useContext(RootStoreContext);

  const {
    handleFilterChanges,
    defaultLitterTypeTableData,
    defaultLitterDensityPerTypeData,
    isComparisonApplied,
    comparisonSetTwoLitterTypeTableData,
    comparisonSetOneLitterTypeTableData,
    comparisonSetOneLitterDensityPerTypeData,
    comparisonSetTwoLitterDensityPerTypeData,
    defaultTotalItemsAndWeightUIData,
    setOneTotalItemsAndWeightUIData,
    setTwoTotalItemsAndWeightUIData,
  } = rootStore.materialAndProductStore;

  const {
    dataSetViewSetOneData,
    dataSetViewSetTwoData,
    filtersApplied,
    isDateFilterAppliedComparisonDataSet,
    isLocationFilterAppliedComparisonDataSet,
    isLitterTypeFilterAppliedComparisonDataSet,
    isLocationTypeFilterAppliedComparisonDataSet,
  } = rootStore.filterStore;

  useEffect(() => {
    ReactGA.pageview("/materialsAndProducts");
    handleFilterChanges(filtersApplied);
    // eslint-disable-next-line
  }, []);

  function onTakeActionClicked(event: any) {
    event.preventDefault();
    //Recording Click Event
    ReactGA.event({
      category: "Take Action",
      action: "Click Event",
    });
    const takeActionURL = "https://litterintelligence.org/action/";
    window.open(takeActionURL, "_blank");
  }

  return isComparisonApplied ? (
    <div>
      <div className="page_title_container">
        <PageTitle title="Materials & Products" />
      </div>
      <div className="page_sub_title_container">
        <PageSubTitle subTitle="Data Set" />
      </div>
      <div className="data_set_view_container__comparison">
        <DataSetViewWithCompCard
          loadingStatus={LoadingStatus.Success}
          viewTitle="SET 1"
          locationType={{
            value: dataSetViewSetOneData.locationTypeFilterTitle,
            isFilterApplied: isLocationTypeFilterAppliedComparisonDataSet,
          }}
          location={{
            value: dataSetViewSetOneData.locationFilterTitle,
            isFilterApplied: isLocationFilterAppliedComparisonDataSet,
          }}
          litterType={{
            value: dataSetViewSetOneData.litterTypeFilterTitle,
            isFilterApplied: isLitterTypeFilterAppliedComparisonDataSet,
          }}
          timeRange={{
            value: dataSetViewSetOneData.dateFilterTitle,
            isFilterApplied: isDateFilterAppliedComparisonDataSet,
          }}
          dataSet={DataSet.SET_ONE}
        />
        <DataSetViewWithCompCard
          loadingStatus={LoadingStatus.Success}
          viewTitle="SET 2"
          locationType={{
            value: dataSetViewSetTwoData.locationTypeFilterTitle,
            isFilterApplied: isLocationTypeFilterAppliedComparisonDataSet,
          }}
          location={{
            value: dataSetViewSetTwoData.locationFilterTitle,
            isFilterApplied: isLocationFilterAppliedComparisonDataSet,
          }}
          litterType={{
            value: dataSetViewSetTwoData.litterTypeFilterTitle,
            isFilterApplied: isLitterTypeFilterAppliedComparisonDataSet,
          }}
          timeRange={{
            value: dataSetViewSetTwoData.dateFilterTitle,
            isFilterApplied: isDateFilterAppliedComparisonDataSet,
          }}
          dataSet={DataSet.SET_TWO}
        />
      </div>
      <div className="page_sub_title_container">
        <PageSubTitle subTitle="Total" />
      </div>
      <div className="total_summary_card_container__comparison">
        <SummaryCardWithCompCard
          dataSet={DataSet.SET_ONE}
          loadingStatus={setOneTotalItemsAndWeightUIData.loadingStatus}
          firstSectionTitle="TOTAL ITEMS"
          secondSectionTitle="TOTAL WEIGHT (KG)"
          firstSectionValue={setOneTotalItemsAndWeightUIData.totalItems}
          secondSectionValue={setOneTotalItemsAndWeightUIData.totalWeight}
        />
        <SummaryCardWithCompCard
          dataSet={DataSet.SET_TWO}
          loadingStatus={setTwoTotalItemsAndWeightUIData.loadingStatus}
          firstSectionTitle="TOTAL ITEMS"
          secondSectionTitle="TOTAL WEIGHT (KG)"
          firstSectionValue={setTwoTotalItemsAndWeightUIData.totalItems}
          secondSectionValue={setTwoTotalItemsAndWeightUIData.totalWeight}
        />
      </div>
      <div className="page_sub_title_container">
        <PageSubTitle
          subTitle="Total Litter By Type"
          helperText="Litter Type is the different Material groups (Plastic, Metal, etc) as well as the secondary subgroups within each Material, which are the individual items collected during a survey (Straws, Foil Wrap, etc)"
        />
      </div>
      <div className="litter_density_per_type_chart_container__comparison">
        <LitterDensityPerTypeWithCompCard
          itemChartData={comparisonSetOneLitterDensityPerTypeData.itemsList}
          weightChartData={comparisonSetOneLitterDensityPerTypeData.weightList}
          dataSet={DataSet.SET_ONE}
          loadingStatus={comparisonSetOneLitterDensityPerTypeData.loadingStatus}
          isComparison={true}
        />
        <LitterDensityPerTypeWithCompCard
          itemChartData={comparisonSetTwoLitterDensityPerTypeData.itemsList}
          weightChartData={comparisonSetTwoLitterDensityPerTypeData.weightList}
          dataSet={DataSet.SET_TWO}
          loadingStatus={comparisonSetTwoLitterDensityPerTypeData.loadingStatus}
          isComparison={true}
        />
      </div>
      <div className="litter_type_subtitle_container">
        <PageSubTitle subTitle="Litter Type" />
        <button className="take_action_btn" onClick={onTakeActionClicked}>
          Take Action
        </button>
      </div>
      <div className="litter_type_table_container__comparison">
        <LitterTypeTableWithCompCard
          itemTableData={comparisonSetOneLitterTypeTableData.itemData}
          weightTableData={comparisonSetOneLitterTypeTableData.weightData}
          dataSet={DataSet.SET_ONE}
          loadingStatus={comparisonSetOneLitterTypeTableData.loadingStatus}
        />
        <LitterTypeTableWithCompCard
          itemTableData={comparisonSetTwoLitterTypeTableData.itemData}
          weightTableData={comparisonSetTwoLitterTypeTableData.weightData}
          dataSet={DataSet.SET_TWO}
          loadingStatus={comparisonSetTwoLitterTypeTableData.loadingStatus}
        />
      </div>
    </div>
  ) : (
    <div>
      <div className="page_title_container">
        <PageTitle title="Materials & Products" />
      </div>
      <div className="page_sub_title_container">
        <PageSubTitle subTitle="Total" />
      </div>
      <div className="total_summary_card_container">
        <SummaryCardWithCompCard
          dataSet={DataSet.DEFAULT}
          loadingStatus={defaultTotalItemsAndWeightUIData.loadingStatus}
          firstSectionTitle="TOTAL ITEMS"
          secondSectionTitle="TOTAL WEIGHT (KG)"
          firstSectionValue={defaultTotalItemsAndWeightUIData.totalItems}
          secondSectionValue={defaultTotalItemsAndWeightUIData.totalWeight}
        />
      </div>
      <div className="page_sub_title_container">
        <PageSubTitle
          subTitle="Total Litter By Type"
          helperText="Litter Type is the different Material groups (Plastic, Metal, etc) as well as the secondary subgroups within each Material, which are the individual items collected during a survey (Straws, Foil Wrap, etc)"
        />
      </div>
      <div className="litter_density_per_type_chart_container">
        <LitterDensityPerTypeWithCompCard
          itemChartData={defaultLitterDensityPerTypeData.itemsList}
          weightChartData={defaultLitterDensityPerTypeData.weightList}
          dataSet={DataSet.DEFAULT}
          loadingStatus={defaultLitterDensityPerTypeData.loadingStatus}
        />
      </div>
      <div className="litter_type_subtitle_container">
        <PageSubTitle subTitle="Litter Type" />
        <button className="take_action_btn" onClick={onTakeActionClicked}>
          Take Action
        </button>
      </div>
      <div className="litter_type_table_container">
        <LitterTypeTableWithCompCard
          itemTableData={defaultLitterTypeTableData.itemData}
          weightTableData={defaultLitterTypeTableData.weightData}
          dataSet={DataSet.DEFAULT}
          loadingStatus={defaultLitterTypeTableData.loadingStatus}
        />
      </div>
    </div>
  );
});
