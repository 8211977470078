import { RootStore } from "../rootStore";
import {
  observable,
  action,
  runInAction,
  computed,
  toJS,
} from "mobx";
import { LoadingStatus, isValidArray, IFilter } from "../util";
import {
  OverviewData,
  SurveyLocationUIData,
  isValidObject,
  convertSurveyStatsObjToDict,
  ItemsBarChartUIData,
  TotalItemAndWeightUI,
  roundToTwo,
  StoreData,
} from "./overviewUtil";
import { getOverviewData } from "../api/agent";
import { DataSet } from "../../components/ComparisonCard/ComparisonCard";
import { convertToApiFilterData } from "../api/networkUtil";
import { shouldNotLoadData } from "../filter/filterUtil";
import { sortArray } from "../../components/Table/TableUtil";

export default class OverviewStore {
  rootStore: RootStore;

  @observable defaultData: StoreData = {
    data: null,
    filtersApplied: null
  };

  @observable dataSetOne: StoreData = {
    data: null,
    filtersApplied: null
  };

  @observable dataSetTwo: StoreData = {
    data: null,
    filtersApplied: null
  };

  @observable loadingStatus: LoadingStatus = LoadingStatus.Loading;
  @observable loadingStatusDataSetOne: LoadingStatus = LoadingStatus.Loading;
  @observable loadingStatusDataSetTwo: LoadingStatus = LoadingStatus.Loading;

  @observable isComparisonApplied = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action handleFilterChanges = (filtersApplied: IFilter) => {
    if (isValidObject(filtersApplied)) {
      if (isValidObject(filtersApplied.comparisonData)) {
        //that means there is a comparison present.
        this.isComparisonApplied = true;
        this.loadData(DataSet.SET_ONE, filtersApplied);
        this.loadData(DataSet.SET_TWO, filtersApplied);
      } else {
        this.isComparisonApplied = false;
        this.loadData(DataSet.DEFAULT, filtersApplied);
      }
    } else {
      this.isComparisonApplied = false;
      this.loadData(DataSet.DEFAULT, filtersApplied);
    }
  };

  @action loadData = async (dataSet: DataSet, filtersApplied: IFilter) => {
    switch (dataSet) {
      case DataSet.DEFAULT:
        if(shouldNotLoadData(this.loadingStatus, this.defaultData.filtersApplied, filtersApplied)) {
          return;
        }
        this.loadingStatus = LoadingStatus.Loading;
        try {
          const responseData: OverviewData = await getOverviewData(convertToApiFilterData(filtersApplied, DataSet.DEFAULT));
          runInAction(() => {
            this.defaultData = {
              data: responseData,
              filtersApplied: toJS(filtersApplied)
            }
            this.loadingStatus = LoadingStatus.Success;
          });
        } catch (error) {
          runInAction(() => {
            this.defaultData = {
              data: null,
              filtersApplied: null
            };
            this.loadingStatus = LoadingStatus.Failed;
          });
        }
        break;
      case DataSet.SET_ONE:
        if(shouldNotLoadData(this.loadingStatusDataSetOne, this.dataSetOne.filtersApplied, filtersApplied)) {
          return;
        }
        this.loadingStatusDataSetOne = LoadingStatus.Loading;
        try {
          const responseData: OverviewData = await getOverviewData(convertToApiFilterData(filtersApplied, DataSet.SET_ONE));
          runInAction(() => {
            this.dataSetOne = {
              data: responseData,
              filtersApplied: toJS(filtersApplied)
            };
            this.loadingStatusDataSetOne = LoadingStatus.Success;
          });
        } catch (error) {
          runInAction(() => {
            this.dataSetOne = {
              data: null,
              filtersApplied: null
            };
            this.loadingStatusDataSetOne = LoadingStatus.Failed;
          });
        }
        break;
      case DataSet.SET_TWO:
        if(shouldNotLoadData(this.loadingStatusDataSetTwo, this.dataSetTwo.filtersApplied, filtersApplied)) {
          return;
        }
        this.loadingStatusDataSetTwo = LoadingStatus.Loading;
        try {
          const responseData: OverviewData = await getOverviewData(convertToApiFilterData(filtersApplied, DataSet.SET_TWO));
          runInAction(() => {
            this.dataSetTwo = {
              data: responseData,
              filtersApplied: toJS(filtersApplied)
            };
            this.loadingStatusDataSetTwo = LoadingStatus.Success;
          });
        } catch (error) {
          runInAction(() => {
            this.dataSetTwo = {
              data: null,
              filtersApplied: null
            };
            this.loadingStatusDataSetTwo = LoadingStatus.Failed;
          });
        }
        break;
      default:
        if(shouldNotLoadData(this.loadingStatus, this.defaultData.filtersApplied, filtersApplied)) {
          return;
        }
        this.loadingStatus = LoadingStatus.Loading;
        try {
          const responseData: OverviewData = await getOverviewData(convertToApiFilterData(filtersApplied, DataSet.DEFAULT));
          runInAction(() => {
            this.defaultData = {
              data: responseData,
              filtersApplied: toJS(filtersApplied)
            };
            this.loadingStatus = LoadingStatus.Success;
          });
        } catch (error) {
          runInAction(() => {
            this.defaultData = {
              data: null,
              filtersApplied: null
            };
            this.loadingStatus = LoadingStatus.Failed;
          });
        }
    }
  };

  @computed get defaultSurveyLocationsUIData(): SurveyLocationUIData {
    switch (this.loadingStatus) {
      case LoadingStatus.Loading:
        return {
          loadingStatus: LoadingStatus.Loading,
          geoData: null,
          infoGroup: null,
          litterDensityUIData: null,
          litterQuantityUIData: null
        };

      case LoadingStatus.Success:
        if (
          isValidObject(this.defaultData.data) &&
          isValidArray(this.defaultData.data.surveyLocations) &&
          isValidObject(this.defaultData.data.surveyStats) && 
          (isValidObject(this.defaultData.data.litterDensities) || isValidObject(this.defaultData.data.litterQuantities))
        ) {
          return {
            loadingStatus: LoadingStatus.Success,
            geoData: this.defaultData.data.surveyLocations.map((item) => ({
              latitude: item.latitude,
              longitude: item.longitude,
              name: item.monitoringSiteName,
              numberOfSurveys: item.numberOfSurveys,
            })),
            infoGroup: convertSurveyStatsObjToDict({
              ...this.defaultData.data.surveyStats
            }),
            litterDensityUIData: isValidObject(this.defaultData.data.litterDensities) ? {
              ...this.defaultData.data.litterDensities
            } : null,
            litterQuantityUIData: isValidObject(this.defaultData.data.litterQuantities) ? {
              ...this.defaultData.data.litterQuantities
            } : null,
          };
        } else {
          return {
            loadingStatus: LoadingStatus.Failed,
            geoData: null,
            infoGroup: null,
            litterDensityUIData: null,
            litterQuantityUIData: null
          };
        }

      case LoadingStatus.Failed:
        return {
          loadingStatus: LoadingStatus.Failed,
          geoData: null,
          infoGroup: null,
          litterDensityUIData: null,
          litterQuantityUIData: null
        };
      default:
        return {
          loadingStatus: LoadingStatus.Failed,
          geoData: null,
          infoGroup: null,
          litterDensityUIData: null, 
          litterQuantityUIData: null
        };
    }
  }

  @computed get setOneSurveyLocationsUIData(): SurveyLocationUIData {
    switch (this.loadingStatusDataSetOne) {
      case LoadingStatus.Loading:
        return {
          loadingStatus: LoadingStatus.Loading,
          geoData: null,
          infoGroup: null,
          litterDensityUIData: null,
          litterQuantityUIData: null
        };

      case LoadingStatus.Success:
        if (
          isValidObject(this.dataSetOne.data) &&
          isValidArray(this.dataSetOne.data.surveyLocations) &&
          isValidObject(this.dataSetOne.data.surveyStats) && 
          (isValidObject(this.dataSetOne.data.litterDensities) || isValidObject(this.dataSetOne.data.litterQuantities))
        ) {
          return {
            loadingStatus: LoadingStatus.Success,
            geoData: this.dataSetOne.data.surveyLocations.map((item) => ({
              latitude: item.latitude,
              longitude: item.longitude,
              name: item.monitoringSiteName,
              numberOfSurveys: item.numberOfSurveys,
            })),
            infoGroup: convertSurveyStatsObjToDict({
              ...this.dataSetOne.data.surveyStats
            }),
            litterDensityUIData: isValidObject(this.dataSetOne.data.litterDensities) ? {
              ...this.dataSetOne.data.litterDensities
            } : null,
            litterQuantityUIData: isValidObject(this.dataSetOne.data.litterQuantities) ? {
              ...this.dataSetOne.data.litterQuantities
            } : null,
          };
        } else {
          return {
            loadingStatus: LoadingStatus.Failed,
            geoData: null,
            infoGroup: null,
            litterDensityUIData: null,
            litterQuantityUIData: null
          };
        }

      case LoadingStatus.Failed:
        return {
          loadingStatus: LoadingStatus.Failed,
          geoData: null,
          infoGroup: null,
          litterDensityUIData: null,
          litterQuantityUIData: null
        };
      default:
        return {
          loadingStatus: LoadingStatus.Failed,
          geoData: null,
          infoGroup: null,
          litterDensityUIData: null,
          litterQuantityUIData: null
        };
    }
  }

  @computed get setTwoSurveyLocationsUIData(): SurveyLocationUIData {
    switch (this.loadingStatusDataSetTwo) {
      case LoadingStatus.Loading:
        return {
          loadingStatus: LoadingStatus.Loading,
          geoData: null,
          infoGroup: null,
          litterDensityUIData: null,
          litterQuantityUIData: null
        };

      case LoadingStatus.Success:
        if (
          isValidObject(this.dataSetTwo.data) &&
          isValidArray(this.dataSetTwo.data.surveyLocations) &&
          isValidObject(this.dataSetTwo.data.surveyStats) && 
          (isValidObject(this.dataSetTwo.data.litterDensities) || isValidObject(this.dataSetTwo.data.litterQuantities))
        ) {
          return {
            loadingStatus: LoadingStatus.Success,
            geoData: this.dataSetTwo.data.surveyLocations.map((item) => ({
              latitude: item.latitude,
              longitude: item.longitude,
              name: item.monitoringSiteName,
              numberOfSurveys: item.numberOfSurveys,
            })),
            infoGroup: convertSurveyStatsObjToDict({
              ...this.dataSetTwo.data.surveyStats
            }),
            litterDensityUIData: isValidObject(this.dataSetTwo.data.litterDensities) ? {
              ...this.dataSetTwo.data.litterDensities
            } : null,
            litterQuantityUIData: isValidObject(this.dataSetTwo.data.litterQuantities) ? {
              ...this.dataSetTwo.data.litterQuantities
            } : null,
          };
        } else {
          return {
            loadingStatus: LoadingStatus.Failed,
            geoData: null,
            infoGroup: null,
            litterDensityUIData: null,
            litterQuantityUIData: null
          };
        }

      case LoadingStatus.Failed:
        return {
          loadingStatus: LoadingStatus.Failed,
          geoData: null,
          infoGroup: null,
          litterDensityUIData: null,
          litterQuantityUIData: null
        };
      default:
        return {
          loadingStatus: LoadingStatus.Failed,
          geoData: null,
          infoGroup: null,
          litterDensityUIData: null,
          litterQuantityUIData: null
        };
    }
  }

  @computed get defaultItemsBarChartUIData(): ItemsBarChartUIData {
    switch (this.loadingStatus) {
      case LoadingStatus.Loading:
        return {
          loadingStatus: LoadingStatus.Loading,
          itemsList: null,
          weightList: null,
        };

      case LoadingStatus.Success:
        if (
          isValidObject(this.defaultData.data) &&
          isValidArray(this.defaultData.data.categoryItemsAndWeight)
        ) {
          const totalSumOfItems = this.defaultData.data.categoryItemsAndWeight
            .map((item) => item.sumOfItemCount)
            .reduce((a, b) => a + b, 0);
          const totalSumOfWeight = this.defaultData.data.categoryItemsAndWeight
            .map((item) => item.sumOfItemWeight)
            .reduce((a, b) => a + b, 0);
          return {
            loadingStatus: LoadingStatus.Success,
            itemsList: sortArray(this.defaultData.data.categoryItemsAndWeight.map((item) => ({
              name: item.categoryName,
              percent: roundToTwo(
                (item.sumOfItemCount / totalSumOfItems) * 100
              ),
              dataSet: DataSet.DEFAULT,
            })), "percent", "desc"),
            weightList: sortArray(this.defaultData.data.categoryItemsAndWeight.map((item) => ({
              name: item.categoryName,
              percent: roundToTwo(
                (item.sumOfItemWeight / totalSumOfWeight) * 100
              ),
              dataSet: DataSet.DEFAULT,
            })), "percent", "desc"),
          };
        } else {
          return {
            loadingStatus: LoadingStatus.Failed,
            itemsList: null,
            weightList: null,
          };
        }

      case LoadingStatus.Failed:
        return {
          loadingStatus: LoadingStatus.Failed,
          itemsList: null,
          weightList: null,
        };

      default:
        return {
          loadingStatus: LoadingStatus.Failed,
          itemsList: null,
          weightList: null,
        };
    }
  }

  @computed get setOneItemsBarChartUIData(): ItemsBarChartUIData {
    switch (this.loadingStatusDataSetOne) {
      case LoadingStatus.Loading:
        return {
          loadingStatus: LoadingStatus.Loading,
          itemsList: null,
          weightList: null,
        };

      case LoadingStatus.Success:
        if (
          isValidObject(this.dataSetOne.data) &&
          isValidArray(this.dataSetOne.data.categoryItemsAndWeight)
        ) {
          const totalSumOfItems = this.dataSetOne.data.categoryItemsAndWeight
            .map((item) => item.sumOfItemCount)
            .reduce((a, b) => a + b, 0);
          const totalSumOfWeight = this.dataSetOne.data.categoryItemsAndWeight
            .map((item) => item.sumOfItemWeight)
            .reduce((a, b) => a + b, 0);
          return {
            loadingStatus: LoadingStatus.Success,
            itemsList: sortArray(this.dataSetOne.data.categoryItemsAndWeight.map((item) => ({
              name: item.categoryName,
              percent: roundToTwo(
                (item.sumOfItemCount / totalSumOfItems) * 100
              ),
              dataSet: DataSet.SET_ONE,
            })), "percent", "desc"),
            weightList: sortArray(this.dataSetOne.data.categoryItemsAndWeight.map((item) => ({
              name: item.categoryName,
              percent: roundToTwo(
                (item.sumOfItemWeight / totalSumOfWeight) * 100
              ),
              dataSet: DataSet.SET_ONE,
            })), "percent", "desc"),
          };
        } else {
          return {
            loadingStatus: LoadingStatus.Failed,
            itemsList: null,
            weightList: null,
          };
        }

      case LoadingStatus.Failed:
        return {
          loadingStatus: LoadingStatus.Failed,
          itemsList: null,
          weightList: null,
        };

      default:
        return {
          loadingStatus: LoadingStatus.Failed,
          itemsList: null,
          weightList: null,
        };
    }
  }

  @computed get setTwoItemsBarChartUIData(): ItemsBarChartUIData {
    switch (this.loadingStatusDataSetTwo) {
      case LoadingStatus.Loading:
        return {
          loadingStatus: LoadingStatus.Loading,
          itemsList: null,
          weightList: null,
        };

      case LoadingStatus.Success:
        if (
          isValidObject(this.dataSetTwo.data) &&
          isValidArray(this.dataSetTwo.data.categoryItemsAndWeight)
        ) {
          const totalSumOfItems = this.dataSetTwo.data.categoryItemsAndWeight
            .map((item) => item.sumOfItemCount)
            .reduce((a, b) => a + b, 0);
          const totalSumOfWeight = this.dataSetTwo.data.categoryItemsAndWeight
            .map((item) => item.sumOfItemWeight)
            .reduce((a, b) => a + b, 0);
          return {
            loadingStatus: LoadingStatus.Success,
            itemsList: sortArray(this.dataSetTwo.data.categoryItemsAndWeight.map((item) => ({
              name: item.categoryName,
              percent: roundToTwo(
                (item.sumOfItemCount / totalSumOfItems) * 100
              ),
              dataSet: DataSet.SET_TWO,
            })), "percent", "desc"),
            weightList: sortArray(this.dataSetTwo.data.categoryItemsAndWeight.map((item) => ({
              name: item.categoryName,
              percent: roundToTwo(
                (item.sumOfItemWeight / totalSumOfWeight) * 100
              ),
              dataSet: DataSet.SET_TWO,
            })), "percent", "desc"),
          };
        } else {
          return {
            loadingStatus: LoadingStatus.Failed,
            itemsList: null,
            weightList: null,
          };
        }

      case LoadingStatus.Failed:
        return {
          loadingStatus: LoadingStatus.Failed,
          itemsList: null,
          weightList: null,
        };

      default:
        return {
          loadingStatus: LoadingStatus.Failed,
          itemsList: null,
          weightList: null,
        };
    }
  }

  @computed get defaultTotalItemsAndWeightUIData(): TotalItemAndWeightUI {
    switch (this.loadingStatus) {
      case LoadingStatus.Loading:
        return {
          loadingStatus: LoadingStatus.Loading,
          totalWeight: null,
          totalItems: null,
        };

      case LoadingStatus.Success:
        if (
          isValidObject(this.defaultData.data) &&
          isValidArray(this.defaultData.data.categoryItemsAndWeight)
        ) {
          const totalSumOfItems = this.defaultData.data.categoryItemsAndWeight
            .map((item) => item.sumOfItemCount)
            .reduce((a, b) => a + b, 0);
          const totalSumOfWeight = this.defaultData.data.categoryItemsAndWeight
            .map((item) => item.sumOfItemWeight)
            .reduce((a, b) => a + b, 0);
          return {
            loadingStatus: LoadingStatus.Success,
            totalItems: totalSumOfItems,
            totalWeight: roundToTwo(totalSumOfWeight / 1000), //This is KG
          };
        } else {
          return {
            loadingStatus: LoadingStatus.Failed,
            totalWeight: null,
            totalItems: null,
          };
        }

      case LoadingStatus.Failed:
        return {
          loadingStatus: LoadingStatus.Failed,
          totalWeight: null,
          totalItems: null,
        };

      default:
        return {
          loadingStatus: LoadingStatus.Failed,
          totalWeight: null,
          totalItems: null,
        };
    }
  }

  @computed get setOneTotalItemsAndWeightUIData(): TotalItemAndWeightUI {
    switch (this.loadingStatusDataSetOne) {
      case LoadingStatus.Loading:
        return {
          loadingStatus: LoadingStatus.Loading,
          totalWeight: null,
          totalItems: null,
        };

      case LoadingStatus.Success:
        if (
          isValidObject(this.dataSetOne.data) &&
          isValidArray(this.dataSetOne.data.categoryItemsAndWeight)
        ) {
          const totalSumOfItems = this.dataSetOne.data.categoryItemsAndWeight
            .map((item) => item.sumOfItemCount)
            .reduce((a, b) => a + b, 0);
          const totalSumOfWeight = this.dataSetOne.data.categoryItemsAndWeight
            .map((item) => item.sumOfItemWeight)
            .reduce((a, b) => a + b, 0);
          return {
            loadingStatus: LoadingStatus.Success,
            totalItems: totalSumOfItems,
            totalWeight: roundToTwo(totalSumOfWeight / 1000), //This is KG
          };
        } else {
          return {
            loadingStatus: LoadingStatus.Failed,
            totalWeight: null,
            totalItems: null,
          };
        }

      case LoadingStatus.Failed:
        return {
          loadingStatus: LoadingStatus.Failed,
          totalWeight: null,
          totalItems: null,
        };

      default:
        return {
          loadingStatus: LoadingStatus.Failed,
          totalWeight: null,
          totalItems: null,
        };
    }
  }

  @computed get setTwoTotalItemsAndWeightUIData(): TotalItemAndWeightUI {
    switch (this.loadingStatusDataSetTwo) {
      case LoadingStatus.Loading:
        return {
          loadingStatus: LoadingStatus.Loading,
          totalWeight: null,
          totalItems: null,
        };

      case LoadingStatus.Success:
        if (
          isValidObject(this.dataSetTwo.data) &&
          isValidArray(this.dataSetTwo.data.categoryItemsAndWeight)
        ) {
          const totalSumOfItems = this.dataSetTwo.data.categoryItemsAndWeight
            .map((item) => item.sumOfItemCount)
            .reduce((a, b) => a + b, 0);
          const totalSumOfWeight = this.dataSetTwo.data.categoryItemsAndWeight
            .map((item) => item.sumOfItemWeight)
            .reduce((a, b) => a + b, 0);
          return {
            loadingStatus: LoadingStatus.Success,
            totalItems: totalSumOfItems,
            totalWeight: roundToTwo(totalSumOfWeight / 1000), //This is KG
          };
        } else {
          return {
            loadingStatus: LoadingStatus.Failed,
            totalWeight: null,
            totalItems: null,
          };
        }

      case LoadingStatus.Failed:
        return {
          loadingStatus: LoadingStatus.Failed,
          totalWeight: null,
          totalItems: null,
        };

      default:
        return {
          loadingStatus: LoadingStatus.Failed,
          totalWeight: null,
          totalItems: null,
        };
    }
  }
}
