import React, { useContext, useState } from "react";
import "./MainContent.scss";
import FilterIcon from "../../assets/filter_icon.svg";
import { RootStoreContext } from "../../store/rootStore";
import { observer } from "mobx-react-lite";
import { Tabs, TabList } from "../Tabs/Tabs";
import { Footer } from "../Footer/Footer";
import { ComparisonModal } from "../ComparisonModal/ComparisonModal";
import { Overview } from "../../Pages/Overview";
import { LitterDensity } from "../../Pages/LitterDensity";
import { MaterialsAndProducts } from "../../Pages/MaterialsAndProducts";
import { Location } from "../../Pages/Location";
import { SurveyEffort } from "../../Pages/SurveyEffort";
import { SideTabs } from "../SideTabs/SideTabs";
import { SideTabList } from "../../store/util";

export const MainContent = observer(() => {
  const rootContext = useContext(RootStoreContext);
  const [, setActiveEventKey] = useState(undefined);

  const { setSideBarOpen, isSideBarOpen, isComparisonModalOpen } =
    rootContext.commonStore;

  return (
    <main id="main_content_id">
      <div className="survey_type_main_view">
        <SideTabs tabList={SideTabList} setActiveEventKey={setActiveEventKey} />
      </div>
      <button className="filter_btn" onClick={() => setSideBarOpen(true)}>
        <p>Filters</p>
        <img src={FilterIcon} alt="Filter Icon" />
      </button>
      <section className="tab_container">
        <Tabs>
          <div
            className={`tab_title_container ${
              isSideBarOpen
                ? "tab_title_container__close"
                : "tab_title_container__open"
            }`}
          >
            {TabList.map((item: { label: string; value: string }) => (
              <Tabs.Tab label={item.label} key={item.label}></Tabs.Tab>
            ))}
          </div>
          <Tabs.Panel label="overview">
            <Overview />
          </Tabs.Panel>
          <Tabs.Panel label="litter_density">
            <LitterDensity />
          </Tabs.Panel>
          <Tabs.Panel label="materials_and_products">
            <MaterialsAndProducts />
          </Tabs.Panel>
          <Tabs.Panel label="location">
            <Location />
          </Tabs.Panel>
          <Tabs.Panel label="survey_effort">
            <SurveyEffort />
          </Tabs.Panel>
        </Tabs>
      </section>
      {isComparisonModalOpen ? <ComparisonModal /> : null}
      <Footer />
    </main>
  );
});
