import React, { useReducer, useContext } from "react";
import "./LocationTypeToggle.scss";
import { ApplyFilterSection } from "./ApplyFilterSection";
import {
  MultiSelectOptionSet,
  optionReducer,
} from "./CustomSelect/CustomSelectUtil";
import { isValidArray } from "../../store/util";
import { RootStoreContext } from "../../store/rootStore";
import ReactGA from 'react-ga';

export const LocationTypeToggle: React.FC<{
  locationTypes: MultiSelectOptionSet[];
}> = ({ locationTypes }) => {
  const [options, dispatchOption] = useReducer(optionReducer, locationTypes);
  const rootStore = useContext(RootStoreContext);

  const { applyLocationTypeFilter } = rootStore.filterStore;

  //On item selected
  function onItemSelected(option: MultiSelectOptionSet) {
    dispatchOption({ type: "toggle", option: option });
  }

  function onApplyFilterClick() {
    const selectedLocationTypes = options
      .filter((item) => item.isSelected)
      .map((item) => ({ value: item.value, label: item.label }));
      
    if (isValidArray(selectedLocationTypes)) {
      applyLocationTypeFilter(selectedLocationTypes);
    } else {
      applyLocationTypeFilter(null);
    }
    //logging events into google analytics
    ReactGA.event({
      category: 'Location Type Filter',
      action: 'Apply Filter'
    })
  }

  return (
    <div className="survey_toggle_container">
      {options.map((element: MultiSelectOptionSet) => (
        <div
          key={`${element.value}_${element.set}`}
          className="checkbox_container"
        >
          <input
            type="checkbox"
            id={`${element.value}_${element.set}`}
            checked={element.isSelected}
            onChange={(event) => onItemSelected(element)}
          />
          <label htmlFor={`${element.value}_${element.set}`}>
            {element.label}
          </label>
        </div>
      ))}
      <ApplyFilterSection onClick={onApplyFilterClick} />
    </div>
  );
};
