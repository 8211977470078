import React, { useState, useEffect, useContext } from "react";
import "./LitterDensityChart.scss";
import { MixedChartData, MixedChart } from "../MixedChart/MixedChart";
import { ToggleContent, CustomToggle } from "../CustomToggle/CustomToggle";
import { DataSet } from "../ComparisonCard/ComparisonCard";
import { LitterDensityChartFooter } from "./LitterDensityChartFooter";
import { LoadingStatus, isValidArray } from "../../store/util";
import { Loader } from "../Loader/Loader";
import ReactGA from 'react-ga';
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../store/rootStore";

type LitterDensityChartProps = {
  defaultItemChartData?: MixedChartData[];
  defaultWeightChartData?: MixedChartData[];
  setOneItemChartData?: MixedChartData[];
  setOneWeightChartData?: MixedChartData[];
  setTwoItemChartData?: MixedChartData[];
  setTwoWeightChartData?: MixedChartData[];
  loadingStatus: LoadingStatus;
};

export const LitterDensityChart: React.FC<LitterDensityChartProps> = observer(({
  defaultItemChartData,
  defaultWeightChartData,
  setOneItemChartData,
  setOneWeightChartData,
  setTwoItemChartData,
  setTwoWeightChartData,
  loadingStatus,
}) => {
  const rootContext = useContext(RootStoreContext);
  const { isStormwaterSelected } = rootContext.commonStore

  const [selectedToggle, setSelectedToggle] = useState(ToggleContent.ITEMS);

  function onToggleChanged(option: string) {
    if (selectedToggle !== option) {
      setSelectedToggle(
        option === ToggleContent.ITEMS
          ? ToggleContent.ITEMS
          : ToggleContent.WEIGHT
      );
    }
  }

  useEffect(() => {
    ReactGA.event({
      category: 'Litter Density Per Survey Chart',
      action: selectedToggle
    })
  }, [selectedToggle])

  switch (loadingStatus) {
    case LoadingStatus.Loading:
      return (
        <div className="comparison_card_container">
          <div className="comparison_card_content loading">
            <Loader
              dataSet={DataSet.DEFAULT}
              containerSize={150}
              contentSize={120}
              backgroundColor={{ r: 255, g: 255, b: 255 }}
            />
          </div>
        </div>
      );

    case LoadingStatus.Success:
      if (selectedToggle === ToggleContent.ITEMS) {
        if (
          isValidArray(setOneItemChartData) ||
          isValidArray(setTwoItemChartData)
        ) {
          return (
            <section className="litter_density_chart_section">
              <div className="litter_density_chart_header">
                <p>{selectedToggle === ToggleContent.ITEMS ? "ITEMS" : "GRAMS"} PER {isStormwaterSelected ? "CATCHPIT" : "1,000M2"}</p>
                <CustomToggle
                  firstOption="ITEMS"
                  secondOption="WEIGHT"
                  selectedToggle={selectedToggle}
                  onToggleChanged={onToggleChanged}
                />
              </div>
              <div className="horizontal_divider"></div>
              <div className="mixed_chart_container">
                <MixedChart
                  comparisonData={{
                    dataSetOne: setOneItemChartData,
                    dataSetTwo: setTwoItemChartData,
                  }}
                  toggleContent={selectedToggle}
                />
              </div>
              <LitterDensityChartFooter
                isComparison={
                  isValidArray(setOneItemChartData) ||
                  isValidArray(setOneWeightChartData) ||
                  isValidArray(setTwoItemChartData) ||
                  isValidArray(setTwoWeightChartData)
                }
              />
            </section>
          );
        } else {
          if (isValidArray(defaultItemChartData)) {
            return (
              <section className="litter_density_chart_section">
                <div className="litter_density_chart_header">
                  <p>{selectedToggle === ToggleContent.ITEMS ? "ITEMS" : "GRAMS"} PER {isStormwaterSelected ? "CATCHPIT" : "1,000M2"}</p>
                  <CustomToggle
                    firstOption="ITEMS"
                    secondOption="WEIGHT"
                    selectedToggle={selectedToggle}
                    onToggleChanged={onToggleChanged}
                  />
                </div>
                <div className="horizontal_divider"></div>
                <div className="mixed_chart_container">
                  <MixedChart
                    mixedChartData={defaultItemChartData}
                    toggleContent={selectedToggle}
                  />
                </div>
                <LitterDensityChartFooter
                  isComparison={
                    isValidArray(setOneItemChartData) ||
                    isValidArray(setOneWeightChartData) ||
                    isValidArray(setTwoItemChartData) ||
                    isValidArray(setTwoWeightChartData)
                  }
                />
              </section>
            );
          } else {
            //Failure
            return (
              <div className="comparison_card_container">
                <div className="comparison_card_content failed_no_data">
                  No data to show
                </div>
              </div>
            );
          }
        }
      } else {
        if (
          isValidArray(setOneWeightChartData) ||
          isValidArray(setTwoWeightChartData)
        ) {
          //there is some data
          return (
            <section className="litter_density_chart_section">
              <div className="litter_density_chart_header">
                <p>{selectedToggle.toUpperCase() === "ITEMS" ? "ITEMS" : "GRAMS"} PER {isStormwaterSelected ? "CATCHPIT" : "1,000M2"}</p>
                <CustomToggle
                  firstOption="ITEMS"
                  secondOption="WEIGHT"
                  selectedToggle={selectedToggle}
                  onToggleChanged={onToggleChanged}
                />
              </div>
              <div className="horizontal_divider"></div>
              <div className="mixed_chart_container">
                <MixedChart
                  comparisonData={{
                    dataSetOne: setOneWeightChartData,
                    dataSetTwo: setTwoWeightChartData,
                  }}
                  toggleContent={selectedToggle}
                />
              </div>
              <LitterDensityChartFooter
                isComparison={
                  isValidArray(setOneItemChartData) ||
                  isValidArray(setOneWeightChartData) ||
                  isValidArray(setTwoItemChartData) ||
                  isValidArray(setTwoWeightChartData)
                }
              />
            </section>
          );
        } else {
          if (isValidArray(defaultWeightChartData)) {
            return (
              <section className="litter_density_chart_section">
                <div className="litter_density_chart_header">
                  <p>{selectedToggle.toUpperCase() === "ITEMS" ? "ITEMS" : "GRAMS"} PER {isStormwaterSelected ? "CATCHPIT" : "1,000M2"}</p>
                  <CustomToggle
                    firstOption="ITEMS"
                    secondOption="WEIGHT"
                    selectedToggle={selectedToggle}
                    onToggleChanged={onToggleChanged}
                  />
                </div>
                <div className="horizontal_divider"></div>
                <div className="mixed_chart_container">
                  <MixedChart
                    mixedChartData={defaultWeightChartData}
                    toggleContent={selectedToggle}
                  />
                </div>
                <LitterDensityChartFooter
                  isComparison={
                    isValidArray(setOneItemChartData) ||
                    isValidArray(setOneWeightChartData) ||
                    isValidArray(setTwoItemChartData) ||
                    isValidArray(setTwoWeightChartData)
                  }
                />
              </section>
            );
          } else {
            //Failure
            return (
              <div className="comparison_card_container">
                <div className="comparison_card_content failed_no_data">
                  No data to show
                </div>
              </div>
            );
          }
        }
      }

    case LoadingStatus.Failed:
      return (
        <div className="comparison_card_container">
          <div className="comparison_card_content failed_no_data">
            No data to show
          </div>
        </div>
      );
  }
});
