import React, { useState, useEffect, useRef } from "react";
import "./CustomSelect.scss";
import DownArrow from "../../../assets/down_arrow.svg";
import UpArrow from "../../../assets/up_arrow.svg";
import { CustomSelectItem } from "./CustomSelectItem";
import { MultiSelectOptionSet, getCustomSelectTitle } from "./CustomSelectUtil";

type CustomSelectProps = {
  options: MultiSelectOptionSet[];
  onItemSelected: (option: MultiSelectOptionSet) => void;
  defaultTitle: string;
  isDisabled?: boolean;
  isModal?: boolean;
};


//Make this a dumb component
export const CustomSelect: React.FC<CustomSelectProps> = ({
  options,
  onItemSelected,
  defaultTitle,
  isDisabled,
  isModal,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef(null);
  useOnClickOutside(containerRef, () => setIsOpen(false));

  return (
    <div className={isDisabled ? "custom_select_disabled" : "custom_select"} ref={containerRef}>
      <button
        className={`${isOpen ? "title_container__open" : "title_container"} ${
          isModal ? "is_modal" : ""
        }`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <p>{getCustomSelectTitle(defaultTitle, options)}</p>
        <img src={isOpen ? UpArrow : DownArrow} alt="Arrow" />
      </button>
      <div
        className={`${
          isOpen ? "custom_select_container" : "custom_select_container__closed"
        } ${isModal ? "is_modal" : ""}`}
      >
        {options.map((item) => (
          <CustomSelectItem
            key={`${item.value}_${item.set}`}
            option={item}
            onSelected={onItemSelected}
          />
        ))}
      </div>
    </div>
  );
};

function useOnClickOutside(ref: any, handler: any) {
  useEffect(() => {
    const listener = (event: any) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      handler(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
}
