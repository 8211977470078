import React from "react";
import "./SummaryCard.scss";

type SummaryCardProps = {
  firstSectionTitle: string;
  secondSectionTitle: string;
  firstSectionValue: number;
  secondSectionValue: number;
  secondSectionDescription?: string;
};

export const SummaryCard: React.FC<SummaryCardProps> = ({
  firstSectionTitle,
  secondSectionTitle,
  firstSectionValue,
  secondSectionValue,
  secondSectionDescription,
}) => {
  return (
    <div className="summary_card_section">
      <div className="section_one">
        <p className="section_title">{firstSectionTitle}</p>
        <div className="section_content">
          <p className="section_value">{firstSectionValue.toLocaleString()}</p>
        </div>
      </div>
      <div className="vertical_divider"></div>
      <div className="section_two">
        <p className="section_title">{secondSectionTitle}</p>
        <div className="section_content">
          <p className="section_value">{secondSectionValue.toLocaleString()}</p>
          {secondSectionDescription !== null &&
          secondSectionDescription !== undefined ? (
            <p className="section_description">{secondSectionDescription}</p>
          ) : null}
        </div>
      </div>
    </div>
  );
};
