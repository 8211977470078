import React, { useContext, useEffect } from "react";
import "./SurveyEffort.scss";
import { SurveyEffortStats } from "../components/SurveyEffortStats/SurveyEffortStats";
import {
  DataSet,
  ComparisonCard,
} from "../components/ComparisonCard/ComparisonCard";
import { PageTitle } from "../components/PageTitle/PageTitle";
import { PageSubTitle } from "../components/PageSubTitle/PageSubTitle";
import { Table } from "../components/Table/Table";
import { SurveyStatsTableColumns } from "../store/constants";
import { RootStoreContext } from "../store/rootStore";
import { LoadingStatus } from "../store/util";
import { DataSetView } from "../components/DataSetView/DataSetView";
import { observer } from "mobx-react-lite";
import ReactGA from 'react-ga';

export const SurveyEffort: React.FC<{}> = observer(() => {
  const SurveyEffortWithCompCard = ComparisonCard(SurveyEffortStats);
  const TableWithCompCard = ComparisonCard(Table);
  const DataSetViewWithCompCard = ComparisonCard(DataSetView);

  const rootStore = useContext(RootStoreContext);

  const {
    handleFilterChanges,
    isComparisonApplied,
    setTwoTableData,
    setOneTableData,
    defaultTableData,
    defaultSurveysStatsInfoGroupUIData,
    setOneSurveysStatsInfoGroupUIData,
    setTwoSurveysStatsInfoGroupUIData,
  } = rootStore.surveyStatsStore;

  const {
    filtersApplied,
    dataSetViewSetOneData,
    dataSetViewSetTwoData,
    isDateFilterAppliedComparisonDataSet,
    isLocationFilterAppliedComparisonDataSet,
    isLitterTypeFilterAppliedComparisonDataSet,
    isLocationTypeFilterAppliedComparisonDataSet
  } = rootStore.filterStore;

  useEffect(() => {
    ReactGA.pageview('/surveyEffort');
    handleFilterChanges(filtersApplied);
    // eslint-disable-next-line 
  }, []);

  return isComparisonApplied ? (
    <div>
      <div className="page_title_container">
        <PageTitle title="Survey Effort" />
      </div>
      <div className="page_sub_title_container">
        <PageSubTitle subTitle="Data Set" />
      </div>
      <div className="data_set_view_container__comparison">
        <DataSetViewWithCompCard
          loadingStatus={LoadingStatus.Success}
          viewTitle="SET 1"
          locationType={{
            value: dataSetViewSetOneData.locationTypeFilterTitle,
            isFilterApplied: isLocationTypeFilterAppliedComparisonDataSet
          }}
          location={{
            value: dataSetViewSetOneData.locationFilterTitle,
            isFilterApplied: isLocationFilterAppliedComparisonDataSet
          }}
          litterType={{
            value: dataSetViewSetOneData.litterTypeFilterTitle,
            isFilterApplied: isLitterTypeFilterAppliedComparisonDataSet
          }}
          timeRange={{
            value: dataSetViewSetOneData.dateFilterTitle,
            isFilterApplied: isDateFilterAppliedComparisonDataSet
          }}
          dataSet={DataSet.SET_ONE}
        />
        <DataSetViewWithCompCard
          loadingStatus={LoadingStatus.Success}
          viewTitle="SET 2"
          locationType={{
            value: dataSetViewSetTwoData.locationTypeFilterTitle,
            isFilterApplied: isLocationTypeFilterAppliedComparisonDataSet
          }}
          location={{
            value: dataSetViewSetTwoData.locationFilterTitle,
            isFilterApplied: isLocationFilterAppliedComparisonDataSet
          }}
          litterType={{
            value: dataSetViewSetTwoData.litterTypeFilterTitle,
            isFilterApplied: isLitterTypeFilterAppliedComparisonDataSet
          }}
          timeRange={{
            value: dataSetViewSetTwoData.dateFilterTitle,
            isFilterApplied: isDateFilterAppliedComparisonDataSet
          }}
          dataSet={DataSet.SET_TWO}
        />
      </div>
      <div className="page_sub_title_container">
        <PageSubTitle subTitle="Stats" />
      </div>
      <div className="survey_effort_stats_container__comparison">
        <SurveyEffortWithCompCard
          infoData={setOneSurveysStatsInfoGroupUIData.data}
          daysFromLastSurvey={
            setOneSurveysStatsInfoGroupUIData.numberOfDaysSinceLastSurvey
          }
          dataSet={DataSet.SET_ONE}
          isComparison={true}
          loadingStatus={setOneSurveysStatsInfoGroupUIData.loadingStatus}
        />
        <SurveyEffortWithCompCard
          infoData={setTwoSurveysStatsInfoGroupUIData.data}
          daysFromLastSurvey={
            setTwoSurveysStatsInfoGroupUIData.numberOfDaysSinceLastSurvey
          }
          dataSet={DataSet.SET_TWO}
          isComparison={true}
          loadingStatus={setTwoSurveysStatsInfoGroupUIData.loadingStatus}
        />
      </div>
      <div className="page_sub_title_container">
        <PageSubTitle
          subTitle="Monitoring Group"
          helperText="Name of the community /school or other group leading the survey effort"
        />
      </div>
      <div className="monitoring_group_table_container__comparison">
        <TableWithCompCard
          data={setOneTableData.data}
          columns={SurveyStatsTableColumns}
          dataSet={DataSet.SET_ONE}
          loadingStatus={setOneTableData.loadingStatus}
        />
        <TableWithCompCard
          data={setTwoTableData.data}
          columns={SurveyStatsTableColumns}
          dataSet={DataSet.SET_TWO}
          loadingStatus={setTwoTableData.loadingStatus}
        />
      </div>
    </div>
  ) : (
    <div>
      <div className="page_title_container">
        <PageTitle title="Survey Effort" />
      </div>
      <div className="page_sub_title_container">
        <PageSubTitle subTitle="Stats" />
      </div>
      <div className="survey_effort_stats_container">
        <SurveyEffortWithCompCard
          infoData={defaultSurveysStatsInfoGroupUIData.data}
          daysFromLastSurvey={
            defaultSurveysStatsInfoGroupUIData.numberOfDaysSinceLastSurvey
          }
          dataSet={DataSet.DEFAULT}
          loadingStatus={defaultSurveysStatsInfoGroupUIData.loadingStatus}
        />
      </div>
      <div className="page_sub_title_container">
        <PageSubTitle
          subTitle="Monitoring Group"
          helperText="Name of the community /school or other group leading the survey effort"
        />
      </div>
      <div className="monitoring_group_table_container">
        <TableWithCompCard
          data={defaultTableData.data}
          columns={SurveyStatsTableColumns}
          dataSet={DataSet.DEFAULT}
          loadingStatus={defaultTableData.loadingStatus}
        />
      </div>
    </div>
  );
});
