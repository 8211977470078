/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import "./Header.scss";
import logo from "../../assets/litter_intelligence_logo.svg";

export const Header: React.FC<{}> = () => {

  const headerRef = useRef(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useOnClickOutside(headerRef, () => setIsOpen(false));

  return (
    <header ref={headerRef}>

      <div className="header-navbar">
        <img src={logo} alt="Logo" className="logo" />

        <button className="hamburger_button" onClick={() => setIsOpen(!isOpen)}>
          <span className={`${isOpen ? 'hamburger_icon__open' : 'hamburger_icon__closed'}`}>
            <span className="hamburger_icon__bar"></span>
          </span>
        </button>

        <div className={`${isOpen ? 'header_page_link_container__open' : 'header_page_link_container__closed'}`}>
          <a href="https://litterintelligence.org/" rel="noopener noreferrer">
            Home
          </a>
          <a
            href="https://litterintelligence.org/data/"
            rel="noopener noreferrer"
          >
            Data
          </a>
          <a
            rel="noopener noreferrer"
            style={{ cursor: "pointer" }}
          >
            Insights
          </a>
          <a
            href="https://litterintelligence.org/action/"
            rel="noopener noreferrer"
          >
            Action
          </a>
          <a
            href="https://litterintelligence.org/education/"
            rel="noopener noreferrer"
          >
            Education
          </a>
          <div className="dropdown">
            <a className="dropdown__trigger" href="https://litterintelligence.org/about/" rel="noopener noreferrer">About
              <svg className="dropdown__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                <path className="dropdown__icon" d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z" />
              </svg>
            </a>
            <input className="dropdown__trigger--mobile" type="checkbox" id="btnControl" />

            <label htmlFor="btnControl">About  <svg className="dropdown__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
              <path className="dropdown__icon" d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z" />
            </svg></label>
            <div className="dropdown__list">
              <a className="dropdown__item" href="https://litterintelligence.org/about/get-involved/">Get Involved</a>
              <a className="dropdown__item" href="https://litterintelligence.org/about/beach-monitoring/">Beach Monitoring</a>
              <a className="dropdown__item" href="https://litterintelligence.org/about/freshwater-monitoring/">Freshwater Monitoring</a>
              <a className="dropdown__item" href="https://litterintelligence.org/about/stormwater-monitoring/">Stormwater Monitoring</a>
              <a className="dropdown__item" href="https://litterintelligence.org/about/data-governance/">Data Governance</a>
              <a className="dropdown__item" href="https://litterintelligence.org/about/media-publications/">Media & Publications</a>
              <a className="dropdown__item" href="https://litterintelligence.org/about/faq/">FAQ</a>
              <a className="dropdown__item" href="https://litterintelligence.org/privacy-policy">Privacy Policy</a>
            </div>
          </div>
          <a
            className="header-navbar-login"
            href="https://app.litterintelligence.org"
            rel="noopener noreferrer"
          >
            Log In
          </a>

        </div>
      </div>
    </header>
  );
};


//Custom hook for listening to outside click events
function useOnClickOutside(ref: any, handler: any) {
  useEffect(() => {
    const listener = (event: any) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      handler(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
}
