import { Color } from "../TreemapChart/TreemapUtils";
import ZeroPercent from "../../assets/gauge/zero_percent.png";
import TenPercent from "../../assets/gauge/ten_percent.png";
import TwentyPercent from "../../assets/gauge/twenty_percent.png";
import ThirtyPercent from "../../assets/gauge/thirty_percent.png";
import FourtyPercent from "../../assets/gauge/fourty_percent.png";
import FiftyPercent from "../../assets/gauge/fifty_percent.png";
import SixtyPercent from "../../assets/gauge/sixty_percent.png";
import SeventyPercent from "../../assets/gauge/seventy_percent.png";
import EightyPercent from "../../assets/gauge/eighty_percent.png";
import NinetyPercent from "../../assets/gauge/ninety_percent.png";
import HundredPercent from "../../assets/gauge/hundred_percent.png";

export const MIN_SURVEY_FREQUENCY = 0;
export const MAX_SURVEY_FREQUENCY = 180;

const MIN_COLOR: Color = {
  r: 53,
  g: 216,
  b: 157,
};

const MAX_COLOR: Color = {
  r: 228,
  g: 0,
  b: 0,
};

export function getLeftPositionForDescription(value: number): number {
  switch (Math.floor(value).toString().length) {
    case 1:
      return 120;
    case 2:
      return 100;
    case 3:
      if(value < 200) {
        return 85;
      }
      return 72;
    default:
      return 60;
  }
}

function getPercentage(value: number) {
  return (
    ((value - MIN_SURVEY_FREQUENCY) /
      (MAX_SURVEY_FREQUENCY - MIN_SURVEY_FREQUENCY)) *
    100
  );
}

function roundToNearestTen(value: number) {
  return Math.round(value / 10) * 10;
}

export function getGaugeImage(value: number): string {
  const nearestPercentage = roundToNearestTen(getPercentage(value));

  if (nearestPercentage < 0) {
    return ZeroPercent;
  }

  switch (nearestPercentage) {
    case 0:
      return ZeroPercent;
    case 10:
      return TenPercent;
    case 20:
      return TwentyPercent;
    case 30:
      return ThirtyPercent;
    case 40:
      return FourtyPercent;
    case 50:
      return FiftyPercent;
    case 60:
      return SixtyPercent;
    case 70:
      return SeventyPercent;
    case 80:
      return EightyPercent;
    case 90:
      return NinetyPercent;
    case 100:
      return HundredPercent;
    default:
      return HundredPercent;
  }
}

export function getColorGradientFor(value: number): Color {
  const nearestPercentage = roundToNearestTen(getPercentage(value));

  if (nearestPercentage <= 0) {
    return MIN_COLOR;
  }

  if (nearestPercentage >= 100) {
    return MAX_COLOR;
  }

  switch (nearestPercentage) {
    case 0:
      return MIN_COLOR;
    case 10:
      return MIN_COLOR;
    case 20:
      return MIN_COLOR;
    case 30:
      return MIN_COLOR;
    case 40:
      return MIN_COLOR;
    case 50:
      return MIN_COLOR;
    case 60:
      return {
        r: 255,
        g: 145,
        b: 21
      };
    case 70:
      return MAX_COLOR;
    case 80:
      return MAX_COLOR;
    case 90:
      return MAX_COLOR;
    case 100:
      return MAX_COLOR;
    default:
      return MAX_COLOR;
  }
  
}
