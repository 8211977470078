import React from "react";
import "./ProgressBarFooter.scss";

export const ProgressBarFooter: React.FC<{}> = () => {
  return (
    <div>
      <div className="custom_progress_footer_section">
        <div className="progress progress_footer">
          <div
            className="progress-bar footer_progress"
            role="progressbar"
            style={{
              width: "100%",
            }}
          >
            0
          </div>
          <div
            className="progress-bar footer_progress"
            role="progressbar"
            style={{
              width: "100%",
            }}
          >
            25
          </div>
          <div
            className="progress-bar footer_progress"
            role="progressbar"
            style={{
              width: "100%",
            }}
          >
            50
          </div>
          <div
            className="progress-bar footer_progress"
            role="progressbar"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              flexWrap: "nowrap",
              alignItems: "center",
            }}
          >
            <div>75</div>
            <div>100</div>
          </div>
        </div>
        <p className="bar_label_footer">100%</p>
      </div>
      <p className="progress_footer_label">Percentage (%)</p>
    </div>
  );
};
