/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";
import "./SideTabs.scss";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../store/rootStore";
import { SideTabNameType, getSurveyTypeCode } from "../../store/util";

interface ISideTabProps {
  tabList: SideTabNameType[];
  setActiveEventKey: (value: undefined | string) => void;
}

export const SideTabs = observer(
  ({ tabList, setActiveEventKey }: ISideTabProps) => {
    const rootStore = useContext(RootStoreContext);
    const { setCurrentSideTab, currentSideTab } = rootStore.commonStore;

    const { resetFilters, loadFilterData } = rootStore.filterStore;

    const handleTabClick = (value: SideTabNameType) => {
      setCurrentSideTab(value);
      loadFilterData(getSurveyTypeCode(value));
      resetFilters();
      setActiveEventKey(undefined);
    };

    return (
      <div className="side-tabs-container">
        <div className="underline-grey"></div>

        {tabList.map((item: SideTabNameType, index: number) => (
          <div className="each-tab" key={index}>
            <a
              className={
                currentSideTab === item ? "selected-tab" : "unselected-tab"
              }
              onClick={() => handleTabClick(item)}
            >
              {item}
            </a>
            <div
              className={currentSideTab === item ? "underline" : "no_underline"}
            ></div>
          </div>
        ))}
      </div>
    );
  }
);
