import React, { useContext } from "react";
import "./LitterDensityChartFooter.scss";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../store/rootStore";


type LitterDensityChartFooterProps = {
  isComparison: boolean;
};

export const LitterDensityChartFooter: React.FC<LitterDensityChartFooterProps> = observer(({
  isComparison,
}) => {
  const rootStore = useContext(RootStoreContext);
  const { isStormwaterSelected } = rootStore.commonStore;
  
  function getLineChartLegendUI(name: string, color: string): JSX.Element {
    return (
      <div className="legend">
        <p className="legend_name">{name}</p>
        <div style={{ backgroundColor: color }} className="line"></div>
      </div>
    );
  }

  return (
    <div className="litter_density_chart_footer">
      {isComparison ? (
        <div className="legend_container">
            <p className="set_title">SET 1</p>
            {getLineChartLegendUI(`Average Litter ${isStormwaterSelected ? "Quantity" : "Density"} Per Month`, "#5E2B9D")}
            <p className="set_title">SET 2</p>
            {getLineChartLegendUI(`Average Litter ${isStormwaterSelected ? "Quantity" : "Density"} Per Month`, "#F05282")}
        </div>
      ) : (
        <div className="legend_container">
          {getLineChartLegendUI(`Average Litter ${isStormwaterSelected ? "Quantity" : "Density"} Per Month`, "#5E2B9D")}
        </div>
      )}
      {/* <div className="note_container">
        Note: The four-survey average is the average of the data from a survey
        and the previous three surveys
      </div> */}
    </div>
  );
});
