import React, { useContext, useEffect } from "react";
import "./Overview.scss";

import {
  ComparisonCard,
  DataSet,
} from "../components/ComparisonCard/ComparisonCard";
import { DataSetView } from "../components/DataSetView/DataSetView";
import { PageTitle } from "../components/PageTitle/PageTitle";
import { PageSubTitle } from "../components/PageSubTitle/PageSubTitle";
import { SummaryCard } from "../components/SummaryCard/SummaryCard";
import { ItemsBarChart } from "../components/ItemsBarChart/ItemsBarChart";
import { SurveyLocations } from "../components/SurveyLocations/SurveyLocations";
import { LoadingStatus } from "../store/util";
import { RootStoreContext } from "../store/rootStore";
import { observer } from "mobx-react-lite";
import ReactGA from 'react-ga';

export const Overview: React.FC<{}> = observer(() => {
  const DataSetViewWithCompCard = ComparisonCard(DataSetView);
  const SummaryCardWithCompCard = ComparisonCard(SummaryCard);
  const ItemsBarChartWithCompCard = ComparisonCard(ItemsBarChart);
  const SurveyLocationsWithCompCard = ComparisonCard(SurveyLocations);

  const rootStore = useContext(RootStoreContext);

  const {
    filtersApplied,
    locationTypeFilterTitle,
    locationFilterTitle,
    litterTypeFilterTitle,
    dateFilterTitle,
    dataSetViewSetOneData,
    dataSetViewSetTwoData,
    isDateFilterApplied,
    isLocationTypeFilterApplied,
    isLocationFilterApplied,
    isLitterTypeFilterApplied,
    isDateFilterAppliedComparisonDataSet,
    isLocationFilterAppliedComparisonDataSet,
    isLitterTypeFilterAppliedComparisonDataSet,
    isLocationTypeFilterAppliedComparisonDataSet
  } = rootStore.filterStore;

  const {
    isComparisonApplied,
    handleFilterChanges,
    defaultSurveyLocationsUIData,
    defaultItemsBarChartUIData,
    defaultTotalItemsAndWeightUIData,

    setOneSurveyLocationsUIData,
    setOneItemsBarChartUIData,
    setOneTotalItemsAndWeightUIData,

    setTwoSurveyLocationsUIData,
    setTwoItemsBarChartUIData,
    setTwoTotalItemsAndWeightUIData,
  } = rootStore.overviewStore;

  useEffect(() => {
    //Called when page is loaded
    handleFilterChanges(filtersApplied);
    ReactGA.pageview('/overview');
    // eslint-disable-next-line 
  }, []);

  return isComparisonApplied ? (
    <div>
      <div className="page_title_container">
        <PageTitle title="Overview" />
      </div>
      <div className="page_sub_title_container">
        <PageSubTitle subTitle="Data Set" />
      </div>
      <div className="data_set_view_container__comparison">
        <DataSetViewWithCompCard
          loadingStatus={LoadingStatus.Success}
          viewTitle="SET 1"
          locationType={{
            value: dataSetViewSetOneData.locationTypeFilterTitle,
            isFilterApplied: isLocationTypeFilterAppliedComparisonDataSet
          }}
          location={{
            value: dataSetViewSetOneData.locationFilterTitle,
            isFilterApplied: isLocationFilterAppliedComparisonDataSet
          }}
          litterType={{
            value: dataSetViewSetOneData.litterTypeFilterTitle,
            isFilterApplied: isLitterTypeFilterAppliedComparisonDataSet
          }}
          timeRange={{
            value: dataSetViewSetOneData.dateFilterTitle,
            isFilterApplied: isDateFilterAppliedComparisonDataSet
          }}
          dataSet={DataSet.SET_ONE}
        />
        <DataSetViewWithCompCard
          loadingStatus={LoadingStatus.Success}
          viewTitle="SET 2"
          locationType={{
            value: dataSetViewSetTwoData.locationTypeFilterTitle,
            isFilterApplied: isLocationTypeFilterAppliedComparisonDataSet
          }}
          location={{
            value: dataSetViewSetTwoData.locationFilterTitle,
            isFilterApplied: isLocationFilterAppliedComparisonDataSet
          }}
          litterType={{
            value: dataSetViewSetTwoData.litterTypeFilterTitle,
            isFilterApplied: isLitterTypeFilterAppliedComparisonDataSet
          }}
          timeRange={{
            value: dataSetViewSetTwoData.dateFilterTitle,
            isFilterApplied: isDateFilterAppliedComparisonDataSet
          }}
          dataSet={DataSet.SET_TWO}
        />
      </div>
      <div className="page_sub_title_container">
        <PageSubTitle
          subTitle="Survey Locations"
          helperText="The geographical location of a survey area"
        />
      </div>
      <div className="survey_locations_container__comparison">
        <SurveyLocationsWithCompCard
          loadingStatus={setOneSurveyLocationsUIData.loadingStatus}
          geoData={setOneSurveyLocationsUIData.geoData}
          infoGroup={setOneSurveyLocationsUIData.infoGroup}
          litterDensityData={setOneSurveyLocationsUIData.litterDensityUIData}
          litterQuantityData={setOneSurveyLocationsUIData.litterQuantityUIData}
          dataSet={DataSet.SET_ONE}
          isComparison={true}
        />

        <SurveyLocationsWithCompCard
          loadingStatus={setTwoSurveyLocationsUIData.loadingStatus}
          geoData={setTwoSurveyLocationsUIData.geoData}
          infoGroup={setTwoSurveyLocationsUIData.infoGroup}
          litterDensityData={setTwoSurveyLocationsUIData.litterDensityUIData}
          litterQuantityData={setTwoSurveyLocationsUIData.litterQuantityUIData}
          dataSet={DataSet.SET_TWO}
          isComparison={true}
        />
      </div>
      <div className="page_sub_title_container">
        <PageSubTitle
          subTitle="Materials & Products"
        />
      </div>
      <div className="products_and_materials_container__comparison">
        <SummaryCardWithCompCard
          dataSet={DataSet.SET_ONE}
          loadingStatus={setOneTotalItemsAndWeightUIData.loadingStatus}
          firstSectionTitle="TOTAL ITEM COUNT"
          secondSectionTitle="TOTAL WEIGHT (KG)"
          firstSectionValue={setOneTotalItemsAndWeightUIData.totalItems}
          secondSectionValue={setOneTotalItemsAndWeightUIData.totalWeight}
        />

        <SummaryCardWithCompCard
          dataSet={DataSet.SET_TWO}
          loadingStatus={setTwoTotalItemsAndWeightUIData.loadingStatus}
          firstSectionTitle="TOTAL ITEM COUNT"
          secondSectionTitle="TOTAL WEIGHT (KG)"
          firstSectionValue={setTwoTotalItemsAndWeightUIData.totalItems}
          secondSectionValue={setTwoTotalItemsAndWeightUIData.totalWeight}
        />
      </div>
      <div className="total_items_chart_container__comparison">
        <ItemsBarChartWithCompCard
          itemsList={setOneItemsBarChartUIData.itemsList}
          weightList={setOneItemsBarChartUIData.weightList}
          dataSet={DataSet.SET_ONE}
          loadingStatus={setOneItemsBarChartUIData.loadingStatus}
        />
        <ItemsBarChartWithCompCard
          itemsList={setTwoItemsBarChartUIData.itemsList}
          weightList={setTwoItemsBarChartUIData.weightList}
          dataSet={DataSet.SET_TWO}
          loadingStatus={setTwoItemsBarChartUIData.loadingStatus}
        />
      </div>
    </div>
  ) : (
    <div>
      <div className="page_title_container">
        <PageTitle title="Overview" />
      </div>
      <div className="page_sub_title_container">
        <PageSubTitle subTitle="Data Set" />
      </div>
      <div className="data_set_view_container">
        <DataSetViewWithCompCard
          loadingStatus={LoadingStatus.Success}
          viewTitle="SET 1"
          locationType={{
           value: locationTypeFilterTitle,
           isFilterApplied: isLocationTypeFilterApplied
          }}
          location={{
            value: locationFilterTitle,
            isFilterApplied: isLocationFilterApplied
          }}
          litterType={{
            value: litterTypeFilterTitle,
            isFilterApplied: isLitterTypeFilterApplied
          }}
          timeRange={{
            value: dateFilterTitle,
            isFilterApplied: isDateFilterApplied
          }}
          dataSet={DataSet.DEFAULT}
        />
      </div>
      <div className="page_sub_title_container">
        <PageSubTitle
          subTitle="Survey Locations"
          helperText="The geographical location of a survey area"
        />
      </div>
      <div className="survey_locations_container">
        <SurveyLocationsWithCompCard
          loadingStatus={defaultSurveyLocationsUIData.loadingStatus}
          geoData={defaultSurveyLocationsUIData.geoData}
          infoGroup={defaultSurveyLocationsUIData.infoGroup}
          litterDensityData={defaultSurveyLocationsUIData.litterDensityUIData}
          litterQuantityData={defaultSurveyLocationsUIData.litterQuantityUIData}
          dataSet={DataSet.DEFAULT}
        />
      </div>
      <div className="page_sub_title_container">
        <PageSubTitle
          subTitle="Materials & Products"
        />
      </div>
      <div className="products_and_materials_container">
        <SummaryCardWithCompCard
          dataSet={DataSet.DEFAULT}
          loadingStatus={defaultTotalItemsAndWeightUIData.loadingStatus}
          firstSectionTitle="TOTAL ITEM COUNT"
          secondSectionTitle="TOTAL WEIGHT (KG)"
          firstSectionValue={defaultTotalItemsAndWeightUIData.totalItems}
          secondSectionValue={defaultTotalItemsAndWeightUIData.totalWeight}
        />
      </div>
      <div className="total_items_chart_container">
        <ItemsBarChartWithCompCard
          itemsList={defaultItemsBarChartUIData.itemsList}
          weightList={defaultItemsBarChartUIData.weightList}
          dataSet={DataSet.DEFAULT}
          loadingStatus={defaultItemsBarChartUIData.loadingStatus}
        />
      </div>
    </div>
  );
});
