import React, { useReducer, useEffect, useContext } from "react";
import "./LocationToggle.scss";
import { CustomSelect } from "../CustomSelect/CustomSelect";
import { ApplyFilterSection } from "../ApplyFilterSection";
import {
  MultiSelectOptionSet,
  optionReducer,
} from "../CustomSelect/CustomSelectUtil";
import {
  ICountry,
  IRegion,
  ISurveyAreas,
} from "../../../store/constants";
import { isLocationSetSelected } from "./LocationToggleUtil";
import { RootStoreContext } from "../../../store/rootStore";
import ReactGA from 'react-ga';

type LocationToggleProps = {
  country: (MultiSelectOptionSet & ICountry)[];
  region: (MultiSelectOptionSet & IRegion)[];
  surveyArea: (MultiSelectOptionSet & ISurveyAreas)[];
};

export const LocationToggle: React.FC<LocationToggleProps> = ({
  country,
  region,
  surveyArea,
}) => {
  const rootStore = useContext(RootStoreContext);

  const { applyLocationFilter } = rootStore.filterStore;

  const [countryOptions, dispatchCountryOption] = useReducer(
    optionReducer,
    country
  );
  const [regionOptions, dispatchRegionOption] = useReducer(
    optionReducer,
    region
  );
  const [surveyAreasOptions, dispatchSurveyAreaOption] = useReducer(
    optionReducer,
    surveyArea
  );

  //On Apply Filter Clicked
  function onApplyFilterClick() {
    if (isLocationSetSelected(countryOptions)) {
      const selectedCountriesLabel = countryOptions
        .filter((item) => item.isSelected)
        .map((item) => item.label);

      const selectedCountries = country
        .filter((item) => selectedCountriesLabel.includes(item.name))
        .map((item) => ({
          id: item.id,
          name: item.name,
        }));

      if (isLocationSetSelected(regionOptions)) {
        const regionLabels = regionOptions
          .filter((item) => item.isSelected)
          .map((item) => item.label);

        const selectedRegions = region
          .filter((item) => regionLabels.includes(item.name))
          .map((item) => ({
            id: item.id,
            name: item.name,
          }));

        if (isLocationSetSelected(surveyAreasOptions)) {
          const surveyAreaLabels = surveyAreasOptions
            .filter((item) => item.isSelected)
            .map((item) => item.label);

          const selectedSurveyAreas = surveyArea
            .filter((item) => surveyAreaLabels.includes(item.name))
            .map((item) => ({
              id: item.id,
              name: item.name,
            }));

          applyLocationFilter({
            countries: selectedCountries,
            regions: selectedRegions,
            surveyAreas: selectedSurveyAreas,
          });
        } else {
          applyLocationFilter({
            countries: selectedCountries,
            regions: selectedRegions,
          });
        }
      } else {
        applyLocationFilter({
          countries: selectedCountries,
        });
      }
    } else {
      applyLocationFilter(null);
    }
    //logging events into google analytics
    ReactGA.event({
      category: 'Location Filter',
      action: 'Apply Filter'
    })
  }

  //On country item selected
  function onCountryItemSelected(option: MultiSelectOptionSet) {
    dispatchCountryOption({ type: "toggle", option: option });
  }

  //On region item selected
  function onRegionItemSelected(option: MultiSelectOptionSet) {
    dispatchRegionOption({ type: "toggle", option: option });
  }

  //On region item selected
  function onSurveyAreaSelected(option: MultiSelectOptionSet) {
    dispatchSurveyAreaOption({ type: "toggle", option: option });
  }

  //Side Effect function for countryOptions
  useEffect(() => {
    if (isLocationSetSelected(countryOptions)) {
      const namesOfSelectedCountries = countryOptions
        .filter((item) => item.isSelected)
        .map((item) => item.label);

      const selectedCountryId = country
        .filter((item) => namesOfSelectedCountries.includes(item.name))
        .map((item) => item.id);

      const regionsWithinSelectedCountries = region.filter((item) =>
        selectedCountryId.includes(item.countryId)
      );

      dispatchRegionOption({
        type: "set",
        option: regionsWithinSelectedCountries,
      });
    } else {
      dispatchRegionOption({
        type: "set",
        option: [],
      });
    }
    // eslint-disable-next-line 
  }, [countryOptions]); //country and region are not included in deps as they are props

  //Side Effect function for regionOptions
  useEffect(() => {
    if (isLocationSetSelected(regionOptions)) {
      const namesOfSelectedRegions = regionOptions
        .filter((item) => item.isSelected)
        .map((item) => item.label);

      const selectedRegionId = region
        .filter((item) => namesOfSelectedRegions.includes(item.name))
        .map((item) => item.id);
        
      const surveyAreasWithinRegions = surveyArea.filter((item) =>
        selectedRegionId.includes(item.regionId)
      );

      dispatchSurveyAreaOption({
        type: "set",
        option: surveyAreasWithinRegions,
      });
    } else {
      dispatchSurveyAreaOption({
        type: "set",
        option: [],
      });
    }
    // eslint-disable-next-line 
  }, [regionOptions]);

  return (
    <section className="location_toggle_container">
      <div className="location_container">
        <CustomSelect
          options={countryOptions}
          onItemSelected={onCountryItemSelected}
          defaultTitle={`All Countries`}
        />

        <CustomSelect
          options={regionOptions}
          onItemSelected={onRegionItemSelected}
          defaultTitle={`All Regions`}
          isDisabled={!isLocationSetSelected(countryOptions)}
        />

        <CustomSelect
          options={surveyAreasOptions}
          onItemSelected={onSurveyAreaSelected}
          defaultTitle={`All Survey Areas`}
          isDisabled={!isLocationSetSelected(regionOptions)}
        />
      </div>
      <ApplyFilterSection onClick={onApplyFilterClick} />
    </section>
  );
};
