import { LoadingStatus, IFilter } from "../util";
import { GeoChartData } from "../../components/GeoChart/GeoChart";
import { LitterDensityData, LitterQuantityData } from "../overview/overviewUtil";

export interface StoreData {
  data: LocationData;
  filtersApplied: IFilter;
}

export interface MonitoringSiteDetail {
  monitoringSiteName: string;
  numberOfSurveys: number;
  latitude: number;
  longitude: number;
}

export interface SurveyStats {
  numberOfSurveyAreas: number;
  numberOfSurveysCompleted: number;
  totalVolunteerHours: number;
}

export interface LitterDensityPerSurveyAreaItem {
    surveyArea: string;
    regionName: string;
    itemLitterDensity: number;
    surveysCompleted: number;
}

export interface LitterQuantityPerSurveyAreaItem {
  surveyArea: string;
  regionName: string;
  itemLitterQuantity: number;
  surveysCompleted: number;
}

export interface LocationData {
    litterDensityPerSurveyAreas?: LitterDensityPerSurveyAreaItem[];
    litterQuantityPerSurveyAreas?: LitterQuantityPerSurveyAreaItem[];
    surveyStats: SurveyStats;
    surveyLocations: MonitoringSiteDetail[];
    litterDensities?: LitterDensityData;
    litterQuantities?: LitterQuantityData;
}

export function formatLitterQuantityArrItemToDensity(data : LocationData): LocationData {
  if (data.litterQuantityPerSurveyAreas) {
    data.litterDensityPerSurveyAreas = data.litterQuantityPerSurveyAreas.map((item) => {
      return {
        surveyArea: item.surveyArea,
        regionName: item.regionName,
        itemLitterDensity: item.itemLitterQuantity,
        surveysCompleted: item.surveysCompleted
      }
    })
  }
  return data
}

export interface SurveyLocationUIData {
  loadingStatus: LoadingStatus;
  geoData: GeoChartData[];
  infoGroup: {
    title: string;
    value: number;
    description?: string;
  }[];
}

export interface LitterDensityPerSurveyAreaTableData {
  data: LitterDensityPerSurveyAreaItem[];
  loadingStatus: LoadingStatus;
}


