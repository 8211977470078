import React from "react";
import "./Footer.scss";
import SCLogo from "../../assets/sustainable_coastlines_logo.svg";
import ReactGA from 'react-ga';

export const Footer: React.FC<{}> = () => {
  return (
    <footer
      className="content"
    >
      <div className="sc_container">
        <img src={SCLogo} alt="Sustainable Coastlines Logo" />
        <p>
          Brought to you by Sustainable Coastlines <br />
          Registered New Zealand Charity <br />
          55 Madden Street, Wynyard Quarter <br />
          Auckland 1010, New Zealand <br />
          Email us support@litterintelligence.org
        </p>
      </div>
      <div className="licence_container">
        <p>
          <a
            target="_blank"
            href=" https://litterintelligence.org/about#methodology"
            rel="noopener noreferrer"
            onClick={() => {
              ReactGA.event({
                category: 'Data Collection Methodology (Footer)',
                action: 'Click Event'
              })
            }}
          >
            Data Collection Methodology
          </a>{" "}
          -{" "}
          <a
            target="_blank"
            href="https://www.data.govt.nz/manage-data/policies/open-data-policy/"
            rel="noopener noreferrer"
            onClick={() => {
              ReactGA.event({
                category: 'Open Data Policy (Footer)',
                action: 'Click Event'
              })
            }}
          >
            Open Data Policy
          </a>{" "}
          -{" "}
          <a
            target="_blank"
            href="https://litterintelligence.org/privacy-statement/"
            rel="noopener noreferrer"
            onClick={() => {
              ReactGA.event({
                category: 'Privacy Policy (Footer)',
                action: 'Click Event'
              })
            }}
          >
            Privacy Policy
          </a>
          <br /> <br />
          Except where otherwise noted, content on this site is licensed under a
          Creative Commons Attribution-Noncommercial 3.0 New Zealand License.
        </p>
      </div>
    </footer>
  );
};
