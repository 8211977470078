import React from "react";
import "./GaugeChart.scss";
import {
  getLeftPositionForDescription,
  getGaugeImage,
  getColorGradientFor,
} from "./GaugeUtils";

type GaugeChartProps = {
  daysSinceLast: number;
};

export const GaugeChart: React.FC<GaugeChartProps> = ({ daysSinceLast }) => {
  return (
    <section className="gauge_chart_section">
      <p className="survey_frequency_header">SURVEY FREQUENCY</p>

      <div className="chart_content">
        <div>
          <img src={getGaugeImage(daysSinceLast)} alt="Gauge Chart" />
          <p
            style={{
              left: `${getLeftPositionForDescription(daysSinceLast)}px`,
              color: `rgb(${getColorGradientFor(daysSinceLast).r}, ${
                getColorGradientFor(daysSinceLast).g
              }, ${getColorGradientFor(daysSinceLast).b})`,
            }}
          >
            {daysSinceLast}
          </p>
        </div>
      </div>
      <p className="survey_frequency_description">Days since last survey</p>
    </section>
  );
};
