import React from "react";
import DownloadIcon from '../../assets/download_icon.svg';
import "./PageTitle.scss";
import ReactGA from 'react-ga';

type PageTitleProps = {
  title: string;
};

function onDownloadDataClicked(event: any) {
  //Recording Click Event
  ReactGA.event({
    category: 'Download All Data',
    action: 'Download'
  })
}

export const PageTitle: React.FC<PageTitleProps> = ({ title }) => {
  return (
    <div className="page_title_section">
      <h1 className="page_title">{title}</h1>
      <a className="download_all_data_btn" onClick={onDownloadDataClicked} href="https://litter-api-production.azurewebsites.net/api/GetReportData?monitoringSiteIds=&surveyDates=" rel="noopener noreferrer"><img src={DownloadIcon} alt="Download all data"/> <p>Download all data</p></a>
    </div>
  );
};
