import React, { useState, useEffect } from "react";
import "./Table.scss";
import SortIcon from "../../assets/table_sort_icon.svg";
import RightArrow from "../../assets/right_arrow_table.svg";
import LeftArrow from "../../assets/left_arrow_table.svg";
import {
  sortArray,
  isNumber,
  isValidString,
  chunkArray,
  DEFAULT_PAGE_SIZE,
} from "./TableUtil";

enum SortOrder {
  ASC = "asc",
  DESC = "desc",
}

type TableProps = {
  data: any; //Any cause there are mutiple data types
  columns: any;
};

export const Table: React.FC<TableProps> = ({ data, columns }) => {

  const [paginatedData, setPaginatedData] = useState<any[]>(chunkArray(data));
  const [currentPage, setCurrentPage] = useState(0);
  const [sortedBy, setSortedBy] = useState<{
    column: string;
    order: SortOrder;
  } | null>(null);
  const [currentTable, setCurrentTable] = useState({
    columns: [
      {
        name: "#",
        value: "index",
      },
      ...columns,
    ],
    data: paginatedData[0].map((item: any, arrayIndex: any) => ({
      index: arrayIndex + 1,
      ...item,
    })),
  });

  function getCurrentPageText() {
    const numberOfChildArrays = data.length / DEFAULT_PAGE_SIZE;
    if (numberOfChildArrays < 1) {
      return `1 - ${data.length}`;
    }
    if (Number.isInteger(numberOfChildArrays)) {
      return `${currentPage * DEFAULT_PAGE_SIZE + 1} - ${
        currentPage * DEFAULT_PAGE_SIZE + DEFAULT_PAGE_SIZE
      }`;
    } else {
      if (currentPage + 1 < numberOfChildArrays) {
        return `${currentPage * DEFAULT_PAGE_SIZE + 1} - ${
          currentPage * DEFAULT_PAGE_SIZE + DEFAULT_PAGE_SIZE
        }`;
      }
      return `${currentPage * DEFAULT_PAGE_SIZE + 1} - ${data.length}`;
    }
  }

  function nextPage() {
    if (currentPage + 1 < paginatedData.length) {
      setCurrentPage(currentPage + 1);
    }
  }

  function previousPage() {
    if (currentPage - 1 >= 0) {
      setCurrentPage(currentPage - 1);
    }
  }

  useEffect(() => {
    if (sortedBy !== null) {
      setPaginatedData(paginated => 
        chunkArray(
          sortArray(paginated.flat(), sortedBy.column, sortedBy.order)
        )
      );
    }
  }, [sortedBy]);

  useEffect(() => {
    setCurrentTable({
      data: paginatedData[currentPage].map((item: any, arrayIndex: any) => ({
        index: arrayIndex + 1,
        ...item,
      })),
      columns: [
        {
          name: "#",
          value: "index",
        },
        ...columns,
      ],
    });
    // eslint-disable-next-line 
  }, [currentPage, paginatedData]);

  //This is a side effect function
  function changeSortedBy(column: string): void {
    if (sortedBy === null) {
      setSortedBy({
        column: column,
        order: SortOrder.DESC,
      });
      return;
    }
    if (sortedBy.column === column) {
      sortedBy.order === SortOrder.ASC
        ? setSortedBy({
            column: column,
            order: SortOrder.DESC,
          })
        : setSortedBy({
            column: column,
            order: SortOrder.ASC,
          });
    } else {
      setSortedBy({
        column: column,
        order: SortOrder.DESC,
      });
    }
  }

  return (
    <div style={{ width: `100%` }}>
      <div className="custom_table_component_container">
        <table className="custom_table">
          <thead className="header">
            <tr>
              {currentTable.columns.map(
                (item: { name: string; value: string }) => (
                  <th
                    key={item.value}
                    onClick={() => {
                      if (item.value !== "index") {
                        //We cannot sort by index
                        changeSortedBy(item.value);
                      }
                    }}
                  >
                    {item.name}
                    <span
                      className={`${
                        sortedBy === null
                          ? "hide_sort_icon"
                          : sortedBy.column === item.value
                          ? "show_sort_icon"
                          : "hide_sort_icon"
                      }`}
                    >
                      <img src={SortIcon} alt="Table Sort Icon" />
                    </span>
                  </th>
                )
              )}
            </tr>
          </thead>
          <tbody>
            {currentTable.data.map((item: any) => (
              <tr className="row" key={JSON.stringify(item)}>
                {Object.values(item).map((rowContent, arrayIndex) => (
                  <td
                    key={`${rowContent as string}_${arrayIndex}`}
                    className={`${
                      (isNumber(rowContent) && arrayIndex !== 0) ||
                      (isValidString(rowContent) &&
                        ((rowContent as string).endsWith("%") ||
                          (rowContent as string).endsWith("kg") ||
                          (rowContent as string).endsWith(" g")))
                        ? "number_cell"
                        : ""
                    }`}
                  >
                    {arrayIndex === 0
                      ? currentPage * DEFAULT_PAGE_SIZE +
                        parseInt(rowContent.toString())
                      : isNumber(rowContent)
                      ? rowContent.toLocaleString()
                      : rowContent}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="custom_table_footer">
        <div className="page_description">
          {getCurrentPageText()} of {data.length}
        </div>
        <div className="page_nav_container">
          <img
            src={LeftArrow}
            alt="page Back"
            className="left_arrow"
            onClick={() => previousPage()}
          />
          <img
            src={RightArrow}
            alt="Page Forward"
            className="right_arrow"
            onClick={() => nextPage()}
          />
        </div>
      </div>
    </div>
  );
};
