import React from "react";
import "./ComparisonCard.scss";
import { LoadingStatus } from "../../store/util";
import { Loader } from "../Loader/Loader";

export enum DataSet {
  DEFAULT,
  SET_ONE,
  SET_TWO,
}

interface ComparisonCardProps {
  dataSet: DataSet;
  loadingStatus: LoadingStatus;
}

function getCardFlierClassName(dataSet: DataSet) {
  switch (dataSet) {
    case DataSet.DEFAULT:
      return "comparison_card_flier_default";
    case DataSet.SET_ONE:
      return "comparison_card_flier_set_one";
    case DataSet.SET_TWO:
      return "comparison_card_flier_set_two";
  }
}

export const ComparisonCard = <P extends object>(
  Component: React.ComponentType<P>
): React.FC<P & ComparisonCardProps> => ({
  dataSet,
  loadingStatus,
  ...props
}: ComparisonCardProps) => {
  switch (loadingStatus) {
    case LoadingStatus.Loading:
      return (
        <div className="comparison_card_container">
          <div className={getCardFlierClassName(dataSet)}></div>
          <div className="comparison_card_content loading">
            <Loader
              dataSet={dataSet}
              containerSize={150}
              contentSize={120}
              backgroundColor={{ r: 255, g: 255, b: 255 }}
            />
          </div>
        </div>
      );

    case LoadingStatus.Success:
      return (
        <div className="comparison_card_container">
          <div className={getCardFlierClassName(dataSet)}></div>
          <div className="comparison_card_content">
            <Component {...(props as P)} />
          </div>
        </div>
      );

    case LoadingStatus.Failed:
      return (
        <div className="comparison_card_container">
          <div className={getCardFlierClassName(dataSet)}></div>
          <div className="comparison_card_content failed_no_data">
            No data to show
          </div>
        </div>
      );
  }
};
