import React from "react";
import "./LitterDensityInfoItem.scss";

export type LitterDensityInfoItemProps = {
  medianLitterDensity: number;
  maximumLitterDensity: number;
  minimumLitterDensity: number;
  title: string;
  description: string;
};

export const LitterDensityInfoItem: React.FC<LitterDensityInfoItemProps> = ({
  medianLitterDensity,
  maximumLitterDensity,
  minimumLitterDensity,
  title,
  description
}) => {
  return (
    <div className="litter_density_info_item">
      <div className="title_section">
        <p className="title">{title}</p>
        <p className="title_description">{description}</p>
      </div>
      <div className="content_section">
        <div className="extremes_container">
          <p className="description">Low</p>
          <p className="value">{minimumLitterDensity.toLocaleString()}</p>
        </div>
        <div className="extremes_container">
          <p className="description">High</p>
          <p className="value">{maximumLitterDensity.toLocaleString()}</p>
        </div>
        <div className="extremes_container">
          <p className="description">Average</p>
          <p className="value">{medianLitterDensity.toLocaleString()}</p>
        </div>
      </div>
    </div>
  );
};
