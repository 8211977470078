import { roundToTwo } from "../../store/overview/overviewUtil";

export interface TreemapLegend {
  legendName: string;
  legendColor: Color;
  legendPercent: number;
}


export interface Color {
  r: number;
  g: number;
  b: number;
}

const MIN_COLOR: Color = {
  r: 101,
  g: 168,
  b: 255,
};

const MID_COLOR: Color = {
  r: 251,
  g: 212,
  b: 224,
};

const MAX_COLOR: Color = {
  r: 53,
  g: 216,
  b: 189,
};

export function lerp(
  minValue: number,
  maxValue: number,
  interpFactor: number
): number {
  return minValue + (maxValue - minValue) * interpFactor;
}

export function getColorForValue(percent: number): Color {
  //Percent is always between 0 to 1
  if (percent === 0) {
    return MIN_COLOR;
  }
  if (percent === 1) {
    return MAX_COLOR;
  }

  if (percent <= 0.5) {
    //max val is MID_COLOR
    return {
      r: parseInt(Number(lerp(MIN_COLOR.r, MID_COLOR.r, percent * 2)).toFixed(0)),
      g: parseInt(Number(lerp(MIN_COLOR.g, MID_COLOR.g, percent * 2)).toFixed(0)),
      b: parseInt(Number(lerp(MIN_COLOR.b, MID_COLOR.b, percent * 2)).toFixed(0)),
    };
  } else {
    //max val is MAX_COLOR
    return {
      r: parseInt(Number(lerp(MID_COLOR.r, MAX_COLOR.r, percent * 2 - 1)).toFixed(0)),
      g: parseInt(Number(lerp(MID_COLOR.g, MAX_COLOR.g, percent * 2 - 1)).toFixed(0)),
      b: parseInt(Number(lerp(MID_COLOR.b, MAX_COLOR.b, percent * 2 - 1)).toFixed(0)),
    };
  }
}

export function isWeightGreaterThanOneKg(itemWeight: number) : boolean {
  return itemWeight.toFixed(0).length > 3
}

export function getCustomToolTipForGoogleChart(
  itemLabel: string,
  itemValue: number,
  isWeightData: boolean
): string {
  return `<div style="box-shadow: 0px 1px 30px rgba(63, 63, 68, 0.15); 
    border-radius: 4px; background-color: #232323; padding: 20px; 
    display:flex; flex-wrap: nowrap; justify-content: space-between">

    <p style="padding: 0px; margin: 0px; font-size: 16px; line-height: 20px;
    color: #FFFFFF; font-family: 'TTNormsRegular'">${itemLabel}</p>
    
    <p style="padding: 0px; margin: 0px 0px 0px 20px; font-size: 16px; line-height: 20px;
    color: #FFFFFF; font-family: 'TTNormsBold'">${isWeightData ? isWeightGreaterThanOneKg(itemValue) ? roundToTwo(itemValue / 1000) : itemValue.toLocaleString() : itemValue.toLocaleString()} ${isWeightData ? isWeightGreaterThanOneKg(itemValue) ? 'kg' : 'g' : ''}</p>
    
    </div>`;
}
