import { FilterApiData } from './filter/filterUtil';
import { LocationType, ComparisonDataSet } from './util';

export function getGoogleAnalyticsEventCategoryForAction (comparisonDataSet: ComparisonDataSet) {
  switch(comparisonDataSet) {
    case ComparisonDataSet.DateRange:
      return 'Date Range Comparison'
    case ComparisonDataSet.LitterType:
      return 'Litter Type Comparison'
    case ComparisonDataSet.Location:
      return 'Location Comparison'
    case ComparisonDataSet.LocationType:
      return 'Location Type Comparison'
    default:
      return 'Comparison'
  }
}

export const SurveyStatsTableColumns = [
  {
    name: "Group",
    value: "monitoringGroup",
  },
  {
    name: "Survey Areas",
    value: "surveyAreas",
  },
  {
    name: "Surveys Completed",
    value: "surveysCompleted",
  },
  {
    name: "Citizen Scientists",
    value: "citizenScientists",
  },
  {
    name: "Volunteer Hours",
    value: "volunteerHours",
  },
];

export const LitterTypeItemTableColumns = [
  {
    name: "Product",
    value: "product",
  },
  {
    name: "Material",
    value: "material",
  },
  {
    name: "Total Items",
    value: "totalItems",
  },
  {
    name: "% of Total",
    value: "percentage",
  },
];

export const LitterTypeWeightTableColumns = [
  {
    name: "Product",
    value: "product",
  },
  {
    name: "Material",
    value: "material",
  },
  {
    name: "Total Weight",
    value: "totalWeight",
  },
  {
    name: "% of Total",
    value: "percentage",
  },
];

export const LitterTypeTableColumns = [
  {
    name: "Product",
    value: "product",
  },
  {
    name: "Material",
    value: "material",
  },
  {
    name: "Total Items",
    value: "totalItems",
  },
  {
    name: "Total Weight",
    value: "totalWeight"
  },
  {
    name: "% of Total",
    value: "percentOfTotal",
  },
];

export const LocationTypeValues: LocationType[] = [
  {
    label: "Urban - Open",
    value: "UrbanOpen"
  },
  {
    label: "Urban - Closed",
    value: "UrbanClosed"
  },
  {
    label: "Rural - Open",
    value: "RuralOpen"
  },
  {
    label: "Rural - Closed",
    value: "RuralClosed"
  }
];

export interface ICountry {
  id: number;
  name: string;
}

export const Countries: ICountry[] = [
  {
    id: 1,
    name: "New Zealand",
  },
  {
    id: 2,
    name: "Samoa",
  }
];

/*-------------------------------------------------------------------------*/

export interface IRegion {
  id: number;
  name: string;
  countryId: number;
  active: boolean;
}

export const Regions: IRegion[] = [
  {
    id: 1,
    name: "Auckland",
    countryId: 1,
    active: true,
  },
  {
    id: 2,
    name: "Bay Of Plenty",
    countryId: 1,
    active: true,
  },
  {
    id: 3,
    name: "Canterbury",
    countryId: 1,
    active: true,
  },
  {
    id: 4,
    name: "Chatham Islands",
    countryId: 1,
    active: true,
  },
  {
    id: 5,
    name: "Gisborne",
    countryId: 1,
    active: true,
  },
  {
    id: 6,
    name: "Hawke's Bay",
    countryId: 1,
    active: true,
  },
  {
    id: 7,
    name: "Manawatu-Wanganui",
    countryId: 1,
    active: true,
  },
  {
    id: 8,
    name: "Marlborough",
    countryId: 1,
    active: true,
  },
  {
    id: 9,
    name: "Northland",
    countryId: 1,
    active: true,
  },
  {
    id: 10,
    name: "Otago",
    countryId: 1,
    active: true,
  },
  {
    id: 11,
    name: "Southland",
    countryId: 1,
    active: true,
  },
  {
    id: 12,
    name: "Taranaki",
    countryId: 1,
    active: true,
  },
  {
    id: 13,
    name: "Tasman-Nelson",
    countryId: 1,
    active: true,
  },
  {
    id: 14,
    name: "Waikato",
    countryId: 1,
    active: true,
  },
  {
    id: 15,
    name: "Wellington",
    countryId: 1,
    active: true,
  },
  {
    id: 16,
    name: "West Coast",
    countryId: 1,
    active: true,
  },
  {
    id: 18,
    name: "Tuamasaga",
    countryId: 2,
    active: true,
  },
  {
    id: 19,
    name: "A'ana",
    countryId: 2,
    active: true,
  },
  {
    id: 20,
    name: "Aiga-i-le-Tai",
    countryId: 2,
    active: true,
  },
  {
    id: 21,
    name: "Atua",
    countryId: 2,
    active: true,
  },
  {
    id: 22,
    name: "Va'a-o-Fonoti",
    countryId: 2,
    active: true,
  },
  {
    id: 23,
    name: "Fa'asaleleaga",
    countryId: 2,
    active: true,
  },
  {
    id: 24,
    name: "Gaga'emauga",
    countryId: 2,
    active: true,
  },
  {
    id: 25,
    name: "Gaga'ifomauga",
    countryId: 2,
    active: true,
  },
  {
    id: 26,
    name: "Vaisigano",
    countryId: 2,
    active: true,
  },
  {
    id: 27,
    name: "Satupa'itea",
    countryId: 2,
    active: true,
  },
  {
    id: 28,
    name: "Palauli",
    countryId: 2,
    active: true,
  },
];

/*-------------------------------------------------------------------------*/

export interface IMonitoringSite {
  id: number;
  name: string;
  regionId: number;
}

export const MonitoringSites: IMonitoringSite[] = [
  {
    id: 1,
    name: "Paraparaumu Beach",
    regionId: 15,
  },
  {
    id: 2,
    name: "Makara Beach",
    regionId: 15,
  },
  {
    id: 3,
    name: "Pauatahanui Inlet",
    regionId: 15,
  },
  {
    id: 4,
    name: "Orewa Beach",
    regionId: 1,
  },
  {
    id: 5,
    name: "Okahu Bay",
    regionId: 1,
  },
  {
    id: 6,
    name: "Lyall Bay",
    regionId: 15,
  },
  {
    id: 9,
    name: "Island Bay",
    regionId: 15,
  },
  {
    id: 11,
    name: "Waikanae Beach",
    regionId: 5,
  },
  {
    id: 12,
    name: "Karioitahi Beach",
    regionId: 1,
  },
  {
    id: 13,
    name: "Petone Beach West",
    regionId: 15,
  },
  {
    id: 14,
    name: "Taipa bay",
    regionId: 9,
  },
  {
    id: 15,
    name: "Matai Bay",
    regionId: 9,
  },
  {
    id: 16,
    name: "Waipapakauri",
    regionId: 9,
  },
  {
    id: 17,
    name: "Ahipara",
    regionId: 9,
  },
  {
    id: 18,
    name: "Spirits Bay",
    regionId: 9,
  },
  {
    id: 19,
    name: "Rarawa Beach",
    regionId: 9,
  },
  {
    id: 20,
    name: "Tapotupotu Bay",
    regionId: 9,
  },
  {
    id: 23,
    name: "Pilots Beach",
    regionId: 10,
  },
  {
    id: 24,
    name: "Hoopers Inlet",
    regionId: 10,
  },
  {
    id: 25,
    name: "Harrington Point Beach",
    regionId: 10,
  },
  {
    id: 26,
    name: "Marsden Bay",
    regionId: 9,
  },
  {
    id: 27,
    name: "Hatea River",
    regionId: 9,
  },
  {
    id: 28,
    name: "Buffalo Beach",
    regionId: 14,
  },
  {
    id: 29,
    name: "Opito Bay",
    regionId: 14,
  },
  {
    id: 30,
    name: "Waitangi",
    regionId: 9,
  },
  {
    id: 31,
    name: "Porirua Harbour",
    regionId: 15,
  },
  {
    id: 32,
    name: "Winstones Cove, Torbay",
    regionId: 1,
  },
  {
    id: 33,
    name: "Hamiltons Gap Beach",
    regionId: 1,
  },
  {
    id: 34,
    name: "Kaiti Beach",
    regionId: 5,
  },
  {
    id: 35,
    name: "Matiu Somes Island",
    regionId: 15,
  },
  {
    id: 36,
    name: "Breaker Bay",
    regionId: 15,
  },
  {
    id: 37,
    name: "Kau Bay",
    regionId: 15,
  },
  {
    id: 38,
    name: "Midway Beach",
    regionId: 5,
  },
  {
    id: 39,
    name: "Little Shoal Bay",
    regionId: 1,
  },
  {
    id: 40,
    name: "Waikanae Estuary",
    regionId: 15,
  },
  {
    id: 41,
    name: "Kaiwharawhara Estuary",
    regionId: 15,
  },
  {
    id: 42,
    name: "Foxton Beach",
    regionId: 7,
  },
  {
    id: 43,
    name: "Patea Beach",
    regionId: 12,
  },
  {
    id: 44,
    name: "Otaki Beach",
    regionId: 15,
  },
  {
    id: 45,
    name: "Petone Beach East",
    regionId: 15,
  },
  {
    id: 46,
    name: "Long Bay",
    regionId: 15,
  },
  {
    id: 47,
    name: "Pukerua Bay",
    regionId: 15,
  },
  {
    id: 48,
    name: "Pilot Bay",
    regionId: 2,
  },
  {
    id: 49,
    name: "Wainamu Beach",
    regionId: 14,
  },
  {
    id: 51,
    name: "Oneroa Beach, Waiheke",
    regionId: 1,
  },
  {
    id: 52,
    name: "Riversdale Beach",
    regionId: 15,
  },
  {
    id: 53,
    name: "Moutere Inlet (Rivermouth)",
    regionId: 13,
  },
  {
    id: 54,
    name: "Ruby Bay",
    regionId: 13,
  },
  {
    id: 55,
    name: "Kina Beach",
    regionId: 13,
  },
  {
    id: 56,
    name: "Mangere Bridge",
    regionId: 1,
  },
  {
    id: 59,
    name: "Picton Foreshore",
    regionId: 8,
  },
  {
    id: 60,
    name: "Tahunanui Beach",
    regionId: 13,
  },
  {
    id: 61,
    name: "Kaiteriteri Beach",
    regionId: 13,
  },
  {
    id: 62,
    name: "Seatoun Beach",
    regionId: 15,
  },
  {
    id: 63,
    name: "Castlecliff Beach",
    regionId: 7,
  },
  {
    id: 64,
    name: "Fitzroy Beach",
    regionId: 12,
  },
  {
    id: 65,
    name: "Oakura Beach",
    regionId: 12,
  },
  {
    id: 66,
    name: "Maraetai Beach",
    regionId: 1,
  },
  {
    id: 67,
    name: "Whitireia Park",
    regionId: 15,
  },
  {
    id: 68,
    name: "NZMSC South Shore",
    regionId: 10,
  },
  {
    id: 69,
    name: "NZMSC North Shore",
    regionId: 10,
  },
  {
    id: 70,
    name: "Tarakena Bay",
    regionId: 15,
  },
  {
    id: 71,
    name: "Muriwai Beach",
    regionId: 1,
  },
  {
    id: 72,
    name: "Long Bay Marine Reserve",
    regionId: 1,
  },
  {
    id: 73,
    name: "Spinnaker Reserve",
    regionId: 1,
  },
  {
    id: 74,
    name: "Taumanu Reserve",
    regionId: 1,
  },
  {
    id: 75,
    name: "Okarito",
    regionId: 16,
  },
  {
    id: 76,
    name: "Gillespies Beach",
    regionId: 16,
  },
  {
    id: 77,
    name: "Cook River Mouth",
    regionId: 16,
  },
  {
    id: 78,
    name: "Marahau",
    regionId: 13,
  },
  {
    id: 79,
    name: "Clive Beach",
    regionId: 6,
  },
  {
    id: 80,
    name: "Waitangi Regional Park",
    regionId: 6,
  },
  {
    id: 81,
    name: "National Aquarium of NZ",
    regionId: 6,
  },
  {
    id: 82,
    name: "Little Kaiteriteri Beach ",
    regionId: 13,
  },
  {
    id: 83,
    name: "Ahuriri Estuary",
    regionId: 6,
  },
  {
    id: 84,
    name: "Waitataramoa Bay",
    regionId: 14,
  },
  {
    id: 85,
    name: "Wainui Beach",
    regionId: 5,
  },
  {
    id: 86,
    name: "Mount Maunganui",
    regionId: 2,
  },
  {
    id: 87,
    name: "Saint Heliers",
    regionId: 1,
  },
  {
    id: 88,
    name: "Double Bay",
    regionId: 8,
  },
  {
    id: 89,
    name: "Boulder Bank Reserve",
    regionId: 13,
  },
  {
    id: 90,
    name: "Kinzett Terrace Carpark",
    regionId: 13,
  },
  {
    id: 91,
    name: "Haulashore Island",
    regionId: 13,
  },
  {
    id: 92,
    name: "The Old Boat Ramp",
    regionId: 13,
  },
  {
    id: 93,
    name: "Point Road, Waimea Inlet",
    regionId: 13,
  },
  {
    id: 94,
    name: "Glenduan Reserve",
    regionId: 13,
  },
  {
    id: 95,
    name: "Ruapuke",
    regionId: 14,
  },
  {
    id: 96,
    name: "Tapuae",
    regionId: 12,
  },

  {
    id: 97,
    name: "Kapiti Island - North end",
    regionId: 15,
  },
  {
    id: 98,
    name: "Mana Island East Side",
    regionId: 15,
  },
  {
    id: 99,
    name: "Mana Island West Side",
    regionId: 15,
  },
  {
    id: 100,
    name: "Test Monitoring Site",
    regionId: 17,
  },
  {
    id: 101,
    name: "Oriental Bay",
    regionId: 15,
  },
  {
    id: 102,
    name: "Woodend Beach",
    regionId: 3,
  },
  {
    id: 103,
    name: "Maleafatu Park (Apia Bay)",
    regionId: 18,
  },
  {
    id: 104,
    name: "Nga Motu Beach",
    regionId: 12,
  },
  {
    id: 105,
    name: "St Clair to St Kilda Beach",
    regionId: 10,
  },
  {
    id: 105,
    name: "St Clair to St Kilda Beach",
    regionId: 10,
  },
  {
    id: 106,
    name: "Macandrew Bay",
    regionId: 10,
  },
  {
    id: 107,
    name: "Jacobs River Estuary Entrance (North Side)",
    regionId: 11,
  },
  {
    id: 108,
    name: "Waikawa Bay",
    regionId: 8,
  },
  {
    id: 109,
    name: "Shelley Beach",
    regionId: 8,
  },
  {
    id: 110,
    name: "Onetahuti Bay",
    regionId: 13,
  },
  {
    id: 111,
    name: "Waitangi Estuary - South Side",
    regionId: 6,
  },
  {
    id: 112,
    name: "Karitane Harbour",
    regionId: 10,
  },
  {
    id: 113,
    name: "Karitane Beach",
    regionId: 10,
  },
  {
    id: 114,
    name: "Purakaunui Bay",
    regionId: 10,
  },
  {
    id: 115,
    name: "Manly Beach",
    regionId: 1,
  },
  {
    id: 116,
    name: "Birds Beach",
    regionId: 9,
  },
  {
    id: 117,
    name: "Pencarrow Coast",
    regionId: 15,
  },
  {
    id: 118,
    name: "Waitaria Bay",
    regionId: 8,
  },
  {
    id: 119,
    name: "Anakiwa",
    regionId: 8,
  },
  {
    id: 120,
    name: "Ngunguru Sandspit (East Side)",
    regionId: 9,
  },
  {
    id: 121,
    name: "Ngunguru Sandspit (West Side)",
    regionId: 9,
  },
  {
    id: 122,
    name: "Te Puru",
    regionId: 14,
  },
  {
    id: 123,
    name: "Hongoeka Bay",
    regionId: 15,
  },
  {
    id: 124,
    name: "Kulim Park",
    regionId: 2,
  },
  {
    id: 125,
    name: "Kaikoura Peninsula",
    regionId: 3,
  },
  {
    id: 126,
    name: "Uawa",
    regionId: 5,
  },
  {
    id: 127,
    name: "Manaroa",
    regionId: 8,
  },
  {
    id: 128,
    name: "Granity",
    regionId: 16,
  },
  {
    id: 129,
    name: "Hokitika",
    regionId: 16,
  },
  {
    id: 130,
    name: "Barrytown",
    regionId: 16,
  },
  {
    id: 131,
    name: "North Beach (Westport)",
    regionId: 16,
  },
  {
    id: 132,
    name: "Greymouth",
    regionId: 16,
  },
  {
    id: 133,
    name: "Patea South",
    regionId: 12,
  },
  {
    id: 134,
    name: "Rabbit Island",
    regionId: 13,
  },
  {
    id: 135,
    name: "Eastern Beach",
    regionId: 1,
  },
  {
    id: 136,
    name: "Warrington Beach",
    regionId: 10,
  },
  {
    id: 137,
    name: "Blueskin Bay",
    regionId: 10,
  },
  {
    id: 138,
    name: "Jacobs River Estuary",
    regionId: 11,
  },
  {
    id: 139,
    name: "Oreti Beach",
    regionId: 11,
  },
  {
    id: 140,
    name: "Greenpoint Domain",
    regionId: 11,
  },
  {
    id: 141,
    name: "North Beach (Riverton)",
    regionId: 11,
  },
  {
    id: 142,
    name: "Cochrane's Gap",
    regionId: 1,
  },
  {
    id: 143,
    name: "Hannahs Clearing",
    regionId: 16,
  },
  {
    id: 144,
    name: "All Day Bay",
    regionId: 10,
  },
  {
    id: 145,
    name: "Moeraki Reserve",
    regionId: 10,
  },
  {
    id: 146,
    name: "Brighton Reserve",
    regionId: 10,
  },
  {
    id: 147,
    name: "Pounawea",
    regionId: 10,
  },
  {
    id: 148,
    name: "Kaka Point",
    regionId: 10,
  },
  {
    id: 149,
    name: "Motueka Sandspit",
    regionId: 13,
  },
  {
    id: 150,
    name: "Tauhinu, Greenhithe",
    regionId: 1,
  },
  {
    id: 151,
    name: "French Bay",
    regionId: 3,
  },
  {
    id: 152,
    name: "Hokitika Southside",
    regionId: 16,
  },
  {
    id: 153,
    name: "Waimapu Estuary, Tauranga Harbour",
    regionId: 2,
  },
  {
    id: 154,
    name: "Takapuna",
    regionId: 1,
  },
  {
    id: 155,
    name: "Toetoes Bay",
    regionId: 11,
  },
  {
    id: 156,
    name: "Haldane Bay",
    regionId: 11,
  },
  {
    id: 157,
    name: "Curio Bay",
    regionId: 11,
  },
  {
    id: 158,
    name: "Ulva Island",
    regionId: 11,
  },
  {
    id: 159,
    name: "Mason Bay",
    regionId: 11,
  },
  {
    id: 160,
    name: "Halfmoon Bay",
    regionId: 11,
  },
  {
    id: 161,
    name: "Tucks Bay",
    regionId: 14,
  },
  {
    id: 162,
    name: "Deep Bay",
    regionId: 11,
  },
  {
    id: 163,
    name: "Leask Bay",
    regionId: 11,
  },
  {
    id: 164,
    name: "Harbour View Beach Reserve",
    regionId: 1,
  },
  {
    id: 165,
    name: "Waituna Lagoon",
    regionId: 11,
  },
  {
    id: 166,
    name: "Haldane Estuary",
    regionId: 11,
  },
  {
    id: 167,
    name: "Monkey Island",
    regionId: 11,
  },
  {
    id: 168,
    name: "Wairaurahiri",
    regionId: 11,
  },
  {
    id: 169,
    name: "Lowry Bay",
    regionId: 15,
  },
  {
    id: 170,
    name: "East Cape",
    regionId: 5,
  },
  {
    id: 171,
    name: "Waikouaiti",
    regionId: 10,
  },
  {
    id: 172,
    name: "Waimairi Beach",
    regionId: 3,
  },
  {
    id: 173,
    name: "Sumner Beach",
    regionId: 3,
  },
  {
    id: 174,
    name: "Avon Heathcote / Ihutai Estuary",
    regionId: 3,
  },
  {
    id: 175,
    name: "Governors Bay",
    regionId: 3,
  },
];

/*-------------------------------------------------------------------------*/

export interface ISurveyAreas {
  id: number;
  name: string;
  regionId: number;
}

export const SurveysAreas: ISurveyAreas[] = [
  {
    id: 15,
    regionId: 15,
    name: "Paraparaumu Beach - Kapiti Boating Club",
  },
  {
    id: 16,
    regionId: 15,
    name: "Paraparaumu Beach - Maclean Park",
  },
  {
    id: 17,
    regionId: 5,
    name: "Waikanae Beach",
  },
  {
    id: 19,
    regionId: 1,
    name: "Karioitahi Beach",
  },
  {
    id: 20,
    regionId: 15,
    name: "Island Bay",
  },
  {
    id: 26,
    regionId: 10,
    name: "Otago Peninsula - Pilots beach",
  },
  {
    id: 27,
    regionId: 15,
    name: "Petone Beach - Honiana Te Puni Reserve",
  },
  {
    id: 28,
    regionId: 9,
    name: "Marsden Bay Reserve",
  },
  {
    id: 31,
    regionId: 14,
    name: "Buffalo beach south",
  },
  {
    id: 36,
    regionId: 15,
    name: "Makara beach",
  },
  {
    id: 38,
    regionId: 1,
    name: "Hamiltons gap Beach",
  },
  {
    id: 39,
    regionId: 15,
    name: "Breaker bay east",
  },
  {
    id: 40,
    regionId: 5,
    name: "Midway Beach - Pipe",
  },
  {
    id: 41,
    regionId: 1,
    name: "Winstone’s Cove, Torbay",
  },
  {
    id: 42,
    regionId: 1,
    name: "Little Shoal Bay",
  },
  {
    id: 43,
    regionId: 9,
    name: "Hatea River",
  },
  {
    id: 44,
    regionId: 15,
    name: "Waikanae Estuary - Dotterel island",
  },
  {
    id: 45,
    regionId: 15,
    name: "Porirua Harbour - West end",
  },
  {
    id: 46,
    regionId: 15,
    name: "Kaiwharawhara estuary - North Side",
  },
  {
    id: 47,
    regionId: 7,
    name: "Foxton beach 1",
  },
  {
    id: 48,
    regionId: 15,
    name: "Otaki river mouth",
  },
  {
    id: 49,
    regionId: 15,
    name: "Petone Beach - Hikokoi Reserve",
  },
  {
    id: 50,
    regionId: 15,
    name: "Pukerua bay pa site",
  },
  {
    id: 51,
    regionId: 2,
    name: "Mid Pilot Bay",
  },
  {
    id: 52,
    regionId: 14,
    name: "Wainamu Beach",
  },
  {
    id: 53,
    regionId: 15,
    name: "Lyall Bay East End",
  },
  {
    id: 54,
    regionId: 1,
    name: "Oneroa Beach, Waiheke - BBQs",
  },
  {
    id: 55,
    regionId: 15,
    name: "Riversdale surf club",
  },
  {
    id: 56,
    regionId: 1,
    name: "Spinnaker Reserve",
  },
  {
    id: 57,
    regionId: 13,
    name: "Batchelors ford entry",
  },
  {
    id: 58,
    regionId: 13,
    name: "Pine hill reserve",
  },
  {
    id: 59,
    regionId: 1,
    name: "Mangere Boat Club",
  },
  {
    id: 60,
    regionId: 13,
    name: "Kina Beach - Campground",
  },
  {
    id: 61,
    regionId: 8,
    name: "Picton foreshore",
  },
  {
    id: 62,
    regionId: 5,
    name: "Waikanae Beach - Big River",
  },
  {
    id: 63,
    regionId: 13,
    name: "Tahunanui Beach Rocks Road",
  },
  {
    id: 92,
    regionId: 15,
    name: "Motukaraka point (east side)",
  },
  {
    id: 93,
    regionId: 12,
    name: "Waitangi ship wreck",
  },
  {
    id: 94,
    regionId: 12,
    name: "Fitzroy surf club",
  },
  {
    id: 95,
    regionId: 12,
    name: "Corbett Park",
  },
  {
    id: 96,
    regionId: 7,
    name: "Castlecliff Beach - North mole",
  },
  {
    id: 97,
    regionId: 1,
    name: "Taumanu Reserve",
  },
  {
    id: 98,
    regionId: 9,
    name: "Matai Bay - North end of the beach",
  },
  {
    id: 99,
    regionId: 9,
    name: "Matai Bay - South end of the bay",
  },
  {
    id: 100,
    regionId: 1,
    name: "Maraetai beach - Maraetai north",
  },
  {
    id: 101,
    regionId: 15,
    name: "Whitireia Park - Onehunga Bay",
  },
  {
    id: 102,
    regionId: 15,
    name: "Tarakena Bay - East beach",
  },
  {
    id: 103,
    regionId: 16,
    name: "Okarito",
  },
  {
    id: 104,
    regionId: 1,
    name: "Muriwai Beach - Muriwai surf tower",
  },
  {
    id: 105,
    regionId: 16,
    name: "Okarito - Three Mile Lagoon",
  },
  {
    id: 106,
    regionId: 5,
    name: "Kaiti Beach - North end",
  },
  {
    id: 107,
    regionId: 16,
    name: "Cook River Mouth South",
  },
  {
    id: 108,
    regionId: 13,
    name: "Marahau Wakatu",
  },
  {
    id: 109,
    regionId: 6,
    name: "Te Atea a Rangi",
  },
  {
    id: 110,
    regionId: 6,
    name: "Marine Parade - National Aquarium of NZ South Site",
  },
  {
    id: 111,
    regionId: 6,
    name: "Marine Parade - National Aquarium of NZ North Site",
  },
  {
    id: 112,
    regionId: 6,
    name: "Ahuriri Estuary",
  },
  {
    id: 113,
    regionId: 6,
    name: "Clive Beach - Richmond road carpark",
  },
  {
    id: 115,
    regionId: 14,
    name: "Waitataramoa Bay - Central Bay",
  },
  {
    id: 116,
    regionId: 14,
    name: "Opito Bay Central",
  },
  {
    id: 117,
    regionId: 2,
    name: "Mount Maunganui - Camping ground",
  },
  {
    id: 120,
    regionId: 13,
    name: "Little Kaiteriteri",
  },
  {
    id: 121,
    regionId: 15,
    name: "Otaki Surf Club",
  },
  {
    id: 122,
    regionId: 15,
    name: "Porirua Stream Estuary",
  },
  {
    id: 123,
    regionId: 1,
    name: "St Heliers playground",
  },
  {
    id: 124,
    regionId: 10,
    name: "NZMSC South Shore - Pipe cove",
  },
  {
    id: 125,
    regionId: 10,
    name: "NZMSC North Shore - North shore 100 m",
  },
  {
    id: 126,
    regionId: 13,
    name: "Glenduan Reserve - Marine reserve north boundary survey area",
  },
  {
    id: 127,
    regionId: 13,
    name: "Waimea Inlet - Point Road, Monaco",
  },
  {
    id: 128,
    regionId: 13,
    name: "The Haven - Kinzett Terrace Carpark",
  },
  {
    id: 129,
    regionId: 8,
    name: "Double bay reserve",
  },
  {
    id: 130,
    regionId: 13,
    name: "Ruby Bay - Mapua primary east end ruby bay",
  },
  {
    id: 131,
    regionId: 14,
    name: "Ruapuke - North",
  },
  {
    id: 132,
    regionId: 13,
    name: "Old Boat Ramp",
  },
  {
    id: 133,
    regionId: 13,
    name: "Haulashore Island - East Beach Haulashore Island",
  },
  {
    id: 134,
    regionId: 15,
    name: "Seatoun Beach - Steeple Rock",
  },
  {
    id: 135,
    regionId: 15,
    name: "Porirua Harbour - Paremata Bridge",
  },
  {
    id: 136,
    regionId: 1,
    name: "Okahu Bay South East End",
  },
  {
    id: 137,
    regionId: 12,
    name: "Tapuae river mouth",
  },
  {
    id: 138,
    regionId: 15,
    name: "McGregors Rock",
  },
  {
    id: 139,
    regionId: 15,
    name: "South Point Mana Island",
  },
  {
    id: 140,
    regionId: 15,
    name: "West Point Mana Island",
  },
  {
    id: 141,
    regionId: 15,
    name: "Paraparaumu Beach - North Beach",
  },
  {
    id: 142,
    regionId: 15,
    name: "Tokahaki Point",
  },
  {
    id: 143,
    regionId: 15,
    name: "Boulder bank north side",
  },
  {
    id: 144,
    regionId: 15,
    name: "Boulder Bank South",
  },
  {
    id: 145,
    regionId: 15,
    name: "Freyberg Pool",
  },
  {
    id: 146,
    regionId: 18,
    name: "Maleafatu Park Seawall (Apia Bay)",
  },
  {
    id: 147,
    regionId: 3,
    name: "Woodend Beach",
  },
  {
    id: 148,
    regionId: 10,
    name: "St Clair to St Kilda Beach - Saint Kilda",
  },
  {
    id: 149,
    regionId: 11,
    name: "Jacobs River Estuary Entrance (North Side) - Rugby club rooms",
  },
  {
    id: 150,
    regionId: 8,
    name: "Shelley Beach",
  },
  {
    id: 151,
    regionId: 6,
    name: "Clive River Mouth",
  },
  {
    id: 152,
    regionId: 13,
    name: "Onetahuti Bay - Onetahuti",
  },
  {
    id: 153,
    regionId: 10,
    name: "Macandrew bay beach",
  },
  {
    id: 154,
    regionId: 10,
    name: "Hoppers inlet - north shore",
  },
  {
    id: 155,
    regionId: 10,
    name: "Karitane beach - north end",
  },
  {
    id: 156,
    regionId: 10,
    name: "Purakaunui Bay",
  },
  {
    id: 157,
    regionId: 1,
    name: "Manly Beach - Ski Lane Marker",
  },
  {
    id: 158,
    regionId: 15,
    name: "Pencarrow Coast - Hinds point",
  },
  {
    id: 159,
    regionId: 8,
    name: "Waitaria Bay - East side of jetty",
  },
  {
    id: 160,
    regionId: 8,
    name: "Anakiwa Bay",
  },
  {
    id: 161,
    regionId: 9,
    name: "Left of Boat Ramp",
  },
  {
    id: 162,
    regionId: 14,
    name: "Northern Creek Mouth",
  },
  {
    id: 163,
    regionId: 9,
    name: "Ngunguru Sandspit SE Mussel Rock",
  },
  {
    id: 164,
    regionId: 15,
    name: "Hongoeka Bay - Hongoeka Beach",
  },
  {
    id: 165,
    regionId: 14,
    name: "North Te Puru",
  },
  {
    id: 166,
    regionId: 2,
    name: "Kulim Beach West",
  },
  {
    id: 167,
    regionId: 3,
    name: "Kaikoura Peninsula - South Bay Reserve ",
  },
  {
    id: 168,
    regionId: 5,
    name: "SLS Tower",
  },
  {
    id: 169,
    regionId: 7,
    name: "Foxton Beach - North of surf club",
  },
  {
    id: 170,
    regionId: 12,
    name: "Patea South - South Mole",
  },
  {
    id: 171,
    regionId: 13,
    name: "Rabbit Island - Rabbit island domain",
  },
  {
    id: 172,
    regionId: 1,
    name: "Eastern Beach - Macleans beach",
  },
  {
    id: 173,
    regionId: 11,
    name: "Jacobs River Estuary - End of Lucknow street",
  },
  {
    id: 174,
    regionId: 16,
    name: "Granity Beach south",
  },
  {
    id: 175,
    regionId: 10,
    name: "Blueskin Bay Estuary",
  },
  {
    id: 176,
    regionId: 16,
    name: "Greymouth - Paroa beach north",
  },
  {
    id: 177,
    regionId: 11,
    name: "Oreti Beach - Christies track",
  },
  {
    id: 178,
    regionId: 11,
    name: "Greenpoint Sandy Beach",
  },
  {
    id: 179,
    regionId: 11,
    name: "North Beach (Riverton) - Princess Street end",
  },
  {
    id: 180,
    regionId: 13,
    name: "Kaiteriteri front beach",
  },
  {
    id: 181,
    regionId: 13,
    name: "Rabbit Island - Western end of domain",
  },
  {
    id: 182,
    regionId: 1,
    name: "Cochrane's Gap #1",
  },
  {
    id: 184,
    regionId: 15,
    name: "Test Site 1 - Devenport Naval Base Car Park",
  },
  {
    id: 185,
    regionId: 10,
    name: "All day bay freedom camping spot",
  },
  {
    id: 186,
    regionId: 10,
    name: "Moeraki reserve",
  },
  {
    id: 187,
    regionId: 10,
    name: "Brighton Beach (near playground)",
  },
  {
    id: 188,
    regionId: 13,
    name: "Motueka Sand Spit",
  },
  {
    id: 189,
    regionId: 16,
    name: "Barrytown  - Stream mouth",
  },
  {
    id: 190,
    regionId: 16,
    name: "Hokitika - Tudor Street Groynes",
  },
  {
    id: 191,
    regionId: 3,
    name: "French Bay - Harbar steps",
  },
  {
    id: 192,
    regionId: 10,
    name: "Kaka Point Surf Lifesaving Club",
  },
  {
    id: 193,
    regionId: 11,
    name: "Dunns Rd right of entrance",
  },
  {
    id: 194,
    regionId: 10,
    name: "Pounawea Motor Camp",
  },
  {
    id: 195,
    regionId: 11,
    name: "Haldane",
  },
  {
    id: 196,
    regionId: 11,
    name: "Ulva Island - Sydney Cove",
  },
  {
    id: 197,
    regionId: 11,
    name: "Deep Bay",
  },
  {
    id: 198,
    regionId: 14,
    name: "Tucks Bay",
  },
  {
    id: 199,
    regionId: 16,
    name: "Hokitika Southside - Southside",
  },
  {
    id: 200,
    regionId: 18,
    name: "Apia harbour sand bank",
  },
  {
    id: 201,
    regionId: 18,
    name: "Moataa Mangrove",
  },
  {
    id: 202,
    regionId: 13,
    name: "Tahunanui Beach - Sand spit",
  },
  {
    id: 203,
    regionId: 10,
    name: "Warrington Beach",
  },
  {
    id: 204,
    regionId: 11,
    name: "Waituna Lagoon",
  },
  {
    id: 205,
    regionId: 11,
    name: "Toetoes Bay",
  },
  {
    id: 206,
    regionId: 1,
    name: "Harbour View Reserve Beach",
  },
  {
    id: 207,
    regionId: 11,
    name: "Haldane Estaury",
  },
  {
    id: 208,
    regionId: 11,
    name: "Wairaurahiri East",
  },
  {
    id: 209,
    regionId: 11,
    name: "Wairaurahiri west",
  },
  {
    id: 210,
    regionId: 11,
    name: "Monkey Island - Freedom Camping Site",
  },
  {
    id: 211,
    regionId: 6,
    name: "Waitangi Estuary - South Side - Clive River Mouth2",
  },
  {
    id: 212,
    regionId: 15,
    name: "Lowry bay",
  },
  {
    id: 213,
    regionId: 10,
    name: "Matanaka Beach end",
  },
  {
    id: 214,
    regionId: 3,
    name: "Wind surfers reserve",
  },
  {
    id: 215,
    regionId: 3,
    name: "Waimairi Beach - stormwater pipe",
  },
  {
    id: 216,
    regionId: 3,
    name: "Sumner Beach Cave Rock End",
  },
  {
    id: 217,
    regionId: 12,
    name: "Nga Motu Beach - Nga Motu North",
  },
  {
    id: 218,
    regionId: 6,
    name: "Marine Parade - The Fountain",
  },
  {
    id: 219,
    regionId: 11,
    name: "Milford Sound - Milford foreshore",
  },
  {
    id: 220,
    regionId: 3,
    name: "Avon Heathcote Estuary - The South New Brighton Yachtclub",
  },
  {
    id: 221,
    regionId: 8,
    name: "Rarangi Beach - Monkey Bay Carpark",
  },
  {
    id: 222,
    regionId: 8,
    name: "Long Island - Ships Cove (South Side)",
  },
  {
    id: 223,
    regionId: 8,
    name: "Long Island - West Beach",
  },
  {
    id: 224,
    regionId: 1,
    name: "Long Bay - MERC Monitoring Site",
  },
  {
    id: 225,
    regionId: 11,
    name: "Gemstone Beach - Mullans Road Carpark",
  },
  {
    id: 226,
    regionId: 16,
    name: "Beachfront beach",
  },
  {
    id: 227,
    regionId: 16,
    name: "Hokitika Beachfront",
  },
  {
    id: 228,
    regionId: 2,
    name: "Waihi Beach Surf Club",
  },
  {
    id: 229,
    regionId: 1,
    name: "Long Bay-MERC Monitoring Site",
  },
  {
    id: 230,
    regionId: 11,
    name: "Sandy Point - Noki Kaik Beach",
  },
  {
    id: 231,
    regionId: 1,
    name: "Piha - Surfers carpark beach",
  },
  {
    id: 232,
    regionId: 15,
    name: "Kāpiti Island - Rangatira South",
  }
];

/*-------------------------------------------------------------------------*/

export interface IMaterials {
  id: number;
  name: string;
}

export const Materials: IMaterials[] = [
  {
    id: 1,
    name: "Plastic",
  },
  {
    id: 2,
    name: "Foamed Plastic",
  },
  {
    id: 3,
    name: "Cloth",
  },
  {
    id: 4,
    name: "Glass & Ceramic",
  },
  {
    id: 5,
    name: "Metal",
  },
  {
    id: 6,
    name: "Paper & Cardboard",
  },
  {
    id: 7,
    name: "Rubber",
  },
  {
    id: 8,
    name: "Wood",
  },
  {
    id: 9,
    name: "Other",
  },
];

/*-------------------------------------------------------------------------*/

export interface IProducts {
  id: number;
  litterCategoryId: number;
  code: string;
  name: string;
}

export const Products: IProducts[] = [
  {
    id: 1,
    litterCategoryId: 1,
    code: "PL01",
    name: "Bottle caps & lids",
  },
  {
    id: 2,
    litterCategoryId: 1,
    code: "PL01.01",
    name: "Bottle neck rings",
  },
  {
    id: 3,
    litterCategoryId: 1,
    code: "PL01.02",
    name: "Bottle seals & tabs",
  },
  {
    id: 4,
    litterCategoryId: 1,
    code: "PL02",
    name: "Bottles <= 2 L",
  },
  {
    id: 5,
    litterCategoryId: 1,
    code: "PL03",
    name: "Bottles, drums, jerrycans & buckets > 2 L",
  },
  {
    id: 6,
    litterCategoryId: 1,
    code: "PL04",
    name: "Plastic utensils",
  },
  {
    id: 7,
    litterCategoryId: 1,
    code: "PL04.01",
    name: "Straws",
  },
  {
    id: 8,
    litterCategoryId: 1,
    code: "PL05",
    name: "Drink package rings",
  },
  {
    id: 9,
    litterCategoryId: 1,
    code: "PL06",
    name: "Food containers",
  },
  {
    id: 10,
    litterCategoryId: 1,
    code: "PL07",
    name: "Plastic bags",
  },
  {
    id: 11,
    litterCategoryId: 1,
    code: "PL07.01",
    name: "Food wrappers",
  },
  {
    id: 12,
    litterCategoryId: 1,
    code: "PL08",
    name: "Toys, sport, & recreation",
  },
  {
    id: 13,
    litterCategoryId: 1,
    code: "PL09",
    name: "Gloves",
  },
  {
    id: 14,
    litterCategoryId: 1,
    code: "PL10",
    name: "Cigarette lighters",
  },
  {
    id: 15,
    litterCategoryId: 1,
    code: "PL11",
    name: "Cigarettes, butts & filters",
  },
  {
    id: 16,
    litterCategoryId: 1,
    code: "PL12",
    name: "Syringes",
  },
  {
    id: 17,
    litterCategoryId: 1,
    code: "PL12.1",
    name: "Medical waste",
  },
  {
    id: 18,
    litterCategoryId: 1,
    code: "PL13",
    name: "Baskets, crates & trays",
  },
  {
    id: 19,
    litterCategoryId: 1,
    code: "PL14",
    name: "Plastic buoys",
  },
  {
    id: 20,
    litterCategoryId: 1,
    code: "PL15",
    name: "Mesh bags",
  },
  {
    id: 21,
    litterCategoryId: 1,
    code: "PL16",
    name: "Plastic sheeting",
  },
  {
    id: 22,
    litterCategoryId: 1,
    code: "PL17",
    name: "Fishing gear",
  },
  {
    id: 23,
    litterCategoryId: 1,
    code: "PL18",
    name: "Fishing line",
  },
  {
    id: 24,
    litterCategoryId: 1,
    code: "PL19",
    name: "Rope",
  },
  {
    id: 25,
    litterCategoryId: 1,
    code: "PL20",
    name: "Fishing nets",
  },
  {
    id: 26,
    litterCategoryId: 1,
    code: "PL21",
    name: "Strapping bands & tape",
  },
  {
    id: 27,
    litterCategoryId: 1,
    code: "PL22",
    name: "Fibreglass fragments",
  },
  {
    id: 28,
    litterCategoryId: 1,
    code: "PL23",
    name: "Resin pellets",
  },
  {
    id: 29,
    litterCategoryId: 1,
    code: "PL24",
    name: "Other Plastic",
  },
  {
    id: 30,
    litterCategoryId: 1,
    code: "PL24.01",
    name: "Unidentifiable hard plastic fragments",
  },
  {
    id: 31,
    litterCategoryId: 1,
    code: "PL24.02",
    name: "Pens",
  },
  {
    id: 32,
    litterCategoryId: 1,
    code: "PL24.03",
    name: "Clothes pegs",
  },
  {
    id: 33,
    litterCategoryId: 1,
    code: "PL24.04",
    name: "Lollipop sticks",
  },
  {
    id: 34,
    litterCategoryId: 1,
    code: "PL24.05",
    name: "Shotgun wadding & shells",
  },
  {
    id: 35,
    litterCategoryId: 1,
    code: "PL24.06",
    name: "Cable ties & zip ties",
  },
  {
    id: 36,
    litterCategoryId: 1,
    code: "PL24.07",
    name: "Gardening & farming related",
  },
  {
    id: 37,
    litterCategoryId: 1,
    code: "PL24.08",
    name: "Safety & construction related",
  },
  {
    id: 38,
    litterCategoryId: 1,
    code: "PL24.09",
    name: "Plastic vehicle parts",
  },
  {
    id: 39,
    litterCategoryId: 1,
    code: "PL24.10",
    name: "Parking tickets & receipts",
  },
  {
    id: 40,
    litterCategoryId: 2,
    code: "FP01",
    name: "Foam sponge",
  },
  {
    id: 41,
    litterCategoryId: 2,
    code: "FP02",
    name: "Polystyrene cups or food packs",
  },
  {
    id: 42,
    litterCategoryId: 2,
    code: "FP03",
    name: "Foam buoys",
  },
  {
    id: 43,
    litterCategoryId: 2,
    code: "FP04",
    name: "Polystyrene insulation or packaging",
  },
  {
    id: 44,
    litterCategoryId: 2,
    code: "FP05",
    name: "Other Foamed Plastic",
  },
  {
    id: 45,
    litterCategoryId: 2,
    code: "FP05.01",
    name: "Unidentifiable foamed plastic fragments",
  },
  {
    id: 46,
    litterCategoryId: 2,
    code: "FP05.02",
    name: "Ear plugs",
  },
  {
    id: 47,
    litterCategoryId: 3,
    code: "CL01",
    name: "Clothing, hats, gloves & towels",
  },
  {
    id: 48,
    litterCategoryId: 3,
    code: "CL01.01",
    name: "Footwear & shoes",
  },
  {
    id: 49,
    litterCategoryId: 3,
    code: "CL02",
    name: "Backpacks & bags",
  },
  {
    id: 50,
    litterCategoryId: 3,
    code: "CL03",
    name: "Canvas, sailcloth & sacking (hessian)",
  },
  {
    id: 51,
    litterCategoryId: 3,
    code: "CL04",
    name: "Rope, line or string (natural)",
  },
  {
    id: 52,
    litterCategoryId: 3,
    code: "CL05",
    name: "Carpet & furnishing",
  },
  {
    id: 53,
    litterCategoryId: 3,
    code: "CL06",
    name: "Other cloth",
  },
  {
    id: 54,
    litterCategoryId: 4,
    code: "GC01",
    name: "Construction material",
  },
  {
    id: 55,
    litterCategoryId: 4,
    code: "GC02",
    name: "Bottles & jars",
  },
  {
    id: 56,
    litterCategoryId: 4,
    code: "GC03",
    name: "Tableware",
  },
  {
    id: 57,
    litterCategoryId: 4,
    code: "GC04",
    name: "Light globes/bulbs",
  },
  {
    id: 58,
    litterCategoryId: 4,
    code: "GC05",
    name: "Fluorescent light tubes",
  },
  {
    id: 59,
    litterCategoryId: 4,
    code: "GC06",
    name: "Glass buoys",
  },
  {
    id: 60,
    litterCategoryId: 4,
    code: "GC07",
    name: "Glass or ceramic fragments",
  },
  {
    id: 61,
    litterCategoryId: 4,
    code: "GC08",
    name: "Other Glass & Ceramic",
  },
  {
    id: 62,
    litterCategoryId: 5,
    code: "ME01",
    name: "Tableware",
  },

  {
    id: 63,
    litterCategoryId: 5,
    code: "ME02",
    name: "Metal Bottle caps, lids & pull tabs",
  },
  {
    id: 64,
    litterCategoryId: 5,
    code: "ME03",
    name: "Aluminium drink cans",
  },
  {
    id: 65,
    litterCategoryId: 5,
    code: "ME04",
    name: "Other cans (<= 4 L)",
  },
  {
    id: 66,
    litterCategoryId: 5,
    code: "ME05",
    name: "Gas bottles, drums & buckets ( > 4 L)",
  },
  {
    id: 67,
    litterCategoryId: 5,
    code: "ME06",
    name: "Foil wrappers",
  },
  {
    id: 68,
    litterCategoryId: 5,
    code: "ME07",
    name: "Fishing related",
  },
  {
    id: 69,
    litterCategoryId: 5,
    code: "ME08",
    name: "Unidentifiable metal fragments",
  },
  {
    id: 70,
    litterCategoryId: 5,
    code: "ME09",
    name: "Construction material",
  },
  {
    id: 71,
    litterCategoryId: 5,
    code: "ME10",
    name: "Other Metal",
  },
  {
    id: 72,
    litterCategoryId: 5,
    code: "ME10.01",
    name: "Sharps, needles, lancets, metal catheters",
  },
  {
    id: 73,
    litterCategoryId: 5,
    code: "ME10.02",
    name: "Metal vehicle parts",
  },
  {
    id: 74,
    litterCategoryId: 6,
    code: "PC01",
    name: "Paper, newspapers & paper receipts",
  },
  {
    id: 75,
    litterCategoryId: 6,
    code: "PC02",
    name: "Cardboard boxes",
  },
  {
    id: 76,
    litterCategoryId: 6,
    code: "PC03",
    name: "Cups, food trays & wrappers",
  },
  {
    id: 77,
    litterCategoryId: 6,
    code: "PC04",
    name: "Fireworks",
  },
  {
    id: 78,
    litterCategoryId: 6,
    code: "PC05",
    name: "Other Paper & Cardboard",
  },
  {
    id: 79,
    litterCategoryId: 7,
    code: "RB01",
    name: "Balloons, tennis balls, footballs, dog toys etc",
  },
  {
    id: 80,
    litterCategoryId: 7,
    code: "RB02",
    name: "Rubber footwear",
  },
  {
    id: 81,
    litterCategoryId: 7,
    code: "RB03",
    name: "Rubber gloves",
  },
  {
    id: 82,
    litterCategoryId: 7,
    code: "RB04",
    name: "Tyres",
  },
  {
    id: 83,
    litterCategoryId: 7,
    code: "RB05",
    name: "Inner-tubes and rubber sheet",
  },
  {
    id: 84,
    litterCategoryId: 7,
    code: "RB06",
    name: "Rubber bands",
  },
  {
    id: 85,
    litterCategoryId: 7,
    code: "RB07",
    name: "Condoms",
  },
  {
    id: 86,
    litterCategoryId: 7,
    code: "RB08",
    name: "Other Rubber",
  },
  {
    id: 87,
    litterCategoryId: 7,
    code: "RB08.01",
    name: "Unidentifiable rubber fragments",
  },
  {
    id: 88,
    litterCategoryId: 8,
    code: "WD01",
    name: "Corks",
  },
  {
    id: 89,
    litterCategoryId: 8,
    code: "WD02",
    name: "Fishing traps and pots",
  },
  {
    id: 90,
    litterCategoryId: 8,
    code: "WD03",
    name: "Wooden utensils",
  },
  {
    id: 91,
    litterCategoryId: 8,
    code: "WD04",
    name: "Processed timber & pallet crates",
  },
  {
    id: 92,
    litterCategoryId: 8,
    code: "WD05",
    name: "Matches and wooden fireworks parts",
  },
  {
    id: 93,
    litterCategoryId: 8,
    code: "WD06",
    name: "Other Wood",
  },
  {
    id: 94,
    litterCategoryId: 9,
    code: "OT01",
    name: "Paraffin or wax",
  },
  {
    id: 95,
    litterCategoryId: 9,
    code: "OT02",
    name: "Sanitary items",
  },
  {
    id: 96,
    litterCategoryId: 9,
    code: "OT02.03",
    name: "Faeces",
  },
  {
    id: 97,
    litterCategoryId: 9,
    code: "OT02.05",
    name: "Personal care items",
  },
  {
    id: 98,
    litterCategoryId: 9,
    code: "OT03",
    name: "Appliances & electronics",
  },
  {
    id: 99,
    litterCategoryId: 9,
    code: "OT04",
    name: "Batteries (Household)",
  },
  {
    id: 100,
    litterCategoryId: 9,
    code: "OT05",
    name: "Other",
  },
  {
    id: 101,
    litterCategoryId: 9,
    code: "OT04.01",
    name: "Batteries (Non-household)",
  },
  {
    id: 102,
    litterCategoryId: 9,
    code: "OT05.02",
    name: "Boat parts",
  },
  {
    id: 103,
    litterCategoryId: 1,
    code: "PL07.02",
    name: "Unidentifiable soft plastic fragments",
  },
  {
    id: 104,
    litterCategoryId: 9,
    code: "OT02.01",
    name: "Cotton buds",
  },
  {
    id: 105,
    litterCategoryId: 3,
    code: "CL06.01",
    name: "Unidentifiable cloth fragments",
  },
  {
    id: 106,
    litterCategoryId: 7,
    code: "RB08.02",
    name: "Chewing gum",
  },
  {
    id: 107,
    litterCategoryId: 6,
    code: "PC01.01",
    name: "Unidentifiable paper and cardboard fragments",
  },
];


export function getDefaultFilterOptions(): FilterApiData {

  return {
    countries: Countries,
    regions: Regions,
    surveyAreas: SurveysAreas,
    materials: Materials,
    products: Products
  }

}

