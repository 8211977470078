import React, { useReducer, useEffect, useContext, useMemo } from "react";
import "./LocationTypeModalToggle.scss";
import { ModalToggleTitle } from "./ModalToggleTitle";
import ComparisonIcon from "../../assets/add_data.svg";
import {
  MultiSelectOptionSet,
  optionReducer,
} from "../Sidebar/CustomSelect/CustomSelectUtil";
import { RootStoreContext } from "../../store/rootStore";
import {
  ComparisonDataSet,
  LocationType,
  isValidArray,
} from "../../store/util";

type LocationTypeModalToggleProps = {
  setOne: MultiSelectOptionSet[];
  setTwo: MultiSelectOptionSet[];
};

export const LocationTypeModalToggle: React.FC<LocationTypeModalToggleProps> = ({
  setOne,
  setTwo,
}) => {
  const rootStore = useContext(RootStoreContext);

  const { setTempComparisonData } = rootStore.filterStore;

  const [setOneOptions, dispatchSetOneOption] = useReducer(
    optionReducer,
    setOne
  );
  const [setTwoOptions, dispatchSetTwoOption] = useReducer(
    optionReducer,
    setTwo
  );

  //On item selected
  function onSetOneItemSelected(option: MultiSelectOptionSet) {
    dispatchSetOneOption({ type: "toggle", option: option });
  }

  //On item selected
  function onSetTwoItemSelected(option: MultiSelectOptionSet) {
    dispatchSetTwoOption({ type: "toggle", option: option });
  }

  const dataSetOne = useMemo<LocationType[] | null>(() => {
    const selectedOptions: LocationType[] = setOneOptions
      .filter((item) => item.isSelected)
      .map((item) => ({ value: item.value, label: item.label }));

    if (isValidArray(selectedOptions)) {
      return selectedOptions;
    }
    return null;
    // eslint-disable-next-line 
  }, [setOneOptions]);

  const dataSetTwo = useMemo<LocationType[] | null>(() => {
    const selectedOptions: LocationType[] = setTwoOptions
      .filter((item) => item.isSelected)
      .map((item) => ({ value: item.value, label: item.label }));

    if(isValidArray(selectedOptions)) {
      return selectedOptions;
    }
    return null;
    // eslint-disable-next-line 
  }, [setTwoOptions]);

  useEffect(() => {
    setTempComparisonData({
      comparisonDataSet: ComparisonDataSet.LocationType,
      dataSetOne: dataSetOne,
      dataSetTwo: dataSetTwo,
    });
    // eslint-disable-next-line 
  }, [setOneOptions, setTwoOptions]);

  return (
    <div className="survey_modal_toggle">
      <ModalToggleTitle
        title="Location Type"
        subTitle="Define your location type comparison"
      />
      <div className="survey_set_container">
        <div className="set_1">
          <p className="set_title">SET 1</p>
          <div className="content">
            {setOneOptions.map((element: MultiSelectOptionSet) => (
              <div
                key={`${element.value}_${element.set}`}
                className="modal_checkbox_container"
              >
                <input
                  type="checkbox"
                  id={`${element.value}_${element.set}`}
                  checked={element.isSelected}
                  onChange={(event) => onSetOneItemSelected(element)}
                />
                <label htmlFor={`${element.value}_${element.set}`}>
                  {element.label}
                </label>
              </div>
            ))}
          </div>
        </div>
        <img
          src={ComparisonIcon}
          alt="Comparison Icon"
          className="divider_image"
        />
        <div className="set_2">
          <p className="set_title">SET 2</p>
          <div className="content">
            {setTwoOptions.map((element: MultiSelectOptionSet) => (
              <div
                key={`${element.value}_${element.set}`}
                className="modal_checkbox_container"
              >
                <input
                  type="checkbox"
                  id={`${element.value}_${element.set}`}
                  checked={element.isSelected}
                  onChange={(event) => onSetTwoItemSelected(element)}
                />
                <label htmlFor={`${element.value}_${element.set}`}>
                  {element.label}
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
