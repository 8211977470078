import * as React from "react";
import { Tab, ITabProps } from "./Tab";
import { Panel, IPanelProps } from "./Panel";

export const TabList = [
  {
    label: "overview",
    value: "Overview",
  },
  {
    label: "litter_density",
    value: "Litter Density",
  },
  {
    label: "materials_and_products",
    value: "Materials & Products",
  },
  {
    label: "location",
    value: "Location",
  },
  {
    label: "survey_effort",
    value: "Survey Effort",
  },
];

interface ITabsContext {
  activeTab: string;
  setActiveTab: (label: string) => void;
}

interface ITabsComposition {
  Tab: React.FC<ITabProps>;
  Panel: React.FC<IPanelProps>;
}

const TabsContext = React.createContext<ITabsContext | undefined>(undefined);

const Tabs: React.FC & ITabsComposition = (props) => {
  //Initial state setup
  const [activeTab, setActiveTab] = React.useState(TabList[0].label);
  
  const memoizedContextValue = React.useMemo(
    () => ({
      activeTab,
      setActiveTab,
    }),
    [activeTab, setActiveTab]
  );

  return (
    <TabsContext.Provider value={memoizedContextValue}>
      {props.children}
    </TabsContext.Provider>
  );
};

export const useTabs = (): ITabsContext => {
  const context = React.useContext(TabsContext);
  if (!context) {
    throw new Error("This component must be used within a <Tabs> component.");
  }
  return context;
};

Tabs.Tab = Tab;
Tabs.Panel = Panel;

export { Tabs };
