import React from "react";
import './ModalHeaderButton.scss';

type ModalHeaderButtonProps = {
  onSelected: () => void;
  controlsLabel: string;
  isSelected: boolean;
  highlightedImg: string;
  iconImg: string;
  label: string;
};

export const ModalHeaderButton: React.FC<ModalHeaderButtonProps> = ({
  onSelected,
  controlsLabel,
  isSelected,
  iconImg,
  label,
  highlightedImg
}) => {
  return (
    <button
      className={`${isSelected ? 'modal_selected': 'modal_header_btn'}`}
      onClick={onSelected}
      aria-controls={controlsLabel}
      aria-expanded={isSelected}
    >
      <img src={isSelected ? highlightedImg : iconImg} alt="Survey Icon" />
      <p> {label}</p>
    </button>
  );
};
