import React, { useState } from "react";
import "./CustomCalendar.scss";
import moment from "moment";
import LeftArrow from "../../../assets/left_arrow_white.svg";
import RightArrow from "../../../assets/right_arrow_white.svg";

type CustomCalendarProps = {
  minDate?: moment.Moment;
  maxDate: moment.Moment;
  selectedDate?: moment.Moment;
  onDateSelected: (chosenDate: moment.Moment | undefined) => void;
};

const daysOfWeek = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];

// Keep Monday as Start
function startOfWeek(date: moment.Moment) {
  if (date.isoWeekday() !== 1) {
    return moment(date).startOf("day").isoWeekday(1);
  }
  return moment(date).startOf("day");
}

function endOfWeek(date: moment.Moment) {
  return moment(date).startOf("day").isoWeekday(7);
}

function isValidDate(date: any): boolean {
  return date !== null && date !== undefined && date.isValid();
}

function isDateWithinRange(
  date: moment.Moment,
  maxDate: moment.Moment,
  minDate: moment.Moment | undefined
): boolean {
  if (isValidDate(maxDate) && isValidDate(minDate)) {
    if (date.isSame(maxDate, "day")) {
      return true;
    } else {
      return date.isBetween(minDate, maxDate, "day");
    }
  }
  return date.isSameOrBefore(maxDate, "day");
}

export const CustomCalendar: React.FC<CustomCalendarProps> = ({
  selectedDate,
  onDateSelected,
  maxDate,
  minDate,
}) => {
  const [currentMonth, setCurrentMonth] = useState<moment.Moment>(
    selectedDate !== null && selectedDate !== undefined
      ? selectedDate
      : moment()
  );

  function dateCell(
    day: moment.Moment,
    otherMonth: boolean,
    aboveRange = false
  ) {
    return (
      <span
        className={`date_picker_day ${
          otherMonth ? "date_picker_other_month" : ""
        } ${aboveRange ? "date_picker_above_max_date" : ""} ${
          selectedDate !== null &&
          selectedDate !== undefined &&
          selectedDate.isValid() &&
          day.isSame(selectedDate, "day")
            ? "date_highlight_middle"
            : ""
        }`}
        key={day.format()}
      >
        <a
          className={`date_picker_link`}
          href="# "
          onClick={(event) => {
            event.preventDefault();
            onDateSelected(day);
          }} //This is to be defined
        >
          {day.date()}
        </a>
      </span>
    );
  }

  const daysInMonth = currentMonth.daysInMonth();
  const monthDays: JSX.Element[] = [];

  const first = moment(currentMonth).startOf("month");
  const start = startOfWeek(first);

  while (start.isBefore(first)) {
    monthDays.push(dateCell(moment(start), true));
    start.add(1, "days");
  }

  for (let i = 0; i < daysInMonth; i++) {
    const day = moment(first).add(i, "days").startOf("day");
    if (isDateWithinRange(day, maxDate, minDate)) {
      monthDays.push(dateCell(day, false));
    } else {
      monthDays.push(dateCell(day, false, true));
    }
  }

  const end = moment(currentMonth).endOf("month").startOf("day");
  const endOfLastWeek = endOfWeek(end);
  end.add(1, "days");

  while (end.isBefore(endOfLastWeek) || end.isSame(endOfLastWeek)) {
    monthDays.push(dateCell(moment(end), true));
    end.add(1, "days");
  }

  const daysElem = daysOfWeek.map((d) => (
    <span className={`date_picker_header`} key={d}>
      {d}
    </span>
  ));

  const goToPreviousMonth = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (minDate === null || minDate === undefined) {
      setCurrentMonth(moment(currentMonth).subtract(1, "months"));
    } else {
      if (
        moment(currentMonth)
          .subtract(1, "months")
          .isSameOrAfter(minDate, "month")
      ) {
        setCurrentMonth(moment(currentMonth).subtract(1, "months"));
      }
    }
  };

  const goToNextMonth = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (maxDate === null || maxDate === undefined) {
      setCurrentMonth(moment(currentMonth).add(1, "months"));
    } else {
      if (
        moment(currentMonth).add(1, "months").isSameOrBefore(maxDate, "month")
      ) {
        setCurrentMonth(moment(currentMonth).add(1, "months"));
      }
    }
  };

  return (
    <div>
      <div className={`date_picker_header date_picker_title_bar`}>
        <a href="# " className={`arrow`} onClick={goToPreviousMonth}>
          <img src={LeftArrow} alt="Left Arrow" className={`arrow_left`} />
        </a>
        <div className={`date_month`}>{currentMonth.format("MMMM YYYY")}</div>
        <a href="# " className={`arrow`} onClick={goToNextMonth}>
          <img src={RightArrow} alt="Right Arrow" className={`arrow_right`} />
        </a>
      </div>
      <div className={`date_picker_grid`}>
        {daysElem}
        {monthDays}
      </div>
    </div>
  );
};
