import React, { useRef, useState, useLayoutEffect, useEffect, useContext } from "react";
import "./MixedChart.scss";
import { Line, ChartData } from "react-chartjs-2";
import moment from "moment";
import Chart, { ChartOptions } from "chart.js";
import { CustomTooltipProps, CustomTooltip } from "./CustomTooltip";
import { ToggleContent } from "../CustomToggle/CustomToggle";
import { isValidObject } from "../../store/overview/overviewUtil";
import { isValidArray } from "../../store/util";
import { useWindowSize } from "../TreemapChart/TreemapChart";
import { RootStoreContext } from "../../store/rootStore";
import { observer } from "mobx-react-lite";


export interface MixedChartData {
  date: moment.Moment;
  lineChartData: number; //Will be renamed in due time
  scatterChartData: number; //Will be renamed in due time
}

type MixedChartProps = {
  mixedChartData?: MixedChartData[];
  comparisonData?: {
    dataSetOne: MixedChartData[];
    dataSetTwo: MixedChartData[];
  };
  toggleContent: ToggleContent;
};

export const MixedChart: React.FC<MixedChartProps> = observer(({
  mixedChartData,
  comparisonData,
  toggleContent,
}) => {
  const rootStore = useContext(RootStoreContext);
  const  { isStormwaterSelected } = rootStore.commonStore;
  const chartContainerRef = useRef(null);
  let chartRef = useRef(null);
  const [toolTipData, setToolTipData] = useState<CustomTooltipProps | null>(
    null
  );
  // eslint-disable-next-line 
  const [screenWidth, screenHeight] = useWindowSize();
  let tickYears: string[] = [];

  useOnClickOutside(chartContainerRef, () => setToolTipData(null));

  Chart.defaults.global.defaultFontFamily = "TTNormsRegular";
  Chart.defaults.global.defaultFontSize = 14;
  Chart.defaults.global.defaultFontColor = "#4C5862";

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  // eslint-disable-next-line
  function getPositionForCustomTooltip(
    top: number,
    left: number
  ): { top: number; left: number } {
    const { width } = getWindowDimensions();
    if (left + 200 > width) {
      return {
        top: top - 175,
        left: left < 200 ? (left < 100 ? left : left - 100) : left - 200,
      };
    }

    return {
      top: top - 175,
      left: left < 100 ? left : left - 100,
    };
  }

  // eslint-disable-next-line
  function getLineChartDataFor(
    date: moment.Moment,
    numberOfSurveys: number
  ): number {
    if (isValidObject(mixedChartData)) {
      const foundElement = mixedChartData.find(
        (item) =>
          item.date.isSame(date, "month") &&
          item.scatterChartData === numberOfSurveys
      );

      if (foundElement !== null && foundElement !== undefined) {
        return foundElement.lineChartData;
      }
    }

    if (isValidObject(comparisonData)) {
      if (isValidArray(comparisonData.dataSetOne)) {
        const foundElement = comparisonData.dataSetOne.find(
          (item) =>
            item.date.isSame(date, "month") &&
            item.scatterChartData === numberOfSurveys
        );

        if (foundElement !== null && foundElement !== undefined) {
          return foundElement.lineChartData;
        }
      }

      if (isValidArray(comparisonData.dataSetTwo)) {
        const foundElementSetTwo = comparisonData.dataSetTwo.find(
          (item) =>
            item.date.isSame(date, "month") &&
            item.scatterChartData === numberOfSurveys
        );

        if (foundElementSetTwo !== null && foundElementSetTwo !== undefined) {
          return foundElementSetTwo.lineChartData;
        }
      }
    }

    return 0;
  }

  const getLineChartData = (canvas: any): ChartData<any> => {
    const context = canvas.getContext("2d");
    const gradientStroke = context.createLinearGradient(0, 440, 0, 0);
    gradientStroke.addColorStop(1, "rgba(121, 70, 230, 0.41)");
    gradientStroke.addColorStop(0, "rgba(121, 70, 230, 0)");

    if (isValidObject(comparisonData)) {
      let mixedChartComparisonDataSet: any = [];
      let labelComparisonDataSet: moment.Moment[] = [];

      if (isValidArray(comparisonData.dataSetOne)) {
        labelComparisonDataSet.push(
          ...comparisonData.dataSetOne.map((item) => item.date)
        );
      }

      if (isValidArray(comparisonData.dataSetTwo)) {
        labelComparisonDataSet.push(
          ...comparisonData.dataSetTwo.map((item) => item.date)
        );
      }

      let uniqueLabelComparisonDataSet = labelComparisonDataSet.filter(
        (v, i, a) => a.findIndex((t) => t.isSame(v, "month")) === i
      ).sort((a, b) => moment(a).diff(b));

      if (isValidArray(comparisonData.dataSetOne)) {

        let comparsionDataSetOneData = uniqueLabelComparisonDataSet.map((element) => {
          const foundElement = comparisonData.dataSetOne.find((item) =>
            item.date.isSame(element, "month")
          );

          if (isValidObject(foundElement)) {
            return foundElement.lineChartData;
          }
          return null;
        })
        
        mixedChartComparisonDataSet.push({
          label: "Litter Items (Set One)",
          order: 1,
          yAxisID: "LineChart_1",
          data: comparsionDataSetOneData,
          fill: false, //Use fill: false to remove background in chartJS
          borderColor: "#5E2B9D",
          pointBackgroundColor: "#5E2B9D",
          pointRadius: comparsionDataSetOneData.filter((item) => item !== null).length > 1 ? 0 : 5, //This makes all the line chart points go away
          spanGaps: true
        });
      }

      if (isValidArray(comparisonData.dataSetTwo)) {

        let comparisonDataSetTwoData = uniqueLabelComparisonDataSet.map((element) => {
          const foundElement = comparisonData.dataSetTwo.find(
            (item) =>
              item.date.isSame(element, "month")
          );
  
          if (isValidObject(foundElement)) {
            return foundElement.lineChartData;
          }
          return null
        });

        mixedChartComparisonDataSet.push({
          label: "Litter Items (Set Two)",
          order: 3,
          yAxisID: "LineChart_1",
          data: comparisonDataSetTwoData,
          fill: false, //Use fill: false to remove background in chartJS
          borderColor: "#F05282",
          pointBackgroundColor: "#F05282",
          pointRadius: comparisonDataSetTwoData.filter((item) => item !== null).length > 1 ? 0 : 5,
          spanGaps: true
        });
        
      }

      return {
        labels: uniqueLabelComparisonDataSet
          .map((item) => item.toDate()),
        datasets: mixedChartComparisonDataSet,
      };
    }
    return {
      labels: mixedChartData.map((item) => item.date.toDate()),
      datasets: [
        {
          label: "Litter Items",
          order: 1,
          yAxisID: "LineChart_1",
          data: mixedChartData.map((item) => item.lineChartData),
          backgroundColor: gradientStroke, //Use fill: false to remove background in chartJS
          borderColor: "#5E2B9D",
          pointRadius: mixedChartData.length > 1 ? 0 : 5, //This makes all the line chart points go away
          pointBackgroundColor: "#5E2B9D"
        }
      ],
    };
  };

  const options: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          id: "LineChart_1",
          type: "linear",
          position: "left",
          ticks: {
            beginAtZero: true,
            padding: 20,
            maxTicksLimit: 6,
          },
          gridLines: {
            borderDash: [8, 4],
            color: "#DDD9ED",
          },
          scaleLabel: {
            display: screenWidth > 500,
            labelString: `Average Litter ${isStormwaterSelected ? "Quantity" : "Density"}`
          }
        }
      ],
      xAxes: [
        {
          type: "time",
          distribution: "series",
          time: {
            parser: "YYYY-MM-DD",
            unit: "day",
            displayFormats: {
              day: "MMMM YYYY",
            },
          },
          ticks: {
            source: "data",
            padding: 20,
            beginAtZero: false,
            callback: function (value: string | number) {
              //Always called in ascending order
              const { width } = getWindowDimensions();
              if (width <= 1300) {
                return value.toString().substring(0, 3); //Sparing ugliness
              }
              if (tickYears.length > 0) {
                if (tickYears.includes(value.toString().substr(-4))) {
                  return value.toString().slice(0, -4);
                }
              }
              tickYears.push(value.toString().substr(-4));
              return value.toString();
            },
          },
          gridLines: {
            display: false,
          }
        }
      ],
    },
    tooltips: {
      enabled: false
    },
    animation: {
      duration: 0, //For the weird behaviour of the last point in scatter chart
    },
  };

  useLayoutEffect(() => {
    //Hides existing tooltip data
    function hideTooltip(event: any) {
      setToolTipData(null);
    }

    // Given the time frame,
    // The snippet for getting reference to the <main> content
    // is a bit hacky at the moment
    // Also cannot use window.scroll as height is set.
    // so need to get reference specifically for <main> content
    // WARNING : Do not use another main tag.

    const main = document.getElementById("main_content_id");
    if (main !== null && main !== undefined) {
      main.addEventListener("scroll", hideTooltip);
    }

    window.addEventListener("resize", hideTooltip);
    window.addEventListener("scroll", hideTooltip); //This is for mobile

    return () => {
      window.removeEventListener("resize", hideTooltip);
      window.removeEventListener("scroll", hideTooltip);
      if (main !== null && main !== undefined) {
        main.removeEventListener("scroll", hideTooltip);
      }
    };
  }, []);

  return (
    <div className="mixed_chart" ref={chartContainerRef}>
      <Line data={getLineChartData} options={options} ref={chartRef} />
      {toolTipData !== null ? <CustomTooltip {...toolTipData} /> : null}
    </div>
  );
});

//Custom hook for listening to outside click events
function useOnClickOutside(ref: any, handler: any) {
  useEffect(() => {
    const listener = (event: any) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      handler(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
}
