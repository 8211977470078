import React from "react";
import "./InfoItem.scss";

type InfoItemProps = {
  title: string;
  value: number;
  description?: string;
};

export const InfoItem: React.FC<InfoItemProps> = ({
  title,
  value,
  description,
}) => {
  return (
    <div className="info_item">
      <p className="title">{title}</p>
      <div className="content">
        <p className="value">{value.toLocaleString()}</p>
        {description !== null && description !== undefined ? (
          <p className="description">{description}</p>
        ) : null}
      </div>
    </div>
  );
};
