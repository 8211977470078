import React from "react";
import "./SurveyLocations.scss";
import { GeoChartData, GeoChart } from "../GeoChart/GeoChart";
import {
  InfoItemData,
  InfoGroup,
  LitterDensityItemUIData,
  LitterQuantityItemUIData,
} from "../InfoGroup/InfoGroup";

type SurveyLocationsProps = {
  geoData: GeoChartData[];
  infoGroup: InfoItemData[];
  litterDensityData?: LitterDensityItemUIData;
  isComparison?: boolean;
  litterQuantityData?: LitterQuantityItemUIData;
};

export const SurveyLocations: React.FC<SurveyLocationsProps> = ({
  geoData,
  infoGroup,
  isComparison,
  litterDensityData,
  litterQuantityData,
}) => {
  return (
    <section
      className={
        isComparison
          ? "survey_locations_section__comparison"
          : "survey_locations_section"
      }
    >
      <GeoChart locationList={geoData} />
      <InfoGroup
        itemData={infoGroup}
        stackedUI={true}
        litterDensityInfo={litterDensityData}
        litterQuantityInfo={litterQuantityData}
      />
    </section>
  );
};
