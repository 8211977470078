import React, { useContext } from "react";
import "./DataSetView.scss";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../store/rootStore";

type DataSetViewProps = {
  viewTitle: string;
  locationType: {
    value: string;
    isFilterApplied: boolean
  };
  location: {
    value: string;
    isFilterApplied: boolean
  };
  litterType: {
    value: string;
    isFilterApplied: boolean
  };
  timeRange: {
    value: string;
    isFilterApplied: boolean
  };
};

export const DataSetView: React.FC<DataSetViewProps> = observer(({
  viewTitle,
  locationType,
  location,
  litterType,
  timeRange,
}) => {
  const rootStore = useContext(RootStoreContext);
  const { shoudRenderLocationType } = rootStore.commonStore;

  return (
    <div className="data_set_view">
      <h5>{viewTitle}</h5>
      <div className="details_container">
        {
          shoudRenderLocationType && 
          <>
            <p className={`${locationType.isFilterApplied ? "data_set_view_filter_applied" : ""}`}>{locationType.value}</p>
            <div className="vertical_divider"> </div>
          </>
        }
        <p className={`${location.isFilterApplied ? "data_set_view_filter_applied" : ""}`}> {location.value} </p>
        <div className="vertical_divider"> </div>
        <p className={`${litterType.isFilterApplied ? "data_set_view_filter_applied" : ""}`}> {litterType.value} </p>
        <div className="vertical_divider"> </div>
        <p className={`${timeRange.isFilterApplied ? "data_set_view_filter_applied" : ""}`}> {timeRange.value} </p>
      </div>
    </div>
  );
});
