import React, { useState, useLayoutEffect, useEffect } from "react";
import "./TreemapChart.scss";
import Chart from "react-google-charts";
import {
  TreemapLegend,
  getColorForValue,
  getCustomToolTipForGoogleChart,
} from "./TreemapUtils";
import { CustomLegend } from "./CustomLegend";

export interface TreemapData {
  id: string;
  parent: string;
  itemValue: number;
  color: number;
}

type TreemapChartProps = {
  treemapList: TreemapData[];
  isComparison?: boolean;
  isWeightData: boolean;
};

export function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

export const TreemapChart: React.FC<TreemapChartProps> = ({
  treemapList,
  isComparison,
  isWeightData
}) => {
  const [redrawKey, setRedrawKey] = useState(false);
  // eslint-disable-next-line 
  const [screenWidth, screenHeight] = useWindowSize();

  function getLegendData(): TreemapLegend[] {
    return treemapList
      .filter(
        (item) =>
          item.id !== null && item.parent !== null && item.itemValue === null
      )
      .map((item) => {
        return {
          legendName: item.id,
          legendColor: getColorForValue(getColorPercent(item.id)),
          legendPercent: parseFloat(getCategoryPercent(item.id).toFixed(2)),
        };
      });
  }

  function getCategoryPercent(name: string) {
    const sumOfAllValues = treemapList
      .map((element) => element.itemValue)
      .reduce((a, b) => a + b, 0);

    const sumOfCategoryValues = treemapList
      .filter((element) => element.parent === name)
      .map((element) => element.itemValue)
      .reduce((a, b) => a + b, 0);

    return (sumOfCategoryValues / sumOfAllValues) * 100;
  }

  function getColorPercent(name: string) {
    const element = treemapList.find((element) => element.id === name);
    if (element !== null && element !== undefined) {
      const maxColorValue = Math.max(
        ...treemapList.map((element) => element.color)
      );
      return element.color / maxColorValue;
    }
    return 0;
  }

  useEffect(() => {
    setRedrawKey((prevKey) => !prevKey);
  }, [screenWidth]);

  return (
    <div
      className={
        isComparison ? "treemap_container__comparison" : "treemap_container"
      }
    >
      <div className="chart">
        <Chart
          key={redrawKey.toString()}
          width={"100%"}
          height={"100%"}
          chartType="TreeMap"
          loader={<div>Loading Chart</div>}
          data={[
            ["ID", "Parent", "Value", "Color"],
            ...treemapList.map((item: TreemapData) => [
              item.id,
              item.parent,
              item.itemValue,
              item.color,
            ]),
          ]}
          options={{
            highlightOnMouseOver: false,
            maxDepth: 2,
            maxPostDepth: 3,
            minColor: "#65A9FF",
            midColor: "#FBD4E0",
            maxColor: "#35D8BC",
            noColor: "#fdc058",
            fontSize: "14px",
            fontFamily: "TTNormsBold",
            headerHeight: 0,
            showScale: false,
            useWeightedAverageForAggregation: true,
            generateTooltip: (row: any, size: number, value: any) =>
              getCustomToolTipForGoogleChart(treemapList[row].id, size, isWeightData),
          }}
          chartEvents={[{
            eventName: 'select',
            callback: () => {
              //Redraw chart to prevent click
              setRedrawKey((prevKey) => !prevKey);
            }
          }]}
          rootProps={{ "data-id": "2" }}
        />
      </div>
      <CustomLegend items={getLegendData()} isComparison={isComparison} />
    </div>
  );
};
