import { LoadingStatus, IFilter } from "../util";
import { TreemapData } from "../../components/TreemapChart/TreemapChart";

export interface StoreData {
    data: MaterialsAndProducts[];
    filtersApplied: IFilter;
}

export interface TotalItemsAndWeightUIData {
    loadingStatus: LoadingStatus;
    totalItems: number;
    totalWeight: number; //This is in KG
}

export interface MaterialsAndProducts {
    productName: string;
    litterCategory: string;
    totalItems: number;
    totalWeight: number;
    percentage: number;
    percentageItemWeight: number;
}

export interface LitterTypeItemTableRow {
    product: string;
    material: string;
    totalItems: number;
    percentage: string;
}

export interface LitterTypeWeightTableRow {
    product: string;
    material: string;
    totalWeight: string;
    percentage: string;
}

export interface LitterTypeTableRow {
    product: string;
    material: string;
    totalItems: number;
    totalWeight: string;
    percentOfTotal: string;
}

export interface LitterTypeTableData {
    loadingStatus: LoadingStatus;
    itemData: LitterTypeItemTableRow[];
    weightData: LitterTypeWeightTableRow[];
}


export interface LitterDensityPerTypeData {
    loadingStatus: LoadingStatus;
    itemsList: TreemapData[];
    weightList: TreemapData[];
}

export function isAllDataEntriesZero(data: MaterialsAndProducts[], isItems: boolean): boolean {
    if(isItems) {
        return data.every(item => item.totalItems === 0)
    } else {
        //Then evaluate weight
        return data.every(item => item.totalWeight === 0)
    }
}