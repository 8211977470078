import "./TimeModalToggle.scss";
import React, { useState, useContext, useEffect, useMemo } from "react";
import { ModalToggleTitle } from "./ModalToggleTitle";
import { CalendarDropdown } from "../Sidebar/CustomCalendar/CalendarDropdown";
import ComparisonIcon from "../../assets/add_data.svg";
import { RootStoreContext } from "../../store/rootStore";
import { ComparisonDataSet, DateFilter } from "../../store/util";
import moment from "moment";

type TimeToggleModalProps = {
  startDateSetOne?: moment.Moment;
  endDateSetOne?: moment.Moment;
  startDateSetTwo?: moment.Moment;
  endDateSetTwo?: moment.Moment;
};

export const TimeModalToggle: React.FC<TimeToggleModalProps> = ({
  startDateSetOne,
  endDateSetOne,
  startDateSetTwo,
  endDateSetTwo,
}) => {
  const rootStore = useContext(RootStoreContext);

  const { setTempComparisonData } = rootStore.filterStore;

  const [localStartDateSetOne, setLocalStartDateSetOne] = useState<
    moment.Moment | undefined
  >(startDateSetOne);
  const [localEndDateSetOne, setLocalEndDateSetOne] = useState<
    moment.Moment | undefined
  >(endDateSetOne);

  const [localStartDateSetTwo, setLocalStartDateSetTwo] = useState<
    moment.Moment | undefined
  >(startDateSetTwo);

  const [localEndDateSetTwo, setLocalEndDateSetTwo] = useState<
    moment.Moment | undefined
  >(endDateSetTwo);

  const [setOneStartDateContainerKey, setSetOneStartDateContainerKey] = useState("set_one_start_calendar_closed");
  const [setOneEndDateContainerKey, setSetOneEndDateContainerKey] = useState("set_one_end_calendar_closed");
  const [setTwoStartDateContainerKey, setSetTwoStartDateContainerKey] = useState("set_two_start_calendar_closed");
  const [setTwoEndDateContainerKey, setSetTwoEndDateContainerKey] = useState("set_two_end_calendar_closed");

  function onStartDateSelectedSetOne(
    chosenDate: moment.Moment | undefined
  ): void {
    setLocalStartDateSetOne(chosenDate);
    setSetOneStartDateContainerKey(setOneStartDateContainerKey === "set_one_start_calendar_closed" ? "set_one_start_calendar_open" : "set_one_start_calendar_closed")
  }

  function onStartDateSelectedSetTwo(
    chosenDate: moment.Moment | undefined
  ): void {
    setLocalStartDateSetTwo(chosenDate);
    setSetTwoStartDateContainerKey(setTwoStartDateContainerKey === "set_two_start_calendar_closed" ? "set_two_start_calendar_open" : "set_two_start_calendar_closed")
  }

  function onEndDateSelectedSetOne(
    chosenDate: moment.Moment | undefined
  ): void {
    setLocalEndDateSetOne(chosenDate);
    setSetOneEndDateContainerKey(setOneEndDateContainerKey === "set_one_end_calendar_closed" ? "set_one_end_calendar_open" : "set_one_end_calendar_closed")
  }

  function onEndDateSelectedSetTwo(
    chosenDate: moment.Moment | undefined
  ): void {
    setLocalEndDateSetTwo(chosenDate);
    setSetTwoEndDateContainerKey(setTwoEndDateContainerKey === "set_two_end_calendar_closed" ? "set_two_end_calendar_open" : "set_two_end_calendar_closed")
  }

  const dataSetOne = useMemo<DateFilter | null>(() => {
    if (
      localStartDateSetOne !== null &&
      localStartDateSetOne !== undefined &&
      localStartDateSetOne.isValid()
    ) {
      if (
        localEndDateSetOne !== null &&
        localEndDateSetOne !== undefined &&
        localEndDateSetOne.isValid()
      ) {
        return {
          startDate: localStartDateSetOne,
          endDate: localEndDateSetOne,
        };
      }

      return {
        startDate: localStartDateSetOne,
        endDate: moment(),
      };
    }

    return null;
  }, [localStartDateSetOne, localEndDateSetOne]);

  const dataSetTwo = useMemo<DateFilter | null>(() => {
    if (
      localStartDateSetTwo !== null &&
      localStartDateSetTwo !== undefined &&
      localStartDateSetTwo.isValid()
    ) {
      if (
        localEndDateSetTwo !== null &&
        localEndDateSetTwo !== undefined &&
        localEndDateSetTwo.isValid()
      ) {
        return {
          startDate: localStartDateSetTwo,
          endDate: localEndDateSetTwo,
        };
      }

      return {
        startDate: localStartDateSetTwo,
        endDate: moment(),
      };
    }

    return null;
  }, [localStartDateSetTwo, localEndDateSetTwo]);

  useEffect(() => {
    setTempComparisonData({
      comparisonDataSet: ComparisonDataSet.DateRange,
      dataSetOne: dataSetOne,
      dataSetTwo: dataSetTwo,
    });
    // eslint-disable-next-line 
  }, [
    localStartDateSetOne,
    localStartDateSetTwo,
    localEndDateSetTwo,
    localEndDateSetOne,
  ]);

  return (
    <div className="time_modal_toggle">
      <ModalToggleTitle
        title="Date Range Comparison"
        subTitle="Define your date range comparison"
      />
      <div className="time_set_container">
        <div className="set_1">
          <p className="set_title">SET 1</p>
          <div className="select_container">
            <CalendarDropdown
              key={setOneStartDateContainerKey}
              selectedDate={localStartDateSetOne}
              onDateSelected={onStartDateSelectedSetOne}
              defaultTitle={`Start Date`}
              maxDate={
                localEndDateSetOne !== null &&
                localEndDateSetOne !== undefined &&
                localEndDateSetOne.isValid()
                  ? localEndDateSetOne
                  : moment()
              }
              isModal={true}
            />
            <CalendarDropdown
              key={setOneEndDateContainerKey}
              selectedDate={localEndDateSetOne}
              onDateSelected={onEndDateSelectedSetOne}
              defaultTitle={`End Date`}
              isModal={true}
              minDate={localStartDateSetOne}
              maxDate={moment()}
              isDisabled={
                !(
                  localStartDateSetOne !== null &&
                  localStartDateSetOne !== undefined &&
                  localStartDateSetOne.isValid()
                )
              }
            />
          </div>
        </div>
        <img
          src={ComparisonIcon}
          alt="Comparison Icon"
          className="divider_image"
        />
        <div className="set_2">
          <p className="set_title">SET 2</p>
          <div className="select_container">
            <CalendarDropdown
              key={setTwoStartDateContainerKey}
              selectedDate={localStartDateSetTwo}
              onDateSelected={onStartDateSelectedSetTwo}
              defaultTitle={`Start Date`}
              isModal={true}
              maxDate={
                localEndDateSetTwo !== null &&
                localEndDateSetTwo !== undefined &&
                localEndDateSetTwo.isValid()
                  ? localEndDateSetTwo
                  : moment()
              }
            />
            <CalendarDropdown
              key={setTwoEndDateContainerKey}
              selectedDate={localEndDateSetTwo}
              onDateSelected={onEndDateSelectedSetTwo}
              defaultTitle={`End Date`}
              isModal={true}
              minDate={localStartDateSetTwo}
              maxDate={moment()}
              isDisabled={
                !(
                  localStartDateSetTwo !== null &&
                  localStartDateSetTwo !== undefined &&
                  localStartDateSetTwo.isValid()
                )
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};
