import { RootStore } from "../rootStore";
import { observable, computed, action, runInAction, toJS } from "mobx";
import { LoadingStatus, isValidArray, IFilter } from "../util";
import {
  MaterialsAndProducts,
  LitterTypeTableData,
  LitterDensityPerTypeData,
  isAllDataEntriesZero,
  StoreData,
  TotalItemsAndWeightUIData,
} from "./materialAndProductUtil";
import { getMaterialsAndProducts } from "../api/agent";
import { TreemapData } from "../../components/TreemapChart/TreemapChart";
import { isValidObject, roundToTwo } from "../overview/overviewUtil";
import { DataSet } from "../../components/ComparisonCard/ComparisonCard";
import { convertToApiFilterData } from "../api/networkUtil";
import { shouldNotLoadData } from "../filter/filterUtil";
import { isWeightGreaterThanOneKg } from "../../components/TreemapChart/TreemapUtils";
import { sortArray } from "../../components/Table/TableUtil";

export default class MaterialAndProductStore {
  rootStore: RootStore;

  @observable loadingStatus: LoadingStatus = LoadingStatus.Loading;
  @observable defaultData: StoreData = {
    data: null,
    filtersApplied: null,
  };

  @observable loadingStatusDataSetOne: LoadingStatus = LoadingStatus.Loading;
  @observable dataSetOne: StoreData = {
    data: null,
    filtersApplied: null,
  };

  @observable loadingStatusDataSetTwo: LoadingStatus = LoadingStatus.Loading;
  @observable dataSetTwo: StoreData = {
    data: null,
    filtersApplied: null,
  };

  @observable isComparisonApplied = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action handleFilterChanges = (filtersApplied: IFilter) => {
    if (isValidObject(filtersApplied)) {
      if (isValidObject(filtersApplied.comparisonData)) {
        //that means there is a comparison present.
        this.isComparisonApplied = true;
        this.loadData(DataSet.SET_ONE, filtersApplied);
        this.loadData(DataSet.SET_TWO, filtersApplied);
      } else {
        this.isComparisonApplied = false;
        this.loadData(DataSet.DEFAULT, filtersApplied);
      }
    } else {
      this.isComparisonApplied = false;
      this.loadData(DataSet.DEFAULT, filtersApplied);
    }
  };

  @action loadData = async (dataSet: DataSet, filtersApplied: IFilter) => {
    switch (dataSet) {
      case DataSet.DEFAULT:
        if (
          shouldNotLoadData(
            this.loadingStatus,
            this.defaultData.filtersApplied,
            filtersApplied
          )
        ) {
          return;
        }
        this.loadingStatus = LoadingStatus.Loading;
        try {
          const responseData: MaterialsAndProducts[] = await getMaterialsAndProducts(
            convertToApiFilterData(filtersApplied, DataSet.DEFAULT)
          );
          runInAction(() => {
            this.defaultData = {
              data: responseData,
              filtersApplied: toJS(filtersApplied),
            };
            this.loadingStatus = LoadingStatus.Success;
          });
        } catch (error) {
          runInAction(() => {
            this.defaultData = {
              data: null,
              filtersApplied: null,
            };
            this.loadingStatus = LoadingStatus.Failed;
          });
        }
        break;
      case DataSet.SET_ONE:
        if (
          shouldNotLoadData(
            this.loadingStatusDataSetOne,
            this.dataSetOne.filtersApplied,
            filtersApplied
          )
        ) {
          return;
        }
        this.loadingStatusDataSetOne = LoadingStatus.Loading;
        try {
          const responseData: MaterialsAndProducts[] = await getMaterialsAndProducts(
            convertToApiFilterData(filtersApplied, DataSet.SET_ONE)
          );
          runInAction(() => {
            this.dataSetOne = {
              data: responseData,
              filtersApplied: toJS(filtersApplied),
            };
            this.loadingStatusDataSetOne = LoadingStatus.Success;
          });
        } catch (error) {
          runInAction(() => {
            this.dataSetOne = {
              data: null,
              filtersApplied: null,
            };
            this.loadingStatusDataSetOne = LoadingStatus.Failed;
          });
        }
        break;
      case DataSet.SET_TWO:
        if (
          shouldNotLoadData(
            this.loadingStatusDataSetTwo,
            this.dataSetTwo.filtersApplied,
            filtersApplied
          )
        ) {
          return;
        }
        this.loadingStatusDataSetTwo = LoadingStatus.Loading;
        try {
          const responseData: MaterialsAndProducts[] = await getMaterialsAndProducts(
            convertToApiFilterData(filtersApplied, DataSet.SET_TWO)
          );
          runInAction(() => {
            this.dataSetTwo = {
              data: responseData,
              filtersApplied: toJS(filtersApplied),
            };
            this.loadingStatusDataSetTwo = LoadingStatus.Success;
          });
        } catch (error) {
          runInAction(() => {
            this.dataSetTwo = {
              data: null,
              filtersApplied: null,
            };
            this.loadingStatusDataSetTwo = LoadingStatus.Failed;
          });
        }
        break;
      default:
        if (
          shouldNotLoadData(
            this.loadingStatus,
            this.defaultData.filtersApplied,
            filtersApplied
          )
        ) {
          return;
        }
        this.loadingStatus = LoadingStatus.Loading;
        try {
          const responseData: MaterialsAndProducts[] = await getMaterialsAndProducts(
            convertToApiFilterData(filtersApplied, DataSet.DEFAULT)
          );
          runInAction(() => {
            this.defaultData = {
              data: responseData,
              filtersApplied: toJS(filtersApplied),
            };
            this.loadingStatus = LoadingStatus.Success;
          });
        } catch (error) {
          runInAction(() => {
            this.defaultData = {
              data: null,
              filtersApplied: null,
            };
            this.loadingStatus = LoadingStatus.Failed;
          });
        }
        break;
    }
  };

  @computed get defaultLitterTypeTableData(): LitterTypeTableData {
    switch (this.loadingStatus) {
      case LoadingStatus.Loading:
        return {
          loadingStatus: LoadingStatus.Loading,
          itemData: null,
          weightData: null,
        };
      case LoadingStatus.Success:
        if (isValidArray(this.defaultData.data)) {
          return {
            loadingStatus: LoadingStatus.Success,
            itemData: this.defaultData.data.map((item) => ({
              product: item.productName,
              material: item.litterCategory,
              totalItems: item.totalItems,
              percentage: `${item.percentage.toFixed(2)} %`,
            })),
            weightData: sortArray(
              this.defaultData.data.map((item) => ({
                product: item.productName,
                material: item.litterCategory,
                totalWeight: isWeightGreaterThanOneKg(item.totalWeight)
                  ? `${roundToTwo(item.totalWeight / 1000)} kg`
                  : `${item.totalWeight} g`,
                percentage: `${item.percentageItemWeight.toFixed(2)} %`,
              })),
              "percentage",
              "desc"
            ),
          };
        } else {
          return {
            loadingStatus: LoadingStatus.Failed,
            itemData: null,
            weightData: null,
          };
        }
      case LoadingStatus.Failed:
        return {
          loadingStatus: LoadingStatus.Failed,
          itemData: null,
          weightData: null,
        };
      default:
        return {
          loadingStatus: LoadingStatus.Failed,
          itemData: null,
          weightData: null,
        };
    }
  }

  @computed get comparisonSetOneLitterTypeTableData(): LitterTypeTableData {
    switch (this.loadingStatusDataSetOne) {
      case LoadingStatus.Loading:
        return {
          loadingStatus: LoadingStatus.Loading,
          itemData: null,
          weightData: null,
        };
      case LoadingStatus.Success:
        if (isValidArray(this.dataSetOne.data)) {
          return {
            loadingStatus: LoadingStatus.Success,
            itemData: this.dataSetOne.data.map((item) => ({
              product: item.productName,
              material: item.litterCategory,
              totalItems: item.totalItems,
              percentage: `${item.percentage.toFixed(2)} %`,
            })),
            weightData: sortArray(
              this.dataSetOne.data.map((item) => ({
                product: item.productName,
                material: item.litterCategory,
                totalWeight: isWeightGreaterThanOneKg(item.totalWeight)
                  ? `${roundToTwo(item.totalWeight / 1000)} kg`
                  : `${item.totalWeight} g`,
                percentage: `${item.percentageItemWeight.toFixed(2)} %`,
              })),
              "percentage",
              "desc"
            ),
          };
        } else {
          return {
            loadingStatus: LoadingStatus.Failed,
            itemData: null,
            weightData: null,
          };
        }
      case LoadingStatus.Failed:
        return {
          loadingStatus: LoadingStatus.Failed,
          itemData: null,
          weightData: null,
        };
      default:
        return {
          loadingStatus: LoadingStatus.Failed,
          itemData: null,
          weightData: null,
        };
    }
  }

  @computed get comparisonSetTwoLitterTypeTableData(): LitterTypeTableData {
    switch (this.loadingStatusDataSetTwo) {
      case LoadingStatus.Loading:
        return {
          loadingStatus: LoadingStatus.Loading,
          itemData: null,
          weightData: null,
        };
      case LoadingStatus.Success:
        if (isValidArray(this.dataSetTwo.data)) {
          return {
            loadingStatus: LoadingStatus.Success,
            itemData: this.dataSetTwo.data.map((item) => ({
              product: item.productName,
              material: item.litterCategory,
              totalItems: item.totalItems,
              percentage: `${item.percentage.toFixed(2)} %`,
            })),
            weightData: sortArray(
              this.dataSetTwo.data.map((item) => ({
                product: item.productName,
                material: item.litterCategory,
                totalWeight: isWeightGreaterThanOneKg(item.totalWeight)
                  ? `${roundToTwo(item.totalWeight / 1000)} kg`
                  : `${item.totalWeight} g`,
                percentage: `${item.percentageItemWeight.toFixed(2)} %`,
              })),
              "percentage",
              "desc"
            ),
          };
        } else {
          return {
            loadingStatus: LoadingStatus.Failed,
            itemData: null,
            weightData: null,
          };
        }
      case LoadingStatus.Failed:
        return {
          loadingStatus: LoadingStatus.Failed,
          itemData: null,
          weightData: null,
        };
      default:
        return {
          loadingStatus: LoadingStatus.Failed,
          itemData: null,
          weightData: null,
        };
    }
  }

  @computed
  get defaultLitterDensityPerTypeData(): LitterDensityPerTypeData {
    switch (this.loadingStatus) {
      case LoadingStatus.Loading:
        return {
          loadingStatus: LoadingStatus.Loading,
          itemsList: null,
          weightList: null,
        };

      case LoadingStatus.Success:
        if (
          isValidArray(this.defaultData.data) &&
          !isAllDataEntriesZero(this.defaultData.data, true) &&
          !isAllDataEntriesZero(this.defaultData.data, false)
        ) {
          const treemapItemArray: TreemapData[] = [
            {
              id: "litter",
              parent: null,
              itemValue: null,
              color: null,
            },
          ];

          const treemapWeightArray: TreemapData[] = [
            {
              id: "litter",
              parent: null,
              itemValue: null,
              color: null,
            },
          ];

          const litterCategoryColorMap: { name: string; color: number }[] = [];

          const distinctCategoryStringList = Array.from(
            new Set(this.defaultData.data.map((item) => item.litterCategory))
          );

          const distinctCategories = Array.from(
            new Set(this.defaultData.data.map((item) => item.litterCategory))
          ).map((item, index) => {
            litterCategoryColorMap.push({
              name: item,
              color: index,
            });
            return {
              id: item,
              parent: "litter",
              color: index,
              itemValue: null,
            };
          });

          treemapItemArray.push(...distinctCategories);
          treemapWeightArray.push(...distinctCategories);

          this.defaultData.data.forEach((item) => {
            if (item.totalItems > 0) {
              treemapItemArray.push({
                id:
                  this.defaultData.data.filter(
                    (element) => element.productName === item.productName
                  ).length > 1 ||
                  distinctCategoryStringList.includes(item.productName)
                    ? `${item.productName} (${item.litterCategory})`
                    : item.productName,
                parent: item.litterCategory,
                itemValue: item.totalItems,
                color: litterCategoryColorMap.find(
                  (element) => element.name === item.litterCategory
                ).color,
              });
            }

            if (item.totalWeight > 0) {
              treemapWeightArray.push({
                id:
                  this.defaultData.data.filter(
                    (element) => element.productName === item.productName
                  ).length > 1 ||
                  distinctCategoryStringList.includes(item.productName)
                    ? `${item.productName} (${item.litterCategory})`
                    : item.productName,
                parent: item.litterCategory,
                itemValue: item.totalWeight,
                color: litterCategoryColorMap.find(
                  (element) => element.name === item.litterCategory
                ).color,
              });
            }
          });

          return {
            itemsList: treemapItemArray,
            weightList: treemapWeightArray,
            loadingStatus: LoadingStatus.Success,
          };
        } else {
          return {
            loadingStatus: LoadingStatus.Failed,
            itemsList: null,
            weightList: null,
          };
        }

      case LoadingStatus.Failed:
        return {
          loadingStatus: LoadingStatus.Failed,
          itemsList: null,
          weightList: null,
        };
      default:
        return {
          loadingStatus: LoadingStatus.Failed,
          itemsList: null,
          weightList: null,
        };
    }
  }

  @computed
  get comparisonSetOneLitterDensityPerTypeData(): LitterDensityPerTypeData {
    switch (this.loadingStatusDataSetOne) {
      case LoadingStatus.Loading:
        return {
          loadingStatus: LoadingStatus.Loading,
          itemsList: null,
          weightList: null,
        };

      case LoadingStatus.Success:
        if (
          isValidArray(this.dataSetOne.data) &&
          !isAllDataEntriesZero(this.dataSetOne.data, true) &&
          !isAllDataEntriesZero(this.dataSetOne.data, false)
        ) {
          const treemapItemArray: TreemapData[] = [
            {
              id: "litter",
              parent: null,
              itemValue: null,
              color: null,
            },
          ];

          const treemapWeightArray: TreemapData[] = [
            {
              id: "litter",
              parent: null,
              itemValue: null,
              color: null,
            },
          ];

          const litterCategoryColorMap: { name: string; color: number }[] = [];

          const distinctCategoryStringList = Array.from(
            new Set(this.dataSetOne.data.map((item) => item.litterCategory))
          );

          const distinctCategories = Array.from(
            new Set(this.dataSetOne.data.map((item) => item.litterCategory))
          ).map((item, index) => {
            litterCategoryColorMap.push({
              name: item,
              color: index,
            });
            return {
              id: item,
              parent: "litter",
              color: index,
              itemValue: null,
            };
          });

          treemapItemArray.push(...distinctCategories);
          treemapWeightArray.push(...distinctCategories);

          this.dataSetOne.data.forEach((item) => {
            if (item.totalItems > 0) {
              treemapItemArray.push({
                id:
                  this.dataSetOne.data.filter(
                    (element) => element.productName === item.productName
                  ).length > 1 ||
                  distinctCategoryStringList.includes(item.productName)
                    ? `${item.productName} (${item.litterCategory})`
                    : item.productName,
                parent: item.litterCategory,
                itemValue: item.totalItems,
                color: litterCategoryColorMap.find(
                  (element) => element.name === item.litterCategory
                ).color,
              });
            }

            if (item.totalWeight > 0) {
              treemapWeightArray.push({
                id:
                  this.dataSetOne.data.filter(
                    (element) => element.productName === item.productName
                  ).length > 1 ||
                  distinctCategoryStringList.includes(item.productName)
                    ? `${item.productName} (${item.litterCategory})`
                    : item.productName,
                parent: item.litterCategory,
                itemValue: item.totalWeight,
                color: litterCategoryColorMap.find(
                  (element) => element.name === item.litterCategory
                ).color,
              });
            }
          });

          return {
            itemsList: treemapItemArray,
            weightList: treemapWeightArray,
            loadingStatus: LoadingStatus.Success,
          };
        } else {
          return {
            loadingStatus: LoadingStatus.Failed,
            itemsList: null,
            weightList: null,
          };
        }

      case LoadingStatus.Failed:
        return {
          loadingStatus: LoadingStatus.Failed,
          itemsList: null,
          weightList: null,
        };
      default:
        return {
          loadingStatus: LoadingStatus.Failed,
          itemsList: null,
          weightList: null,
        };
    }
  }

  @computed
  get comparisonSetTwoLitterDensityPerTypeData(): LitterDensityPerTypeData {
    switch (this.loadingStatusDataSetTwo) {
      case LoadingStatus.Loading:
        return {
          loadingStatus: LoadingStatus.Loading,
          itemsList: null,
          weightList: null,
        };

      case LoadingStatus.Success:
        if (
          isValidArray(this.dataSetTwo.data) &&
          !isAllDataEntriesZero(this.dataSetTwo.data, true) &&
          !isAllDataEntriesZero(this.dataSetTwo.data, false)
        ) {
          const treemapItemArray: TreemapData[] = [
            {
              id: "litter",
              parent: null,
              itemValue: null,
              color: null,
            },
          ];

          const treemapWeightArray: TreemapData[] = [
            {
              id: "litter",
              parent: null,
              itemValue: null,
              color: null,
            },
          ];

          const litterCategoryColorMap: { name: string; color: number }[] = [];

          const distinctCategoryStringList = Array.from(
            new Set(this.dataSetTwo.data.map((item) => item.litterCategory))
          );

          const distinctCategories = Array.from(
            new Set(this.dataSetTwo.data.map((item) => item.litterCategory))
          ).map((item, index) => {
            litterCategoryColorMap.push({
              name: item,
              color: index,
            });
            return {
              id: item,
              parent: "litter",
              color: index,
              itemValue: null,
            };
          });

          treemapItemArray.push(...distinctCategories);
          treemapWeightArray.push(...distinctCategories);

          this.dataSetTwo.data.forEach((item) => {
            if (item.totalItems > 0) {
              treemapItemArray.push({
                id:
                  this.dataSetTwo.data.filter(
                    (element) => element.productName === item.productName
                  ).length > 1 ||
                  distinctCategoryStringList.includes(item.productName)
                    ? `${item.productName} (${item.litterCategory})`
                    : item.productName,
                parent: item.litterCategory,
                itemValue: item.totalItems,
                color: litterCategoryColorMap.find(
                  (element) => element.name === item.litterCategory
                ).color,
              });
            }

            if (item.totalWeight > 0) {
              treemapWeightArray.push({
                id:
                  this.dataSetTwo.data.filter(
                    (element) => element.productName === item.productName
                  ).length > 1 ||
                  distinctCategoryStringList.includes(item.productName)
                    ? `${item.productName} (${item.litterCategory})`
                    : item.productName,
                parent: item.litterCategory,
                itemValue: item.totalWeight,
                color: litterCategoryColorMap.find(
                  (element) => element.name === item.litterCategory
                ).color,
              });
            }
          });

          return {
            itemsList: treemapItemArray,
            weightList: treemapWeightArray,
            loadingStatus: LoadingStatus.Success,
          };
        } else {
          return {
            loadingStatus: LoadingStatus.Failed,
            itemsList: null,
            weightList: null,
          };
        }

      case LoadingStatus.Failed:
        return {
          loadingStatus: LoadingStatus.Failed,
          itemsList: null,
          weightList: null,
        };
      default:
        return {
          loadingStatus: LoadingStatus.Failed,
          itemsList: null,
          weightList: null,
        };
    }
  }

  @computed get defaultTotalItemsAndWeightUIData(): TotalItemsAndWeightUIData {
    switch (this.loadingStatus) {
      case LoadingStatus.Loading:
        return {
          loadingStatus: LoadingStatus.Loading,
          totalItems: 0,
          totalWeight: 0,
        };
      case LoadingStatus.Success:
        if (isValidArray(this.defaultData.data)) {
          return {
            loadingStatus: LoadingStatus.Success,
            totalItems: this.defaultData.data.reduce(
              (a, b) => a + b.totalItems,
              0
            ),
            totalWeight: roundToTwo(
              this.defaultData.data.reduce((a, b) => a + b.totalWeight, 0) /
                1000
            ),
          };
        }
        return {
          loadingStatus: LoadingStatus.Failed,
          totalItems: 0,
          totalWeight: 0,
        };
      case LoadingStatus.Failed:
        return {
          loadingStatus: LoadingStatus.Failed,
          totalItems: 0,
          totalWeight: 0,
        };
      default:
        return {
          loadingStatus: LoadingStatus.Failed,
          totalItems: 0,
          totalWeight: 0,
        };
    }
  }

  @computed get setOneTotalItemsAndWeightUIData(): TotalItemsAndWeightUIData {
    switch (this.loadingStatusDataSetOne) {
      case LoadingStatus.Loading:
        return {
          loadingStatus: LoadingStatus.Loading,
          totalItems: 0,
          totalWeight: 0,
        };
      case LoadingStatus.Success:
        if (isValidArray(this.dataSetOne.data)) {
          return {
            loadingStatus: LoadingStatus.Success,
            totalItems: this.dataSetOne.data.reduce(
              (a, b) => a + b.totalItems,
              0
            ),
            totalWeight: roundToTwo(
              this.dataSetOne.data.reduce((a, b) => a + b.totalWeight, 0) / 1000
            ),
          };
        }
        return {
          loadingStatus: LoadingStatus.Failed,
          totalItems: 0,
          totalWeight: 0,
        };
      case LoadingStatus.Failed:
        return {
          loadingStatus: LoadingStatus.Failed,
          totalItems: 0,
          totalWeight: 0,
        };
      default:
        return {
          loadingStatus: LoadingStatus.Failed,
          totalItems: 0,
          totalWeight: 0,
        };
    }
  }

  @computed get setTwoTotalItemsAndWeightUIData(): TotalItemsAndWeightUIData {
    switch (this.loadingStatusDataSetTwo) {
      case LoadingStatus.Loading:
        return {
          loadingStatus: LoadingStatus.Loading,
          totalItems: 0,
          totalWeight: 0,
        };
      case LoadingStatus.Success:
        if (isValidArray(this.dataSetTwo.data)) {
          return {
            loadingStatus: LoadingStatus.Success,
            totalItems: this.dataSetTwo.data.reduce(
              (a, b) => a + b.totalItems,
              0
            ),
            totalWeight: roundToTwo(
              this.dataSetTwo.data.reduce((a, b) => a + b.totalWeight, 0) / 1000
            ),
          };
        }
        return {
          loadingStatus: LoadingStatus.Failed,
          totalItems: 0,
          totalWeight: 0,
        };
      case LoadingStatus.Failed:
        return {
          loadingStatus: LoadingStatus.Failed,
          totalItems: 0,
          totalWeight: 0,
        };
      default:
        return {
          loadingStatus: LoadingStatus.Failed,
          totalItems: 0,
          totalWeight: 0,
        };
    }
  }
}
