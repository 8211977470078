import React, { useState, useEffect } from "react";
import "./LitterDensityPerTypeChart.scss";
import { ToggleContent, CustomToggle } from "../CustomToggle/CustomToggle";
import { TreemapChart, TreemapData } from "../TreemapChart/TreemapChart";
import ReactGA from 'react-ga';

type LitterDensityPerTypeChartProps = {
  itemChartData: TreemapData[];
  weightChartData: TreemapData[];
  isComparison?: boolean;
};

export const LitterDensityPerTypeChart: React.FC<LitterDensityPerTypeChartProps> = ({
  itemChartData,
  weightChartData,
  isComparison,
}) => {
  const [selectedToggle, setSelectedToggle] = useState(ToggleContent.ITEMS);

  function onToggleChanged(option: string) {
    if (selectedToggle !== option) {
      setSelectedToggle(
        option === ToggleContent.ITEMS
          ? ToggleContent.ITEMS
          : ToggleContent.WEIGHT
      );
    }
  }

  useEffect(() => {
    ReactGA.event({
      category: 'Litter Density Per Type Chart',
      action: selectedToggle
    })
  }, [selectedToggle])

  return (
    <section className="litter_density_per_type_chart_section">
      <div className="chart_header">
        <p>{selectedToggle === ToggleContent.ITEMS ? "Total Items" : "Total Weight (Kg)"}</p>
        <CustomToggle
          firstOption="ITEMS"
          secondOption="WEIGHT"
          selectedToggle={selectedToggle}
          onToggleChanged={onToggleChanged}
        />
      </div>
      <div className="horizontal_divider"></div>
      <TreemapChart
        treemapList={
          selectedToggle === ToggleContent.ITEMS
            ? itemChartData
            : weightChartData
        }
        isComparison={isComparison}
        isWeightData={selectedToggle === ToggleContent.WEIGHT}
      />
    </section>
  );
};
