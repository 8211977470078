import React from "react";
import "./PageSubTitle.scss";
import HelperIcon from "../../assets/helper_icon.svg";
import { isValidString } from "../Table/TableUtil";
import { OverlayTrigger } from "react-bootstrap";

type PageSubTitleProps = {
  subTitle: string;
  helperText?: string;
};

export const PageSubTitle: React.FC<PageSubTitleProps> = ({
  subTitle,
  helperText,
}) => {
  return (
    <div className="page_sub_title">
      <h2 className="title_text">{subTitle}</h2>
      {isValidString(helperText) ? (
        <OverlayTrigger
          key={helperText}
          placement="top"
          overlay={<div className="page_sub_title_tooltip">{helperText}</div>}
        >
          <img src={HelperIcon} alt="Helper Icon" className="helper_icon" />
        </OverlayTrigger>
      ) : null}
    </div>
  );
};
