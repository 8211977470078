import React from 'react';
import './ModalToggleTitle.scss';

type ModalToggleTitleProps = {
    title: string;
    subTitle: string;
}

export const ModalToggleTitle: React.FC<ModalToggleTitleProps> = ({ title, subTitle }) => {
    return (
        <div className="modal_toggle_title_container">
            <h4>
                {title}
            </h4>
            <p>
                {subTitle}
            </p>
        </div>
    );
}