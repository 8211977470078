import { isValidObject } from "./overview/overviewUtil";

export enum LoadingStatus {
  Success,
  Loading,
  Failed,
}

export interface LocationType {
  label: string;
  value: string;
}

export enum ComparisonDataSet {
  LocationType,
  Location,
  LitterType,
  DateRange,
}

export interface ComparisonData {
  comparisonDataSet: ComparisonDataSet;
  dataSetOne:
    | LocationFilter
    | LitterTypeFilter
    | DateFilter
    | LocationType[]
    | null;
  dataSetTwo:
    | LocationFilter
    | LitterTypeFilter
    | DateFilter
    | LocationType[]
    | null;
}

export interface LocationFilter {
  countries: {
    id: number;
    name: string;
  }[];
  regions?: {
    id: number;
    name: string;
  }[];
  surveyAreas?: {
    id: number;
    name: string;
  }[];
}

export interface LitterTypeFilter {
  materials: {
    id: number;
    name: string;
  }[];
  products?: {
    id: number;
    name: string;
  }[];
}

export interface DateFilter {
  startDate: moment.Moment;
  endDate: moment.Moment;
}

export const SideTab: SideTabType = {
  BEACH: { name: "Beach", value: 1 },
  FRESHWATER: { name: "Freshwater", value: 2 },
  STORMWATER: { name: "Stormwater", value: 4 },
};

export interface SideTabType {
  BEACH: { name: SideTabNameType; value: SurveyType };
  FRESHWATER: { name: SideTabNameType; value: SurveyType };
  STORMWATER: { name: SideTabNameType; value: SurveyType };
}

export function getSurveyTypeCode(name: SideTabNameType): SurveyType {
  if (SideTab.BEACH.name === name) {
    return SideTab.BEACH.value;
  } else if (SideTab.FRESHWATER.name === name) {
    return SideTab.FRESHWATER.value;
  } else if (SideTab.STORMWATER.name === name) {
    return SideTab.STORMWATER.value;
  } else {
    return undefined;
  }
}

export type SideTabNameType = "Beach" | "Freshwater" | "Stormwater";

export const SideTabList = [
  SideTab.BEACH.name,
  SideTab.FRESHWATER.name,
  SideTab.STORMWATER.name,
];

export type SurveyType = 1 | 2 | 4 | undefined;

export interface IFilter {
  locationTypeFilter?: LocationType[];
  locationFilter?: LocationFilter;
  litterTypeFilter?: LitterTypeFilter;
  dateFilter?: DateFilter;
  comparisonData?: ComparisonData;
  surveyType?: SurveyType;
}

export interface ApiFilterData {
  locationTypes: number[];
  locations: {
    countries: number[];
    regions: number[];
    surveyAreas: number[];
  };
  litterTypes: {
    materials: number[];
    products: number[];
  };
  dateRange: {
    startDate: string;
    endDate: string;
  };
  surveyType: SurveyType;
}

export function isValidLocationFilter(locationFilterAttribute: any) {
  return (
    locationFilterAttribute !== null &&
    locationFilterAttribute !== undefined &&
    Array.isArray(locationFilterAttribute) &&
    locationFilterAttribute.length > 0
  );
}

export function isFilterNotApplied(filterApplied: any) {
  return filterApplied === null || filterApplied === undefined;
}

export function isValidArray(array: any) {
  return Array.isArray(array) && array.length > 0;
}

export function getComparisonDataSetTitlesForLocation(
  dataSetOne: LocationFilter,
  dataSetTwo: LocationFilter
): { dataSetOne: string; dataSetTwo: string } {
  return {
    dataSetOne: getLocationTitleFor(dataSetOne),
    dataSetTwo: getLocationTitleFor(dataSetTwo),
  };
}

function getLocationTitleFor(dataSet: LocationFilter): string {
  if (dataSet === null || dataSet === undefined) {
    return "All Locations";
  }

  if (isValidLocationFilter(dataSet.countries)) {
    const countries = dataSet.countries.map((item) => item.name).join(", ");

    if (isValidLocationFilter(dataSet.regions)) {
      const regions = dataSet.regions.map((item) => item.name).join(", ");

      if (isValidLocationFilter(dataSet.surveyAreas)) {
        const surveyAreas = dataSet.surveyAreas
          .map((item) => item.name)
          .join(", ");

        return `${countries}, ${regions}, ${surveyAreas}`;
      }

      return `${countries}, ${regions}`;
    }

    return countries;
  }

  return "All Locations";
}

export function getComparisonDataSetTitlesForLitterType(
  dataSetOne: LitterTypeFilter,
  dataSetTwo: LitterTypeFilter
): { dataSetOne: string; dataSetTwo: string } {
  return {
    dataSetOne: getLitterTypeTitleFor(dataSetOne),
    dataSetTwo: getLitterTypeTitleFor(dataSetTwo),
  };
}

function getLitterTypeTitleFor(dataSet: LitterTypeFilter): string {
  if (dataSet === undefined || dataSet === null) {
    return "All Litter Types";
  }

  if (isValidLocationFilter(dataSet.materials)) {
    const materials = dataSet.materials.map((item) => item.name).join(", ");

    if (isValidLocationFilter(dataSet.products)) {
      const products = dataSet.products.map((item) => item.name).join(", ");

      return `${materials}, ${products}`;
    }

    return materials;
  }

  return "All Litter Types";
}

export function getComparisonDataSetTitlesForDate(
  dataSetOne: DateFilter,
  dataSetTwo: DateFilter
): { dataSetOne: string; dataSetTwo: string } {
  return {
    dataSetOne: getDateTitleFor(dataSetOne),
    dataSetTwo: getDateTitleFor(dataSetTwo),
  };
}

function getDateTitleFor(dataSet: DateFilter): string {
  if (dataSet === null || dataSet === undefined) {
    return "All Time";
  }
  return `${dataSet.startDate.format("ll")} - ${dataSet.endDate.format("ll")}`;
}

export function getComparisonDataSetTitleForLocationType(
  dataSetOne: LocationType[],
  dataSetTwo: LocationType[]
): { dataSetOne: string; dataSetTwo: string } {
  return {
    dataSetOne: getLocationTypeTitleFor(dataSetOne),
    dataSetTwo: getLocationTypeTitleFor(dataSetTwo),
  };
}

function getLocationTypeTitleFor(dataSet: LocationType[]) {
  if (dataSet === null || dataSet === undefined) {
    return "All Location Types";
  }
  if (isValidArray(dataSet)) {
    return dataSet.map((item) => item.label).join(", ");
  }
  return "All Location Types";
}

export function getSetOneLocationTypeFilterTitle(
  filtersApplied: IFilter
): string {
  if (
    !isFilterNotApplied(filtersApplied) &&
    !isFilterNotApplied(filtersApplied.comparisonData) &&
    filtersApplied.comparisonData.comparisonDataSet ===
      ComparisonDataSet.LocationType
  ) {
    if (
      isValidLocationFilter(
        filtersApplied.comparisonData.dataSetOne as LocationType[]
      )
    ) {
      return (filtersApplied.comparisonData.dataSetOne as LocationType[])
        .map((item) => item.label)
        .join(", ");
    }
    return "All Location Types";
  } else {
    //Send the default locationTypeFilter Data
    if (
      isFilterNotApplied(filtersApplied) ||
      isFilterNotApplied(filtersApplied.locationTypeFilter)
    ) {
      return "All Location Types";
    }
    if (isValidLocationFilter(filtersApplied.locationTypeFilter)) {
      return filtersApplied.locationTypeFilter
        .map((item) => item.label)
        .join(", ");
    }
    return "All Location Types";
  }
}

export function getSetTwoLocationTypeFilterTitle(
  filtersApplied: IFilter
): string {
  if (
    !isFilterNotApplied(filtersApplied) &&
    !isFilterNotApplied(filtersApplied.comparisonData) &&
    filtersApplied.comparisonData.comparisonDataSet ===
      ComparisonDataSet.LocationType
  ) {
    if (
      isValidLocationFilter(
        filtersApplied.comparisonData.dataSetTwo as LocationType[]
      )
    ) {
      return (filtersApplied.comparisonData.dataSetTwo as LocationType[])
        .map((item) => item.label)
        .join(", ");
    }
    return "All Location Types";
  } else {
    //Send the default locationTypeFilter Data
    if (
      isFilterNotApplied(filtersApplied) ||
      isFilterNotApplied(filtersApplied.locationTypeFilter)
    ) {
      return "All Location Types";
    }
    if (isValidLocationFilter(filtersApplied.locationTypeFilter)) {
      return filtersApplied.locationTypeFilter
        .map((item) => item.label)
        .join(", ");
    }
    return "All Location Types";
  }
}

export function getSetOneLocationFilterTitle(filtersApplied: IFilter): string {
  if (
    !isFilterNotApplied(filtersApplied) &&
    !isFilterNotApplied(filtersApplied.comparisonData) &&
    filtersApplied.comparisonData.comparisonDataSet ===
      ComparisonDataSet.Location
  ) {
    if (
      isValidObject(filtersApplied.comparisonData.dataSetOne) &&
      isValidLocationFilter(
        (filtersApplied.comparisonData.dataSetOne as LocationFilter).countries
      )
    ) {
      const countries = (
        filtersApplied.comparisonData.dataSetOne as LocationFilter
      ).countries
        .map((item) => item.name)
        .join(", ");

      if (
        isValidLocationFilter(
          (filtersApplied.comparisonData.dataSetOne as LocationFilter).regions
        )
      ) {
        const regions = (
          filtersApplied.comparisonData.dataSetOne as LocationFilter
        ).regions
          .map((item) => item.name)
          .join(", ");

        if (
          isValidLocationFilter(
            (filtersApplied.comparisonData.dataSetOne as LocationFilter)
              .surveyAreas
          )
        ) {
          const surveyAreas = (
            filtersApplied.comparisonData.dataSetOne as LocationFilter
          ).surveyAreas
            .map((item) => item.name)
            .join(", ");

          return `${countries}, ${regions}, ${surveyAreas}`;
        }

        return `${countries}, ${regions}`;
      }

      return countries;
    }

    return "All Locations";
  } else {
    //Send the default locationFilter Title
    if (
      isFilterNotApplied(filtersApplied) ||
      isFilterNotApplied(filtersApplied.locationFilter)
    ) {
      return "All Locations";
    }

    if (isValidLocationFilter(filtersApplied.locationFilter.countries)) {
      const countries = filtersApplied.locationFilter.countries
        .map((item) => item.name)
        .join(", ");

      if (isValidLocationFilter(filtersApplied.locationFilter.regions)) {
        const regions = filtersApplied.locationFilter.regions
          .map((item) => item.name)
          .join(", ");

        if (isValidLocationFilter(filtersApplied.locationFilter.surveyAreas)) {
          const surveyAreas = filtersApplied.locationFilter.surveyAreas
            .map((item) => item.name)
            .join(", ");

          return `${countries}, ${regions}, ${surveyAreas}`;
        }

        return `${countries}, ${regions}`;
      }

      return countries;
    }

    return "All Locations";
  }
}

export function getSetTwoLocationFilterTitle(filtersApplied: IFilter): string {
  if (
    !isFilterNotApplied(filtersApplied) &&
    !isFilterNotApplied(filtersApplied.comparisonData) &&
    filtersApplied.comparisonData.comparisonDataSet ===
      ComparisonDataSet.Location
  ) {
    if (
      isValidObject(filtersApplied.comparisonData.dataSetTwo) &&
      isValidLocationFilter(
        (filtersApplied.comparisonData.dataSetTwo as LocationFilter).countries
      )
    ) {
      const countries = (
        filtersApplied.comparisonData.dataSetTwo as LocationFilter
      ).countries
        .map((item) => item.name)
        .join(", ");

      if (
        isValidLocationFilter(
          (filtersApplied.comparisonData.dataSetTwo as LocationFilter).regions
        )
      ) {
        const regions = (
          filtersApplied.comparisonData.dataSetTwo as LocationFilter
        ).regions
          .map((item) => item.name)
          .join(", ");

        if (
          isValidLocationFilter(
            (filtersApplied.comparisonData.dataSetTwo as LocationFilter)
              .surveyAreas
          )
        ) {
          const surveyAreas = (
            filtersApplied.comparisonData.dataSetTwo as LocationFilter
          ).surveyAreas
            .map((item) => item.name)
            .join(", ");

          return `${countries}, ${regions}, ${surveyAreas}`;
        }

        return `${countries}, ${regions}`;
      }

      return countries;
    }

    return "All Locations";
  } else {
    //Send the default locationFilter Title
    if (
      isFilterNotApplied(filtersApplied) ||
      isFilterNotApplied(filtersApplied.locationFilter)
    ) {
      return "All Locations";
    }

    if (isValidLocationFilter(filtersApplied.locationFilter.countries)) {
      const countries = filtersApplied.locationFilter.countries
        .map((item) => item.name)
        .join(", ");

      if (isValidLocationFilter(filtersApplied.locationFilter.regions)) {
        const regions = filtersApplied.locationFilter.regions
          .map((item) => item.name)
          .join(", ");

        if (isValidLocationFilter(filtersApplied.locationFilter.surveyAreas)) {
          const surveyAreas = filtersApplied.locationFilter.surveyAreas
            .map((item) => item.name)
            .join(", ");

          return `${countries}, ${regions}, ${surveyAreas}`;
        }

        return `${countries}, ${regions}`;
      }

      return countries;
    }

    return "All Locations";
  }
}

export function getSetOneLitterTypeFilterTitle(
  filtersApplied: IFilter
): string {
  if (
    !isFilterNotApplied(filtersApplied) &&
    !isFilterNotApplied(filtersApplied.comparisonData) &&
    filtersApplied.comparisonData.comparisonDataSet ===
      ComparisonDataSet.LitterType
  ) {
    if (
      isValidObject(filtersApplied.comparisonData.dataSetOne) &&
      isValidLocationFilter(
        (filtersApplied.comparisonData.dataSetOne as LitterTypeFilter).materials
      )
    ) {
      const materials = (
        filtersApplied.comparisonData.dataSetOne as LitterTypeFilter
      ).materials
        .map((item) => item.name)
        .join(", ");

      if (
        isValidLocationFilter(
          (filtersApplied.comparisonData.dataSetOne as LitterTypeFilter)
            .products
        )
      ) {
        const products = (
          filtersApplied.comparisonData.dataSetOne as LitterTypeFilter
        ).products
          .map((item) => item.name)
          .join(", ");

        return `${materials}, ${products}`;
      }

      return materials;
    }

    return "All Litter Types";
  } else {
    //Send the default litterTypeFilter Data
    if (
      isFilterNotApplied(filtersApplied) ||
      isFilterNotApplied(filtersApplied.litterTypeFilter)
    ) {
      return "All Litter Types";
    }

    if (isValidLocationFilter(filtersApplied.litterTypeFilter.materials)) {
      const materials = filtersApplied.litterTypeFilter.materials
        .map((item) => item.name)
        .join(", ");

      if (isValidLocationFilter(filtersApplied.litterTypeFilter.products)) {
        const products = filtersApplied.litterTypeFilter.products
          .map((item) => item.name)
          .join(", ");

        return `${materials}, ${products}`;
      }

      return materials;
    }

    return "All Litter Types";
  }
}

export function getSetTwoLitterTypeFilterTitle(
  filtersApplied: IFilter
): string {
  if (
    !isFilterNotApplied(filtersApplied) &&
    !isFilterNotApplied(filtersApplied.comparisonData) &&
    filtersApplied.comparisonData.comparisonDataSet ===
      ComparisonDataSet.LitterType
  ) {
    if (
      isValidObject(filtersApplied.comparisonData.dataSetTwo) &&
      isValidLocationFilter(
        (filtersApplied.comparisonData.dataSetTwo as LitterTypeFilter).materials
      )
    ) {
      const materials = (
        filtersApplied.comparisonData.dataSetTwo as LitterTypeFilter
      ).materials
        .map((item) => item.name)
        .join(", ");

      if (
        isValidLocationFilter(
          (filtersApplied.comparisonData.dataSetTwo as LitterTypeFilter)
            .products
        )
      ) {
        const products = (
          filtersApplied.comparisonData.dataSetTwo as LitterTypeFilter
        ).products
          .map((item) => item.name)
          .join(", ");

        return `${materials}, ${products}`;
      }

      return materials;
    }

    return "All Litter Types";
  } else {
    //Send the default litterTypeFilter Data
    if (
      isFilterNotApplied(filtersApplied) ||
      isFilterNotApplied(filtersApplied.litterTypeFilter)
    ) {
      return "All Litter Types";
    }

    if (isValidLocationFilter(filtersApplied.litterTypeFilter.materials)) {
      const materials = filtersApplied.litterTypeFilter.materials
        .map((item) => item.name)
        .join(", ");

      if (isValidLocationFilter(filtersApplied.litterTypeFilter.products)) {
        const products = filtersApplied.litterTypeFilter.products
          .map((item) => item.name)
          .join(", ");

        return `${materials}, ${products}`;
      }

      return materials;
    }

    return "All Litter Types";
  }
}

export function getSetOneDateFilterTitle(filtersApplied: IFilter): string {
  if (
    !isFilterNotApplied(filtersApplied) &&
    !isFilterNotApplied(filtersApplied.comparisonData) &&
    filtersApplied.comparisonData.comparisonDataSet ===
      ComparisonDataSet.DateRange
  ) {
    if (
      isFilterNotApplied(filtersApplied.comparisonData.dataSetOne as DateFilter)
    ) {
      return "All Time";
    }
    return `${(
      filtersApplied.comparisonData.dataSetOne as DateFilter
    ).startDate.format("ll")} - ${(
      filtersApplied.comparisonData.dataSetOne as DateFilter
    ).endDate.format("ll")}`;
  } else {
    //Send the default dateFilter Data
    if (
      isFilterNotApplied(filtersApplied) ||
      isFilterNotApplied(filtersApplied.dateFilter)
    ) {
      return "All Time";
    }

    return `${filtersApplied.dateFilter.startDate.format(
      "ll"
    )} - ${filtersApplied.dateFilter.endDate.format("ll")}`;
  }
}

export function getSetTwoDateFilterTitle(filtersApplied: IFilter): string {
  if (
    !isFilterNotApplied(filtersApplied) &&
    !isFilterNotApplied(filtersApplied.comparisonData) &&
    filtersApplied.comparisonData.comparisonDataSet ===
      ComparisonDataSet.DateRange
  ) {
    if (
      isFilterNotApplied(filtersApplied.comparisonData.dataSetTwo as DateFilter)
    ) {
      return "All Time";
    }
    return `${(
      filtersApplied.comparisonData.dataSetTwo as DateFilter
    ).startDate.format("ll")} - ${(
      filtersApplied.comparisonData.dataSetTwo as DateFilter
    ).endDate.format("ll")}`;
  } else {
    //Send the default dateFilter Data
    if (
      isFilterNotApplied(filtersApplied) ||
      isFilterNotApplied(filtersApplied.dateFilter)
    ) {
      return "All Time";
    }

    return `${filtersApplied.dateFilter.startDate.format(
      "ll"
    )} - ${filtersApplied.dateFilter.endDate.format("ll")}`;
  }
}
