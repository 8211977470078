import React from "react";
import DownArrow from "../../assets/down_arrow.svg";
import UpArrow from "../../assets/up_arrow.svg";
import "./ToggleHeader.scss";
import ComparisonIcon from '../../assets/comparison_icon_grey.svg';

type ToggleHeaderProps = {
  isOpen: boolean;
  label: string;
  icon: string;
  isFilterApplied?: boolean;
  onResetClicked: () => void;
  comparisonData?: {
    dataSetOne: string;
    dataSetTwo: string;
  };
  onRemoveClicked?: () => void;
  isLoading?: boolean;
};

export const ToggleHeader: React.FC<ToggleHeaderProps> = ({
  isOpen,
  label,
  icon,
  isFilterApplied,
  onResetClicked,
  comparisonData,
  onRemoveClicked,
  isLoading
}) => {
  return comparisonData === null || comparisonData === undefined ? (
    <div className={isOpen ? "toggle_header__open" : "toggle_header"}>
      <div className="toggle_header_content">
        <img src={icon} alt={label} />
        <p
          className="text"
          style={{
            fontFamily: isFilterApplied ? "TTNormsBold" : "TTNormsRegular",
          }}
        >
          {isLoading ? 'Loading...' : label}
        </p>
        <p
          className={isFilterApplied ? "reset_text" : "reset_text_disabled"}
          onClick={onResetClicked}
        >
          Reset
        </p>
      </div>
      <img src={isOpen ? UpArrow : DownArrow} alt="Arrow Direction" />
    </div>
  ) : (
    <div className="comparison_toggle_header">
      <div className="dataset_one">
        <img src={icon} alt={label} />
        <p
          className="text"
        >
          {comparisonData.dataSetOne}
        </p>
        <p
          className="reset_text"
          onClick={onResetClicked}
        >
          Reset
        </p>
      </div>
      <img src={ComparisonIcon} alt="Comparison Icon" className="comparison_icon" />
      <div className="dataset_two">
        <img src={icon} alt={label} />
        <p
          className="text"
        >
          {comparisonData.dataSetTwo}
        </p>
        <p
          className="remove_text"
          onClick={onRemoveClicked}
        >
          Remove
        </p>
      </div>
    </div>
  );
};
