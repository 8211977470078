import React, { useState } from "react";
import {
  LitterTypeItemTableColumns,
  LitterTypeWeightTableColumns,
} from "../../store/constants";
import {
  LitterTypeItemTableRow,
  LitterTypeWeightTableRow,
} from "../../store/materialAndProduct/materialAndProductUtil";
import { CustomToggle, ToggleContent } from "../CustomToggle/CustomToggle";
import { Table } from "../Table/Table";
import "./LitterTypeTable.scss";

type LitterTypeTableProps = {
  itemTableData: LitterTypeItemTableRow[];
  weightTableData: LitterTypeWeightTableRow[];
};

export const LitterTypeTable: React.FC<LitterTypeTableProps> = ({
  itemTableData,
  weightTableData,
}) => {
  const [selectedToggle, setSelectedToggle] = useState<string>(
    ToggleContent.ITEMS
  );

  function onToggleChanged(option: string) {
    if (selectedToggle !== option) {
      setSelectedToggle(option);
    }
  }

  return (
    <section className="items_bar_chart_section">
      <div className="items_bar_chart_header">
        <p>
          Litter Type -{" "}
          {selectedToggle === ToggleContent.ITEMS ? "ITEMS" : "WEIGHT"}
        </p>
        <CustomToggle
          firstOption="ITEMS"
          secondOption="WEIGHT"
          selectedToggle={selectedToggle}
          onToggleChanged={onToggleChanged}
        />
      </div>
      <div className="horizontal_divider"></div>
      <div>
        {selectedToggle === ToggleContent.ITEMS ? (
          <Table
            data={itemTableData}
            key={"LitterTypeItemTable"}
            columns={LitterTypeItemTableColumns}
          />
        ) : (
          <Table
            data={weightTableData}
            key={"LitterTypeWeightTable"}
            columns={LitterTypeWeightTableColumns}
          />
        )}
      </div>
    </section>
  );
};
