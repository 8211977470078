import React, { useState, useContext } from "react";
import AddDataIcon from "../../assets/add_data.svg";
import { Accordion, Card } from "react-bootstrap";
import SurveyIcon from "../../assets/survey_icon.svg";
import LocationIcon from "../../assets/location_icon.svg";
import LitterIcon from "../../assets/litter_icon.svg";
import TimeIcon from "../../assets/calendar_icon.svg";
import "./Sidebar.scss";
import { ToggleHeader } from "./ToggleHeader";
import { LocationTypeToggle } from "./LocationTypeToggle";
import { LocationToggle } from "./LocationToggle/LocationToggle";
import { LitterTypeToggle } from "./LitterTypeToggle";
import { TimeToggle } from "./TimeToggle";
import { RootStoreContext } from "../../store/rootStore";
import { observer } from "mobx-react-lite";
import moment from "moment";
import {
  isFilterNotApplied,
  LitterTypeFilter,
  LocationFilter,
  DateFilter,
  ComparisonDataSet,
  isValidArray,
} from "../../store/util";
import { isValidString } from "../Table/TableUtil";
import { LocationType } from "../../store/util";
import { SideTabs } from "../SideTabs/SideTabs";
import { SideTabList } from "../../store/util";

const eventKeyMapping: {
  comparisonDataSet: ComparisonDataSet;
  eventKey: string;
}[] = [
  {
    comparisonDataSet: ComparisonDataSet.LocationType,
    eventKey: "location_type",
  },
  {
    comparisonDataSet: ComparisonDataSet.Location,
    eventKey: "location",
  },
  {
    comparisonDataSet: ComparisonDataSet.LitterType,
    eventKey: "litter_type",
  },
  {
    comparisonDataSet: ComparisonDataSet.DateRange,
    eventKey: "time",
  },
];

export const Sidebar: React.FC<{}> = observer(() => {
  const [activeEventKey, setActiveEventKey] = useState(undefined);
  const rootStore = useContext(RootStoreContext);

  const {
    isSideBarOpen,
    setIsComparisonModalOpen,
    setSideBarOpen,
    shoudRenderLocationType,
  } = rootStore.commonStore;

  const {
    filtersApplied,
    locationTypeFilterTitle,
    locationFilterTitle,
    isLocationFilterApplied,
    countriesForSidebar,
    regionsForSidebar,
    surveyAreasForSidebar,
    litterTypeFilterTitle,
    isLitterTypeFilterApplied,
    materialsForSidebar,
    productsForSidebar,
    dateFilterTitle,
    isDateFilterApplied,
    dateFilterStartDate,
    dateFilterEndDate,
    resetFilters,
    applyLocationFilter,
    applyLitterTypeFilter,
    applyDateFilter,
    applyComparisonData,
    isComparisonApplied,
    comparisonDataSetTitles,
    isLocationTypeFilterApplied,
    locationTypeForSidebar,
    applyLocationTypeFilter,
    isFilterOptionsLoadComplete
  } = rootStore.filterStore;

  function onAccordionSelect(e: any) {
    if (
      isValidString(e) &&
      isComparisonApplied !== null &&
      isComparisonApplied !== undefined
    ) {
      if (
        eventKeyMapping.filter(
          (item) => item.comparisonDataSet === isComparisonApplied
        )[0].eventKey === e
      ) {
        return;
      }
    }

    if(isValidString(e) && e === "location" && !isFilterOptionsLoadComplete) {
      return; //Only being able to open when isFilterOptionsLoadComplete
    }

    if(isValidString(e) && e === "litter_type" && !isFilterOptionsLoadComplete) {
      return; //Only being able to open when isFilterOptionsLoadComplete
    }

    setActiveEventKey(e);
  }

  return (
    <nav className={`${isSideBarOpen ? "open_nav" : "close_nav"}`}>
      <div className="filters_header">
        <h3>Filters</h3>
        <p
          onClick={() => {
            resetFilters();
            setActiveEventKey(undefined);
          }}
        >
          Reset Filters
        </p>
      </div>

      <SideTabs tabList={SideTabList} setActiveEventKey={setActiveEventKey}/>

      <div className="dropdown_container">
        <Accordion activeKey={activeEventKey} onSelect={onAccordionSelect}>
          <div>
            {
              shoudRenderLocationType &&
              <>
              <div>
              <Accordion.Toggle
                as={Card}
                variant="link"
                eventKey="location_type"
              >
                <ToggleHeader
                  isOpen={activeEventKey === "location_type"}
                  isFilterApplied={isLocationTypeFilterApplied}
                  comparisonData={
                    isComparisonApplied !== null &&
                    isComparisonApplied !== undefined &&
                    comparisonDataSetTitles !== null &&
                    isComparisonApplied === ComparisonDataSet.LocationType
                      ? comparisonDataSetTitles
                      : null
                  }
                  onRemoveClicked={() => {
                    if (
                      !isFilterNotApplied(filtersApplied) &&
                      !isFilterNotApplied(filtersApplied.comparisonData) &&
                      !isFilterNotApplied(
                        filtersApplied.comparisonData.dataSetOne
                      ) &&
                      isComparisonDataLocationTypeFilter(
                        filtersApplied.comparisonData.dataSetOne
                      )
                    ) {
                      applyLocationTypeFilter(
                        filtersApplied.comparisonData
                          .dataSetOne as LocationType[]
                      );
                      applyComparisonData(null);
                    } else {
                      applyLocationTypeFilter(null);
                      applyComparisonData(null);
                    }
                  }}
                  onResetClicked={() => {
                    if (
                      !isFilterNotApplied(filtersApplied) &&
                      !isFilterNotApplied(filtersApplied.comparisonData)
                    ) {
                      if (
                        filtersApplied.comparisonData.comparisonDataSet ===
                        ComparisonDataSet.LocationType
                      ) {
                        applyLocationTypeFilter(null);
                        applyComparisonData(null);
                      } else {
                        applyLocationTypeFilter(null);
                      }
                    } else {
                      applyLocationTypeFilter(null);
                      applyComparisonData(null);
                    }
                  }}
                  label={locationTypeFilterTitle}
                  icon={SurveyIcon}
                />
              </Accordion.Toggle>
            </div>
            {activeEventKey === "location_type" ? (
              <Accordion.Collapse eventKey="location_type">
                <LocationTypeToggle locationTypes={locationTypeForSidebar} />
              </Accordion.Collapse>
            ) : null}
              </>
            }
          </div>

          <div>
            <div>
              <Accordion.Toggle as={Card} variant="link" eventKey="location">
                <ToggleHeader
                  onResetClicked={() => {
                    if (
                      !isFilterNotApplied(filtersApplied) &&
                      !isFilterNotApplied(filtersApplied.comparisonData)
                    ) {
                      if (
                        filtersApplied.comparisonData.comparisonDataSet ===
                        ComparisonDataSet.Location
                      ) {
                        applyLocationFilter(null);
                        applyComparisonData(null);
                      } else {
                        applyLocationFilter(null);
                      }
                    } else {
                      applyLocationFilter(null);
                      applyComparisonData(null);
                    }
                  }}
                  isOpen={activeEventKey === "location"}
                  label={locationFilterTitle}
                  icon={LocationIcon}
                  isFilterApplied={isLocationFilterApplied}
                  comparisonData={
                    isComparisonApplied !== null &&
                    isComparisonApplied !== undefined &&
                    comparisonDataSetTitles !== null &&
                    isComparisonApplied === ComparisonDataSet.Location
                      ? comparisonDataSetTitles
                      : null
                  }
                  isLoading={!isFilterOptionsLoadComplete}
                  onRemoveClicked={() => {
                    if (
                      !isFilterNotApplied(filtersApplied) &&
                      !isFilterNotApplied(filtersApplied.comparisonData) &&
                      !isFilterNotApplied(
                        filtersApplied.comparisonData.dataSetOne
                      ) &&
                      isComparisonDataLocationFilter(
                        filtersApplied.comparisonData.dataSetOne
                      )
                    ) {
                      applyLocationFilter(
                        filtersApplied.comparisonData
                          .dataSetOne as LocationFilter
                      );
                      applyComparisonData(null);
                    } else {
                      applyLocationFilter(null);
                      applyComparisonData(null);
                    }
                  }}
                />
              </Accordion.Toggle>
            </div>
            {activeEventKey === "location" ? (
              <Accordion.Collapse eventKey="location">
                <LocationToggle
                  country={countriesForSidebar}
                  region={regionsForSidebar}
                  surveyArea={surveyAreasForSidebar}
                />
              </Accordion.Collapse>
            ) : null}
          </div>
          <div>
            <div>
              <Accordion.Toggle as={Card} variant="link" eventKey="litter_type">
                <ToggleHeader
                  onResetClicked={() => {
                    if (
                      !isFilterNotApplied(filtersApplied) &&
                      !isFilterNotApplied(filtersApplied.comparisonData)
                    ) {
                      if (
                        filtersApplied.comparisonData.comparisonDataSet ===
                        ComparisonDataSet.LitterType
                      ) {
                        applyLitterTypeFilter(null);
                        applyComparisonData(null);
                      } else {
                        applyLitterTypeFilter(null);
                      }
                    } else {
                      applyLitterTypeFilter(null);
                      applyComparisonData(null);
                    }
                  }}
                  isOpen={activeEventKey === "litter_type"}
                  label={litterTypeFilterTitle}
                  icon={LitterIcon}
                  isLoading={!isFilterOptionsLoadComplete}
                  isFilterApplied={isLitterTypeFilterApplied}
                  comparisonData={
                    isComparisonApplied !== null &&
                    isComparisonApplied !== undefined &&
                    comparisonDataSetTitles !== null &&
                    isComparisonApplied === ComparisonDataSet.LitterType
                      ? comparisonDataSetTitles
                      : null
                  }
                  onRemoveClicked={() => {
                    if (
                      !isFilterNotApplied(filtersApplied) &&
                      !isFilterNotApplied(filtersApplied.comparisonData) &&
                      !isFilterNotApplied(
                        filtersApplied.comparisonData.dataSetOne
                      ) &&
                      isComparisonDataLitterTypeFilter(
                        filtersApplied.comparisonData.dataSetOne
                      )
                    ) {
                      applyLitterTypeFilter(
                        filtersApplied.comparisonData
                          .dataSetOne as LitterTypeFilter
                      );
                      applyComparisonData(null);
                    } else {
                      applyLitterTypeFilter(null);
                      applyComparisonData(null);
                    }
                  }}
                />
              </Accordion.Toggle>
            </div>
            {activeEventKey === "litter_type" ? (
              <Accordion.Collapse eventKey="litter_type">
                <LitterTypeToggle
                  materials={materialsForSidebar}
                  products={productsForSidebar}
                />
              </Accordion.Collapse>
            ) : null}
          </div>
          <div>
            <div>
              <Accordion.Toggle as={Card} variant="link" eventKey="time">
                <ToggleHeader
                  onResetClicked={() => {
                    if (
                      !isFilterNotApplied(filtersApplied) &&
                      !isFilterNotApplied(filtersApplied.comparisonData)
                    ) {
                      if (
                        filtersApplied.comparisonData.comparisonDataSet ===
                        ComparisonDataSet.DateRange
                      ) {
                        applyDateFilter(null);
                        applyComparisonData(null);
                      } else {
                        applyDateFilter(null);
                      }
                    } else {
                      applyDateFilter(null);
                      applyComparisonData(null);
                    }
                  }}
                  isOpen={activeEventKey === "time"}
                  label={dateFilterTitle}
                  icon={TimeIcon}
                  isFilterApplied={isDateFilterApplied}
                  comparisonData={
                    isComparisonApplied !== null &&
                    isComparisonApplied !== undefined &&
                    comparisonDataSetTitles !== null &&
                    isComparisonApplied === ComparisonDataSet.DateRange
                      ? comparisonDataSetTitles
                      : null
                  }
                  onRemoveClicked={() => {
                    if (
                      !isFilterNotApplied(filtersApplied) &&
                      !isFilterNotApplied(filtersApplied.comparisonData) &&
                      !isFilterNotApplied(
                        filtersApplied.comparisonData.dataSetOne
                      ) &&
                      isComparisonDataDateFilter(
                        filtersApplied.comparisonData.dataSetOne
                      )
                    ) {
                      applyDateFilter(
                        filtersApplied.comparisonData.dataSetOne as DateFilter
                      );
                      applyComparisonData(null);
                    } else {
                      applyDateFilter(null);
                      applyComparisonData(null);
                    }
                  }}
                />
              </Accordion.Toggle>
            </div>
            {activeEventKey === "time" ? (
              <Accordion.Collapse eventKey="time">
                <TimeToggle
                  startDate={dateFilterStartDate}
                  endDate={dateFilterEndDate}
                  maxEndDate={moment()}
                />
              </Accordion.Collapse>
            ) : null}
          </div>
        </Accordion>
      </div>

      { isFilterOptionsLoadComplete && 
        <button
          className="add_data_btn"
          onClick={() => {
            setActiveEventKey(undefined);
            setIsComparisonModalOpen(true);
          }}
        >
          <img src={AddDataIcon} alt="Create Comparison Icon" />
          <p>Create Comparison</p>
        </button>
      }

      <button className="done_btn" onClick={() => setSideBarOpen(false)}>
        Done
      </button>
    </nav>
  );
});

function isComparisonDataLocationFilter(
  dataSet: LocationFilter | LitterTypeFilter | DateFilter | LocationType[]
): dataSet is LocationFilter {
  return (
    (dataSet as LocationFilter).countries !== undefined &&
    (dataSet as LocationFilter).countries !== null
  );
}

function isComparisonDataLitterTypeFilter(
  dataSet: LocationFilter | LitterTypeFilter | DateFilter | LocationType[]
): dataSet is LitterTypeFilter {
  return (
    (dataSet as LitterTypeFilter).materials !== undefined &&
    (dataSet as LitterTypeFilter).materials !== null
  );
}

function isComparisonDataDateFilter(
  dataSet: LocationFilter | LitterTypeFilter | DateFilter | LocationType[]
): dataSet is DateFilter {
  return (
    (dataSet as DateFilter).startDate !== undefined &&
    (dataSet as DateFilter).endDate !== undefined
  );
}

function isComparisonDataLocationTypeFilter(
  dataSet: LocationFilter | LitterTypeFilter | DateFilter | LocationType[]
): dataSet is LocationType[] {
  if (dataSet === undefined || dataSet === null || !isValidArray(dataSet)) {
    return false;
  }
  return (dataSet as LocationType[]).every(
    (item) =>
      item.label !== undefined &&
      item.label !== null &&
      item.value !== undefined &&
      item.value !== null
  );
}
