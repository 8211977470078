import React from "react";
import "./ProgressBar.scss";
import { DataSet } from "../ComparisonCard/ComparisonCard";
import { BarChartDataItem } from './CustomBarChart';


function getBackgroundColor(dataSet: DataSet) {
  switch (dataSet) {
    case DataSet.DEFAULT:
      return "#5E2B9D";
    case DataSet.SET_ONE:
      return "#5E2B9D";
    case DataSet.SET_TWO:
      return "#F05282";
  }
}

export const ProgressBar: React.FC<BarChartDataItem> = ({
  percent,
  name,
  dataSet,
}) => {
  return (
    <div className="custom_progress_section">
      <div className="progress">
        <div
          className="progress-bar"
          role="progressbar"
          style={{
            width: `${percent}%`,
            backgroundColor: `${getBackgroundColor(dataSet)}`,
            borderRadius: '0px 4px 4px 0px'
          }}
        ></div>
        <p className="progress_label">{name}</p>
      </div>
      <p className="bar_label">{`${percent}%`}</p>
    </div>
  );
};
