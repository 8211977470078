import React from 'react';
import './SurveyEffortStats.scss';
import { InfoItemData, InfoGroup } from "../InfoGroup/InfoGroup";
import { GaugeChart } from '../GaugeChart/GaugeChart';


type SurveyEffortStatsProps = {
    daysFromLastSurvey: number;
    infoData: InfoItemData[];
    isComparison?: boolean;
}


export const SurveyEffortStats: React.FC<SurveyEffortStatsProps> = ({ daysFromLastSurvey, infoData, isComparison }) => {
    
    return <section className={isComparison ? "survey_effort_stats_section__comparison" : "survey_effort_stats_section"}>
        <GaugeChart daysSinceLast={daysFromLastSurvey} />
        <InfoGroup itemData={infoData} stackedUI={false} surveyEffortUI={true} isComparison={isComparison}/>
    </section>

}

