import "./LitterTypeModalToggle.scss";
import React, { useReducer, useEffect, useContext, useMemo } from "react";
import { ModalToggleTitle } from "./ModalToggleTitle";
import { CustomSelect } from "../Sidebar/CustomSelect/CustomSelect";
import ComparisonIcon from "../../assets/add_data.svg";
import {
  MultiSelectOptionSet,
  optionReducer,
} from "../Sidebar/CustomSelect/CustomSelectUtil";
import { IMaterials, IProducts } from "../../store/constants";
import { isLocationSetSelected } from "../Sidebar/LocationToggle/LocationToggleUtil";
import { RootStoreContext } from "../../store/rootStore";
import {
  isValidArray,
  ComparisonDataSet,
  LitterTypeFilter,
} from "../../store/util";

type LitterTypeModalToggleProps = {
  materialsSetOne: (MultiSelectOptionSet & IMaterials)[];
  productsSetOne: (MultiSelectOptionSet & IProducts)[];
  materialsSetTwo: (MultiSelectOptionSet & IMaterials)[];
  productsSetTwo: (MultiSelectOptionSet & IProducts)[];
};

export const LitterTypeModalToggle: React.FC<LitterTypeModalToggleProps> = ({
  materialsSetOne,
  productsSetOne,
  materialsSetTwo,
  productsSetTwo,
}) => {
  const rootStore = useContext(RootStoreContext);

  const { setTempComparisonData } = rootStore.filterStore;

  const [materialOptionsSetOne, dispatchMaterialOptionSetOne] = useReducer(
    optionReducer,
    materialsSetOne
  );

  const [materialOptionsSetTwo, dispatchMaterialOptionSetTwo] = useReducer(
    optionReducer,
    materialsSetTwo
  );

  const [productOptionsSetOne, dispatchProductOptionSetOne] = useReducer(
    optionReducer,
    productsSetOne
  );

  const [productOptionsSetTwo, dispatchProductOptionSetTwo] = useReducer(
    optionReducer,
    productsSetTwo
  );

  function onMaterialItemSelectedSetOne(option: MultiSelectOptionSet) {
    dispatchMaterialOptionSetOne({ type: "toggle", option: option });
  }

  function onMaterialItemSelectedSetTwo(option: MultiSelectOptionSet) {
    dispatchMaterialOptionSetTwo({ type: "toggle", option: option });
  }

  function onProductItemSelectedSetOne(option: MultiSelectOptionSet) {
    dispatchProductOptionSetOne({ type: "toggle", option: option });
  }

  function onProductItemSelectedSetTwo(option: MultiSelectOptionSet) {
    dispatchProductOptionSetTwo({ type: "toggle", option: option });
  }

  useEffect(() => {
    if (isLocationSetSelected(materialOptionsSetOne)) {
      const namesOfSelectedMaterials = materialOptionsSetOne
        .filter((item) => item.isSelected)
        .map((item) => item.label);

      const selectedMaterialIds = materialsSetOne
        .filter((item) => namesOfSelectedMaterials.includes(item.name))
        .map((item) => item.id);

      const productsWithinSelectedMaterials = productsSetOne.filter((item) =>
        selectedMaterialIds.includes(item.litterCategoryId)
      );

      dispatchProductOptionSetOne({
        type: "set",
        option: productsWithinSelectedMaterials,
      });
    } else {
      dispatchProductOptionSetOne({
        type: "set",
        option: [],
      });
    }
    // eslint-disable-next-line 
  }, [materialOptionsSetOne]);

  useEffect(() => {
    if (isLocationSetSelected(materialOptionsSetTwo)) {
      const namesOfSelectedMaterials = materialOptionsSetTwo
        .filter((item) => item.isSelected)
        .map((item) => item.label);

      const selectedMaterialIds = materialsSetTwo
        .filter((item) => namesOfSelectedMaterials.includes(item.name))
        .map((item) => item.id);

      const productsWithinSelectedMaterials = productsSetTwo.filter((item) =>
        selectedMaterialIds.includes(item.litterCategoryId)
      );

      dispatchProductOptionSetTwo({
        type: "set",
        option: productsWithinSelectedMaterials,
      });
    } else {
      dispatchProductOptionSetTwo({
        type: "set",
        option: [],
      });
    }
    // eslint-disable-next-line 
  }, [materialOptionsSetTwo]);

  const dataSetOne = useMemo<LitterTypeFilter | null>(() => {
    const selectedMaterialLabels = materialOptionsSetOne
      .filter((item) => item.isSelected)
      .map((item) => item.label);

    if (isValidArray(selectedMaterialLabels)) {
      const selectedMaterials = materialsSetOne
        .filter((item) => selectedMaterialLabels.includes(item.name))
        .map((item) => ({
          id: item.id,
          name: item.name,
        }));

      const selectedProductLabels = productOptionsSetOne
        .filter((item) => item.isSelected)
        .map((item) => item.label);

      if (isValidArray(selectedProductLabels)) {
        const selectedProducts = productsSetOne
          .filter((item) => selectedProductLabels.includes(item.name))
          .map((item) => ({
            id: item.id,
            name: item.name,
          }));

        return {
          materials: selectedMaterials,
          products: selectedProducts,
        };
      }

      return {
        materials: selectedMaterials,
      };
    }
    return null;
    // eslint-disable-next-line 
  }, [materialOptionsSetOne, productOptionsSetOne]);

  const dataSetTwo = useMemo<LitterTypeFilter | null>(() => {
    const selectedMaterialLabels = materialOptionsSetTwo
      .filter((item) => item.isSelected)
      .map((item) => item.label);

    if (isValidArray(selectedMaterialLabels)) {
      const selectedMaterials = materialsSetTwo
        .filter((item) => selectedMaterialLabels.includes(item.name))
        .map((item) => ({
          id: item.id,
          name: item.name,
        }));

      const selectedProductLabels = productOptionsSetTwo
        .filter((item) => item.isSelected)
        .map((item) => item.label);

      if (isValidArray(selectedProductLabels)) {
        const selectedProducts = productsSetTwo
          .filter((item) => selectedProductLabels.includes(item.name))
          .map((item) => ({
            id: item.id,
            name: item.name,
          }));

        return {
          materials: selectedMaterials,
          products: selectedProducts,
        };
      }

      return {
        materials: selectedMaterials,
      };
    }
    return null;
    // eslint-disable-next-line 
  }, [materialOptionsSetTwo, productOptionsSetTwo]);

  useEffect(() => {
    setTempComparisonData({
      comparisonDataSet: ComparisonDataSet.LitterType,
      dataSetOne: dataSetOne,
      dataSetTwo: dataSetTwo,
    });
    // eslint-disable-next-line 
  }, [
    materialOptionsSetOne,
    materialOptionsSetTwo,
    productOptionsSetOne,
    productOptionsSetTwo,
  ]);

  return (
    <div className="litter_type_modal_container">
      <ModalToggleTitle
        title="Litter Type Comparison"
        subTitle="Define your litter type comparison"
      />
      <div className="litter_type_set_container">
        <div className="set_1">
          <p className="set_title">SET 1</p>
          <div className="select_container">
            <CustomSelect
              options={materialOptionsSetOne}
              defaultTitle={`All Materials`}
              isModal={true}
              onItemSelected={onMaterialItemSelectedSetOne}
            />
            <CustomSelect
              options={productOptionsSetOne}
              defaultTitle={`All Products`}
              isModal={true}
              onItemSelected={onProductItemSelectedSetOne}
              isDisabled={!isLocationSetSelected(materialOptionsSetOne)}
            />
          </div>
        </div>
        <img
          src={ComparisonIcon}
          alt="Comparison Icon"
          className="divider_image"
        />
        <div className="set_2">
          <p className="set_title">SET 2</p>
          <div className="select_container">
            <CustomSelect
              options={materialOptionsSetTwo}
              defaultTitle={`All Materials`}
              isModal={true}
              onItemSelected={onMaterialItemSelectedSetTwo}
            />
            <CustomSelect
              options={productOptionsSetTwo}
              defaultTitle={`All Products`}
              isModal={true}
              onItemSelected={onProductItemSelectedSetTwo}
              isDisabled={!isLocationSetSelected(materialOptionsSetTwo)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
