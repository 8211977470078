import React, { useState, useContext } from "react";
import "./ComparisonModal.scss";
import CloseIcon from "../../assets/close_icon.svg";
import SurveyIcon from "../../assets/survey_icon.svg";
import SurveySelectedIcon from "../../assets/survey_icon_selected.svg";
import LocationIcon from "../../assets/location_icon.svg";
import LocationSelectedIcon from "../../assets/location_icon_selected.svg";
import LitterIcon from "../../assets/litter_icon.svg";
import LitterIconSelected from "../../assets/litter_icon_selected.svg";
import CalendarIcon from "../../assets/calendar_icon.svg";
import CalendarIconSelected from "../../assets/calendar_icon_selected.svg";
import { Collapse } from "react-bootstrap";
import { ModalHeaderButton } from "./ModalHeaderButton";
import { LocationTypeModalToggle } from "./LocationTypeModalToggle";
import { LocationModalToggle } from "./LocationModalToggle";
import { LitterTypeModalToggle } from "./LitterTypeModalToggle";
import { TimeModalToggle } from "./TimeModalToggle";
import { RootStoreContext } from "../../store/rootStore";
import { DataSet } from "../ComparisonCard/ComparisonCard";
import {
  getGoogleAnalyticsEventCategoryForAction,
} from "../../store/constants";
import { LocationTypeValues } from "../../store/constants";
import { observer } from "mobx-react-lite";
import ReactGA from "react-ga";

export const ComparisonModal: React.FC<{}> = observer(() => {
  const [activeEventKey, setActiveEventKey] = useState<string | null>(null);
  const rootStore = useContext(RootStoreContext);

  const { setIsComparisonModalOpen, shoudRenderLocationType } = rootStore.commonStore;
  const {
    applyComparisonData,
    comparisonTempData,
    surveyAreasForComparisonModal,
    regionsForComparisonModal,
    countriesForComparisonModal,
    materialsForComparisonModal,
    productsForComparisonModal,
  } = rootStore.filterStore;

  const { currentSideTab } = rootStore.commonStore;

  //This is a side effect function
  function toggleActiveEventKey(eventKey: string) {
    if (activeEventKey === eventKey) {
      setActiveEventKey(null);
    } else {
      setActiveEventKey(eventKey);
    }
  }

  return (
    <div className="modal">
      <div
        className={`${
          activeEventKey !== null ? "modal_content_toggle_selected" : ""
        } modal_content`}
      >
        <div className="modal_title_container">
          <h1>{`Create ${currentSideTab} Comparison`}</h1>
          <img
            src={CloseIcon}
            alt="Close Icon"
            onClick={() => setIsComparisonModalOpen(false)}
          />
        </div>

        <div className="subtitle_container">
          <h2>Data sets</h2>
          <p>Select a data set to compare</p>
        </div>

        <div>
          <div className="header_btn_grp">
            {
              shoudRenderLocationType && 
                <ModalHeaderButton
                onSelected={() => toggleActiveEventKey("location_type_modal")}
                controlsLabel="location_type_modal"
                iconImg={SurveyIcon}
                highlightedImg={SurveySelectedIcon}
                isSelected={activeEventKey === "location_type_modal"}
                label="Location Type"
              />
            }
      
            <ModalHeaderButton
              onSelected={() => toggleActiveEventKey("location_modal")}
              controlsLabel="location_modal"
              iconImg={LocationIcon}
              highlightedImg={LocationSelectedIcon}
              isSelected={activeEventKey === "location_modal"}
              label="Location"
            />
            <ModalHeaderButton
              onSelected={() => toggleActiveEventKey("litter_type_modal")}
              controlsLabel="litter_type_modal"
              iconImg={LitterIcon}
              highlightedImg={LitterIconSelected}
              isSelected={activeEventKey === "litter_type_modal"}
              label="Litter Type"
            />
            <ModalHeaderButton
              onSelected={() => toggleActiveEventKey("date_modal")}
              controlsLabel="date_modal"
              iconImg={CalendarIcon}
              highlightedImg={CalendarIconSelected}
              isSelected={activeEventKey === "date_modal"}
              label="Date Range"
            />
          </div>
          <div>
            {activeEventKey === "location_type_modal" ? (
              <Collapse in={activeEventKey === "location_type_modal"}>
                <div id="location_type_modal">
                  <LocationTypeModalToggle
                    setOne={LocationTypeValues.map((item) => ({
                      isSelected: false,
                      set: DataSet.SET_ONE,
                      label: item.label,
                      value: item.value,
                    }))}
                    setTwo={LocationTypeValues.map((item) => ({
                      isSelected: false,
                      set: DataSet.SET_TWO,
                      label: item.label,
                      value: item.value,
                    }))}
                  />
                </div>
              </Collapse>
            ) : null}
            {activeEventKey === "location_modal" ? (
              <Collapse in={activeEventKey === "location_modal"}>
                <div id="location_modal">
                  <LocationModalToggle
                    countriesSetOne={countriesForComparisonModal.setOne}
                    regionSetOne={regionsForComparisonModal.setOne}
                    surveyAreaSetOne={surveyAreasForComparisonModal.setOne}
                    countriesSetTwo={countriesForComparisonModal.setTwo}
                    regionSetTwo={regionsForComparisonModal.setTwo}
                    surveyAreaSetTwo={surveyAreasForComparisonModal.setTwo}
                  />
                </div>
              </Collapse>
            ) : null}
            {activeEventKey === "litter_type_modal" ? (
              <Collapse in={activeEventKey === "litter_type_modal"}>
                <div id="litter_type_modal">
                  <LitterTypeModalToggle
                    materialsSetOne={materialsForComparisonModal.setOne}
                    productsSetOne={productsForComparisonModal.setOne}
                    materialsSetTwo={materialsForComparisonModal.setTwo}
                    productsSetTwo={productsForComparisonModal.setTwo}
                  />
                </div>
              </Collapse>
            ) : null}
            {activeEventKey === "date_modal" ? (
              <Collapse in={activeEventKey === "date_modal"}>
                <div id="date_modal">
                  <TimeModalToggle />
                </div>
              </Collapse>
            ) : null}
          </div>
        </div>
        <div className="horizontal_divider"></div>
        <div className="footer_button_grp">
          <button
            className="cancel_btn"
            onClick={() => setIsComparisonModalOpen(false)}
          >
            Cancel
          </button>
          <button
            className={
              activeEventKey === null ? "save_btn__disabled" : "save_btn"
            }
            onClick={() => {
              if (
                comparisonTempData !== null &&
                comparisonTempData !== undefined
              ) {
                applyComparisonData(comparisonTempData);
                //Recording create comparison event with Google analytics
                ReactGA.event({
                  category: getGoogleAnalyticsEventCategoryForAction(
                    comparisonTempData.comparisonDataSet
                  ),
                  action: "Create Comparison",
                });
              }
              setIsComparisonModalOpen(false);
            }}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
});
