import React from "react";
import "./CustomBarChart.scss";
import { DataSet } from "../ComparisonCard/ComparisonCard";
import { ProgressBar } from "./ProgressBar";
import { ProgressBarFooter } from "./ProgressBarFooter";

export type BarChartDataItem = {
  percent: number; //This should always be a number from 0 to 100
  name: string;
  dataSet: DataSet;
};

export type CustomBarChartProps = {
  itemList: BarChartDataItem[];
};

export const CustomBarChart: React.FC<CustomBarChartProps> = ({ itemList }) => {
  return (
    <div className="custom_bar_chart">
      {itemList.map((item: BarChartDataItem) => (
        <ProgressBar
          key={item.name}
          percent={item.percent}
          name={item.name}
          dataSet={item.dataSet}
        />
      ))}
      <ProgressBarFooter />
    </div>
  );
};
