import React from "react";
import "./CustomSelectItem.scss";
import { MultiSelectOptionSet } from './CustomSelectUtil';

type CustomSelectItemProps = {
  option: MultiSelectOptionSet;
  onSelected: (option: MultiSelectOptionSet) => void; //This is basically a toggle function
};

export const CustomSelectItem: React.FC<CustomSelectItemProps> = ({
  option,
  onSelected,
}) => {
  return (
    <div
      className="checkbox_container"
    >
      <input type="checkbox" id={`${option.value}_${option.set}`} checked={option.isSelected} onChange={(event) => {
        onSelected(option);
      }} />
      <label
        htmlFor={`${option.value}_${option.set}`}
      >
        {option.label}
      </label>
    </div>
  );
};
