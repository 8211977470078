import React from "react";
import "./CustomToggle.scss";

export enum ToggleContent {
  ITEMS = "ITEMS",
  WEIGHT = "WEIGHT"
}

type CustomToggleProps = {
  firstOption: string;
  secondOption: string;
  selectedToggle: string;
  onToggleChanged: (option: string) => void;
};

export const CustomToggle: React.FC<CustomToggleProps> = ({
  firstOption,
  secondOption,
  selectedToggle,
  onToggleChanged,
}) => {
  //This is a side effect function
  function getToggleClassName(option: string) {
    if (selectedToggle === option) {
      return "selected_toggle";
    }
    return "unselected_toggle";
  }

  return (
    <div className="custom_toggle_section">
      <div
        className={getToggleClassName(firstOption)}
        onClick={() => onToggleChanged(firstOption)}
      >
        {firstOption}
      </div>
      <div
        className={getToggleClassName(secondOption)}
        onClick={() => onToggleChanged(secondOption)}
      >
        {secondOption}
      </div>
    </div>
  );
};
