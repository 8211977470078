import React from "react";
import "./ApplyFilterSection.scss";

type ApplyFilterSectionProps = {
  onClick: () => void;
};

export const ApplyFilterSection: React.FC<ApplyFilterSectionProps> = ({
  onClick,
}) => {
  return (
    <section className="apply_filter_container">
      <div className="horizontal_divider"></div>
      <button className="apply_filter_btn" onClick={onClick}>
        Apply Filter
      </button>
    </section>
  );
};
