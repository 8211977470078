import { createContext } from "react";
import FilterStore from "./filter/filterStore";
import CommonStore from "./commonStore";
import { configure, reaction, toJS } from "mobx";
import OverviewStore from "./overview/overviewStore";
import LitterDensityStore from "./litterDensity/litterDensityStore";
import MaterialAndProductStore from "./materialAndProduct/materialAndProductStore";
import LocationStore from "./location/locationStore";
import SurveyStatsStore from "./surveyStats/surveyStatsStore";

configure({ enforceActions: "always" });

export class RootStore {
  filterStore: FilterStore;
  commonStore: CommonStore;
  overviewStore: OverviewStore;
  litterDensityStore: LitterDensityStore;
  materialAndProductStore: MaterialAndProductStore;
  locationStore: LocationStore;
  surveyStatsStore: SurveyStatsStore;

  constructor() {
    this.filterStore = new FilterStore(this);
    this.commonStore = new CommonStore(this);
    this.overviewStore = new OverviewStore(this);
    this.litterDensityStore = new LitterDensityStore(this);
    this.materialAndProductStore = new MaterialAndProductStore(this);
    this.locationStore = new LocationStore(this);
    this.surveyStatsStore = new SurveyStatsStore(this);

    reaction(
      () => toJS(this.filterStore.filtersApplied),
      (filtersApplied) => {
        switch (this.commonStore.currentTab) {
          case "overview":
            this.overviewStore.handleFilterChanges(filtersApplied);
            break;
          case "litter_density":
            this.litterDensityStore.handleFilterChanges(filtersApplied);
            break;
          case "materials_and_products":
            this.materialAndProductStore.handleFilterChanges(filtersApplied);
            break;
          case "location":
            this.locationStore.handleFilterChanges(filtersApplied);
            break;
          case "survey_effort":
            this.surveyStatsStore.handleFilterChanges(filtersApplied);
            break;
          default:
            this.overviewStore.handleFilterChanges(filtersApplied);
            break;
        }
      }
    );
    
    this.filterStore.loadFilterData();

  }
}

export const RootStoreContext = createContext(new RootStore());
