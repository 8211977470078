import { observable, action, computed, runInAction, toJS } from "mobx";
import {
  IFilter,
  LocationFilter,
  LitterTypeFilter,
  isFilterNotApplied,
  DateFilter,
  isValidLocationFilter,
  ComparisonData,
  ComparisonDataSet,
  getComparisonDataSetTitlesForLocation,
  getComparisonDataSetTitlesForLitterType,
  getComparisonDataSetTitlesForDate,
  LocationType,
  getComparisonDataSetTitleForLocationType,
  getSetOneLocationTypeFilterTitle,
  getSetTwoLocationTypeFilterTitle,
  getSetOneLocationFilterTitle,
  getSetTwoLocationFilterTitle,
  getSetOneLitterTypeFilterTitle,
  getSetTwoLitterTypeFilterTitle,
  getSetOneDateFilterTitle,
  getSetTwoDateFilterTitle,
} from "../util";
import {
  ICountry,
  IRegion,
  ISurveyAreas,
  IMaterials,
  IProducts,
  LocationTypeValues,
  getDefaultFilterOptions,
} from "../constants";
import { MultiSelectOptionSet } from "../../components/Sidebar/CustomSelect/CustomSelectUtil";
import { DataSet } from "../../components/ComparisonCard/ComparisonCard";
import { RootStore } from "../rootStore";
import moment from "moment";
import { isValidObject } from "../overview/overviewUtil";
import { getFilterData } from "../api/agent";
import { FilterApiData } from "./filterUtil";
import { SurveyType, SideTab, getSurveyTypeCode } from "../util";

export default class FilterStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable filtersApplied: IFilter = {
    surveyType: SideTab.BEACH.value,
  };
  @observable comparisonTempData: ComparisonData;
  @observable filterOptions: {
    isLoading: boolean;
    data: FilterApiData;
  } = {
    isLoading: true,
    data: null,
  };

  @action loadFilterData = async (surveyType: SurveyType = 1) => {
    try {
      const responseData = await getFilterData(surveyType);
      runInAction(() => {
        this.filterOptions = {
          isLoading: false,
          data: toJS(responseData),
        };
      });
    } catch (error) {
      runInAction(() => {
        this.filterOptions = {
          isLoading: false,
          data: getDefaultFilterOptions(),
        };
      });
    }
  };

  @action applyLocationFilter = (locationFilter: LocationFilter) => {
    if (isFilterNotApplied(this.filtersApplied)) {
      this.filtersApplied = {
        locationFilter: locationFilter,
      };
    } else {
      this.filtersApplied.locationFilter = locationFilter;
    }
  };

  @action applyLitterTypeFilter = (litterTypeFilter: LitterTypeFilter) => {
    if (isFilterNotApplied(this.filtersApplied)) {
      this.filtersApplied = {
        litterTypeFilter: litterTypeFilter,
      };
    } else {
      this.filtersApplied.litterTypeFilter = litterTypeFilter;
    }
  };

  @action applyComparisonData = (comparisonData: ComparisonData) => {
    if (isFilterNotApplied(this.filtersApplied)) {
      this.filtersApplied = {
        comparisonData: comparisonData,
      };
    } else {
      this.filtersApplied.comparisonData = comparisonData;
    }
  };

  @action applyDateFilter = (dateFilter: DateFilter) => {
    if (isFilterNotApplied(this.filtersApplied)) {
      this.filtersApplied = {
        dateFilter: dateFilter,
      };
    } else {
      this.filtersApplied.dateFilter = dateFilter;
    }
  };

  @action applyLocationTypeFilter = (locationTypeFilter: LocationType[]) => {
    if (isFilterNotApplied(this.filtersApplied)) {
      this.filtersApplied = {
        locationTypeFilter: locationTypeFilter,
      };
    } else {
      this.filtersApplied.locationTypeFilter = locationTypeFilter;
    }
  };

  @action applySurveyTypeFilter = (surveyType: SurveyType) => {
    if (isFilterNotApplied(this.filtersApplied)) {
      this.filtersApplied = {
        surveyType,
      };
    } else {
      this.filtersApplied.surveyType = surveyType;
    }
  };

  @action resetFilters = () => {
    if (this.filtersApplied !== null && this.filtersApplied !== undefined) {
      this.filtersApplied = null;
    }
    const { currentSideTab } = this.rootStore.commonStore;
    this.applySurveyTypeFilter(getSurveyTypeCode(currentSideTab));
  };

  @computed get locationTypeFilterTitle(): string {
    if (
      isFilterNotApplied(this.filtersApplied) ||
      isFilterNotApplied(this.filtersApplied.locationTypeFilter)
    ) {
      return "All Location Types";
    }
    if (isValidLocationFilter(this.filtersApplied.locationTypeFilter)) {
      return this.filtersApplied.locationTypeFilter
        .map((item) => item.label)
        .join(", ");
    }
    return "All Location Types";
  }

  @computed get isLocationTypeFilterApplied(): boolean {
    if (
      isFilterNotApplied(this.filtersApplied) ||
      isFilterNotApplied(this.filtersApplied.locationTypeFilter)
    ) {
      return false;
    }

    if (isValidLocationFilter(this.filtersApplied.locationTypeFilter)) {
      return true;
    }
    return false;
  }

  @computed get locationTypeForSidebar(): MultiSelectOptionSet[] {
    if (
      isFilterNotApplied(this.filtersApplied) ||
      isFilterNotApplied(this.filtersApplied.locationTypeFilter)
    ) {
      return LocationTypeValues.map((item) => ({
        isSelected: false,
        set: DataSet.DEFAULT,
        label: item.label,
        value: item.value,
      }));
    }

    if (isValidLocationFilter(this.filtersApplied.locationTypeFilter)) {
      return LocationTypeValues.map((item) => ({
        isSelected: this.filtersApplied.locationTypeFilter.some(
          (element) => element.label === item.label
        ),
        set: DataSet.DEFAULT,
        label: item.label,
        value: item.value,
      }));
    }
    return LocationTypeValues.map((item) => ({
      isSelected: false,
      set: DataSet.DEFAULT,
      label: item.label,
      value: item.value,
    }));
  }

  @computed get locationFilterTitle(): string {
    if (
      isFilterNotApplied(this.filtersApplied) ||
      isFilterNotApplied(this.filtersApplied.locationFilter)
    ) {
      return "All Locations";
    }

    if (isValidLocationFilter(this.filtersApplied.locationFilter.countries)) {
      const countries = this.filtersApplied.locationFilter.countries
        .map((item) => item.name)
        .join(", ");

      if (isValidLocationFilter(this.filtersApplied.locationFilter.regions)) {
        const regions = this.filtersApplied.locationFilter.regions
          .map((item) => item.name)
          .join(", ");

        if (
          isValidLocationFilter(this.filtersApplied.locationFilter.surveyAreas)
        ) {
          const surveyAreas = this.filtersApplied.locationFilter.surveyAreas
            .map((item) => item.name)
            .join(", ");

          return `${countries}, ${regions}, ${surveyAreas}`;
        }

        return `${countries}, ${regions}`;
      }

      return countries;
    }

    return "All Locations";
  }

  @computed get isLocationFilterApplied(): boolean {
    if (
      isFilterNotApplied(this.filtersApplied) ||
      isFilterNotApplied(this.filtersApplied.locationFilter)
    ) {
      return false;
    }

    if (isValidLocationFilter(this.filtersApplied.locationFilter.countries)) {
      return true;
    }
    return false;
  }

  @computed get isFilterOptionsLoadComplete(): boolean {
    return (
      isValidObject(this.filterOptions) &&
      !this.filterOptions.isLoading &&
      this.filterOptions.data !== null
    );
  }

  @computed get currentFilterOptions(): FilterApiData {
    if (this.filterOptions.data !== null && !this.filterOptions.isLoading) {
      return this.filterOptions.data;
    }
    return getDefaultFilterOptions();
  }

  @computed get countriesForSidebar(): (ICountry & MultiSelectOptionSet)[] {
    if (
      isValidObject(this.filtersApplied) &&
      isValidObject(this.filtersApplied.locationFilter) &&
      isValidLocationFilter(this.filtersApplied.locationFilter.countries)
    ) {
      return this.currentFilterOptions.countries.map((item) => ({
        ...item,
        label: item.name,
        value: `${item.name}_${item.id}`,
        isSelected: this.filtersApplied.locationFilter.countries.some(
          (element) => element.name === item.name && element.id === item.id
        ),
        set: DataSet.DEFAULT,
      }));
    }

    return this.currentFilterOptions.countries.map((item) => ({
      ...item,
      label: item.name,
      value: `${item.name}_${item.id}`,
      isSelected: false,
      set: DataSet.DEFAULT,
    }));
  }

  @computed get countriesForComparisonModal(): {
    setOne: (ICountry & MultiSelectOptionSet)[];
    setTwo: (ICountry & MultiSelectOptionSet)[];
  } {
    return {
      setOne: this.currentFilterOptions.countries.map((item) => ({
        ...item,
        label: item.name,
        value: `${item.name}_${item.id}`,
        isSelected: false,
        set: DataSet.SET_ONE,
      })),
      setTwo: this.currentFilterOptions.countries.map((item) => ({
        ...item,
        label: item.name,
        value: `${item.name}_${item.id}`,
        isSelected: false,
        set: DataSet.SET_TWO,
      })),
    };
  }

  @computed get regionsForSidebar(): (IRegion & MultiSelectOptionSet)[] {
    if (
      isValidObject(this.filtersApplied) &&
      isValidObject(this.filtersApplied.locationFilter) &&
      isValidLocationFilter(this.filtersApplied.locationFilter.regions)
    ) {
      return this.currentFilterOptions.regions.map((item) => ({
        ...item,
        label: item.name,
        value: `${item.name}_${item.id}`,
        isSelected: this.filtersApplied.locationFilter.regions.some(
          (element) => element.name === item.name && element.id === item.id
        ),
        set: DataSet.DEFAULT,
      }));
    }

    return this.currentFilterOptions.regions.map((item) => ({
      ...item,
      label: item.name,
      value: `${item.name}_${item.id}`,
      isSelected: false,
      set: DataSet.DEFAULT,
    }));
  }

  @computed get regionsForComparisonModal(): {
    setOne: (IRegion & MultiSelectOptionSet)[];
    setTwo: (IRegion & MultiSelectOptionSet)[];
  } {
    return {
      setOne: this.currentFilterOptions.regions.map((item) => ({
        ...item,
        label: item.name,
        value: `${item.name}_${item.id}`,
        isSelected: false,
        set: DataSet.SET_ONE,
      })),
      setTwo: this.currentFilterOptions.regions.map((item) => ({
        ...item,
        label: item.name,
        value: `${item.name}_${item.id}`,
        isSelected: false,
        set: DataSet.SET_TWO,
      })),
    };
  }

  @computed get surveyAreasForSidebar(): (ISurveyAreas &
    MultiSelectOptionSet)[] {
    if (
      isValidObject(this.filtersApplied) &&
      isValidObject(this.filtersApplied.locationFilter) &&
      isValidLocationFilter(this.filtersApplied.locationFilter.surveyAreas)
    ) {
      return this.currentFilterOptions.surveyAreas.map((item) => ({
        ...item,
        label: item.name,
        value: `${item.name}_${item.id}`,
        isSelected: this.filtersApplied.locationFilter.surveyAreas.some(
          (element) => element.name === item.name && element.id === item.id
        ),
        set: DataSet.DEFAULT,
      }));
    }
    return this.currentFilterOptions.surveyAreas.map((item) => ({
      ...item,
      label: item.name,
      value: `${item.name}_${item.id}`,
      isSelected: false,
      set: DataSet.DEFAULT,
    }));
  }

  @computed get surveyAreasForComparisonModal(): {
    setOne: (ISurveyAreas & MultiSelectOptionSet)[];
    setTwo: (ISurveyAreas & MultiSelectOptionSet)[];
  } {
    return {
      setOne: this.currentFilterOptions.surveyAreas.map((item) => ({
        ...item,
        label: item.name,
        value: `${item.name}_${item.id}`,
        isSelected: false,
        set: DataSet.SET_ONE,
      })),
      setTwo: this.currentFilterOptions.surveyAreas.map((item) => ({
        ...item,
        label: item.name,
        value: `${item.name}_${item.id}`,
        isSelected: false,
        set: DataSet.SET_TWO,
      })),
    };
  }

  @computed get litterTypeFilterTitle(): string {
    if (
      isFilterNotApplied(this.filtersApplied) ||
      isFilterNotApplied(this.filtersApplied.litterTypeFilter)
    ) {
      return "All Litter Types";
    }

    if (isValidLocationFilter(this.filtersApplied.litterTypeFilter.materials)) {
      const materials = this.filtersApplied.litterTypeFilter.materials
        .map((item) => item.name)
        .join(", ");

      if (
        isValidLocationFilter(this.filtersApplied.litterTypeFilter.products)
      ) {
        const products = this.filtersApplied.litterTypeFilter.products
          .map((item) => item.name)
          .join(", ");

        return `${materials}, ${products}`;
      }

      return materials;
    }

    return "All Litter Types";
  }

  @computed get isLitterTypeFilterApplied(): boolean {
    if (
      isFilterNotApplied(this.filtersApplied) ||
      isFilterNotApplied(this.filtersApplied.litterTypeFilter)
    ) {
      return false;
    }

    if (isValidLocationFilter(this.filtersApplied.litterTypeFilter.materials)) {
      return true;
    }
    return false;
  }

  @computed get materialsForSidebar(): (IMaterials & MultiSelectOptionSet)[] {
    if (
      isValidObject(this.filtersApplied) &&
      isValidObject(this.filtersApplied.litterTypeFilter) &&
      isValidLocationFilter(this.filtersApplied.litterTypeFilter.materials)
    ) {
      return this.currentFilterOptions.materials.map((item) => ({
        ...item,
        label: item.name,
        value: `${item.name}_${item.id}`,
        isSelected: this.filtersApplied.litterTypeFilter.materials.some(
          (element) => element.name === item.name && element.id === item.id
        ),
        set: DataSet.DEFAULT,
      }));
    }

    return this.currentFilterOptions.materials.map((item) => ({
      ...item,
      label: item.name,
      value: `${item.name}_${item.id}`,
      isSelected: false,
      set: DataSet.DEFAULT,
    }));
  }

  @computed get materialsForComparisonModal(): {
    setOne: (IMaterials & MultiSelectOptionSet)[];
    setTwo: (IMaterials & MultiSelectOptionSet)[];
  } {
    return {
      setOne: this.currentFilterOptions.materials.map((item) => ({
        ...item,
        label: item.name,
        value: `${item.name}_${item.id}`,
        isSelected: false,
        set: DataSet.SET_ONE,
      })),
      setTwo: this.currentFilterOptions.materials.map((item) => ({
        ...item,
        label: item.name,
        value: `${item.name}_${item.id}`,
        isSelected: false,
        set: DataSet.SET_TWO,
      })),
    };
  }

  @computed get productsForSidebar(): (IProducts & MultiSelectOptionSet)[] {
    if (
      isValidObject(this.filtersApplied) &&
      isValidObject(this.filtersApplied.litterTypeFilter) &&
      isValidLocationFilter(this.filtersApplied.litterTypeFilter.products)
    ) {
      return this.currentFilterOptions.products.map((item) => ({
        ...item,
        label: item.name,
        value: `${item.name}_${item.id}`,
        isSelected: this.filtersApplied.litterTypeFilter.products.some(
          (element) => element.name === item.name && element.id === item.id
        ),
        set: DataSet.DEFAULT,
      }));
    }

    return this.currentFilterOptions.products.map((item) => ({
      ...item,
      label: item.name,
      value: `${item.name}_${item.id}`,
      isSelected: false,
      set: DataSet.DEFAULT,
    }));
  }

  @computed get productsForComparisonModal(): {
    setOne: (IProducts & MultiSelectOptionSet)[];
    setTwo: (IProducts & MultiSelectOptionSet)[];
  } {
    return {
      setOne: this.currentFilterOptions.products.map((item) => ({
        ...item,
        label: item.name,
        value: `${item.name}_${item.id}`,
        isSelected: false,
        set: DataSet.SET_ONE,
      })),
      setTwo: this.currentFilterOptions.products.map((item) => ({
        ...item,
        label: item.name,
        value: `${item.name}_${item.id}`,
        isSelected: false,
        set: DataSet.SET_TWO,
      })),
    };
  }

  @computed get dateFilterTitle(): string {
    if (
      isFilterNotApplied(this.filtersApplied) ||
      isFilterNotApplied(this.filtersApplied.dateFilter)
    ) {
      return "All Time";
    }

    return `${this.filtersApplied.dateFilter.startDate.format(
      "ll"
    )} - ${this.filtersApplied.dateFilter.endDate.format("ll")}`;
  }

  @computed get isDateFilterApplied(): boolean {
    if (
      isFilterNotApplied(this.filtersApplied) ||
      isFilterNotApplied(this.filtersApplied.dateFilter)
    ) {
      return false;
    }
    return true;
  }

  @computed get dateFilterStartDate(): moment.Moment | undefined {
    if (
      isFilterNotApplied(this.filtersApplied) ||
      isFilterNotApplied(this.filtersApplied.dateFilter)
    ) {
      return undefined;
    }
    return this.filtersApplied.dateFilter.startDate;
  }

  @computed get dateFilterEndDate(): moment.Moment | undefined {
    if (
      isFilterNotApplied(this.filtersApplied) ||
      isFilterNotApplied(this.filtersApplied.dateFilter)
    ) {
      return undefined;
    }
    return this.filtersApplied.dateFilter.endDate;
  }

  @action setTempComparisonData = (comparisonData: ComparisonData) => {
    this.comparisonTempData = comparisonData;
  };

  @computed get isComparisonApplied(): ComparisonDataSet | null {
    if (
      isFilterNotApplied(this.filtersApplied) ||
      isFilterNotApplied(this.filtersApplied.comparisonData)
    ) {
      return null;
    }
    return this.filtersApplied.comparisonData.comparisonDataSet;
  }

  @computed get comparisonDataSetTitles(): {
    dataSetOne: string;
    dataSetTwo: string;
  } | null {
    if (
      isFilterNotApplied(this.filtersApplied) ||
      isFilterNotApplied(this.filtersApplied.comparisonData)
    ) {
      return null;
    }

    switch (this.filtersApplied.comparisonData.comparisonDataSet) {
      case ComparisonDataSet.Location:
        return getComparisonDataSetTitlesForLocation(
          this.filtersApplied.comparisonData.dataSetOne as LocationFilter,
          this.filtersApplied.comparisonData.dataSetTwo as LocationFilter
        );
      case ComparisonDataSet.LitterType:
        return getComparisonDataSetTitlesForLitterType(
          this.filtersApplied.comparisonData.dataSetOne as LitterTypeFilter,
          this.filtersApplied.comparisonData.dataSetTwo as LitterTypeFilter
        );
      case ComparisonDataSet.DateRange:
        return getComparisonDataSetTitlesForDate(
          this.filtersApplied.comparisonData.dataSetOne as DateFilter,
          this.filtersApplied.comparisonData.dataSetTwo as DateFilter
        );
      case ComparisonDataSet.LocationType:
        return getComparisonDataSetTitleForLocationType(
          this.filtersApplied.comparisonData.dataSetOne as
            | LocationType[]
            | null,
          this.filtersApplied.comparisonData.dataSetTwo as LocationType[] | null
        );
      default:
        return null;
    }
  }

  @computed get dataSetViewSetOneData(): {
    locationTypeFilterTitle: string;
    locationFilterTitle: string;
    litterTypeFilterTitle: string;
    dateFilterTitle: string;
  } {
    return {
      locationFilterTitle: getSetOneLocationFilterTitle(this.filtersApplied),
      locationTypeFilterTitle: getSetOneLocationTypeFilterTitle(
        this.filtersApplied
      ),
      litterTypeFilterTitle: getSetOneLitterTypeFilterTitle(
        this.filtersApplied
      ),
      dateFilterTitle: getSetOneDateFilterTitle(this.filtersApplied),
    };
  }

  @computed get isLocationTypeFilterAppliedComparisonDataSet(): boolean {
    if (isValidObject(this.filtersApplied)) {
      if (
        isValidObject(this.filtersApplied.comparisonData) &&
        isValidObject(this.filtersApplied.comparisonData.comparisonDataSet) &&
        this.filtersApplied.comparisonData.comparisonDataSet ===
          ComparisonDataSet.LocationType
      ) {
        return true;
      }
      return this.isLocationTypeFilterApplied;
    }
    return false;
  }

  @computed get isLocationFilterAppliedComparisonDataSet(): boolean {
    if (isValidObject(this.filtersApplied)) {
      if (
        isValidObject(this.filtersApplied.comparisonData) &&
        isValidObject(this.filtersApplied.comparisonData.comparisonDataSet) &&
        this.filtersApplied.comparisonData.comparisonDataSet ===
          ComparisonDataSet.Location
      ) {
        return true;
      }
      return this.isLocationFilterApplied;
    }
    return false;
  }

  @computed get isLitterTypeFilterAppliedComparisonDataSet(): boolean {
    if (isValidObject(this.filtersApplied)) {
      if (
        isValidObject(this.filtersApplied.comparisonData) &&
        isValidObject(this.filtersApplied.comparisonData.comparisonDataSet) &&
        this.filtersApplied.comparisonData.comparisonDataSet ===
          ComparisonDataSet.LitterType
      ) {
        return true;
      }
      return this.isLitterTypeFilterApplied;
    }
    return false;
  }

  @computed get isDateFilterAppliedComparisonDataSet(): boolean {
    if (isValidObject(this.filtersApplied)) {
      if (
        isValidObject(this.filtersApplied.comparisonData) &&
        isValidObject(this.filtersApplied.comparisonData.comparisonDataSet) &&
        this.filtersApplied.comparisonData.comparisonDataSet ===
          ComparisonDataSet.DateRange
      ) {
        return true;
      }
      return this.isDateFilterApplied;
    }
    return false;
  }

  @computed get dataSetViewSetTwoData(): {
    locationTypeFilterTitle: string;
    locationFilterTitle: string;
    litterTypeFilterTitle: string;
    dateFilterTitle: string;
  } {
    return {
      locationFilterTitle: getSetTwoLocationFilterTitle(this.filtersApplied),
      locationTypeFilterTitle: getSetTwoLocationTypeFilterTitle(
        this.filtersApplied
      ),
      litterTypeFilterTitle: getSetTwoLitterTypeFilterTitle(
        this.filtersApplied
      ),
      dateFilterTitle: getSetTwoDateFilterTitle(this.filtersApplied),
    };
  }
}
