import React, { useContext, useEffect } from "react";
import "./LitterDensity.scss";

import { PageTitle } from "../components/PageTitle/PageTitle";
import { PageSubTitle } from "../components/PageSubTitle/PageSubTitle";
import { LitterDensityChart } from "../components/LitterDensityChart/LitterDensityChart";
import {
  DataSet,
  ComparisonCard,
} from "../components/ComparisonCard/ComparisonCard";
import { SummaryCard } from "../components/SummaryCard/SummaryCard";
import { DataSetView } from "../components/DataSetView/DataSetView";
import { RootStoreContext } from "../store/rootStore";
import { observer } from "mobx-react-lite";
import { isValidObject } from "../store/overview/overviewUtil";
import { LoadingStatus } from "../store/util";
import ReactGA from "react-ga";

export const LitterDensity: React.FC<{}> = observer(() => {
  const SummaryCardWithCompCard = ComparisonCard(SummaryCard);
  const DataSetViewWithCompCard = ComparisonCard(DataSetView);
  const rootStore = useContext(RootStoreContext);

  const { filtersApplied } = rootStore.filterStore;

  const {
    isComparisonApplied,
    comparisonLitterDensityChartData,
    handleFilterChanges,
    defaultLitterDensityChartData,
    defaultLitterDensityInfoGroupWeightData,
    defaultLitterDensityInfoGroupItemData,
    comparisonLitterDensityInfoGroupWeightData,
    comparisonLitterDensityInfoGroupItemData,
  } = rootStore.litterDensityStore;

  const {
    dataSetViewSetOneData,
    dataSetViewSetTwoData,
    isDateFilterAppliedComparisonDataSet,
    isLocationFilterAppliedComparisonDataSet,
    isLitterTypeFilterAppliedComparisonDataSet,
    isLocationTypeFilterAppliedComparisonDataSet,
  } = rootStore.filterStore;

  const { isStormwaterSelected } = rootStore.commonStore;

  useEffect(() => {
    ReactGA.pageview("/litterDensity");
    handleFilterChanges(filtersApplied);
    // eslint-disable-next-line
  }, []);

  const averageDensityText = isStormwaterSelected
    ? "AVERAGE QUANTITY"
    : "AVERAGE DENSITY";

  return isComparisonApplied ? (
    <div>
      <div className="page_title_container">
        <PageTitle
          title={isStormwaterSelected ? "Litter Quantity" : "Litter Density"}
        />
      </div>
      <div className="page_sub_title_container">
        <PageSubTitle subTitle="Data Set" />
      </div>
      <div className="data_set_view_container__comparison">
        <DataSetViewWithCompCard
          loadingStatus={LoadingStatus.Success}
          viewTitle="SET 1"
          locationType={{
            value: dataSetViewSetOneData.locationTypeFilterTitle,
            isFilterApplied: isLocationTypeFilterAppliedComparisonDataSet,
          }}
          location={{
            value: dataSetViewSetOneData.locationFilterTitle,
            isFilterApplied: isLocationFilterAppliedComparisonDataSet,
          }}
          litterType={{
            value: dataSetViewSetOneData.litterTypeFilterTitle,
            isFilterApplied: isLitterTypeFilterAppliedComparisonDataSet,
          }}
          timeRange={{
            value: dataSetViewSetOneData.dateFilterTitle,
            isFilterApplied: isDateFilterAppliedComparisonDataSet,
          }}
          dataSet={DataSet.SET_ONE}
        />
        <DataSetViewWithCompCard
          loadingStatus={LoadingStatus.Success}
          viewTitle="SET 2"
          locationType={{
            value: dataSetViewSetTwoData.locationTypeFilterTitle,
            isFilterApplied: isLocationTypeFilterAppliedComparisonDataSet,
          }}
          location={{
            value: dataSetViewSetTwoData.locationFilterTitle,
            isFilterApplied: isLocationFilterAppliedComparisonDataSet,
          }}
          litterType={{
            value: dataSetViewSetTwoData.litterTypeFilterTitle,
            isFilterApplied: isLitterTypeFilterAppliedComparisonDataSet,
          }}
          timeRange={{
            value: dataSetViewSetTwoData.dateFilterTitle,
            isFilterApplied: isDateFilterAppliedComparisonDataSet,
          }}
          dataSet={DataSet.SET_TWO}
        />
      </div>
      <div className="page_sub_title_container">
        <PageSubTitle
          subTitle={`Average Litter ${
            isStormwaterSelected ? "Quantity" : "Density"
          } per Month`}
          helperText={
            isStormwaterSelected
              ? "The graph below shows the average number of items found in stormwater catchpit surveys each month (based on current filters)."
              : "The litter density measure is the number of items per 1000m2. This measure enables comparison of different locations or time periods."
          }
        />
      </div>
      <div className="litter_density_chart_container">
        <LitterDensityChart
          setOneWeightChartData={
            isValidObject(comparisonLitterDensityChartData.setOne)
              ? comparisonLitterDensityChartData.setOne.weightData
              : null
          }
          setOneItemChartData={
            isValidObject(comparisonLitterDensityChartData.setOne)
              ? comparisonLitterDensityChartData.setOne.itemData
              : null
          }
          setTwoItemChartData={
            isValidObject(comparisonLitterDensityChartData.setTwo)
              ? comparisonLitterDensityChartData.setTwo.itemData
              : null
          }
          setTwoWeightChartData={
            isValidObject(comparisonLitterDensityChartData.setTwo)
              ? comparisonLitterDensityChartData.setTwo.weightData
              : null
          }
          loadingStatus={comparisonLitterDensityChartData.loadingStatus}
        />
      </div>
      <div className="page_sub_title_container">
        <PageSubTitle
          subTitle="Items"
          helperText="Item is the finest description of rubbish, often product type such as ‘Bottle Caps, lids and pull tabs’"
        />
      </div>
      <div className="litter_density_item_summary_container__comparison">
        <SummaryCardWithCompCard
          dataSet={DataSet.SET_ONE}
          loadingStatus={comparisonLitterDensityInfoGroupItemData.loadingStatus}
          firstSectionTitle="TOTAL ITEMS"
          secondSectionTitle={averageDensityText}
          firstSectionValue={
            comparisonLitterDensityInfoGroupItemData.setOne.totalItems
          }
          secondSectionValue={
            comparisonLitterDensityInfoGroupItemData.setOne.averageItemDensity
          }
          secondSectionDescription={
            isStormwaterSelected ? "Items per catchpit" : "Items Per 1000m2"
          }
        />
        <SummaryCardWithCompCard
          dataSet={DataSet.SET_TWO}
          loadingStatus={comparisonLitterDensityInfoGroupItemData.loadingStatus}
          firstSectionTitle="TOTAL ITEMS"
          secondSectionTitle={averageDensityText}
          firstSectionValue={
            comparisonLitterDensityInfoGroupItemData.setTwo.totalItems
          }
          secondSectionValue={
            comparisonLitterDensityInfoGroupItemData.setTwo.averageItemDensity
          }
          secondSectionDescription={
            isStormwaterSelected ? "Items per catchpit" : "Items Per 1000m2"
          }
        />
      </div>
      <div className="page_sub_title_container">
        <PageSubTitle
          subTitle="Weight"
          helperText={
            isStormwaterSelected
              ? "Total Weight (in kilograms) refers to the weight of the items found at all stormwater catchpit surveys (based on current filters). Average Litter Quantity is the average weight of items (in grams) found in stormwater catchpit surveys."
              : "Weight is in Kgs for Total Weight, which refers to the weight of all items collected and counted at surveys within the filter parameters currently set, as well as in Grams for the Average Litter Density"
          }
        />
      </div>
      <div className="litter_density_weight_summary_container__comparison">
        <SummaryCardWithCompCard
          dataSet={DataSet.SET_ONE}
          loadingStatus={
            comparisonLitterDensityInfoGroupWeightData.loadingStatus
          }
          firstSectionTitle="TOTAL WEIGHT (KG)"
          secondSectionTitle={averageDensityText}
          firstSectionValue={
            comparisonLitterDensityInfoGroupWeightData.setOne.totalWeight
          }
          secondSectionValue={
            comparisonLitterDensityInfoGroupWeightData.setOne
              .averageWeightDensity
          }
          secondSectionDescription={
            isStormwaterSelected ? "Grams per catchpit" : "Grams per 1000m2"
          }
        />
        <SummaryCardWithCompCard
          dataSet={DataSet.SET_TWO}
          loadingStatus={
            comparisonLitterDensityInfoGroupWeightData.loadingStatus
          }
          firstSectionTitle="TOTAL WEIGHT (KG)"
          secondSectionTitle={averageDensityText}
          firstSectionValue={
            comparisonLitterDensityInfoGroupWeightData.setTwo.totalWeight
          }
          secondSectionValue={
            comparisonLitterDensityInfoGroupWeightData.setTwo
              .averageWeightDensity
          }
          secondSectionDescription={
            isStormwaterSelected ? "Grams per catchpit" : "Grams per 1000m2"
          }
        />
      </div>
    </div>
  ) : (
    <div>
      <div className="page_title_container">
        <PageTitle
          title={isStormwaterSelected ? "Litter Quantity" : "Litter Density"}
        />
      </div>
      <div className="page_sub_title_container">
        <PageSubTitle
          subTitle={`Average Litter ${
            isStormwaterSelected ? "Quantity" : "Density"
          } per Month`}
          helperText={
            isStormwaterSelected
              ? "The graph below shows the average number of items found in stormwater catchpit surveys each month (based on current filters)."
              : "The litter density measure is the number of items per 1000m2. This measure enables comparison of different locations or time periods."
          }
        />
      </div>
      <div className="litter_density_chart_container">
        <LitterDensityChart
          defaultItemChartData={defaultLitterDensityChartData.itemData}
          defaultWeightChartData={defaultLitterDensityChartData.weightData}
          loadingStatus={defaultLitterDensityChartData.loadingStatus}
        />
      </div>
      <div className="page_sub_title_container">
        <PageSubTitle
          subTitle="Items"
          helperText="Item is the finest description of rubbish, often product type such as ‘Bottle Caps, lids and pull tabs’"
        />
      </div>
      <div className="litter_density_item_summary_container">
        <SummaryCardWithCompCard
          dataSet={DataSet.DEFAULT}
          loadingStatus={defaultLitterDensityInfoGroupItemData.loadingStatus}
          firstSectionTitle="TOTAL ITEMS"
          secondSectionTitle={averageDensityText}
          firstSectionValue={defaultLitterDensityInfoGroupItemData.totalItems}
          secondSectionValue={
            defaultLitterDensityInfoGroupItemData.averageItemDensity
          }
          secondSectionDescription={
            isStormwaterSelected ? "Items per catchpit" : "Items Per 1000m2"
          }
        />
      </div>
      <div className="page_sub_title_container">
        <PageSubTitle
          subTitle="Weight"
          helperText={
            isStormwaterSelected
              ? "Total Weight (in kilograms) refers to the weight of the items found at all stormwater catchpit surveys (based on current filters). Average Litter Quantity is the average weight of items (in grams) found in stormwater catchpit surveys."
              : "Weight is in Kgs for Total Weight, which refers to the weight of all items collected and counted at surveys within the filter parameters currently set, as well as in Grams for the Average Litter Density"
          }
        />
      </div>
      <div className="litter_density_weight_summary_container">
        <SummaryCardWithCompCard
          dataSet={DataSet.DEFAULT}
          loadingStatus={defaultLitterDensityInfoGroupWeightData.loadingStatus}
          firstSectionTitle="TOTAL WEIGHT (KG)"
          secondSectionTitle={averageDensityText}
          firstSectionValue={
            defaultLitterDensityInfoGroupWeightData.totalWeight
          }
          secondSectionValue={
            defaultLitterDensityInfoGroupWeightData.averageWeightDensity
          }
          secondSectionDescription={
            isStormwaterSelected ? "Grams per catchpit" : "Grams per 1000m2"
          }
        />
      </div>
    </div>
  );
});
