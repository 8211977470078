import * as React from "react";
import { useTabs } from "./Tabs";
import "./Tab.scss";
import LeftArrow from "../../assets/left_arrow.svg";
import RightArrow from "../../assets/right_arrow.svg";
import { TabList } from "./Tabs";
import { RootStoreContext } from "../../store/rootStore";
import { observer } from "mobx-react-lite";


export interface ITabProps {
  label: string;
  // nextTab: () => void;
  // previousTab: () => void;
}

function getLabelValue(label: string) {
  let index = TabList.findIndex((element) => element.label === label);
  if (index >= 0) {
    return TabList[index].value;
  }
  return "No Value";
}

//Currently not doing any extreme error handling as TabList considered as source of truth
function getLabelPosition(label: string) {
  //Position is essentially index + 1 for UI
  return TabList.findIndex((element) => element.label === label) + 1;
}

export const Tab: React.FC<ITabProps> = observer((props) => {
  const rootStore = React.useContext(RootStoreContext);
  const { activeTab, setActiveTab } = useTabs();
  const { isStormwaterSelected } = rootStore.commonStore;

  //Currently not doing any extreme error handling as TabList considered as source of truth
  function nextTab() {
    let index = TabList.findIndex((element) => element.label === activeTab);
    if (index >= 0) {
      if (index === TabList.length - 1) {
        //This is the last element
        setActiveTab(TabList[0].label);
        rootStore.commonStore.setCurrentTab(TabList[0].label);
      } else {
        setActiveTab(TabList[index + 1].label);
        rootStore.commonStore.setCurrentTab(TabList[index + 1].label);
      }
    }
  }

  //Currently not doing any extreme error handling as TabList considered as source of truth
  function previousTab() {
    let index = TabList.findIndex((element) => element.label === activeTab);
    if (index >= 0) {
      if (index === 0) {
        //This is the last element
        setActiveTab(TabList[TabList.length - 1].label);
        rootStore.commonStore.setCurrentTab(TabList[TabList.length - 1].label);
      } else {
        setActiveTab(TabList[index - 1].label);
        rootStore.commonStore.setCurrentTab(TabList[index - 1].label);
      }
    }
  }

  const labelValue = getLabelValue(props.label)

  return (
    <div
      className={`tab ${activeTab === props.label ? "" : "non_current_tab"}`}
    >
      <div
        className={`content ${activeTab === props.label ? "selected" : ""}`}
        onClick={() => {
          setActiveTab(props.label);
          rootStore.commonStore.setCurrentTab(props.label);
        }}
      >
        {(isStormwaterSelected && labelValue === "Litter Density") ? "Litter Quantity"  : labelValue}{" "}
        <span className="number_span">
          ({getLabelPosition(props.label)}/{TabList.length})
        </span>
      </div>
      <div
        className={activeTab === props.label ? "underline" : "no_underline"}
      ></div>

      <div className="left_arrow" onClick={previousTab}>
        <img src={LeftArrow} alt="Left Arrow" />
      </div>
      <div className="right_arrow" onClick={nextTab}>
        <img src={RightArrow} alt="Right Arrow" />
      </div>
    </div>
  );
});
