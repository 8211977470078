import { MixedChartData } from "../../components/MixedChart/MixedChart";
import { LoadingStatus, IFilter } from "../util";

export interface StoreData {
    data: LitterDensityData;
    filtersApplied: IFilter;
}

export interface LitterDensityPerSurvey {
    totalItemCount: number;
    totalWeightCount: number;
    numberOfSurveys: number;
    month: string;
}

export interface LitterDensityData {
    litterDensityPerSurvey: LitterDensityPerSurvey[];
    averageItemDensity: number;
    averageGramDensity: number;
    totalItems: number;
    totalWeight: number;
}

export interface LitterQuantityData {
    litterQuantityPerSurvey: LitterDensityPerSurvey[];
    averageItemQuantity: number;
    averageGramQuantity: number;
    totalItems: number;
    totalWeight: number;
}


export interface LitterDensityChartData {
    itemData: MixedChartData[];
    weightData: MixedChartData[];
    loadingStatus: LoadingStatus;
}

export interface LitterDensityInfoGroupItemData {
    loadingStatus: LoadingStatus;
    totalItems: number;
    averageItemDensity: number;
}

export interface LitterDensityInfoGroupWeightData {
    loadingStatus: LoadingStatus;
    totalWeight: number; //This will be in grams
    averageWeightDensity: number;
}

export const convertToLitterDensityData = (obj: LitterQuantityData | LitterDensityData) : LitterDensityData => {
    return {
        litterDensityPerSurvey: "litterQuantityPerSurvey" in obj ? obj.litterQuantityPerSurvey : obj.litterDensityPerSurvey,
        averageItemDensity: "averageItemQuantity" in obj ? obj.averageItemQuantity : obj.averageItemDensity,
        averageGramDensity: "averageGramQuantity" in obj ? obj.averageGramQuantity : obj.averageGramDensity,
        totalItems: obj.totalItems,
        totalWeight: obj.totalWeight,
    }
}

export const LITTER_DENSITY_API_DATE_FORMAT = "YYYY-MM"