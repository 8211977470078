import React from "react";
import "./Loader.scss";
import { Color } from "../TreemapChart/TreemapUtils";
import { DataSet } from "../ComparisonCard/ComparisonCard";

type LoaderProps = {
    containerSize: number;
    contentSize: number;
    backgroundColor: Color;
    dataSet: DataSet;
}

function getLoaderBorderColor(dataSet: DataSet) {
  switch(dataSet) {
    case DataSet.DEFAULT:
      return "loader_border_default_bg";
    case DataSet.SET_ONE:
      return "loader_border_set_one_bg";
    case DataSet.SET_TWO:
      return "loader_border_set_two_bg";
    default:
      return "loader_border_default_bg";
  }
}

export const Loader: React.FC<LoaderProps> = ({ containerSize, contentSize, backgroundColor, dataSet }) => {
  return (
    <div className="loader-container" style={{ width: `${containerSize}px`, height: `${containerSize}px` }}>
      <div className={`loader-border ${getLoaderBorderColor(dataSet)}`} style={{ width: `${contentSize}px`, height: `${contentSize}px` }}>
        <div className="loader-core" style={{ backgroundColor: `rgb(${backgroundColor.r}, ${backgroundColor.g}, ${backgroundColor.b})` }}></div>
      </div>
    </div>
  );
};
