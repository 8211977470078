import React, { useReducer, useEffect, useContext, useMemo } from "react";
import "./LocationModalToggle.scss";
import {
  MultiSelectOptionSet,
  optionReducer,
} from "../Sidebar/CustomSelect/CustomSelectUtil";
import { ModalToggleTitle } from "./ModalToggleTitle";
import ComparisonIcon from "../../assets/add_data.svg";
import { CustomSelect } from "../Sidebar/CustomSelect/CustomSelect";
import {
  ICountry,
  IRegion,
  ISurveyAreas
} from "../../store/constants";
import { isLocationSetSelected } from "../Sidebar/LocationToggle/LocationToggleUtil";
import { RootStoreContext } from "../../store/rootStore";
import {
  isValidArray,
  ComparisonDataSet,
  LocationFilter,
} from "../../store/util";

type LocationModalToggleProps = {
  countriesSetOne: (MultiSelectOptionSet & ICountry)[];
  regionSetOne: (MultiSelectOptionSet & IRegion)[];
  surveyAreaSetOne: (MultiSelectOptionSet & ISurveyAreas)[];
  countriesSetTwo: (MultiSelectOptionSet & ICountry)[];
  regionSetTwo: (MultiSelectOptionSet & IRegion)[];
  surveyAreaSetTwo: (MultiSelectOptionSet & ISurveyAreas)[];
};

export const LocationModalToggle: React.FC<LocationModalToggleProps> = ({
  countriesSetOne,
  regionSetOne,
  surveyAreaSetOne,
  countriesSetTwo,
  regionSetTwo,
  surveyAreaSetTwo,
}) => {
  const rootStore = useContext(RootStoreContext);

  const { setTempComparisonData } = rootStore.filterStore;

  const [countryOptionsSetOne, dispatchCountryOptionSetOne] = useReducer(
    optionReducer,
    countriesSetOne
  );
  const [regionOptionsSetOne, dispatchRegionOptionSetOne] = useReducer(
    optionReducer,
    regionSetOne
  );
  const [surveyAreasOptionsSetOne, dispatchSurveyAreaOptionSetOne] = useReducer(
    optionReducer,
    surveyAreaSetOne
  );

  const [countryOptionsSetTwo, dispatchCountryOptionSetTwo] = useReducer(
    optionReducer,
    countriesSetTwo
  );
  const [regionOptionsSetTwo, dispatchRegionOptionSetTwo] = useReducer(
    optionReducer,
    regionSetTwo
  );
  const [surveyAreasOptionsSetTwo, dispatchSurveyAreaOptionSetTwo] = useReducer(
    optionReducer,
    surveyAreaSetTwo
  );

  function onCountryItemSelectedSetOne(option: MultiSelectOptionSet) {
    dispatchCountryOptionSetOne({ type: "toggle", option: option });
  }

  function onRegionItemSelectedSetOne(option: MultiSelectOptionSet) {
    dispatchRegionOptionSetOne({ type: "toggle", option: option });
  }

  function onSurveyAreaSelectedSetOne(option: MultiSelectOptionSet) {
    dispatchSurveyAreaOptionSetOne({ type: "toggle", option: option });
  }

  function onCountryItemSelectedSetTwo(option: MultiSelectOptionSet) {
    dispatchCountryOptionSetTwo({ type: "toggle", option: option });
  }

  function onRegionItemSelectedSetTwo(option: MultiSelectOptionSet) {
    dispatchRegionOptionSetTwo({ type: "toggle", option: option });
  }

  function onSurveyAreaSelectedSetTwo(option: MultiSelectOptionSet) {
    dispatchSurveyAreaOptionSetTwo({ type: "toggle", option: option });
  }

  useEffect(() => {
    if (isLocationSetSelected(countryOptionsSetOne)) {
      const namesOfSelectedCountries = countryOptionsSetOne
        .filter((item) => item.isSelected)
        .map((item) => item.label);

      const selectedCountryId = countriesSetOne
        .filter((item) => namesOfSelectedCountries.includes(item.name))
        .map((item) => item.id);

      const regionsWithinSelectedCountries = regionSetOne.filter((item) =>
        selectedCountryId.includes(item.countryId)
      );

      dispatchRegionOptionSetOne({
        type: "set",
        option: regionsWithinSelectedCountries,
      });
    } else {
      dispatchRegionOptionSetOne({ type: "set", option: [] });
    }
    // eslint-disable-next-line 
  }, [countryOptionsSetOne]);

  useEffect(() => {
    if (isLocationSetSelected(regionOptionsSetOne)) {
      const namesOfSelectedRegions = regionOptionsSetOne
        .filter((item) => item.isSelected)
        .map((item) => item.label);

      const selectedRegionId = regionSetOne
        .filter((item) => namesOfSelectedRegions.includes(item.name))
        .map((item) => item.id);

      const surveyAreasWithinRegions = surveyAreaSetOne.filter((item) =>
        selectedRegionId.includes(item.regionId)
      );

      dispatchSurveyAreaOptionSetOne({
        type: "set",
        option: surveyAreasWithinRegions,
      });
    } else {
      dispatchSurveyAreaOptionSetOne({
        type: "set",
        option: [],
      });
    }
    // eslint-disable-next-line 
  }, [regionOptionsSetOne]);

  useEffect(() => {
    if (isLocationSetSelected(countryOptionsSetTwo)) {
      const namesOfSelectedCountries = countryOptionsSetTwo
        .filter((item) => item.isSelected)
        .map((item) => item.label);

      const selectedCountryId = countriesSetTwo
        .filter((item) => namesOfSelectedCountries.includes(item.name))
        .map((item) => item.id);

      const regionsWithinSelectedCountries = regionSetTwo.filter((item) =>
        selectedCountryId.includes(item.countryId)
      );

      dispatchRegionOptionSetTwo({
        type: "set",
        option: regionsWithinSelectedCountries,
      });
    } else {
      dispatchRegionOptionSetTwo({ type: "set", option: [] });
    }
    // eslint-disable-next-line 
  }, [countryOptionsSetTwo]);

  useEffect(() => {
    if (isLocationSetSelected(regionOptionsSetTwo)) {
      const namesOfSelectedRegions = regionOptionsSetTwo
        .filter((item) => item.isSelected)
        .map((item) => item.label);

      const selectedRegionId = regionSetTwo
        .filter((item) => namesOfSelectedRegions.includes(item.name))
        .map((item) => item.id);
        
      const surveyAreasWithinRegions = surveyAreaSetTwo.filter((item) =>
        selectedRegionId.includes(item.regionId)
      );

      dispatchSurveyAreaOptionSetTwo({
        type: "set",
        option: surveyAreasWithinRegions,
      });
    } else {
      dispatchSurveyAreaOptionSetTwo({
        type: "set",
        option: [],
      });
    }
    // eslint-disable-next-line 
  }, [regionOptionsSetTwo]);

  const dataSetOne = useMemo<LocationFilter | null>(() => {
    const selectedCountriesLabel = countryOptionsSetOne
      .filter((item) => item.isSelected)
      .map((item) => item.label);

    if (isValidArray(selectedCountriesLabel)) {
      const selectedCountries = countriesSetOne
        .filter((item) => selectedCountriesLabel.includes(item.name))
        .map((item) => ({
          id: item.id,
          name: item.name,
        }));

      const selectedRegionLabel = regionOptionsSetOne
        .filter((item) => item.isSelected)
        .map((item) => item.label);

      if (isValidArray(selectedRegionLabel)) {
        const selectedRegions = regionSetOne
          .filter((item) => selectedRegionLabel.includes(item.name))
          .map((item) => ({
            id: item.id,
            name: item.name,
          }));

        const selectedSurveyAreaLabels = surveyAreasOptionsSetOne
          .filter((item) => item.isSelected)
          .map((item) => item.label);

        if (isValidArray(selectedSurveyAreaLabels)) {
          const selectedSurveyAreas = surveyAreaSetOne
            .filter((item) => selectedSurveyAreaLabels.includes(item.name))
            .map((item) => ({
              id: item.id,
              name: item.name,
            }));

          return {
            countries: selectedCountries,
            regions: selectedRegions,
            surveyAreas: selectedSurveyAreas,
          };
        }

        return {
          countries: selectedCountries,
          regions: selectedRegions,
        };
      }

      return {
        countries: selectedCountries,
      };
    }
    return null;
    // eslint-disable-next-line 
  }, [countryOptionsSetOne, regionOptionsSetOne, surveyAreasOptionsSetOne]);

  const dataSetTwo = useMemo<LocationFilter | null>(() => {
    const selectedCountriesLabel = countryOptionsSetTwo
      .filter((item) => item.isSelected)
      .map((item) => item.label);

    if (isValidArray(selectedCountriesLabel)) {
      const selectedCountries = countriesSetTwo
        .filter((item) => selectedCountriesLabel.includes(item.name))
        .map((item) => ({
          id: item.id,
          name: item.name,
        }));

      const selectedRegionLabel = regionOptionsSetTwo
        .filter((item) => item.isSelected)
        .map((item) => item.label);

      if (isValidArray(selectedRegionLabel)) {
        const selectedRegions = regionSetTwo
          .filter((item) => selectedRegionLabel.includes(item.name))
          .map((item) => ({
            id: item.id,
            name: item.name,
          }));

        const selectedSurveyAreaLabels = surveyAreasOptionsSetTwo
          .filter((item) => item.isSelected)
          .map((item) => item.label);

        if (isValidArray(selectedSurveyAreaLabels)) {
          const selectedSurveyAreas = surveyAreaSetTwo
            .filter((item) => selectedSurveyAreaLabels.includes(item.name))
            .map((item) => ({
              id: item.id,
              name: item.name,
            }));

          return {
            countries: selectedCountries,
            regions: selectedRegions,
            surveyAreas: selectedSurveyAreas,
          };
        }

        return {
          countries: selectedCountries,
          regions: selectedRegions,
        };
      }

      return {
        countries: selectedCountries,
      };
    }
    return null;
    // eslint-disable-next-line 
  }, [countryOptionsSetTwo, regionOptionsSetTwo, surveyAreasOptionsSetTwo]);

  useEffect(() => {
    setTempComparisonData({
      comparisonDataSet: ComparisonDataSet.Location,
      dataSetOne: dataSetOne,
      dataSetTwo: dataSetTwo,
    });
    // eslint-disable-next-line 
  }, [
    regionOptionsSetOne,
    regionOptionsSetTwo,
    surveyAreasOptionsSetOne,
    surveyAreasOptionsSetTwo,
    countryOptionsSetOne,
    countryOptionsSetTwo,
  ]);

  return (
    <div className="location_modal_container">
      <ModalToggleTitle
        title="Location Comparison"
        subTitle="Define your location comparison"
      />
      <div className="location_set_container">
        <div className="set_1">
          <p className="set_title">SET 1</p>
          <div className="select_container">
            <CustomSelect
              options={countryOptionsSetOne}
              defaultTitle={`All Countries`}
              isModal={true}
              onItemSelected={onCountryItemSelectedSetOne}
            />
            <CustomSelect
              options={regionOptionsSetOne}
              defaultTitle={`All Regions`}
              isModal={true}
              onItemSelected={onRegionItemSelectedSetOne}
              isDisabled={!isLocationSetSelected(countryOptionsSetOne)}
            />
            <CustomSelect
              options={surveyAreasOptionsSetOne}
              defaultTitle={`All Survey Areas`}
              isModal={true}
              onItemSelected={onSurveyAreaSelectedSetOne}
              isDisabled={!isLocationSetSelected(regionOptionsSetOne)}
            />
          </div>
        </div>
        <img
          src={ComparisonIcon}
          alt="Comparison Icon"
          className="divider_image"
        />
        <div className="set_2">
          <p className="set_title">SET 2</p>
          <div className="select_container">
            <CustomSelect
              options={countryOptionsSetTwo}
              defaultTitle={`All Countries`}
              isModal={true}
              onItemSelected={onCountryItemSelectedSetTwo}
            />
            <CustomSelect
              options={regionOptionsSetTwo}
              defaultTitle={`All Regions`}
              isModal={true}
              onItemSelected={onRegionItemSelectedSetTwo}
              isDisabled={!isLocationSetSelected(countryOptionsSetTwo)}
            />
            <CustomSelect
              options={surveyAreasOptionsSetTwo}
              defaultTitle={`All Survey Areas`}
              isModal={true}
              onItemSelected={onSurveyAreaSelectedSetTwo}
              isDisabled={!isLocationSetSelected(regionOptionsSetTwo)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
