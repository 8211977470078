import { LoadingStatus, IFilter } from "../util";

export interface StoreData {
  data: SurveyStatsData;
  filtersApplied: IFilter;
}

export interface SurveyStatsData {
  surveyStats: SurveyStatsDataItem[];
  surveyStatDetails: SurveyStatDetails;
}

export interface SurveyStatDetails {
  daysSinceLastSurvey: number;
  surveyAreas: number;
  surveysCompleted: number;
  citizenScientists: number;
  volunteerHours: number;
}

export interface SurveyStatsDataItem {
  monitoringGroupName: string;
  numberOfSurveyAreas: number;
  numberOfSurveysCompleted: number;
  numberOfCitizenScientists: number;
  numberOfVolunteerHours: number;
}

export interface SurveyStatsTableRow {
  monitoringGroup: string;
  surveyAreas: number;
  surveysCompleted: number;
  citizenScientists: number;
  volunteerHours: number;
}

export interface SurveyStatsTableData {
  loadingStatus: LoadingStatus;
  data: SurveyStatsTableRow[];
}

export interface SurveysStatsInfoGroupUIData {
    loadingStatus: LoadingStatus;
    data: {title: string, value: number}[];
    numberOfDaysSinceLastSurvey: number;
}

export interface SurveyStats {
  numberOfDaysSinceLastSurvey: number;
  numberOfSurveyAreas: number;
  numberOfSurveysCompleted: number;
  numberOfCitizenScientists: number;
  numberOfVolunteerHours: number;
}

export function convertSurveyStatsObjToInfoGroupData(surveyStats: SurveyStats) {
  return Object.keys(surveyStats)
    .filter((item) => item !== "numberOfDaysSinceLastSurvey")
    .map((key) => ({
      title: getSurveyStatsUIPropName(key),
      value: surveyStats[key as keyof SurveyStats],
    }));
}

function getSurveyStatsUIPropName(data: string): string {
  switch (data) {
    case "numberOfCitizenScientists":
      return "Citizen Scientists";
    case "numberOfSurveyAreas":
      return "Survey Areas";
    case "numberOfSurveysCompleted":
      return "Surveys Completed";
    case "numberOfVolunteerHours":
      return "Volunteer Hours";
    default:
      return "Survey Frequency";
  }
}
