import React, { useContext, useEffect } from "react";
import "./Location.scss";
import { PageTitle } from "../components/PageTitle/PageTitle";
import { PageSubTitle } from "../components/PageSubTitle/PageSubTitle";
import {
  DataSet,
  ComparisonCard,
} from "../components/ComparisonCard/ComparisonCard";
import { SurveyLocations } from "../components/SurveyLocations/SurveyLocations";
import { Table } from "../components/Table/Table";
import { RootStoreContext } from "../store/rootStore";
import { DataSetView } from "../components/DataSetView/DataSetView";
import { LoadingStatus } from "../store/util";
import { observer } from "mobx-react-lite";
import ReactGA from "react-ga";

export const Location: React.FC<{}> = observer(() => {
  const DataSetViewWithCompCard = ComparisonCard(DataSetView);
  const SurveyAreasWithCompCard = ComparisonCard(SurveyLocations);
  const TableWithCompCard = ComparisonCard(Table);

  const rootStore = useContext(RootStoreContext);

  const {
    filtersApplied,
    dataSetViewSetOneData,
    dataSetViewSetTwoData,
    isDateFilterAppliedComparisonDataSet,
    isLocationFilterAppliedComparisonDataSet,
    isLitterTypeFilterAppliedComparisonDataSet,
    isLocationTypeFilterAppliedComparisonDataSet,
  } = rootStore.filterStore;

  const {
    handleFilterChanges,
    defaultLitterDensityTableData,
    defaultSurveyLocationsUIData,
    setOneSurveyLocationsUIData,
    setTwoSurveyLocationsUIData,
    isComparisonApplied,
    setOneLitterDensityTableData,
    setTwoLitterDensityTableData,
  } = rootStore.locationStore;

  const { isStormwaterSelected } = rootStore.commonStore;

  const LitterDensityPerSurveyTableColumns = [
    {
      name: "Survey Areas",
      value: "surveyArea",
    },
    {
      name: "Region",
      value: "regionName",
    },
    {
      name: `Average Litter ${isStormwaterSelected ? "Quantity" : "Density"}`,
      value: "itemLitterDensity",
    },
    {
      name: "Surveys Completed",
      value: "surveysCompleted",
    },
  ];

  useEffect(() => {
    ReactGA.pageview("/location");
    handleFilterChanges(filtersApplied);
    // eslint-disable-next-line
  }, []);

  function onViewAllSurveysClicked(event: any) {
    event.preventDefault();
    //Recording Click Event
    ReactGA.event({
      category: "View All Surveys",
      action: "Click Event",
    });
    const takeActionURL = "https://litterintelligence.org/data/";
    window.open(takeActionURL, "_blank");
  }

  const bottomSurveySubTitile = isStormwaterSelected
    ? "Litter Quantity Per Survey Area"
    : "Litter Density Per Survey Area";

  return isComparisonApplied ? (
    <div>
      <div className="page_title_container">
        <PageTitle title="Location" />
      </div>
      <div className="page_sub_title_container">
        <PageSubTitle subTitle="Data Set" />
      </div>
      <div className="data_set_view_container__comparison">
        <DataSetViewWithCompCard
          loadingStatus={LoadingStatus.Success}
          viewTitle="SET 1"
          locationType={{
            value: dataSetViewSetOneData.locationTypeFilterTitle,
            isFilterApplied: isLocationTypeFilterAppliedComparisonDataSet,
          }}
          location={{
            value: dataSetViewSetOneData.locationFilterTitle,
            isFilterApplied: isLocationFilterAppliedComparisonDataSet,
          }}
          litterType={{
            value: dataSetViewSetOneData.litterTypeFilterTitle,
            isFilterApplied: isLitterTypeFilterAppliedComparisonDataSet,
          }}
          timeRange={{
            value: dataSetViewSetOneData.dateFilterTitle,
            isFilterApplied: isDateFilterAppliedComparisonDataSet,
          }}
          dataSet={DataSet.SET_ONE}
        />
        <DataSetViewWithCompCard
          loadingStatus={LoadingStatus.Success}
          viewTitle="SET 2"
          locationType={{
            value: dataSetViewSetTwoData.locationTypeFilterTitle,
            isFilterApplied: isLocationTypeFilterAppliedComparisonDataSet,
          }}
          location={{
            value: dataSetViewSetTwoData.locationFilterTitle,
            isFilterApplied: isLocationFilterAppliedComparisonDataSet,
          }}
          litterType={{
            value: dataSetViewSetTwoData.litterTypeFilterTitle,
            isFilterApplied: isLitterTypeFilterAppliedComparisonDataSet,
          }}
          timeRange={{
            value: dataSetViewSetTwoData.dateFilterTitle,
            isFilterApplied: isDateFilterAppliedComparisonDataSet,
          }}
          dataSet={DataSet.SET_TWO}
        />
      </div>
      <div className="page_sub_title_container">
        <PageSubTitle
          subTitle="Survey Areas"
          helperText={
            isStormwaterSelected
              ? "The map below shows the locations where stormwater catchpit litter surveys have been completed (based on current filters)."
              : "The designated area for a litter survey is typically a 100m x 20m ‘transect’ but area may differ based on constraints outlined in methodology"
          }
        />
      </div>
      <div className="survey_areas_container__comparison">
        <SurveyAreasWithCompCard
          dataSet={DataSet.SET_ONE}
          isComparison={true}
          geoData={setOneSurveyLocationsUIData.geoData}
          infoGroup={setOneSurveyLocationsUIData.infoGroup}
          loadingStatus={setOneSurveyLocationsUIData.loadingStatus}
          litterDensityData={setOneSurveyLocationsUIData.litterDensityUIData}
          litterQuantityData={setOneSurveyLocationsUIData.litterQuantityUIData}
        />
        <SurveyAreasWithCompCard
          dataSet={DataSet.SET_TWO}
          isComparison={true}
          geoData={setTwoSurveyLocationsUIData.geoData}
          infoGroup={setTwoSurveyLocationsUIData.infoGroup}
          loadingStatus={setTwoSurveyLocationsUIData.loadingStatus}
          litterDensityData={setTwoSurveyLocationsUIData.litterDensityUIData}
          litterQuantityData={setTwoSurveyLocationsUIData.litterQuantityUIData}
        />
      </div>
      <div className="litter_density_subtitle_container">
        <PageSubTitle
          subTitle={bottomSurveySubTitile}
          helperText={
            isStormwaterSelected
              ? "The table below shows the average number of items found per stormwater catchpit survey area (based on current filters)."
              : "The litter density measure is the number of items per 1000m2. This measure enables comparison of different locations or time periods"
          }
        />
        <button
          className="view_all_surveys_btn"
          onClick={onViewAllSurveysClicked}
        >
          View all Surveys
        </button>
      </div>
      <div className="litter_density_table_container__comparison">
        <TableWithCompCard
          data={setOneLitterDensityTableData.data}
          columns={LitterDensityPerSurveyTableColumns}
          dataSet={DataSet.SET_ONE}
          loadingStatus={setOneLitterDensityTableData.loadingStatus}
        />
        <TableWithCompCard
          data={setTwoLitterDensityTableData.data}
          columns={LitterDensityPerSurveyTableColumns}
          dataSet={DataSet.SET_TWO}
          loadingStatus={setTwoLitterDensityTableData.loadingStatus}
        />
      </div>
    </div>
  ) : (
    <div>
      <div className="page_title_container">
        <PageTitle title="Location" />
      </div>
      <div className="page_sub_title_container">
        <PageSubTitle
          subTitle="Survey Areas"
          helperText={
            isStormwaterSelected
              ? "The map below shows the locations where stormwater catchpit litter surveys have been completed (based on current filters)."
              : "The designated area for a litter survey is typically a 100m x 20m ‘transect’ but area may differ based on constraints outlined in methodology"
          }
        />
      </div>
      <div className="survey_areas_container">
        <SurveyAreasWithCompCard
          dataSet={DataSet.DEFAULT}
          geoData={defaultSurveyLocationsUIData.geoData}
          infoGroup={defaultSurveyLocationsUIData.infoGroup}
          litterDensityData={defaultSurveyLocationsUIData.litterDensityUIData}
          litterQuantityData={defaultSurveyLocationsUIData.litterQuantityUIData}
          loadingStatus={defaultSurveyLocationsUIData.loadingStatus}
        />
      </div>
      <div className="litter_density_subtitle_container">
        <PageSubTitle
          subTitle={bottomSurveySubTitile}
          helperText={
            isStormwaterSelected
              ? "The table below shows the average number of items found per stormwater catchpit survey area (based on current filters)."
              : "The litter density measure is the number of items per 1000m2. This measure enables comparison of different locations or time periods"
          }
        />
        <button
          className="view_all_surveys_btn"
          onClick={onViewAllSurveysClicked}
        >
          View all Surveys
        </button>
      </div>
      <div className="litter_density_table_container">
        <TableWithCompCard
          data={defaultLitterDensityTableData.data}
          columns={LitterDensityPerSurveyTableColumns}
          dataSet={DataSet.DEFAULT}
          loadingStatus={defaultLitterDensityTableData.loadingStatus}
        />
      </div>
    </div>
  );
});
