import { OverviewData } from "../overview/overviewUtil";
import axios from "axios";
import {
  LitterDensityData,
  LitterQuantityData,
} from "../litterDensity/litterDensityUtil";
import { MaterialsAndProducts } from "../materialAndProduct/materialAndProductUtil";
import { LocationData } from "../location/locationStoreUtil";
import { SurveyStatsData } from "../surveyStats/surveyStatsUtil";
import { ApiFilterData, SurveyType } from "../util";
import { FilterApiData } from "../filter/filterUtil";

const API_ROOT = process.env.REACT_APP_API_URL;

export async function getOverviewData(
  data: ApiFilterData
): Promise<OverviewData> {
  const response = await axios({
    method: "post",
    url: `${API_ROOT}/api/Overview?code=${process.env.REACT_APP_API_OVERVIEW_CODE}`,
    data: data,
  });
  if (response.status === 200) {
    return response.data;
  }
  throw new Error("Could not get overview data");
}

export async function getLitterDensityData(
  data: ApiFilterData
): Promise<LitterDensityData | LitterQuantityData> {
  const response = await axios({
    method: "post",
    url: `${API_ROOT}/api/LitterDensity?code=${process.env.REACT_APP_API_LITTER_DENSITY_CODE}`,
    data: data,
  });
  if (response.status === 200) {
    return response.data;
  }
  throw new Error("Could not get litter density data");
}

export async function getMaterialsAndProducts(
  data: ApiFilterData
): Promise<MaterialsAndProducts[]> {
  const response = await axios({
    method: "post",
    url: `${API_ROOT}/api/MaterialsAndProducts?code=${process.env.REACT_APP_API_MATERIALS_AND_PRODUCTS_CODE}`,
    data: data,
  });

  if (response.status === 200) {
    return response.data;
  }

  throw new Error("Could not get materials and data");
}

export async function getLocationData(
  data: ApiFilterData
): Promise<LocationData> {
  const response = await axios({
    method: "post",
    url: `${API_ROOT}/api/Location?code=${process.env.REACT_APP_API_LOCATION_CODE}`,
    data: data,
  });

  if (response.status === 200) {
    return response.data;
  }

  throw new Error("Could not get location data");
}

export async function getSurveyStatsData(
  data: ApiFilterData
): Promise<SurveyStatsData> {
  const response = await axios({
    method: "post",
    url: `${API_ROOT}/api/SurveyStats?code=${process.env.REACT_APP_API_SURVEY_STATS_CODE}`,
    data: data,
  });

  if (response.status === 200) {
    return response.data;
  }

  throw new Error("Could not get survey Stats Data");
}

export async function getFilterData(
  surveyType: SurveyType = 1
): Promise<FilterApiData> {
  const response = await axios({
    method: "POST",
    url: `${API_ROOT}/api/Filter?code=${process.env.REACT_APP_API_FILTER_CODE}`,
    data: { surveyType },
  });

  if (response.status === 200) {
    return response.data;
  }

  throw new Error("Could not get Filter Data");
}
