import { observable, action, runInAction, computed, toJS } from "mobx";
import { LoadingStatus, isValidArray, IFilter } from "../util";
import {
  SurveyStatsTableData,
  SurveysStatsInfoGroupUIData,
  convertSurveyStatsObjToInfoGroupData,
  StoreData,
} from "./surveyStatsUtil";
import { RootStore } from "../rootStore";
import { getSurveyStatsData } from "../api/agent";
import { isValidObject } from "../overview/overviewUtil";
import { DataSet } from "../../components/ComparisonCard/ComparisonCard";
import { convertToApiFilterData } from "../api/networkUtil";
import { shouldNotLoadData } from "../filter/filterUtil";

export default class SurveyStatsStore {
  rootStore: RootStore;

  @observable loadingStatus = LoadingStatus.Loading;
  @observable defaultData: StoreData = {
    data: null,
    filtersApplied: null,
  };

  @observable loadingStatusDataSetOne = LoadingStatus.Loading;
  @observable dataSetOne: StoreData = {
    data: null,
    filtersApplied: null,
  };

  @observable loadingStatusDataSetTwo = LoadingStatus.Loading;
  @observable dataSetTwo: StoreData = {
    data: null,
    filtersApplied: null,
  };

  @observable isComparisonApplied = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action handleFilterChanges = (filtersApplied: IFilter) => {
    if (isValidObject(filtersApplied)) {
      if (isValidObject(filtersApplied.comparisonData)) {
        //that means there is a comparison present.
        this.isComparisonApplied = true;
        this.loadData(DataSet.SET_ONE, filtersApplied);
        this.loadData(DataSet.SET_TWO, filtersApplied);
      } else {
        this.isComparisonApplied = false;
        this.loadData(DataSet.DEFAULT, filtersApplied);
      }
    } else {
      this.isComparisonApplied = false;
      this.loadData(DataSet.DEFAULT, filtersApplied);
    }
  };

  @action loadData = async (dataSet: DataSet, filtersApplied: IFilter) => {
    switch (dataSet) {
      case DataSet.DEFAULT:
        if (
          shouldNotLoadData(
            this.loadingStatus,
            this.defaultData.filtersApplied,
            filtersApplied
          )
        ) {
          return;
        }
        this.loadingStatus = LoadingStatus.Loading;
        try {
          const responseData = await getSurveyStatsData(
            convertToApiFilterData(filtersApplied, DataSet.DEFAULT)
          );
          runInAction(() => {
            this.defaultData = {
              data: responseData,
              filtersApplied: toJS(filtersApplied),
            };
            this.loadingStatus = LoadingStatus.Success;
          });
        } catch (error) {
          runInAction(() => {
            this.defaultData = {
              data: null,
              filtersApplied: null,
            };
            this.loadingStatus = LoadingStatus.Failed;
          });
        }
        break;
      case DataSet.SET_ONE:
        if (
          shouldNotLoadData(
            this.loadingStatusDataSetOne,
            this.dataSetOne.filtersApplied,
            filtersApplied
          )
        ) {
          return;
        }
        this.loadingStatusDataSetOne = LoadingStatus.Loading;
        try {
          const responseData = await getSurveyStatsData(
            convertToApiFilterData(filtersApplied, DataSet.SET_ONE)
          );
          runInAction(() => {
            this.dataSetOne = {
              data: responseData,
              filtersApplied: toJS(filtersApplied),
            };
            this.loadingStatusDataSetOne = LoadingStatus.Success;
          });
        } catch (error) {
          runInAction(() => {
            this.dataSetOne = {
              data: null,
              filtersApplied: null,
            };
            this.loadingStatusDataSetOne = LoadingStatus.Failed;
          });
        }
        break;
      case DataSet.SET_TWO:
        if (
          shouldNotLoadData(
            this.loadingStatusDataSetTwo,
            this.dataSetTwo.filtersApplied,
            filtersApplied
          )
        ) {
          return;
        }
        this.loadingStatusDataSetTwo = LoadingStatus.Loading;
        try {
          const responseData = await getSurveyStatsData(
            convertToApiFilterData(filtersApplied, DataSet.SET_TWO)
          );
          runInAction(() => {
            this.dataSetTwo = {
              data: responseData,
              filtersApplied: toJS(filtersApplied),
            };
            this.loadingStatusDataSetTwo = LoadingStatus.Success;
          });
        } catch (error) {
          runInAction(() => {
            this.dataSetTwo = {
              data: null,
              filtersApplied: null,
            };
            this.loadingStatusDataSetTwo = LoadingStatus.Failed;
          });
        }
        break;
      default:
        if (
          shouldNotLoadData(
            this.loadingStatus,
            this.defaultData.filtersApplied,
            filtersApplied
          )
        ) {
          return;
        }
        this.loadingStatus = LoadingStatus.Loading;
        try {
          const responseData = await getSurveyStatsData(
            convertToApiFilterData(filtersApplied, DataSet.DEFAULT)
          );
          runInAction(() => {
            this.defaultData = {
              data: responseData,
              filtersApplied: toJS(filtersApplied),
            };
            this.loadingStatus = LoadingStatus.Success;
          });
        } catch (error) {
          runInAction(() => {
            this.defaultData = {
              data: null,
              filtersApplied: null,
            };
            this.loadingStatus = LoadingStatus.Failed;
          });
        }
        break;
    }
  };

  @computed get defaultTableData(): SurveyStatsTableData {
    switch (this.loadingStatus) {
      case LoadingStatus.Loading:
        return {
          loadingStatus: LoadingStatus.Loading,
          data: null,
        };

      case LoadingStatus.Success:
        if (
          isValidObject(this.defaultData.data) &&
          isValidArray(this.defaultData.data.surveyStats)
        ) {
          return {
            loadingStatus: LoadingStatus.Success,
            data: this.defaultData.data.surveyStats.map((item) => ({
              monitoringGroup: item.monitoringGroupName,
              surveyAreas: item.numberOfSurveyAreas,
              surveysCompleted: item.numberOfSurveysCompleted,
              citizenScientists: item.numberOfCitizenScientists,
              volunteerHours: item.numberOfVolunteerHours,
            })),
          };
        } else {
          return {
            loadingStatus: LoadingStatus.Failed,
            data: null,
          };
        }

      case LoadingStatus.Failed:
        return {
          loadingStatus: LoadingStatus.Failed,
          data: null,
        };

      default:
        return {
          loadingStatus: LoadingStatus.Failed,
          data: null,
        };
    }
  }

  @computed get setOneTableData(): SurveyStatsTableData {
    switch (this.loadingStatusDataSetOne) {
      case LoadingStatus.Loading:
        return {
          loadingStatus: LoadingStatus.Loading,
          data: null,
        };

      case LoadingStatus.Success:
        if (
          isValidObject(this.dataSetOne.data) &&
          isValidArray(this.dataSetOne.data.surveyStats)
        ) {
          return {
            loadingStatus: LoadingStatus.Success,
            data: this.dataSetOne.data.surveyStats.map((item) => ({
              monitoringGroup: item.monitoringGroupName,
              surveyAreas: item.numberOfSurveyAreas,
              surveysCompleted: item.numberOfSurveysCompleted,
              citizenScientists: item.numberOfCitizenScientists,
              volunteerHours: item.numberOfVolunteerHours,
            })),
          };
        } else {
          return {
            loadingStatus: LoadingStatus.Failed,
            data: null,
          };
        }

      case LoadingStatus.Failed:
        return {
          loadingStatus: LoadingStatus.Failed,
          data: null,
        };

      default:
        return {
          loadingStatus: LoadingStatus.Failed,
          data: null,
        };
    }
  }

  @computed get setTwoTableData(): SurveyStatsTableData {
    switch (this.loadingStatusDataSetTwo) {
      case LoadingStatus.Loading:
        return {
          loadingStatus: LoadingStatus.Loading,
          data: null,
        };

      case LoadingStatus.Success:
        if (
          isValidObject(this.dataSetTwo.data) &&
          isValidArray(this.dataSetTwo.data.surveyStats)
        ) {
          return {
            loadingStatus: LoadingStatus.Success,
            data: this.dataSetTwo.data.surveyStats.map((item) => ({
              monitoringGroup: item.monitoringGroupName,
              surveyAreas: item.numberOfSurveyAreas,
              surveysCompleted: item.numberOfSurveysCompleted,
              citizenScientists: item.numberOfCitizenScientists,
              volunteerHours: item.numberOfVolunteerHours,
            })),
          };
        } else {
          return {
            loadingStatus: LoadingStatus.Failed,
            data: null,
          };
        }

      case LoadingStatus.Failed:
        return {
          loadingStatus: LoadingStatus.Failed,
          data: null,
        };

      default:
        return {
          loadingStatus: LoadingStatus.Failed,
          data: null,
        };
    }
  }

  @computed
  get defaultSurveysStatsInfoGroupUIData(): SurveysStatsInfoGroupUIData {
    switch (this.loadingStatus) {
      case LoadingStatus.Loading:
        return {
          loadingStatus: LoadingStatus.Loading,
          data: null,
          numberOfDaysSinceLastSurvey: null,
        };

      case LoadingStatus.Success:
        if (
          isValidObject(this.defaultData.data) &&
          isValidObject(this.defaultData.data.surveyStatDetails)
        ) {
          return {
            loadingStatus: LoadingStatus.Success,
            data: convertSurveyStatsObjToInfoGroupData({
              numberOfSurveyAreas: this.defaultData.data.surveyStatDetails
                .surveyAreas,
              numberOfSurveysCompleted: this.defaultData.data.surveyStatDetails
                .surveysCompleted,
              numberOfCitizenScientists: this.defaultData.data.surveyStatDetails
                .citizenScientists,
              numberOfVolunteerHours: this.defaultData.data.surveyStatDetails
                .volunteerHours,
              numberOfDaysSinceLastSurvey: this.defaultData.data
                .surveyStatDetails.daysSinceLastSurvey,
            }),
            numberOfDaysSinceLastSurvey: this.defaultData.data.surveyStatDetails
              .daysSinceLastSurvey,
          };
        } else {
          return {
            loadingStatus: LoadingStatus.Failed,
            data: null,
            numberOfDaysSinceLastSurvey: null,
          };
        }

      case LoadingStatus.Failed:
        return {
          loadingStatus: LoadingStatus.Failed,
          data: null,
          numberOfDaysSinceLastSurvey: null,
        };

      default:
        return {
          loadingStatus: LoadingStatus.Failed,
          data: null,
          numberOfDaysSinceLastSurvey: null,
        };
    }
  }

  @computed
  get setOneSurveysStatsInfoGroupUIData(): SurveysStatsInfoGroupUIData {
    switch (this.loadingStatusDataSetOne) {
      case LoadingStatus.Loading:
        return {
          loadingStatus: LoadingStatus.Loading,
          data: null,
          numberOfDaysSinceLastSurvey: null,
        };

      case LoadingStatus.Success:
        if (
          isValidObject(this.dataSetOne.data) &&
          isValidObject(this.dataSetOne.data.surveyStatDetails)
        ) {
          return {
            loadingStatus: LoadingStatus.Success,
            data: convertSurveyStatsObjToInfoGroupData({
              numberOfSurveyAreas: this.dataSetOne.data.surveyStatDetails
                .surveyAreas,
              numberOfSurveysCompleted: this.dataSetOne.data.surveyStatDetails
                .surveysCompleted,
              numberOfCitizenScientists: this.dataSetOne.data.surveyStatDetails
                .citizenScientists,
              numberOfVolunteerHours: this.dataSetOne.data.surveyStatDetails
                .volunteerHours,
              numberOfDaysSinceLastSurvey: this.dataSetOne.data
                .surveyStatDetails.daysSinceLastSurvey,
            }),
            numberOfDaysSinceLastSurvey: this.dataSetOne.data.surveyStatDetails
              .daysSinceLastSurvey,
          };
        } else {
          return {
            loadingStatus: LoadingStatus.Failed,
            data: null,
            numberOfDaysSinceLastSurvey: null,
          };
        }

      case LoadingStatus.Failed:
        return {
          loadingStatus: LoadingStatus.Failed,
          data: null,
          numberOfDaysSinceLastSurvey: null,
        };

      default:
        return {
          loadingStatus: LoadingStatus.Failed,
          data: null,
          numberOfDaysSinceLastSurvey: null,
        };
    }
  }

  @computed
  get setTwoSurveysStatsInfoGroupUIData(): SurveysStatsInfoGroupUIData {
    switch (this.loadingStatusDataSetTwo) {
      case LoadingStatus.Loading:
        return {
          loadingStatus: LoadingStatus.Loading,
          data: null,
          numberOfDaysSinceLastSurvey: null,
        };

      case LoadingStatus.Success:
        if (
          isValidObject(this.dataSetTwo.data) &&
          isValidObject(this.dataSetTwo.data.surveyStatDetails)
        ) {
          return {
            loadingStatus: LoadingStatus.Success,
            data: convertSurveyStatsObjToInfoGroupData({
              numberOfSurveyAreas: this.dataSetTwo.data.surveyStatDetails
                .surveyAreas,
              numberOfSurveysCompleted: this.dataSetTwo.data.surveyStatDetails
                .surveysCompleted,
              numberOfCitizenScientists: this.dataSetTwo.data.surveyStatDetails
                .citizenScientists,
              numberOfVolunteerHours: this.dataSetTwo.data.surveyStatDetails
                .volunteerHours,
              numberOfDaysSinceLastSurvey: this.dataSetTwo.data
                .surveyStatDetails.daysSinceLastSurvey,
            }),
            numberOfDaysSinceLastSurvey: this.dataSetTwo.data.surveyStatDetails
              .daysSinceLastSurvey,
          };
        } else {
          return {
            loadingStatus: LoadingStatus.Failed,
            data: null,
            numberOfDaysSinceLastSurvey: null,
          };
        }

      case LoadingStatus.Failed:
        return {
          loadingStatus: LoadingStatus.Failed,
          data: null,
          numberOfDaysSinceLastSurvey: null,
        };

      default:
        return {
          loadingStatus: LoadingStatus.Failed,
          data: null,
          numberOfDaysSinceLastSurvey: null,
        };
    }
  }
}
