import React, { useReducer, useEffect, useContext } from "react";
import "./LitterTypeToggle.scss";
import { CustomSelect } from "./CustomSelect/CustomSelect";
import { ApplyFilterSection } from "./ApplyFilterSection";
import {
  MultiSelectOptionSet,
  optionReducer,
} from "./CustomSelect/CustomSelectUtil";
import { IMaterials, IProducts } from "../../store/constants";
import { isLocationSetSelected } from "./LocationToggle/LocationToggleUtil";
import { RootStoreContext } from "../../store/rootStore";
import ReactGA from 'react-ga';

type LitterTypeToggleProps = {
  materials: (MultiSelectOptionSet & IMaterials)[];
  products: (MultiSelectOptionSet & IProducts)[];
};

export const LitterTypeToggle: React.FC<LitterTypeToggleProps> = ({
  materials,
  products,
}) => {
  const rootStore = useContext(RootStoreContext);

  const { applyLitterTypeFilter } = rootStore.filterStore;

  const [materialOptions, dispatchMaterialOptions] = useReducer(
    optionReducer,
    materials
  );
  const [productOptions, dispatchProductOptions] = useReducer(
    optionReducer,
    products
  );

  //On material item selected
  function onMaterialOptionSelected(option: MultiSelectOptionSet) {
    dispatchMaterialOptions({ type: "toggle", option: option });
  }

  //On product item selected
  function onProductItemSelected(option: MultiSelectOptionSet) {
    dispatchProductOptions({ type: "toggle", option: option });
  }

  function onApplyFilterClick() {
    if (isLocationSetSelected(materialOptions)) {
      const selectedMaterialsLabel = materialOptions
        .filter((item) => item.isSelected)
        .map((item) => item.label);

      const selectedMaterials = materials
        .filter((item) => selectedMaterialsLabel.includes(item.name))
        .map((item) => ({
          id: item.id,
          name: item.name,
        }));

      if (isLocationSetSelected(productOptions)) {
        const selectedProductLabels = productOptions
          .filter((item) => item.isSelected)
          .map((item) => item.label);

        const selectedProducts = products
          .filter((item) => selectedProductLabels.includes(item.name))
          .map((item) => ({
            id: item.id,
            name: item.name,
          }));

        applyLitterTypeFilter({
          materials: selectedMaterials,
          products: selectedProducts,
        });
      } else {
        applyLitterTypeFilter({
          materials: selectedMaterials,
        });
      }
    } else {
      applyLitterTypeFilter(null);
    }

    //logging events into google analytics
    ReactGA.event({
      category: 'Litter Type Filter',
      action: 'Apply Filter'
    })
  }

  useEffect(() => {
    if (isLocationSetSelected(materialOptions)) {
      const namesOfSelectedMaterials = materialOptions
        .filter((item) => item.isSelected)
        .map((item) => item.label);

      const selectedMaterialIds = materials
        .filter((item) => namesOfSelectedMaterials.includes(item.name))
        .map((item) => item.id);

      const productsWithinSelectedMaterials = products.filter((item) =>
        selectedMaterialIds.includes(item.litterCategoryId)
      );

      dispatchProductOptions({
        type: "set",
        option: productsWithinSelectedMaterials,
      });
    } else {
      dispatchProductOptions({
        type: "set",
        option: [],
      });
    }
    // eslint-disable-next-line 
  }, [materialOptions]);

  return (
    <section className="litter_type_container">
      <div className="content_container">
        <CustomSelect
          defaultTitle={`All Materials`}
          options={materialOptions}
          onItemSelected={onMaterialOptionSelected}
        />
        <CustomSelect
          defaultTitle={`All Products`}
          options={productOptions}
          onItemSelected={onProductItemSelected}
          isDisabled={!isLocationSetSelected(materialOptions)}
        />
      </div>
      <ApplyFilterSection onClick={onApplyFilterClick} />
    </section>
  );
};
