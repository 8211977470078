import React, { useContext } from "react";
import "./InfoGroup.scss";
import { InfoItem } from "../InfoItem/InfoItem";
import { LitterDensityInfoItem } from "../LitterDensityInfoItem/LitterDensityInfoItem";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../store/rootStore";

export interface InfoItemData {
  title: string;
  value: number;
  description?: string;
}

export interface LitterDensityItemUIData {
  medianLitterDensity: number;
  minimumLitterDensity: number;
  maximumLitterDensity: number;
}

export interface LitterQuantityItemUIData {
  medianLitterQuantity: number;
  minimumLitterQuantity: number;
  maximumLitterQuantity: number;
}

type InfoGroupProps = {
  itemData: InfoItemData[]; //This only accept upto 4 entries [no less - no more]
  stackedUI: boolean; //The default is true
  surveyEffortUI?: boolean;
  isComparison?: boolean;
  litterDensityInfo?: LitterDensityItemUIData;
  litterQuantityInfo?: LitterQuantityItemUIData;
};

export const InfoGroup: React.FC<InfoGroupProps> = observer(
  ({
    itemData,
    stackedUI,
    surveyEffortUI,
    isComparison,
    litterDensityInfo,
    litterQuantityInfo,
  }) => {
    const rootStore = useContext(RootStoreContext);
    const { isStormwaterSelected } = rootStore.commonStore;

    const litterDensityOrQuantityInfo = () => {
      if (litterDensityInfo !== null && litterDensityInfo !== undefined) {
        return (
          <div
            className={`${
              stackedUI
                ? "info_item_container"
                : "info_item_container_unstacked"
            }`}
          >
            <LitterDensityInfoItem
              title="Litter Density"
              description={
                isStormwaterSelected ? "Items per catchpit" : "Items Per 1000m2"
              }
              medianLitterDensity={litterDensityInfo.medianLitterDensity}
              minimumLitterDensity={litterDensityInfo.minimumLitterDensity}
              maximumLitterDensity={litterDensityInfo.maximumLitterDensity}
            />
          </div>
        );
      } else if (
        litterQuantityInfo !== null &&
        litterQuantityInfo !== undefined
      ) {
        return (
          <div
            className={`${
              stackedUI
                ? "info_item_container"
                : "info_item_container_unstacked"
            }`}
          >
            <LitterDensityInfoItem
              title="Litter Quantity"
              description="Items per catchpit"
              medianLitterDensity={litterQuantityInfo.medianLitterQuantity}
              minimumLitterDensity={litterQuantityInfo.minimumLitterQuantity}
              maximumLitterDensity={litterQuantityInfo.maximumLitterQuantity}
            />
          </div>
        );
      } else {
        return null;
      }
    };

    return (
      <section
        className={`${
          stackedUI ? "info_group_section" : "info_group_section_unstacked"
        } ${
          surveyEffortUI
            ? isComparison
              ? "info_group_section_survey_effort__comparison"
              : "info_group_section_survey_effort"
            : ""
        }`}
      >
        {litterDensityOrQuantityInfo()}

        {itemData.map((item) => (
          <div
            key={item.title}
            className={`${
              stackedUI
                ? "info_item_container"
                : "info_item_container_unstacked"
            }`}
          >
            <InfoItem
              title={item.title}
              value={item.value}
              description={item.description}
            />
          </div>
        ))}
      </section>
    );
  }
);
