import React, { useState, useContext } from "react";
import "./TimeToggle.scss";
import { CalendarDropdown } from "./CustomCalendar/CalendarDropdown";
import { ApplyFilterSection } from "./ApplyFilterSection";
import { RootStoreContext } from "../../store/rootStore";
import moment from "moment";
import ReactGA from 'react-ga';

type TimeToggleProps = {
  maxEndDate: moment.Moment;
  startDate?: moment.Moment;
  endDate?: moment.Moment;
};

export const TimeToggle: React.FC<TimeToggleProps> = ({
  startDate,
  endDate,
  maxEndDate,
}) => {
  const rootStore = useContext(RootStoreContext);

  const { applyDateFilter } = rootStore.filterStore;

  const [startDateContainerKey, setStartDateContainerKey] = useState("start_calendar_toggle_closed");
  const [endDateContainerKey, setEndDateContainerKey] = useState("end_calendar_toggle_closed");

  const [localStartDate, setLocalStartDate] = useState<
    moment.Moment | undefined
  >(startDate);
  const [localEndDate, setLocalEndDate] = useState<moment.Moment | undefined>(
    endDate
  );

  function onStartDateSelected(chosenDate: moment.Moment | undefined): void {
    setLocalStartDate(chosenDate);
    setStartDateContainerKey(startDateContainerKey === "start_calendar_toggle_closed" ? "start_calendar_toggle_open" : "start_calendar_toggle_closed");
  }

  function onEndDateSelected(chosenDate: moment.Moment | undefined): void {
    setLocalEndDate(chosenDate);
    setEndDateContainerKey(endDateContainerKey === "end_calendar_toggle_closed" ? "end_calendar_toggle_open" : "end_calendar_toggle_closed");
  }

  function onApplyFilterClick() {
    if (
      localStartDate !== null &&
      localStartDate !== undefined &&
      localStartDate.isValid()
    ) {
      if (
        localEndDate !== null &&
        localEndDate !== undefined &&
        localEndDate.isValid()
      ) {
        applyDateFilter({
          startDate: localStartDate,
          endDate: localEndDate,
        });
      } else {
        applyDateFilter({
          startDate: localStartDate,
          endDate: moment(),
        });
      }
    } else {
      applyDateFilter(null);
    }
    //logging events into google analytics
    ReactGA.event({
      category: 'Date Filter',
      action: 'Apply Filter'
    })
  }

  return (
    <section className="time_toggle_container">
      <div className="content_container">
        <CalendarDropdown
          key={startDateContainerKey}
          defaultTitle={`Start Date`}
          selectedDate={localStartDate}
          onDateSelected={onStartDateSelected}
          maxDate={
            localEndDate !== null &&
            localEndDate !== undefined &&
            localEndDate.isValid()
              ? localEndDate
              : moment()
          }
        />
        <CalendarDropdown
          key={endDateContainerKey}
          defaultTitle={`End Date`}
          selectedDate={localEndDate}
          onDateSelected={onEndDateSelected}
          maxDate={maxEndDate}
          minDate={localStartDate}
          isDisabled={
            !(
              localStartDate !== null &&
              localStartDate !== undefined &&
              localStartDate.isValid()
            )
          }
        />
      </div>
      <ApplyFilterSection onClick={onApplyFilterClick} />
    </section>
  );
};
