import React, { useState, useEffect } from "react";
import "./ItemsBarChart.scss";
import {
  BarChartDataItem,
  CustomBarChart,
} from "../CustomBarChart/CustomBarChart";
import { CustomToggle, ToggleContent } from "../CustomToggle/CustomToggle";
import ReactGA from "react-ga";

type ItemsBarChart = {
  itemsList: BarChartDataItem[];
  weightList: BarChartDataItem[];
};

export const ItemsBarChart: React.FC<ItemsBarChart> = ({
  itemsList,
  weightList,
}) => {
  const [selectedToggle, setSelectedToggle] = useState("ITEMS");

  function onToggleChanged(option: string) {
    if (selectedToggle !== option) {
      setSelectedToggle(option);
    }
  }

  useEffect(() => {
    ReactGA.event({
      category: "Percent of Total Items Chart",
      action: selectedToggle,
    });
  }, [selectedToggle]);

  return (
    <section className="items_bar_chart_section">
      <div className="items_bar_chart_header">
        <p>
          PERCENTAGE OF TOTAL {" "}
          {selectedToggle === ToggleContent.ITEMS ? "ITEMS" : "WEIGHT"}
        </p>
        <CustomToggle
          firstOption="ITEMS"
          secondOption="WEIGHT"
          selectedToggle={selectedToggle}
          onToggleChanged={onToggleChanged}
        />
      </div>
      <div className="horizontal_divider"></div>
      <div className="item_chart_container">
        <CustomBarChart
          itemList={selectedToggle === "ITEMS" ? itemsList : weightList}
        />
      </div>
    </section>
  );
};
