import { RootStore } from "./rootStore";
import { observable, action, computed } from "mobx";
import { TabList } from "../components/Tabs/Tabs";
import { SideTabNameType, SideTabList, SideTab } from "../store/util";

export default class CommonStore {
  rootStore: RootStore;

  @observable isComparisonModalOpen = false;
  @observable isSideBarOpen = false;
  @observable currentTab = TabList[0].label;
  @observable currentSideTab = SideTabList[0];

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action setSideBarOpen = (isSidebarOpen: boolean) => {
    this.isSideBarOpen = isSidebarOpen;
  };

  @action setIsComparisonModalOpen = (isOpen: boolean) => {
    this.isComparisonModalOpen = isOpen;
  };

  @action setCurrentTab = (currentTab: string) => {
    this.currentTab = currentTab;
  };

  @action setCurrentSideTab = (currentSideTab: SideTabNameType) => {
    this.currentSideTab = currentSideTab;
  };

  @computed get shoudRenderLocationType(): boolean {
    return this.currentSideTab === SideTab.BEACH.name;
  }

  @computed get isStormwaterSelected(): boolean {
    return this.currentSideTab === SideTab.STORMWATER.name;
  }

  @computed get isFreshwaterSelected(): boolean {
    return this.currentSideTab === SideTab.FRESHWATER.name;
  }
}
