import { LoadingStatus, IFilter } from "../util";
import { GeoChartData } from "../../components/GeoChart/GeoChart";
import { BarChartDataItem } from "../../components/CustomBarChart/CustomBarChart";

export interface StoreData {
  data: OverviewData;
  filtersApplied: IFilter;
}

export interface CategoryItemAndWeight {
  sumOfItemCount: number;
  sumOfItemWeight: number;
  categoryName: string;
}

export interface MonitoringSiteDetail {
  monitoringSiteName: string;
  numberOfSurveys: number;
  latitude: number;
  longitude: number;
}

export interface SurveyStats {
  numberOfSurveyAreas: number;
  numberOfSurveysCompleted: number;
  totalVolunteerHours: number;
}

export interface OverviewData {
  categoryItemsAndWeight: CategoryItemAndWeight[] | null;
  surveyLocations: MonitoringSiteDetail[] | null;
  surveyStats: SurveyStats | null;
  litterDensities?: LitterDensityData;
  litterQuantities?: LitterQuantityData;
}

export interface LitterQuantityData {
  medianLitterQuantity: number;
  minimumLitterQuantity: number;
  maximumLitterQuantity: number;
}

export interface LitterDensityData {
  medianLitterDensity: number;
  minimumLitterDensity: number;
  maximumLitterDensity: number;
}

export interface SurveyLocationUIData {
  loadingStatus: LoadingStatus;
  geoData: GeoChartData[];
  infoGroup: {
    title: string;
    value: number;
    description?: string;
  }[];
  litterDensityUIData: LitterDensityData | null;
  litterQuantityUIData: LitterQuantityData | null;
}

export interface TotalItemAndWeightUI {
  loadingStatus: LoadingStatus
  totalItems: number;
  totalWeight: number;
}

export function isValidObject(data: any) {
  return data !== null && data !== undefined;
}

export function convertSurveyStatsObjToDict(surveyStats: SurveyStats) {
  return Object.keys(surveyStats).map((key) =>
    key === "medianLitterDensity"
      ? {
          title: getSurveyStatsUIPropName(key),
          value: surveyStats[key as keyof SurveyStats],
          description: "Items per 1000m2",
        }
      : {
          title: getSurveyStatsUIPropName(key),
          value: surveyStats[key as keyof SurveyStats],
        }
  );
}

function getSurveyStatsUIPropName(data: string): string {
  switch (data) {
    case "medianLitterDensity":
      return "Median Litter Density";
    case "numberOfSurveyAreas":
      return "Survey Areas";
    case "numberOfSurveysCompleted":
      return "Surveys Completed";
    case "totalVolunteerHours":
      return "Volunteer Hours";
  }
}

export interface ItemsBarChartUIData
{
  itemsList: BarChartDataItem[];
  weightList: BarChartDataItem[];
  loadingStatus: LoadingStatus;
}

export function roundToTwo(data: number): number {
  return Math.round(data * 100) / 100
}