import { RootStore } from "../rootStore";
import { observable, action, runInAction, computed, toJS } from "mobx";
import { LoadingStatus, isValidArray, IFilter } from "../util";
import {
  LitterDensityPerSurveyAreaTableData,
  StoreData,
  formatLitterQuantityArrItemToDensity
} from "./locationStoreUtil";
import { getLocationData } from "../api/agent";
import {
  SurveyLocationUIData,
  isValidObject,
  convertSurveyStatsObjToDict,
} from "../overview/overviewUtil";
import { DataSet } from "../../components/ComparisonCard/ComparisonCard";
import { convertToApiFilterData } from "../api/networkUtil";
import { shouldNotLoadData } from "../filter/filterUtil";

export default class LocationStore {
  rootStore: RootStore;

  @observable loadingStatus = LoadingStatus.Loading;
  @observable defaultData: StoreData = {
    data: null,
    filtersApplied: null,
  };

  @observable loadingStatusDataSetOne = LoadingStatus.Loading;
  @observable dataSetOne: StoreData = {
    data: null,
    filtersApplied: null,
  };

  @observable loadingStatusDataSetTwo = LoadingStatus.Loading;
  @observable dataSetTwo: StoreData = {
    data: null,
    filtersApplied: null,
  };

  @observable isComparisonApplied = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action handleFilterChanges = (filtersApplied: IFilter) => {
    if (isValidObject(filtersApplied)) {
      if (isValidObject(filtersApplied.comparisonData)) {
        //that means there is a comparison present.
        this.isComparisonApplied = true;
        this.loadData(DataSet.SET_ONE, filtersApplied);
        this.loadData(DataSet.SET_TWO, filtersApplied);
      } else {
        this.isComparisonApplied = false;
        this.loadData(DataSet.DEFAULT, filtersApplied);
      }
    } else {
      this.isComparisonApplied = false;
      this.loadData(DataSet.DEFAULT, filtersApplied);
    }
  };

  @action loadData = async (dataSet: DataSet, filtersApplied: IFilter) => {
    switch (dataSet) {
      case DataSet.DEFAULT:
        if (
          shouldNotLoadData(
            this.loadingStatus,
            this.defaultData.filtersApplied,
            filtersApplied
          )
        ) {
          return;
        }
        this.loadingStatus = LoadingStatus.Loading;
        try {
          const responseData = formatLitterQuantityArrItemToDensity(await getLocationData(
            convertToApiFilterData(filtersApplied, DataSet.DEFAULT)
          ));
          runInAction(() => {
            this.defaultData = {
              data: responseData,
              filtersApplied: toJS(filtersApplied),
            };
            this.loadingStatus = LoadingStatus.Success;
          });
        } catch (error) {
          runInAction(() => {
            this.defaultData = {
              data: null,
              filtersApplied: null,
            };
            this.loadingStatus = LoadingStatus.Failed;
          });
        }
        break;
      case DataSet.SET_ONE:
        if(shouldNotLoadData(this.loadingStatusDataSetOne, this.dataSetOne.filtersApplied, filtersApplied)) {
          return;
        }
        this.loadingStatusDataSetOne = LoadingStatus.Loading;
        try {
          const responseData = formatLitterQuantityArrItemToDensity(await getLocationData(
            convertToApiFilterData(filtersApplied, DataSet.SET_ONE)
          ));
          runInAction(() => {
            this.dataSetOne = {
              data: responseData,
              filtersApplied: toJS(filtersApplied)
            };
            this.loadingStatusDataSetOne = LoadingStatus.Success;
          });
        } catch (error) {
          runInAction(() => {
            this.dataSetOne = {
              data: null,
              filtersApplied: null
            };
            this.loadingStatusDataSetOne = LoadingStatus.Failed;
          });
        }
        break;
      case DataSet.SET_TWO:
        if(shouldNotLoadData(this.loadingStatusDataSetTwo, this.dataSetTwo.filtersApplied, filtersApplied)) {
          return;
        }
        this.loadingStatusDataSetTwo = LoadingStatus.Loading;
        try {
          const responseData = formatLitterQuantityArrItemToDensity(await getLocationData(
            convertToApiFilterData(filtersApplied, DataSet.SET_TWO)
          ));
          runInAction(() => {
            this.dataSetTwo = {
              data: responseData,
              filtersApplied: toJS(filtersApplied)
            };
            this.loadingStatusDataSetTwo = LoadingStatus.Success;
          });
        } catch (error) {
          runInAction(() => {
            this.dataSetTwo = {
              data: null,
              filtersApplied: null
            };
            this.loadingStatusDataSetTwo = LoadingStatus.Failed;
          });
        }
        break;
      default:
        if (
          shouldNotLoadData(
            this.loadingStatus,
            this.defaultData.filtersApplied,
            filtersApplied
          )
        ) {
          return;
        }
        this.loadingStatus = LoadingStatus.Loading;
        try {
          const responseData = formatLitterQuantityArrItemToDensity(await getLocationData(
            convertToApiFilterData(filtersApplied, DataSet.DEFAULT)
          ));
          runInAction(() => {
            this.defaultData = {
              data: responseData,
              filtersApplied: toJS(filtersApplied),
            };
            this.loadingStatus = LoadingStatus.Success;
          });
        } catch (error) {
          runInAction(() => {
            this.defaultData = {
              data: null,
              filtersApplied: null,
            };
            this.loadingStatus = LoadingStatus.Failed;
          });
        }
        break;
    }
  };

  @computed get defaultSurveyLocationsUIData(): SurveyLocationUIData {
    switch (this.loadingStatus) {
      case LoadingStatus.Loading:
        return {
          loadingStatus: LoadingStatus.Loading,
          geoData: null,
          infoGroup: null,
          litterDensityUIData: null,
          litterQuantityUIData: null
        };

      case LoadingStatus.Success:
        if (
          isValidObject(this.defaultData.data) &&
          isValidArray(this.defaultData.data.surveyLocations) &&
          (isValidArray(this.defaultData.data.litterDensityPerSurveyAreas) || isValidArray(this.defaultData.data.litterQuantityPerSurveyAreas)) &&
          isValidObject(this.defaultData.data.surveyStats) && 
          (isValidObject(this.defaultData.data.litterDensities) || isValidObject(this.defaultData.data.litterQuantities))
        ) {
          return {
            loadingStatus: LoadingStatus.Success,
            geoData: this.defaultData.data.surveyLocations.map((item) => ({
              latitude: item.latitude,
              longitude: item.longitude,
              name: item.monitoringSiteName,
              numberOfSurveys: item.numberOfSurveys,
            })),
            infoGroup: convertSurveyStatsObjToDict({
              ...this.defaultData.data.surveyStats
            }),
            litterDensityUIData: isValidObject(this.defaultData.data.litterDensities) ? {
              ...this.defaultData.data.litterDensities
            } : null,
            litterQuantityUIData: isValidObject(this.defaultData.data.litterQuantities) ? {
              ...this.defaultData.data.litterQuantities
            } : null,
          };
        } else {
          return {
            loadingStatus: LoadingStatus.Failed,
            geoData: null,
            infoGroup: null,
            litterDensityUIData: null,
            litterQuantityUIData: null
          };
        }

      case LoadingStatus.Failed:
        return {
          loadingStatus: LoadingStatus.Failed,
          geoData: null,
          infoGroup: null,
          litterDensityUIData: null,
          litterQuantityUIData: null
        };
      default:
        return {
          loadingStatus: LoadingStatus.Failed,
          geoData: null,
          infoGroup: null,
          litterDensityUIData: null,
          litterQuantityUIData: null
        };
    }
  }

  @computed get setOneSurveyLocationsUIData(): SurveyLocationUIData {
    switch (this.loadingStatusDataSetOne) {
      case LoadingStatus.Loading:
        return {
          loadingStatus: LoadingStatus.Loading,
          geoData: null,
          infoGroup: null,
          litterDensityUIData: null,
          litterQuantityUIData: null
        };

      case LoadingStatus.Success:
        if (
          isValidObject(this.dataSetOne.data) &&
          isValidArray(this.dataSetOne.data.surveyLocations) &&
          (isValidArray(this.dataSetOne.data.litterDensityPerSurveyAreas) || isValidArray(this.dataSetOne.data.litterQuantityPerSurveyAreas)) &&
          isValidObject(this.dataSetOne.data.surveyStats) && 
          (isValidObject(this.dataSetOne.data.litterDensities) || isValidObject(this.dataSetOne.data.litterQuantities))
        ) {
          return {
            loadingStatus: LoadingStatus.Success,
            geoData: this.dataSetOne.data.surveyLocations.map((item) => ({
              latitude: item.latitude,
              longitude: item.longitude,
              name: item.monitoringSiteName,
              numberOfSurveys: item.numberOfSurveys,
            })),
            infoGroup: convertSurveyStatsObjToDict({
              ...this.dataSetOne.data.surveyStats
            }),
            litterDensityUIData: isValidObject(this.dataSetOne.data.litterDensities) ? {
              ...this.dataSetOne.data.litterDensities
            } : null,
            litterQuantityUIData: isValidObject(this.dataSetOne.data.litterQuantities) ? {
              ...this.dataSetOne.data.litterQuantities
            } : null,
          };
        } else {
          return {
            loadingStatus: LoadingStatus.Failed,
            geoData: null,
            infoGroup: null,
            litterDensityUIData: null,
            litterQuantityUIData: null
          };
        }

      case LoadingStatus.Failed:
        return {
          loadingStatus: LoadingStatus.Failed,
          geoData: null,
          infoGroup: null,
          litterDensityUIData: null,
          litterQuantityUIData: null
        };
      default:
        return {
          loadingStatus: LoadingStatus.Failed,
          geoData: null,
          infoGroup: null,
          litterDensityUIData: null,
          litterQuantityUIData: null
        };
    }
  }

  @computed get setTwoSurveyLocationsUIData(): SurveyLocationUIData {
    switch (this.loadingStatusDataSetTwo) {
      case LoadingStatus.Loading:
        return {
          loadingStatus: LoadingStatus.Loading,
          geoData: null,
          infoGroup: null,
          litterDensityUIData: null,
          litterQuantityUIData: null
        };

      case LoadingStatus.Success:
        if (
          isValidObject(this.dataSetTwo.data) &&
          isValidArray(this.dataSetTwo.data.surveyLocations) &&
          (isValidArray(this.dataSetTwo.data.litterDensityPerSurveyAreas) || isValidArray(this.dataSetTwo.data.litterQuantityPerSurveyAreas)) &&
          isValidObject(this.dataSetTwo.data.surveyStats) && 
          (isValidObject(this.dataSetTwo.data.litterDensities) || isValidObject(this.dataSetTwo.data.litterQuantities))
        ) {
          return {
            loadingStatus: LoadingStatus.Success,
            geoData: this.dataSetTwo.data.surveyLocations.map((item) => ({
              latitude: item.latitude,
              longitude: item.longitude,
              name: item.monitoringSiteName,
              numberOfSurveys: item.numberOfSurveys,
            })),
            infoGroup: convertSurveyStatsObjToDict({
              ...this.dataSetTwo.data.surveyStats
            }),
            litterDensityUIData: isValidObject(this.dataSetTwo.data.litterDensities) ? {
              ...this.dataSetTwo.data.litterDensities
            } : null,
            litterQuantityUIData: isValidObject(this.dataSetTwo.data.litterQuantities) ? {
              ...this.dataSetTwo.data.litterQuantities
            } : null,
          };
        } else {
          return {
            loadingStatus: LoadingStatus.Failed,
            geoData: null,
            infoGroup: null,
            litterDensityUIData: null,
            litterQuantityUIData: null
          };
        }

      case LoadingStatus.Failed:
        return {
          loadingStatus: LoadingStatus.Failed,
          geoData: null,
          infoGroup: null,
          litterDensityUIData: null,
          litterQuantityUIData: null
        };
      default:
        return {
          loadingStatus: LoadingStatus.Failed,
          geoData: null,
          infoGroup: null,
          litterDensityUIData: null,
          litterQuantityUIData: null
        };
    }
  }

  @computed
  get setOneLitterDensityTableData(): LitterDensityPerSurveyAreaTableData {
    switch (this.loadingStatusDataSetOne) {
      case LoadingStatus.Loading:
        return {
          loadingStatus: LoadingStatus.Loading,
          data: null,
        };

      case LoadingStatus.Success:
        if (
          isValidObject(this.dataSetOne.data) &&
          isValidArray(this.dataSetOne.data.litterDensityPerSurveyAreas) 
        ) {
          return {
            loadingStatus: LoadingStatus.Success,
            data: this.dataSetOne.data.litterDensityPerSurveyAreas 
          };
        } else {
          return {
            loadingStatus: LoadingStatus.Failed,
            data: null,
          };
        }

      case LoadingStatus.Failed:
        return {
          loadingStatus: LoadingStatus.Failed,
          data: null,
        };
      default:
        return {
          loadingStatus: LoadingStatus.Failed,
          data: null,
        };
    }
  }

  @computed
  get setTwoLitterDensityTableData(): LitterDensityPerSurveyAreaTableData {
    switch (this.loadingStatusDataSetTwo) {
      case LoadingStatus.Loading:
        return {
          loadingStatus: LoadingStatus.Loading,
          data: null,
        };

      case LoadingStatus.Success:
        if (
          isValidObject(this.dataSetTwo.data) &&
          isValidArray(this.dataSetTwo.data.litterDensityPerSurveyAreas)
        ) {
          return {
            loadingStatus: LoadingStatus.Success,
            data: this.dataSetTwo.data.litterDensityPerSurveyAreas,
          };
        } else {
          return {
            loadingStatus: LoadingStatus.Failed,
            data: null,
          };
        }

      case LoadingStatus.Failed:
        return {
          loadingStatus: LoadingStatus.Failed,
          data: null,
        };
      default:
        return {
          loadingStatus: LoadingStatus.Failed,
          data: null,
        };
    }
  }

  @computed
  get defaultLitterDensityTableData(): LitterDensityPerSurveyAreaTableData {
    switch (this.loadingStatus) {
      case LoadingStatus.Loading:
        return {
          loadingStatus: LoadingStatus.Loading,
          data: null,
        };

      case LoadingStatus.Success:
        if (
          isValidObject(this.defaultData.data) &&
          isValidArray(this.defaultData.data.litterDensityPerSurveyAreas)
        ) {
          return {
            loadingStatus: LoadingStatus.Success,
            data: this.defaultData.data.litterDensityPerSurveyAreas,
          };
        } else {
          return {
            loadingStatus: LoadingStatus.Failed,
            data: null,
          };
        }

      case LoadingStatus.Failed:
        return {
          loadingStatus: LoadingStatus.Failed,
          data: null,
        };
      default:
        return {
          loadingStatus: LoadingStatus.Failed,
          data: null,
        };
    }
  }
}
