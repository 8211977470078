import { RootStore } from "../rootStore";
import { observable, runInAction, action, computed, toJS } from "mobx";
import { LoadingStatus, isValidArray, IFilter } from "../util";
import {
  LitterDensityData,
  LitterDensityChartData,
  LitterDensityInfoGroupItemData,
  LitterDensityInfoGroupWeightData,
  StoreData,
  LITTER_DENSITY_API_DATE_FORMAT,
  convertToLitterDensityData
} from "./litterDensityUtil";
import { getLitterDensityData } from "../api/agent";
import { isValidObject, roundToTwo } from "../overview/overviewUtil";
import moment from "moment";
import { MixedChartData } from "../../components/MixedChart/MixedChart";
import { convertToApiFilterData } from "../api/networkUtil";
import { DataSet } from "../../components/ComparisonCard/ComparisonCard";
import { shouldNotLoadData } from "../filter/filterUtil";

export default class LitterDensityStore {
  rootStore: RootStore;

  @observable loadingStatus: LoadingStatus = LoadingStatus.Loading;
  @observable defaultData: StoreData = {
    data: null,
    filtersApplied: null,
  };

  @observable comparisonLoadingStatus: LoadingStatus = LoadingStatus.Loading;
  @observable comparisonDataSet: {
    setOne: LitterDensityData;
    setTwo: LitterDensityData;
    filtersApplied: IFilter;
  } = null;

  @observable isComparisonApplied = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action handleFilterChanges = (filtersApplied: IFilter) => {
    if (isValidObject(filtersApplied)) {
      if (isValidObject(filtersApplied.comparisonData)) {
        //that means there is a comparison present.
        this.isComparisonApplied = true;
        this.loadData(true, filtersApplied);
      } else {
        this.isComparisonApplied = false;
        this.loadData(false, filtersApplied);
      }
    } else {
      this.isComparisonApplied = false;
      this.loadData(false, filtersApplied);
    }
  };

  @action loadData = async (isComparison: boolean, filtersApplied: IFilter) => {
    if (isComparison) {
      if (
        shouldNotLoadData(
          this.comparisonLoadingStatus,
          this.comparisonDataSet === null ||
            this.comparisonDataSet === undefined
            ? null
            : this.comparisonDataSet.filtersApplied,
          filtersApplied
        )
      ) {
        return;
      }
      this.comparisonLoadingStatus = LoadingStatus.Loading;
      let comparisonDataSet: {
        dataSetOne: LitterDensityData;
        dataSetTwo: LitterDensityData;
      } = {
        dataSetOne: null,
        dataSetTwo: null,
      };
      try {
        const litterDensityDataSetOne: LitterDensityData = convertToLitterDensityData((await getLitterDensityData(
          convertToApiFilterData(filtersApplied, DataSet.SET_ONE)
        )));
        comparisonDataSet.dataSetOne = {
          litterDensityPerSurvey:
            litterDensityDataSetOne.litterDensityPerSurvey,
          averageGramDensity: litterDensityDataSetOne.averageGramDensity,
          averageItemDensity: litterDensityDataSetOne.averageItemDensity,
          totalItems: litterDensityDataSetOne.totalItems,
          totalWeight: litterDensityDataSetOne.totalWeight
        };
      } catch (error) {}
      try {
        const litterDensityDataSetTwo: LitterDensityData = convertToLitterDensityData(await getLitterDensityData(
          convertToApiFilterData(filtersApplied, DataSet.SET_TWO)
        ));
        comparisonDataSet.dataSetTwo = {
          litterDensityPerSurvey:
            litterDensityDataSetTwo.litterDensityPerSurvey,
          averageGramDensity: litterDensityDataSetTwo.averageGramDensity,
          averageItemDensity: litterDensityDataSetTwo.averageItemDensity,
          totalItems: litterDensityDataSetTwo.totalItems,
          totalWeight: litterDensityDataSetTwo.totalWeight
        };
      } catch (error) {}

      if (
        !isValidObject(comparisonDataSet.dataSetOne) &&
        !isValidObject(comparisonDataSet.dataSetTwo)
      ) {
        runInAction("failed to load litter density data", () => {
          this.comparisonDataSet = null;
          this.comparisonLoadingStatus = LoadingStatus.Failed;
        });
      } else {
        runInAction("successfully loaded litter density data", () => {
          this.comparisonDataSet = {
            setOne: isValidObject(comparisonDataSet.dataSetOne)
              ? {
                  litterDensityPerSurvey:
                    comparisonDataSet.dataSetOne.litterDensityPerSurvey,
                  averageGramDensity:
                    comparisonDataSet.dataSetOne.averageGramDensity,
                  averageItemDensity:
                    comparisonDataSet.dataSetOne.averageItemDensity,
                  totalItems: comparisonDataSet.dataSetOne.totalItems,
                  totalWeight: comparisonDataSet.dataSetOne.totalWeight
                }
              : null,
            setTwo: isValidObject(comparisonDataSet.dataSetTwo)
              ? {
                  litterDensityPerSurvey:
                    comparisonDataSet.dataSetTwo.litterDensityPerSurvey,
                  averageGramDensity:
                    comparisonDataSet.dataSetTwo.averageGramDensity,
                  averageItemDensity:
                    comparisonDataSet.dataSetTwo.averageItemDensity,
                  totalItems: comparisonDataSet.dataSetTwo.totalItems,
                  totalWeight: comparisonDataSet.dataSetTwo.totalWeight
                }
              : null,
            filtersApplied: toJS(filtersApplied),
          };
          this.comparisonLoadingStatus = LoadingStatus.Success;
        });
      }
    } else {
      if (
        shouldNotLoadData(
          this.loadingStatus,
          this.defaultData.filtersApplied,
          filtersApplied
        )
      ) {
        return;
      }
      this.loadingStatus = LoadingStatus.Loading;
      try {
        const litterDensityData: LitterDensityData = convertToLitterDensityData(await getLitterDensityData(
          convertToApiFilterData(filtersApplied, DataSet.DEFAULT)
        ));
        runInAction("successfully loaded litter density data", () => {
          this.defaultData = {
            data: litterDensityData,
            filtersApplied: toJS(filtersApplied),
          };
          this.loadingStatus = LoadingStatus.Success;
        });
      } catch (error) {
        runInAction("failed to load litter density data", () => {
          this.defaultData = {
            data: null,
            filtersApplied: null,
          };
          this.loadingStatus = LoadingStatus.Failed;
        });
      }
    }
  };

  @computed get defaultLitterDensityChartData(): LitterDensityChartData {
    switch (this.loadingStatus) {
      case LoadingStatus.Loading:
        return {
          itemData: null,
          weightData: null,
          loadingStatus: LoadingStatus.Loading,
        };
      case LoadingStatus.Success:
        if (
          isValidObject(this.defaultData.data) &&
          isValidArray(this.defaultData.data.litterDensityPerSurvey) &&
          this.defaultData.data.litterDensityPerSurvey.every((item) =>
            moment(item.month, LITTER_DENSITY_API_DATE_FORMAT).isValid()
          )
        ) {
          return {
            loadingStatus: LoadingStatus.Success,
            itemData: this.defaultData.data.litterDensityPerSurvey.map((item) => ({
              lineChartData: item.totalItemCount,
              scatterChartData: item.numberOfSurveys,
              date: moment(item.month, LITTER_DENSITY_API_DATE_FORMAT),
            })),
            weightData: this.defaultData.data.litterDensityPerSurvey.map((item) => ({
              lineChartData: item.totalWeightCount,
              scatterChartData: item.numberOfSurveys,
              date: moment(item.month, LITTER_DENSITY_API_DATE_FORMAT),
            })),
          };
        } else {
          return {
            itemData: null,
            weightData: null,
            loadingStatus: LoadingStatus.Failed,
          };
        }

      case LoadingStatus.Failed:
        return {
          itemData: null,
          weightData: null,
          loadingStatus: LoadingStatus.Failed,
        };
      default:
        return {
          itemData: null,
          weightData: null,
          loadingStatus: LoadingStatus.Failed,
        };
    }
  }

  @computed get comparisonLitterDensityChartData(): {
    loadingStatus: LoadingStatus;
    setOne: {
      itemData: MixedChartData[];
      weightData: MixedChartData[];
    };
    setTwo: {
      itemData: MixedChartData[];
      weightData: MixedChartData[];
    };
  } {
    switch (this.comparisonLoadingStatus) {
      case LoadingStatus.Loading:
        return {
          loadingStatus: LoadingStatus.Loading,
          setOne: null,
          setTwo: null,
        };
      case LoadingStatus.Success:
        if (
          !isValidObject(this.comparisonDataSet) ||
          (!isValidObject(this.comparisonDataSet.setOne) &&
            !isValidObject(this.comparisonDataSet.setTwo))
        ) {
          return {
            loadingStatus: LoadingStatus.Failed,
            setOne: null,
            setTwo: null,
          };
        } else {
          let setOne: {
            itemData: MixedChartData[];
            weightData: MixedChartData[];
          } = null;
          let setTwo: {
            itemData: MixedChartData[];
            weightData: MixedChartData[];
          } = null;

          if (
            isValidObject(this.comparisonDataSet.setOne) &&
            isValidArray(this.comparisonDataSet.setOne.litterDensityPerSurvey)
          ) {
            setOne = {
              itemData: this.comparisonDataSet.setOne.litterDensityPerSurvey.map(
                (item) => ({
                  lineChartData: item.totalItemCount,
                  scatterChartData: item.numberOfSurveys,
                  date: moment(item.month, LITTER_DENSITY_API_DATE_FORMAT),
                })
              ),
              weightData: this.comparisonDataSet.setOne.litterDensityPerSurvey.map(
                (item) => ({
                  lineChartData: item.totalWeightCount,
                  scatterChartData: item.numberOfSurveys,
                  date: moment(item.month, LITTER_DENSITY_API_DATE_FORMAT),
                })
              ),
            };
          }

          if (
            isValidObject(this.comparisonDataSet.setTwo) &&
            isValidArray(this.comparisonDataSet.setTwo.litterDensityPerSurvey)
          ) {
            setTwo = {
              itemData: this.comparisonDataSet.setTwo.litterDensityPerSurvey.map(
                (item) => ({
                  lineChartData: item.totalItemCount,
                  scatterChartData: item.numberOfSurveys,
                  date: moment(item.month, LITTER_DENSITY_API_DATE_FORMAT),
                })
              ),
              weightData: this.comparisonDataSet.setTwo.litterDensityPerSurvey.map(
                (item) => ({
                  lineChartData: item.totalWeightCount,
                  scatterChartData: item.numberOfSurveys,
                  date: moment(item.month, LITTER_DENSITY_API_DATE_FORMAT),
                })
              ),
            };
          }

          return {
            loadingStatus: LoadingStatus.Success,
            setOne: isValidObject(setOne)
              ? {
                  itemData: setOne.itemData,
                  weightData: setOne.weightData,
                }
              : null,
            setTwo: isValidObject(setTwo)
              ? {
                  itemData: setTwo.itemData,
                  weightData: setTwo.weightData,
                }
              : null,
          };
        }

      case LoadingStatus.Failed:
        return {
          loadingStatus: LoadingStatus.Failed,
          setOne: null,
          setTwo: null,
        };
      default:
        return {
          loadingStatus: LoadingStatus.Failed,
          setOne: null,
          setTwo: null,
        };
    }
  }

  @computed
  get defaultLitterDensityInfoGroupItemData(): LitterDensityInfoGroupItemData {
    switch (this.loadingStatus) {
      case LoadingStatus.Loading:
        return {
          loadingStatus: LoadingStatus.Loading,
          totalItems: 0,
          averageItemDensity: 0,
        };
      case LoadingStatus.Success:
        if (
          isValidObject(this.defaultData.data) &&
          isValidArray(this.defaultData.data.litterDensityPerSurvey) &&
          this.defaultData.data.litterDensityPerSurvey.every((item) =>
            moment(item.month, LITTER_DENSITY_API_DATE_FORMAT).isValid()
          ) &&
          isValidObject(this.defaultData.data.averageItemDensity)
        ) {
          return {
            totalItems: this.defaultData.data.totalItems,
            averageItemDensity: this.defaultData.data.averageItemDensity,
            loadingStatus: LoadingStatus.Success,
          };
        } else {
          return {
            totalItems: 0,
            averageItemDensity: 0,
            loadingStatus: LoadingStatus.Failed,
          };
        }

      case LoadingStatus.Failed:
        return {
          totalItems: 0,
          averageItemDensity: 0,
          loadingStatus: LoadingStatus.Failed,
        };
      default:
        return {
          totalItems: 0,
          averageItemDensity: 0,
          loadingStatus: LoadingStatus.Failed,
        };
    }
  }

  @computed
  get comparisonLitterDensityInfoGroupItemData(): {
    loadingStatus: LoadingStatus;
    setOne: {
      totalItems: number;
      averageItemDensity: number;
    };
    setTwo: {
      totalItems: number;
      averageItemDensity: number;
    };
  } {
    switch (this.comparisonLoadingStatus) {
      case LoadingStatus.Loading:
        return {
          loadingStatus: LoadingStatus.Loading,
          setOne: {
            totalItems: 0,
            averageItemDensity: 0,
          },
          setTwo: {
            totalItems: 0,
            averageItemDensity: 0,
          },
        };
      case LoadingStatus.Success:
        if (isValidObject(this.comparisonDataSet)) {
          let setOne: { totalItems: number; averageItemDensity: number } = null;
          let setTwo: { totalItems: number; averageItemDensity: number } = null;
          if (
            isValidObject(this.comparisonDataSet.setOne) &&
            isValidArray(this.comparisonDataSet.setOne.litterDensityPerSurvey)
          ) {
            setOne = {
              totalItems: this.comparisonDataSet.setOne.totalItems,
              averageItemDensity: this.comparisonDataSet.setOne
                .averageItemDensity,
            };
          }

          if (
            isValidObject(this.comparisonDataSet.setTwo) &&
            isValidArray(this.comparisonDataSet.setTwo.litterDensityPerSurvey)
          ) {
            setTwo = {
              totalItems: this.comparisonDataSet.setTwo.totalItems,
              averageItemDensity: this.comparisonDataSet.setTwo
                .averageItemDensity,
            };
          }

          return {
            loadingStatus: LoadingStatus.Success,
            setOne:
              setOne === null || setOne === undefined
                ? {
                    totalItems: 0,
                    averageItemDensity: 0,
                  }
                : {
                    totalItems: setOne.totalItems,
                    averageItemDensity: setOne.averageItemDensity,
                  },
            setTwo:
              setTwo === null || setTwo === undefined
                ? {
                    totalItems: 0,
                    averageItemDensity: 0,
                  }
                : {
                    totalItems: setTwo.totalItems,
                    averageItemDensity: setTwo.averageItemDensity,
                  },
          };
        } else {
          return {
            loadingStatus: LoadingStatus.Failed,
            setOne: {
              totalItems: 0,
              averageItemDensity: 0,
            },
            setTwo: {
              totalItems: 0,
              averageItemDensity: 0,
            },
          };
        }

      case LoadingStatus.Failed:
        return {
          loadingStatus: LoadingStatus.Failed,
          setOne: {
            totalItems: 0,
            averageItemDensity: 0,
          },
          setTwo: {
            totalItems: 0,
            averageItemDensity: 0,
          },
        };
      default:
        return {
          loadingStatus: LoadingStatus.Failed,
          setOne: {
            totalItems: 0,
            averageItemDensity: 0,
          },
          setTwo: {
            totalItems: 0,
            averageItemDensity: 0,
          },
        };
    }
  }

  @computed
  get defaultLitterDensityInfoGroupWeightData(): LitterDensityInfoGroupWeightData {
    switch (this.loadingStatus) {
      case LoadingStatus.Loading:
        return {
          loadingStatus: LoadingStatus.Loading,
          totalWeight: 0,
          averageWeightDensity: 0,
        };
      case LoadingStatus.Success:
        if (
          isValidObject(this.defaultData.data) &&
          isValidArray(this.defaultData.data.litterDensityPerSurvey) &&
          this.defaultData.data.litterDensityPerSurvey.every((item) =>
            moment(item.month, LITTER_DENSITY_API_DATE_FORMAT).isValid()
          ) &&
          isValidObject(this.defaultData.data.averageGramDensity)
        ) {
          return {
            totalWeight: roundToTwo(this.defaultData.data.totalWeight),
            averageWeightDensity: this.defaultData.data.averageGramDensity,
            loadingStatus: LoadingStatus.Success,
          };
        } else {
          return {
            totalWeight: 0,
            averageWeightDensity: 0,
            loadingStatus: LoadingStatus.Failed,
          };
        }

      case LoadingStatus.Failed:
        return {
          totalWeight: 0,
          averageWeightDensity: 0,
          loadingStatus: LoadingStatus.Failed,
        };
      default:
        return {
          totalWeight: 0,
          averageWeightDensity: 0,
          loadingStatus: LoadingStatus.Failed,
        };
    }
  }

  @computed
  get comparisonLitterDensityInfoGroupWeightData(): {
    loadingStatus: LoadingStatus;
    setOne: {
      totalWeight: number;
      averageWeightDensity: number;
    };
    setTwo: {
      totalWeight: number;
      averageWeightDensity: number;
    };
  } {
    switch (this.comparisonLoadingStatus) {
      case LoadingStatus.Loading:
        return {
          loadingStatus: LoadingStatus.Loading,
          setOne: {
            totalWeight: 0,
            averageWeightDensity: 0,
          },
          setTwo: {
            totalWeight: 0,
            averageWeightDensity: 0,
          },
        };
      case LoadingStatus.Success:
        if (isValidObject(this.comparisonDataSet)) {
          let setOne: {
            totalWeight: number;
            averageWeightDensity: number;
          } = null;
          let setTwo: {
            totalWeight: number;
            averageWeightDensity: number;
          } = null;
          if (
            isValidObject(this.comparisonDataSet.setOne) &&
            isValidArray(this.comparisonDataSet.setOne.litterDensityPerSurvey)
          ) {
            setOne = {
              totalWeight: roundToTwo(this.comparisonDataSet.setOne.totalWeight),
              averageWeightDensity: this.comparisonDataSet.setOne
                .averageGramDensity,
            };
          }

          if (
            isValidObject(this.comparisonDataSet.setTwo) &&
            isValidArray(this.comparisonDataSet.setTwo.litterDensityPerSurvey)
          ) {
            setTwo = {
              totalWeight: roundToTwo(this.comparisonDataSet.setTwo.totalWeight),
              averageWeightDensity: this.comparisonDataSet.setTwo
                .averageGramDensity,
            };
          }

          return {
            loadingStatus: LoadingStatus.Success,
            setOne:
              setOne === null || setOne === undefined
                ? {
                    totalWeight: 0,
                    averageWeightDensity: 0,
                  }
                : {
                    totalWeight: setOne.totalWeight,
                    averageWeightDensity: setOne.averageWeightDensity,
                  },
            setTwo:
              setTwo === null || setTwo === undefined
                ? {
                    totalWeight: 0,
                    averageWeightDensity: 0,
                  }
                : {
                    totalWeight: setTwo.totalWeight,
                    averageWeightDensity: setTwo.averageWeightDensity,
                  },
          };
        } else {
          return {
            loadingStatus: LoadingStatus.Failed,
            setOne: {
              totalWeight: 0,
              averageWeightDensity: 0,
            },
            setTwo: {
              totalWeight: 0,
              averageWeightDensity: 0,
            },
          };
        }

      case LoadingStatus.Failed:
        return {
          loadingStatus: LoadingStatus.Failed,
          setOne: {
            totalWeight: 0,
            averageWeightDensity: 0,
          },
          setTwo: {
            totalWeight: 0,
            averageWeightDensity: 0,
          },
        };
      default:
        return {
          loadingStatus: LoadingStatus.Failed,
          setOne: {
            totalWeight: 0,
            averageWeightDensity: 0,
          },
          setTwo: {
            totalWeight: 0,
            averageWeightDensity: 0,
          },
        };
    }
  }
}
