import {
  IFilter,
  ApiFilterData,
  LocationType,
  isValidArray,
  LocationFilter,
  LitterTypeFilter,
  DateFilter,
  ComparisonDataSet,
} from "../util";
import { isValidObject } from "../overview/overviewUtil";
import { DataSet } from "../../components/ComparisonCard/ComparisonCard";
import { LocationTypeValues } from "../constants";

export function convertToApiFilterData(
  filtersApplied: IFilter,
  dataSet: DataSet
): ApiFilterData {
  switch (dataSet) {
    case DataSet.DEFAULT:
      if (isValidObject(filtersApplied)) {
        return {
          locationTypes: convertToApiLocationTypeFilter(
            filtersApplied.locationTypeFilter
          ),
          locations: convertToApiLocationsFilter(filtersApplied.locationFilter),
          litterTypes: convertToApiLitterTypeFilter(
            filtersApplied.litterTypeFilter
          ),
          dateRange: convertToApiDateRange(filtersApplied.dateFilter),
          surveyType: filtersApplied?.surveyType || undefined,
        };
      }

      return {
        locationTypes: [],
        locations: {
          countries: [],
          regions: [],
          surveyAreas: [],
        },
        litterTypes: {
          materials: [],
          products: [],
        },
        dateRange: null,
        surveyType: filtersApplied?.surveyType || undefined,
      };

    case DataSet.SET_ONE:
      if (isValidObject(filtersApplied)) {
        return {
          locationTypes:
            isComparisonApplied(filtersApplied) &&
            filtersApplied.comparisonData.comparisonDataSet ===
              ComparisonDataSet.LocationType
              ? convertToApiLocationTypeFilter(
                  filtersApplied.comparisonData.dataSetOne as LocationType[]
                )
              : convertToApiLocationTypeFilter(
                  filtersApplied.locationTypeFilter
                ),
          locations:
            isComparisonApplied(filtersApplied) &&
            filtersApplied.comparisonData.comparisonDataSet ===
              ComparisonDataSet.Location
              ? convertToApiLocationsFilter(
                  filtersApplied.comparisonData.dataSetOne as LocationFilter
                )
              : convertToApiLocationsFilter(filtersApplied.locationFilter),
          litterTypes:
            isComparisonApplied(filtersApplied) &&
            filtersApplied.comparisonData.comparisonDataSet ===
              ComparisonDataSet.LitterType
              ? convertToApiLitterTypeFilter(
                  filtersApplied.comparisonData.dataSetOne as LitterTypeFilter
                )
              : convertToApiLitterTypeFilter(filtersApplied.litterTypeFilter),
          dateRange:
            isComparisonApplied(filtersApplied) &&
            filtersApplied.comparisonData.comparisonDataSet ===
              ComparisonDataSet.DateRange
              ? convertToApiDateRange(
                  filtersApplied.comparisonData.dataSetOne as DateFilter
                )
              : convertToApiDateRange(filtersApplied.dateFilter),
          surveyType: filtersApplied?.surveyType || undefined,
            };
      }
      return {
        locationTypes: [],
        locations: {
          countries: [],
          regions: [],
          surveyAreas: [],
        },
        litterTypes: {
          materials: [],
          products: [],
        },
        dateRange: null,
        surveyType: filtersApplied?.surveyType || undefined,
      };
    case DataSet.SET_TWO:
      if (isValidObject(filtersApplied)) {
        return {
          locationTypes:
            isComparisonApplied(filtersApplied) &&
            filtersApplied.comparisonData.comparisonDataSet ===
              ComparisonDataSet.LocationType
              ? convertToApiLocationTypeFilter(
                  filtersApplied.comparisonData.dataSetTwo as LocationType[]
                )
              : convertToApiLocationTypeFilter(
                  filtersApplied.locationTypeFilter
                ),
          locations:
            isComparisonApplied(filtersApplied) &&
            filtersApplied.comparisonData.comparisonDataSet ===
              ComparisonDataSet.Location
              ? convertToApiLocationsFilter(
                  filtersApplied.comparisonData.dataSetTwo as LocationFilter
                )
              : convertToApiLocationsFilter(filtersApplied.locationFilter),
          litterTypes:
            isComparisonApplied(filtersApplied) &&
            filtersApplied.comparisonData.comparisonDataSet ===
              ComparisonDataSet.LitterType
              ? convertToApiLitterTypeFilter(
                  filtersApplied.comparisonData.dataSetTwo as LitterTypeFilter
                )
              : convertToApiLitterTypeFilter(filtersApplied.litterTypeFilter),
          dateRange:
            isComparisonApplied(filtersApplied) &&
            filtersApplied.comparisonData.comparisonDataSet ===
              ComparisonDataSet.DateRange
              ? convertToApiDateRange(
                  filtersApplied.comparisonData.dataSetTwo as DateFilter
                )
              : convertToApiDateRange(filtersApplied.dateFilter),
          surveyType: filtersApplied?.surveyType || undefined,

        };
      }
      return {
        locationTypes: [],
        locations: {
          countries: [],
          regions: [],
          surveyAreas: [],
        },
        litterTypes: {
          materials: [],
          products: [],
        },
        dateRange: null,
        surveyType: filtersApplied?.surveyType || undefined,
      };
    default:
      if (isValidObject(filtersApplied)) {
        return {
          locationTypes: convertToApiLocationTypeFilter(
            filtersApplied.locationTypeFilter
          ),
          locations: convertToApiLocationsFilter(filtersApplied.locationFilter),
          litterTypes: convertToApiLitterTypeFilter(
            filtersApplied.litterTypeFilter
          ),
          dateRange: convertToApiDateRange(filtersApplied.dateFilter),
          surveyType: filtersApplied?.surveyType || undefined,
        };
      }

      return {
        locationTypes: [],
        locations: {
          countries: [],
          regions: [],
          surveyAreas: [],
        },
        litterTypes: {
          materials: [],
          products: [],
        },
        dateRange: null,
        surveyType: filtersApplied?.surveyType || undefined,
      };
  }
}

function isComparisonApplied(filtersApplied: IFilter): boolean {
  return (
    isValidObject(filtersApplied) &&
    isValidObject(filtersApplied.comparisonData) &&
    isValidObject(filtersApplied.comparisonData.comparisonDataSet)
  );
}

function convertToApiLocationTypeFilter(
  locationType: LocationType[]
): number[] {
  if (isValidArray(locationType)) {
    return locationType.map((item) => LocationTypeValues.findIndex(element => element.label === item.label));
  }
  return [];
}

function convertToApiLocationsFilter(
  location: LocationFilter
): {
  countries: number[];
  regions: number[];
  surveyAreas: number[];
} {
  if (isValidObject(location)) {
    return {
      countries: convertToApiCountriesFilter(location.countries),
      regions: convertToApiRegionsFilter(location.regions),
      surveyAreas: convertToApiSurveyAreasFilter(location.surveyAreas),
    };
  }
  return {
    countries: [],
    regions: [],
    surveyAreas: [],
  };
}

function convertToApiLitterTypeFilter(
  litterType: LitterTypeFilter
): {
  materials: number[];
  products: number[];
} {
  if (isValidObject(litterType)) {
    return {
      materials: convertToApiMaterialsFilters(litterType.materials),
      products: convertToApiProductsFilters(litterType.products),
    };
  }
  return {
    materials: [],
    products: [],
  };
}

function convertToApiDateRange(
  dateRange: DateFilter
): {
  startDate: string;
  endDate: string;
} {
  if (
    isValidObject(dateRange) &&
    isValidObject(dateRange.startDate) &&
    dateRange.startDate.isValid() &&
    isValidObject(dateRange.endDate) &&
    dateRange.endDate.isValid()
  ) {
    return {
      startDate: dateRange.startDate.format("YYYY-MM-DD"),
      endDate: dateRange.endDate.format("YYYY-MM-DD"),
    };
  }
  return null;
}

function convertToApiMaterialsFilters(
  materials: {
    id: number;
    name: string;
  }[]
): number[] {
  if (isValidArray(materials)) {
    return materials.map((item) => item.id);
  }
  return [];
}

function convertToApiProductsFilters(
  products: {
    id: number;
    name: string;
  }[]
): number[] {
  if (isValidArray(products)) {
    return products.map((item) => item.id);
  }
  return [];
}

function convertToApiCountriesFilter(
  countries: {
    id: number;
    name: string;
  }[]
): number[] {
  if (isValidArray(countries)) {
    return countries.map((item) => item.id);
  }
  return [];
}

function convertToApiRegionsFilter(
  regions: {
    id: number;
    name: string;
  }[]
): number[] {
  if (isValidArray(regions)) {
    return regions.map((item) => item.id);
  }
  return [];
}

function convertToApiSurveyAreasFilter(
  surveyAreas: {
    id: number;
    name: string;
  }[]
): number[] {
  if (isValidArray(surveyAreas)) {
    return surveyAreas.map((item) => item.id);
  }
  return [];
}
