import React, { useRef, useEffect, useState } from "react";
import "./CalendarDropdown.scss";
import DownArrow from "../../../assets/down_arrow.svg";
import UpArrow from "../../../assets/up_arrow.svg";
import { CustomCalendar } from "../CustomCalendar/CustomCalendar";

type CalendarDropdownProps = {
  defaultTitle: string;
  selectedDate: moment.Moment | undefined; //TODO: remove nullability
  onDateSelected: (chosenDate: moment.Moment | undefined) => void;
  maxDate: moment.Moment;
  minDate?: moment.Moment;
  isModal?: boolean;
  isDisabled?: boolean;
};

function getCalendarTitle(
  defaultTitle: string,
  selectedDate?: moment.Moment
): string {
  if (
    selectedDate !== null &&
    selectedDate !== undefined &&
    selectedDate.isValid()
  ) {
    return selectedDate.format("ll");
  }
  return defaultTitle;
}

export const CalendarDropdown: React.FC<CalendarDropdownProps> = ({
  defaultTitle,
  selectedDate,
  onDateSelected,
  isModal,
  maxDate,
  isDisabled,
  minDate
}) => {
  const containerRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  useOnClickOutside(containerRef, () => setIsOpen(false));

  return (
    <div className={`calendar_dropdown ${isDisabled ? 'calendar_dropdown_is_disabled' : ''}`} ref={containerRef}>
      <button
        className={`${isOpen ? "title_container__open" : "title_container"} ${
          isModal ? "is_modal" : ""
        }`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <p>{getCalendarTitle(defaultTitle, selectedDate)}</p>
        <img src={isOpen ? UpArrow : DownArrow} alt="Arrow" />
      </button>
      {isOpen ? (
        <div
          className={`calendar_dropdown_container ${isModal ? "is_modal" : ""}
        `}
        >
          <CustomCalendar
            onDateSelected={onDateSelected}
            selectedDate={selectedDate}
            maxDate={maxDate}
            minDate={minDate}
          />
        </div>
      ) : null}
    </div>
  );
};

//Custom hook for listening to outside click events
function useOnClickOutside(ref: any, handler: any) {
  useEffect(() => {
    const listener = (event: any) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      handler(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
}
